import React, { Fragment } from 'react';

import { Table } from 'reactstrap';

import { moneyConvert } from '../../utils/moneyConvert';

function BalanceByAccounts({ balance }) {

  const getCashflowTotal = groups => {
    return groups.reduce((prev, current) => prev + getGroupTotal(current.accounts), 0);
  };

  const getGroupTotal = (accounts) => {
    return accounts.reduce((prev, current) => prev + current.total, 0);
  };

  return (
    <Table bordered className='mt-5'>
      <tbody>
        <tr style={{ backgroundColor: '#eee' }}>
          <th className='text-center' colSpan={2}>Saldo bancário detalhado por conta bancária</th>
        </tr>

        <tr>
          <th>Descrição</th>
          <th style={{ width: '20%' }} className="text-center">Saldo atual</th>
        </tr>

        {balance.map(({ cashflowId, cashflowName, groups }) => (
          <Fragment key={cashflowId}>
            <tr>
              <th style={{ paddingLeft: '40px', backgroundColor: '#f7f7f7' }}>{cashflowName}</th>
              <td style={{ width: '20%', backgroundColor: '#f7f7f7' }} className="text-center">{moneyConvert(getCashflowTotal(groups))}</td>
            </tr>

            {groups.map(({ groupId, groupName, accounts }) => (
              <Fragment key={groupId}>
                <tr>
                  <th style={{ paddingLeft: '60px' }}>{groupName}</th>
                  <td style={{ width: '20%' }} className="text-center">{moneyConvert(getGroupTotal(accounts))}</td>
                </tr>

                {accounts.map(({ id, accountName, total }) => (
                  <tr key={id}>
                    <td style={{ paddingLeft: '80px' }}>{accountName}</td>
                    <td style={{ width: '20%' }} className="text-center">{moneyConvert(total)}</td>
                  </tr>
                ))}
              </Fragment>
            ))}
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
}

export default BalanceByAccounts;