import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import sweetalert2 from "sweetalert2";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  CardHeader,
  CardTitle,
  CardText,
} from "reactstrap";

import { Typeahead } from "react-bootstrap-typeahead";
import { format, parseISO, compareAsc } from "date-fns";
import { ThumbsDown, Edit, List, Delete } from "react-feather";
import Breadcrumb from "../../layout/breadcrumb";
import Print from "../../components/Print";
import {
  getTransacoes,
  addTransacao,
  editTransacao,
  deleteTransacao,
  setTransactionsLastSearchPeriod,
} from "../../redux/actions";

import api from "../../services/api";

import "react-datepicker/dist/react-datepicker.css";

const Transacoes = ({
  tiposFluxo,
  tiposTransacoes,
  blocos,
  eventos,
  transacoes,
  dadosTransacoes,
  loadingTransacoes,
  getTransacoes,
  addTransacao,
  notAuthorized,
  previsto,
  editTransacao,
  deleteTransacao,
  previsoes,
  evento,
  user,
  contas,
  grupos,
  fornecedores,
  setTransactionsLastSearchPeriod,
  initialDate,
  endDate
}) => {
  const { tipo } = useParams();

  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(null);
  const [view, setView] = useState(null);
  const [previstoOptions, setPrevistoOptions] = useState([]);

  const [search, setSearch] = useState({
    evento: "",
    dataInicio: format(new Date(), "yyyy-MM-dd"),
    dataFim: format(new Date(), "yyyy-MM-dd"),
    conta: "",
    fornecedor_id: "",
    valor: 0,
    grupo: "",
  });

  const printRef = useRef();

  const [fields, setFields] = useState({
    tipos_transacoes_id: "",
    transacoes_eventos_id: "",
    transacoes_blocos_id: null,
    valor: 0,
    data: format(new Date(), "yyyy-MM-dd"),
    observacao: "",
    tipos_fluxos_id: tipo,
    previsao_id: null,
    conta_id: null,
    transacoes_grupo_id: null,
    fornecedor_id: "",
  });

  const fluxo = tiposFluxo.find((t) => t.id == tipo)?.nome;

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Relatório ${fluxo} ${user?.cidade?.name}`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  useEffect(() => {
    getTransacoes({
      tipo,
      dataInicio: initialDate,
      dataFim: endDate,
    });

    setFields({
      tipos_transacoes_id: "",
      transacoes_eventos_id: "",
      transacoes_blocos_id: null,
      valor: 0,
      data: format(new Date(), "yyyy-MM-dd"),
      observacao: "",
      tipos_fluxos_id: tipo,
      transacoes_grupo_id: null,
      conta_id: null,
      fornecedor_id: "",
    });

    setSearch({
      dataInicio: initialDate,
      dataFim: endDate,
      evento: "",
      fornecedor_id: "",
      valor: 0,
    });
  }, [tipo]);

  useEffect(() => {
    if (!!fields.transacoes_eventos_id && !!fields.data) {
      getPrevisoesOptions({
        transacoes_eventos_id: fields.transacoes_eventos_id,
        mes: format(parseISO(fields.data), "MM"),
        ano: format(parseISO(fields.data), "yyyy"),
      });
    } else {
      setPrevistoOptions([]);
    }
  }, [fields]);

  useEffect(() => {
    if (!add) {
      setPrevistoOptions([]);

      setFields({
        tipos_transacoes_id: "",
        transacoes_eventos_id: "",
        transacoes_blocos_id: null,
        valor: 0,
        data: format(new Date(), "yyyy-MM-dd"),
        observacao: "",
        tipos_fluxos_id: tipo,
        transacoes_grupo_id: null,
        conta_id: null,
        fornecedor_id: "",
      });
    }
  }, [add]);

  const getPrevisoesOptions = async (params) => {
    try {
      const { data } = await api.get("/api/auth/previsoes/getPrevisaoOptions", {
        params,
      });

      setPrevistoOptions(data);
    } catch (err) { }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      data,
      tipos_transacoes_id,
      transacoes_blocos_id,
      transacoes_eventos_id,
      valor,
      conta_id,
      fornecedor_id,
    } = fields;

    if (tipo == 2) {
      const conditions =
        tipos_transacoes_id == 1
          ? !!data &&
          !!tipos_transacoes_id &&
          !!transacoes_blocos_id &&
          !!transacoes_eventos_id &&
          !!valor &&
          !!conta_id
          : !!data &&
          !!tipos_transacoes_id &&
          !!transacoes_eventos_id &&
          !!valor &&
          !!conta_id &&
          fornecedor_id;

      if (conditions) {
        addTransacao(fields);

        setFields({
          tipos_transacoes_id: "",
          transacoes_eventos_id: "",
          transacoes_blocos_id: null,
          valor: 0,
          data: format(new Date(), "yyyy-MM-dd"),
          observacao: "",
          tipos_fluxos_id: tipo,
          conta_id: null,
          transacoes_grupo_id: null,
          fornecedor_id: "",
        });

        setAdd(false);
      } else {
        toast.error("Preencha todos os campos para prosseguir", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      const conditions =
        tipos_transacoes_id == 1
          ? !!data &&
          !!tipos_transacoes_id &&
          !!transacoes_eventos_id &&
          !!valor &&
          !!conta_id
          : !!data &&
          !!tipos_transacoes_id &&
          !!transacoes_eventos_id &&
          !!valor &&
          !!conta_id &&
          fornecedor_id;

      if (conditions) {
        addTransacao(fields);

        setFields({
          tipos_transacoes_id: "",
          transacoes_eventos_id: "",
          transacoes_blocos_id: null,
          valor: 0,
          data: format(new Date(), "yyyy-MM-dd"),
          observacao: "",
          tipos_fluxos_id: tipo,
          conta_id: null,
          transacoes_grupo_id: null,
          fornecedor_id: "",
        });

        setAdd(false);
      } else {
        toast.error("Preencha todos os campos para prosseguir", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const filtroEventos = eventos.filter((e) => {
    if (tipo != 2) {
      return (
        e.tipos_transacoes_id == fields.tipos_transacoes_id &&
        e.transacoes_grupo_id == fields.transacoes_grupo_id &&
        e.tipos_fluxos_id == tipo
      );
    } else {
      if (fields.tipos_transacoes_id == 1)
        return (
          e.tipos_transacoes_id == fields.tipos_transacoes_id &&
          e.transacoes_grupo_id == fields.transacoes_grupo_id &&
          e.tipos_fluxos_id == tipo
        );
      else
        return (
          e.tipos_transacoes_id == fields.tipos_transacoes_id &&
          e.transacoes_grupo_id == fields.transacoes_grupo_id &&
          e.tipos_fluxos_id == tipo
        );
    }
  });

  const optionsGrupos = grupos.filter(
    ({ tipos_fluxos_id, tipos_transacoes_id }) =>
      tipos_fluxos_id == tipo &&
      tipos_transacoes_id == fields.tipos_transacoes_id
  );

  if (loadingTransacoes)
    return (
      <Fragment>
        <Breadcrumb parent="Fluxo de Caixa" title={fluxo} />
        <Container fluid={true}></Container>
        <div className="loader-box">
          <div className="loader-1"></div>
        </div>
      </Fragment>
    );

  const resumo1ReceitaPrevista =
    parseFloat(previsto.find((p) => p.tipos_transacoes_id == 1)?.total) || 0;
  const resumo1DespesaPrevista =
    previsto
      .filter((p) => p.tipos_transacoes_id != 1)
      .reduce((p, c) => p + parseFloat(c.total), 0) || 0;

  const resumo1Receita =
    parseFloat(
      dadosTransacoes.find((p) => p.tipos_transacoes_id == 1)?.total
    ) || 0;
  const resumo1Despesa =
    dadosTransacoes
      .filter((p) => p.tipos_transacoes_id != 1)
      .reduce((p, c) => p + parseFloat(c.total), 0) || 0;

  let totalPrevistoSaudeReceitas = 0,
    totalRealizadoSaudeReceitas = 0;
  let totalPrevistoSaudeDespesas = 0,
    totalRealizadoSaudeDespesas = 0;
  previsto.forEach((p) => {
    if (p.tipos_transacoes_id == 1) {
      totalPrevistoSaudeReceitas += parseFloat(p.total);
    } else {
      totalPrevistoSaudeDespesas += parseFloat(p.total);
    }
  });

  dadosTransacoes.forEach((d) => {
    if (d.tipos_transacoes_id == 1) {
      totalRealizadoSaudeReceitas += parseFloat(d.total);
    } else {
      totalRealizadoSaudeDespesas += parseFloat(d.total);
    }
  });

  // const previsoesRealizadasIds = transacoes.map(t => t.previsao_id);

  const listTransacoes = [
    ...transacoes,
    // ...previsoes.filter(p => !previsoesRealizadasIds.includes(p.id))
  ].sort((a, b) => compareAsc(parseISO(a.data), parseISO(b.data)));

  const searchEventos = eventos.filter(
    (e) => e.tipos_fluxos_id == tipo && e.transacoes_grupo_id == search.grupo
  );

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Fluxo de Caixa" title={fluxo} />
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                if (!!search.dataInicio && !!search.dataFim) {
                  if (
                    compareAsc(
                      parseISO(search.dataInicio),
                      parseISO(search.dataFim)
                    ) > 0
                  ) {
                    toast.error("Data Inicial não pode ser maior que a final", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  } else {
                    getTransacoes({
                      tipo,
                      ...search,
                    });

                    setTransactionsLastSearchPeriod({
                      initialDate: search.dataInicio,
                      endDate: search.dataFim,
                    });
                  }
                } else {
                  toast.error("Selecione o período", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="tipo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o grupo"
                        options={grupos.filter(
                          ({ tipos_fluxos_id }) => tipos_fluxos_id == tipo
                        )}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...fields,
                              grupo: data[0].id,
                              evento: null,
                            });
                          } else {
                            setFields({
                              ...fields,
                              grupo: null,
                              evento: null,
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="tipo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o subgrupo"
                        options={searchEventos}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              evento: data[0]?.id,
                            });
                          } else {
                            setSearch({
                              ...search,
                              evento: "",
                            });
                          }
                        }}
                        selected={searchEventos.filter(
                          ({ id }) => id == search.evento
                        )}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="conta"
                        labelKey="conta"
                        clearButton
                        placeholder="Selecione a conta"
                        options={contas.map(({ conta, ...c }) => ({
                          ...c,
                          conta: String(conta),
                        }))}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              conta: data[0]?.id,
                            });
                          } else {
                            setSearch({
                              ...search,
                              conta: "",
                            });
                          }
                        }}
                        selected={contas.filter(({ id }) => id == search.conta)}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="fornecedor"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o fornecedor"
                        options={fornecedores}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              fornecedor_id: data[0]?.id,
                            });
                          } else {
                            setSearch({
                              ...search,
                              fornecedor_id: "",
                            });
                          }
                        }}
                        selected={fornecedores.filter(
                          ({ id }) => id == search.fornecedor_id
                        )}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Inicial"
                        selected={
                          !!search.dataInicio ? parseISO(search.dataInicio) : ""
                        }
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataInicio: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Final"
                        selected={
                          !!search.dataFim ? parseISO(search.dataFim) : ""
                        }
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataFim: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="6" className="mt-3">
                      <NumberFormat
                        placeholder="Informe o valor"
                        thousandSeparator="."
                        customInput={Input}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        value={parseFloat(search?.valor)}
                        onValueChange={({ floatValue }) => {
                          setSearch({
                            ...search,
                            valor: floatValue || 0,
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12" style={{ marginTop: 10 }}>
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Button
              color="primary"
              className="mb-5 float-right"
              onClick={handlePrint}
            >
              Imprimir
            </Button>
          </Col>
        </Row>

        <Row>
          {notAuthorized && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: 300,
                width: "100%",
              }}
            >
              <ThumbsDown color="red" />

              <h4 style={{ marginTop: 10 }}>Usuário não autorizado</h4>
            </div>
          )}

          {!notAuthorized && (
            <Print ref={printRef}>
              <Card id="toPrint" className="font-arial-rlt">
                <CardBody>
                  <Row>
                    <Col
                      sm="12"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginBottom: 20,
                      }}
                    >
                      <img
                        className="img-fluid for-light"
                        src={require("../../assets/images/logo/logo.jpg")}
                        alt=""
                        style={{
                          width: "130px",
                          height: "130px",
                          objectFit: "contain",
                        }}
                      />

                      <h5>{user?.cidade?.name}</h5>
                    </Col>
                    <Col sm="12">
                      <h6 style={{ textAlign: "center" }}>
                        Resumo Geral - {fluxo}{" "}
                        {!!evento
                          ? `- ${eventos.find((e) => e.id == evento)?.nome}`
                          : ""}
                      </h6>

                      {tipo == 2 && (
                        <Fragment>
                          <Table bordered style={{ marginTop: 15 }}>
                            <thead>
                              <tr>
                                <th>
                                  Resumo Geral por Bloco de Financiamento -
                                  Receitas
                                </th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  Previsto
                                </th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  Realizado
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {blocos.map((b) => (
                                <tr key={b.id}>
                                  <td>{b.nome}</td>
                                  <td style={{ textAlign: "center" }}>
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(
                                      previsto.find(
                                        (p) =>
                                          p.tipos_transacoes_id == 1 &&
                                          p.transacoes_blocos_id == b.id
                                      )?.total || 0
                                    )}
                                  </td>
                                  <td style={{ textAlign: "center" }}>
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(
                                      dadosTransacoes.find(
                                        (p) =>
                                          p.tipos_transacoes_id == 1 &&
                                          p.transacoes_blocos_id == b.id
                                      )?.total || 0
                                    )}
                                  </td>
                                </tr>
                              ))}

                              <tr>
                                <th>Total Geral</th>
                                <th style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(totalPrevistoSaudeReceitas)}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(totalRealizadoSaudeReceitas)}
                                </th>
                              </tr>
                            </tbody>
                          </Table>

                          <Table bordered style={{ marginTop: 15 }}>
                            <thead>
                              <tr>
                                <th>Resumo do período</th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  Previsto
                                </th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  Realizado
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td>Saldo Geral - Receitas</td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(totalPrevistoSaudeReceitas)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(totalRealizadoSaudeReceitas)}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  Saldo Geral - Despesas/Investimentos/Provisões
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(totalPrevistoSaudeDespesas)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(totalRealizadoSaudeDespesas)}
                                </td>
                              </tr>

                              <tr>
                                <th>Resultado do período</th>
                                <th style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(
                                    totalPrevistoSaudeReceitas -
                                    totalPrevistoSaudeDespesas
                                  )}
                                </th>
                                <th style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(
                                    totalRealizadoSaudeReceitas -
                                    totalRealizadoSaudeDespesas
                                  )}
                                </th>
                              </tr>
                            </tbody>
                          </Table>
                        </Fragment>
                      )}

                      {tipo != 2 && (
                        <Fragment>
                          <Table bordered style={{ marginTop: 15 }}>
                            <tbody>
                              <tr>
                                <th>Descrição</th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  Previsto
                                </th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  Realizado
                                </th>
                              </tr>

                              <tr>
                                <td>Receita Total</td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(resumo1ReceitaPrevista)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(resumo1Receita)}
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          <Table bordered style={{ marginTop: 15 }}>
                            <tbody>
                              <tr>
                                <th>Descrição</th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  Previsto
                                </th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  Realizado
                                </th>
                              </tr>

                              <tr>
                                <td>Despesas/Investimentos/Provisões Total</td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(resumo1DespesaPrevista)}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(resumo1Despesa)}
                                </td>
                              </tr>
                            </tbody>
                          </Table>

                          <Table bordered style={{ marginTop: 15 }}>
                            <tbody>
                              <tr>
                                <th>Resultado do Mês</th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(
                                    resumo1ReceitaPrevista -
                                    resumo1DespesaPrevista
                                  )}
                                </th>
                                <th style={{ textAlign: "center", width: 200 }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(resumo1Receita - resumo1Despesa)}
                                </th>
                              </tr>
                            </tbody>
                          </Table>
                        </Fragment>
                      )}
                    </Col>

                    <Col sm="12">
                      <Table bordered style={{ marginTop: 20 }}>
                        <tbody>
                          <tr>
                            <th colSpan={6} style={{ textAlign: "center" }}>
                              Receitas
                            </th>
                          </tr>
                          <tr>
                            <th>Recurso</th>
                            <th style={{ textAlign: "center" }}>
                              Data Realizado
                            </th>
                            <th style={{ textAlign: "center" }}>
                              Valor Realizado
                            </th>
                            <th>Conta</th>
                            {user.cidade_id == 4491 && <th>Fornecedor</th>}
                            <th
                              className="noPrint"
                              style={{ width: "10%", textAlign: "center" }}
                            >
                              Ações
                            </th>
                          </tr>

                          {listTransacoes
                            .filter((t) => t.tipos_transacoes_id == 1)
                            .map((t, key) => (
                              <tr key={key}>
                                <td>{t.evento?.nome}</td>
                                <td style={{ textAlign: "center" }}>
                                  {format(parseISO(t.data), "dd/MM/yyyy")}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(parseFloat(t.valor))}
                                </td>
                                <td>{t.conta?.conta}</td>
                                {user.cidade_id == 4491 && <td>{t.fornecedor?.nome || 'Sem fornecedor'}</td>}
                                <td
                                  className="noPrint"
                                  style={{ textAlign: "center", width: "15%" }}
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 5,
                                    }}
                                    onClick={() => {
                                      setView(t);
                                    }}
                                  >
                                    <List size={18} />
                                  </span>

                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 5,
                                    }}
                                    onClick={() => {
                                      setEdit(t);
                                    }}
                                  >
                                    <Edit size={18} />
                                  </span>

                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 5,
                                    }}
                                    onClick={async () => {
                                      const confirm = await sweetalert2.fire({
                                        title: "Deseja realmente excluir?",
                                        icon: "question",
                                        showCancelButton: true,
                                        confirmButtonText: "Sim",
                                        cancelButtonText: "Não",
                                        reverseButtons: true,
                                      });
                                      if (confirm.value) {
                                        deleteTransacao(t);
                                      }
                                    }}
                                  >
                                    <Delete size={18} />
                                  </span>
                                </td>
                              </tr>
                            ))}

                          <tr>
                            <td>-</td>
                            <td style={{ textAlign: "center" }}>-</td>
                            <td style={{ textAlign: "center" }}>
                              {new Intl.NumberFormat("pt-Br", {
                                currency: "BRL",
                                style: "currency",
                              }).format(
                                tipo != 2
                                  ? resumo1Receita
                                  : totalRealizadoSaudeReceitas
                              )}
                            </td>
                            <td style={{ textAlign: "center" }}>-</td>
                            {/* <td style={{ textAlign: 'center' }}>-</td> */}
                            <td
                              className="noPrint"
                              style={{ textAlign: "center" }}
                            >
                              -
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>

                    <Col sm="12">
                      <Table bordered style={{ marginTop: 20 }}>
                        <tbody>
                          <tr>
                            <th colSpan={6} style={{ textAlign: "center" }}>
                              Despesas
                            </th>
                          </tr>
                          <tr>
                            <th>Recurso</th>
                            <th style={{ textAlign: "center" }}>
                              Data Realizado
                            </th>
                            <th style={{ textAlign: "center" }}>
                              Valor Realizado
                            </th>
                            <th>Conta</th>
                            <th>Fornecedor</th>
                            <th
                              className="noPrint"
                              style={{ width: "10%", textAlign: "center" }}
                            >
                              Ações
                            </th>
                          </tr>

                          {listTransacoes
                            .filter((t) => t.tipos_transacoes_id != 1)
                            .map((t, key) => (
                              <tr key={key}>
                                <td>{t.evento?.nome}</td>
                                <td style={{ textAlign: "center" }}>
                                  {format(parseISO(t.data), "dd/MM/yyyy")}
                                </td>
                                <td style={{ textAlign: "center" }}>
                                  {new Intl.NumberFormat("pt-Br", {
                                    currency: "BRL",
                                    style: "currency",
                                  }).format(parseFloat(t.valor))}
                                </td>
                                <td>{t.conta?.conta}</td>
                                <td>{t.fornecedor?.nome}</td>
                                <td
                                  className="noPrint"
                                  style={{ textAlign: "center", width: "15%" }}
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 5,
                                    }}
                                    onClick={() => {
                                      setView(t);
                                    }}
                                  >
                                    <List size={18} />
                                  </span>

                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 5,
                                    }}
                                    onClick={() => {
                                      setEdit(t);
                                    }}
                                  >
                                    <Edit size={18} />
                                  </span>

                                  <span
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: 5,
                                    }}
                                    onClick={async () => {
                                      const confirm = await sweetalert2.fire({
                                        title: "Deseja realmente excluir?",
                                        icon: "question",
                                        showCancelButton: true,
                                        confirmButtonText: "Sim",
                                        cancelButtonText: "Não",
                                        reverseButtons: true,
                                      });
                                      if (confirm.value) {
                                        deleteTransacao(t);
                                      }
                                    }}
                                  >
                                    <Delete size={18} />
                                  </span>
                                </td>
                              </tr>
                            ))}

                          <tr>
                            <td>-</td>
                            <td style={{ textAlign: "center" }}>-</td>
                            <td style={{ textAlign: "center" }}>
                              {new Intl.NumberFormat("pt-Br", {
                                currency: "BRL",
                                style: "currency",
                              }).format(
                                tipo != 2
                                  ? resumo1Despesa
                                  : totalRealizadoSaudeDespesas
                              )}
                            </td>
                            <td style={{ textAlign: "center" }}>-</td>
                            <td style={{ textAlign: "center" }}>-</td>
                            <td
                              className="noPrint"
                              style={{ textAlign: "center" }}
                            >
                              -
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Print>
          )}
        </Row>
      </Container>

      {!!view && (
        <Modal
          size="lg"
          isOpen={true}
          toggle={() => {
            setView(null);
          }}
          backdrop="static"
        >
          <ModalHeader
            toggle={() => {
              setView(null);
            }}
          >
            Editar Transação
          </ModalHeader>

          <ModalBody>
            <Row>
              <Col md="6" xs="12">
                <h6>RECURSO/RECEITA/DESPESA</h6>
                <p>{view?.evento?.nome}</p>
              </Col>

              <Col md="6" xs="12">
                <h6>Tipo de Transação</h6>
                <p>{view?.tipo_transacao?.nome}</p>
              </Col>

              <Col md="6" xs="12">
                <h6>Data Realizado</h6>
                <p>
                  {!!view?.data
                    ? format(parseISO(view.data), "dd/MM/yyyy")
                    : ""}
                </p>
              </Col>

              <Col md="6" xs="12">
                <h6>Valor Realizado</h6>
                <p>
                  {new Intl.NumberFormat("pt-Br", {
                    currency: "BRL",
                    style: "currency",
                  }).format(view?.valor || 0)}
                </p>
              </Col>

              {!!view?.bloco && (
                <Col md="6" xs="12">
                  <h6>Bloco</h6>
                  <p>{view?.bloco?.nome}</p>
                </Col>
              )}

              <Col md="12" xs="12">
                <h6>Observação/Discriminação</h6>
                <p>{view?.observacao || "Não possui observação"}</p>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              color="danger"
              onClick={() => {
                setView(null);
              }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {!!edit && (
        <Modal
          size="lg"
          isOpen={true}
          toggle={() => {
            setEdit(null);
          }}
          backdrop="static"
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();

              if (!!edit?.valor && edit?.data) {
                editTransacao(edit);
                setEdit(null);
              } else {
                toast.error("Preencha todos os campos para prosseguir", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }}
          >
            <ModalHeader
              toggle={() => {
                setEdit(null);
              }}
            >
              Editar Transação
            </ModalHeader>

            <ModalBody>
              <Row>
                <Col md="6" xs="12">
                  <FormGroup>
                    <Label>Valor Realizado</Label>
                    <NumberFormat
                      placeholder="Informe o valor"
                      thousandSeparator="."
                      customInput={Input}
                      decimalSeparator=","
                      decimalScale={2}
                      fixedDecimalScale
                      value={parseFloat(edit?.valor)}
                      onValueChange={({ floatValue }) => {
                        setEdit({
                          ...edit,
                          valor: floatValue || 0,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="6" xs="12">
                  <FormGroup>
                    <Label>Data Realizado</Label>
                    <DatePicker
                      locale={br}
                      autoComplete="off"
                      placeholderText="Selecione a Data"
                      // minDate={new Date(1930, 0, 1)}
                      // maxDate={new Date()}
                      selected={!!edit?.data ? parseISO(edit.data) : null}
                      onCalendarClose={() => { }}
                      dateFormat="dd/MM/yyyy"
                      className="form-control digits"
                      onChange={(selected) => {
                        setEdit({
                          ...edit,
                          data: format(selected, "yyyy-MM-dd"),
                        });
                      }}
                    />
                  </FormGroup>
                </Col>

                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>Observação</Label>
                    <Input
                      type="textarea"
                      name="observacao"
                      value={edit?.observacao || ""}
                      onChange={({ target }) => {
                        const { value } = target;

                        setEdit({
                          ...edit,
                          observacao: value,
                        });
                      }}
                      placeholder="Informe a observação"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </ModalBody>

            <ModalFooter
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                color="danger"
                onClick={() => {
                  setEdit(null);
                }}
              >
                Fechar
              </Button>

              <Button color="primary" disabled={loadingTransacoes}>
                {loadingTransacoes ? "Carregando..." : "Salvar"}
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      )}

      <Modal
        size="lg"
        isOpen={add}
        toggle={() => {
          setAdd(false);
        }}
        backdrop="static"
      >
        <Form onSubmit={handleSubmit}>
          <ModalHeader
            toggle={() => {
              setAdd(false);
            }}
          >
            Incluir Transação
          </ModalHeader>

          <ModalBody>
            <Row>
              <Col md="6" xs="12">
                <FormGroup>
                  <Label>Tipo de Transação</Label>
                  <Typeahead
                    id="transacao"
                    labelKey="nome"
                    clearButton
                    placeholder="Selecione o tipo"
                    options={tiposTransacoes}
                    onChange={(data) => {
                      if (data?.length) {
                        setFields({
                          ...fields,
                          tipos_transacoes_id: data[0].id,
                        });
                      } else {
                        setFields({
                          ...fields,
                          tipos_transacoes_id: null,
                          transacoes_eventos_id: null,
                          transacoes_blocos_id: null,
                        });
                      }
                    }}
                    emptyLabel="Nenhum resultado encontrado"
                  />
                </FormGroup>
              </Col>

              <Col md="6" xs="12">
                <FormGroup>
                  <Label>Data Realizado</Label>
                  <DatePicker
                    locale={br}
                    autoComplete="off"
                    placeholderText="Selecione a Data"
                    // minDate={new Date(1930, 0, 1)}
                    // maxDate={new Date()}
                    selected={!!fields.data ? parseISO(fields.data) : null}
                    onCalendarClose={() => { }}
                    dateFormat="dd/MM/yyyy"
                    className="form-control digits"
                    onChange={(selected) => {
                      setFields({
                        ...fields,
                        data: format(selected, "yyyy-MM-dd"),
                      });
                    }}
                  />
                </FormGroup>
              </Col>

              {tipo == 2 && fields.tipos_transacoes_id == 1 && (
                <Col md="6" xs="12">
                  <FormGroup>
                    <Label>Bloco</Label>
                    <Typeahead
                      id="bloco"
                      labelKey="nome"
                      clearButton
                      placeholder="Selecione o bloco"
                      options={blocos}
                      onChange={(data) => {
                        if (data?.length) {
                          setFields({
                            ...fields,
                            transacoes_blocos_id: data[0].id,
                          });
                        } else {
                          setFields({
                            ...fields,
                            transacoes_blocos_id: null,
                            transacoes_eventos_id:
                              tipo == 2 ? null : fields.transacoes_eventos_id,
                          });

                          if (tipo == 2) {
                            setPrevistoOptions([]);
                          }
                        }
                      }}
                      emptyLabel="Nenhum resultado encontrado"
                    />
                  </FormGroup>
                </Col>
              )}

              <Col md="6" xs="12">
                <FormGroup>
                  <Label>Valor Realizado</Label>
                  <NumberFormat
                    placeholder="Informe o valor"
                    thousandSeparator="."
                    customInput={Input}
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={({ floatValue }) => {
                      setFields({
                        ...fields,
                        valor: floatValue || 0,
                      });
                    }}
                  />
                </FormGroup>
              </Col>

              {!!fields.tipos_transacoes_id && (
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>Grupo</Label>
                    <Typeahead
                      id="tipo"
                      labelKey="nome"
                      clearButton
                      placeholder="Selecione o grupo"
                      options={optionsGrupos}
                      onChange={(data) => {
                        if (data?.length) {
                          setFields({
                            ...fields,
                            transacoes_grupo_id: data[0].id,
                            transacoes_eventos_id: null,
                          });
                        } else {
                          setFields({
                            ...fields,
                            transacoes_grupo_id: null,
                            transacoes_eventos_id: null,
                          });
                        }
                      }}
                      emptyLabel="Nenhum resultado encontrado"
                    />
                  </FormGroup>
                </Col>
              )}

              {!!fields.tipos_transacoes_id && !!fields.transacoes_grupo_id && (
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>Subgrupo</Label>
                    <Typeahead
                      id="tipo"
                      labelKey="nome"
                      clearButton
                      placeholder="Selecione o subgrupo"
                      options={filtroEventos}
                      onChange={(data) => {
                        if (data?.length) {
                          setFields({
                            ...fields,
                            transacoes_eventos_id: data[0].id,
                          });
                        } else {
                          setFields({
                            ...fields,
                            transacoes_eventos_id: null,
                          });
                        }
                      }}
                      emptyLabel="Nenhum resultado encontrado"
                    />
                  </FormGroup>
                </Col>
              )}

              {!!previstoOptions.length && (
                <Fragment>
                  <Col md="6" xs="12">
                    <FormGroup>
                      <Label>Data Previsto</Label>
                      <Typeahead
                        id="tipo"
                        labelKey="data"
                        clearButton
                        placeholder="Selecione o tipo"
                        options={previstoOptions.map((p) => ({
                          ...p,
                          data: format(parseISO(p.data), "dd/MM/yyyy"),
                        }))}
                        onChange={(data) => {
                          if (data?.length) {
                            setFields({
                              ...fields,
                              previsao_id: data[0].id,
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" xs="12">
                    <FormGroup>
                      <Label>Valor Previsto</Label>
                      <NumberFormat
                        placeholder="Informe o valor"
                        thousandSeparator="."
                        disabled
                        customInput={Input}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={({ floatValue }) => { }}
                        value={parseFloat(
                          previstoOptions.find(
                            (p) => p.id == fields.previsao_id
                          )?.valor
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Fragment>
              )}

              <Col md="6" xs="12">
                <FormGroup>
                  <Label>Conta</Label>
                  <Typeahead
                    id="conta"
                    labelKey="nome"
                    clearButton
                    placeholder="Selecione o tipo"
                    options={contas
                      .filter(({ tipos_fluxos_id }) => tipos_fluxos_id == tipo)
                      .map((c) => ({
                        ...c,
                        nome: `${c.conta} - ${c.banco}`,
                      }))}
                    onChange={(data) => {
                      if (data?.length) {
                        setFields({
                          ...fields,
                          conta_id: data[0].id,
                        });
                      } else {
                        setFields({
                          ...fields,
                          conta_id: null,
                        });
                      }
                    }}
                    emptyLabel="Nenhum resultado encontrado"
                  />
                </FormGroup>
              </Col>

              {fields.tipos_transacoes_id != 1 && (
                <Col md="6" xs="12">
                  <FormGroup>
                    <Label>Fornecedor</Label>
                    <Typeahead
                      id="fornecedor"
                      labelKey="nome"
                      clearButton
                      placeholder="Selecione o fornecedor"
                      options={fornecedores}
                      onChange={(data) => {
                        if (data?.length) {
                          setFields({
                            ...fields,
                            fornecedor_id: data[0].id,
                          });
                        } else {
                          setFields({
                            ...fields,
                            fornecedor_id: null,
                          });
                        }
                      }}
                      emptyLabel="Nenhum resultado encontrado"
                    />
                  </FormGroup>
                </Col>
              )}

              <Col md="12" xs="12">
                <FormGroup>
                  <Label>Observação/Discriminação</Label>
                  <Input
                    type="textarea"
                    name="observacao"
                    value={fields.observacao}
                    onChange={handleChange}
                    placeholder="Informe a observação"
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              color="danger"
              onClick={() => {
                setAdd(false);
              }}
            >
              Fechar
            </Button>

            <Button color="primary" disabled={loadingTransacoes}>
              {loadingTransacoes ? "Carregando..." : "Salvar"}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = ({
  Transacoes,
  Auth,
  Contas,
  Consolidado,
  Fornecedores,
  TransactionsLastSearchPeriod
}) => {
  const {
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    transacoes,
    loadingTransacoes,
    dadosTransacoes,
    notAuthorized,
    previsto,
    previsoes,
    evento,
  } = Transacoes;

  const { loadingFornecedores, fornecedores } = Fornecedores;
  const { user } = Auth;
  const { contas } = Contas;
  const { grupos } = Consolidado;

  const { initialDate, endDate } = TransactionsLastSearchPeriod;

  return {
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    transacoes,
    loadingTransacoes,
    dadosTransacoes,
    notAuthorized,
    previsto,
    previsoes,
    evento,
    user,
    contas,
    grupos,
    fornecedores,
    initialDate,
    endDate
  };
};

export default connect(mapStateToProps, {
  getTransacoes,
  addTransacao,
  editTransacao,
  deleteTransacao,
  setTransactionsLastSearchPeriod
})(Transacoes);
