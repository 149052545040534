import { all, takeEvery, fork, call, put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify';

import {
    GET_MENSAGENS,
    ADD_MENSAGENS,
    EDIT_MENSAGENS,
    DELETE_MENSAGENS
} from '../actionTypes'

import {
    setMensagens
} from '../actions'

import api from '../../services/api'

function* sagaGetMensagens() {
    try {
        const data = yield call(apiGetMensagens)

        yield put(setMensagens(data))
    } catch (err) {
        console.log(err)
        yield put(setMensagens())
    }
}

const apiGetMensagens = async () => {
    const { data } = await api.get('/api/auth/mensagens')

    return data
}

function* sagaAddMensagens({ payload }) {
    const { mensagens } = yield select(state => state.Mensagens)

    try {
        const data = yield call(apiAddMensagens, payload)

        if (data.ok) {
            yield put(setMensagens([
                ...mensagens,
                data.mensagem
            ]))

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            yield put(setMensagens())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } catch (err) {
        yield put(setMensagens())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiAddMensagens = async payload => {
    const { data } = await api.post('/api/auth/mensagens/add', payload)

    return data
}

function* sagaEditMensagens({ payload }) {
    const { mensagens } = yield select(state => state.Mensagens)

    try {
        const data = yield call(apiEditMensagens, payload)

        if (data.ok) {
            yield put(
                setMensagens(mensagens.map(
                    g => {
                        if (g.id == data.mensagem.id) {
                            return data.mensagem
                        } else {
                            return g
                        }
                    }
                ))
            )

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            yield put(setMensagens())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } catch (err) {
        yield put(setMensagens())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiEditMensagens = async payload => {
    const { data } = await api.put('/api/auth/mensagens/edit', payload)

    return data
}

function* sagaDeleteMensagens({ payload }) {
    const { mensagens } = yield select(state => state.Mensagens)

    try {
        const data = yield call(apiDeleteMensagens, payload)

        if (data.ok) {
            yield put(
                setMensagens(mensagens.filter(
                    ({ id }) => id != payload
                ))
            )

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            yield put(setMensagens())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } catch (err) {
        yield put(setMensagens())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiDeleteMensagens = async payload => {
    const { data } = await api.delete(`/api/auth/mensagens/delete/${payload}`)

    return data
}

function* watchGetMensagens() {
    yield takeEvery(GET_MENSAGENS, sagaGetMensagens)
}

function* watchAddMensagens() {
    yield takeEvery(ADD_MENSAGENS, sagaAddMensagens)
}

function* watchEditMensagens() {
    yield takeEvery(EDIT_MENSAGENS, sagaEditMensagens)
}

function* watchDeleteMensagens() {
    yield takeEvery(DELETE_MENSAGENS, sagaDeleteMensagens)
}

export default function* rootSaga() {
    yield all([
        fork(watchGetMensagens),
        fork(watchAddMensagens),
        fork(watchEditMensagens),
        fork(watchDeleteMensagens)
    ])
}