import React, { useState } from 'react';
import { Container, Row, Col, CardBody, Form, FormGroup, Input, Label, Button } from 'reactstrap'
import Register from './register';
import { loginUser } from "../../redux/actions";
import { connect } from "react-redux";
const Login = ({ loginUser, loading }) => {
  const [fields, setFields] = useState({ email: null, password: null });
  const toggleform = () => {
    document.querySelector('.cont').classList.toggle('s--signup');
  }
  const submitLogin = e => {
    e.preventDefault();
    loginUser({ fields })
  }
  const handleChange = e => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  }

  return (
    <div className="page-wrapper">
      <Container fluid={true} className="p-0">
        <div className="authentication-main mt-0">
          <Row>
            <Col md="12">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Form
                  // className="theme-form"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    width: 350
                  }}
                  onSubmit={submitLogin}
                >
                  <img
                    src={require("../../assets/images/logo/logo.jpg")}
                    alt=""
                    style={{
                      width: 250,
                      height: 250,
                      objectFit: 'contain',
                      alignSelf: 'center'
                    }}
                  />
                  <FormGroup>
                    <Label className="col-form-label pt-0">E-mail</Label>
                    <Input className="form-control" type="email" name="email" onChange={handleChange} required="" />
                  </FormGroup>
                  <FormGroup>
                    <Label className="col-form-label">Senha</Label>
                    <Input className="form-control" type="password" onChange={handleChange} name="password" required="" />
                  </FormGroup>
                  <FormGroup className="form-row mt-3 mb-0">
                    <Button color="primary btn-block" disabled={loading}>{loading ? 'Processando...' : 'ENTRAR'}</Button>
                  </FormGroup>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )

  return (
    <div className="page-wrapper">
      <Container fluid={true} className="p-0">
        <div className="authentication-main mt-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright auth-bg">
                <div className="authentication-box">
                  <div className="mt-4">
                    <CardBody className="p-0">
                      <div className="cont text-center">
                        <div>
                          <Form className="theme-form" onSubmit={submitLogin}>
                            <h4>LOGIN</h4>
                            <h6>Informe seu E-mail e Senha</h6>
                            <FormGroup>
                              <Label className="col-form-label pt-0">E-mail</Label>
                              <Input className="form-control" type="email" name="email" onChange={handleChange} required="" />
                            </FormGroup>
                            <FormGroup>
                              <Label className="col-form-label">Senha</Label>
                              <Input className="form-control" type="password" onChange={handleChange} name="password" required="" />
                            </FormGroup>
                            <div className="checkbox p-0" style={{ opacity: 0 }}>
                              <Input id="checkbox1" type="checkbox" />
                              <Label for="checkbox1">Manter Conectado</Label>
                            </div>
                            <FormGroup className="form-row mt-3 mb-0">
                              <Button color="primary btn-block" disabled={loading}>{loading ? 'Processando...' : 'ENTRAR'}</Button>
                            </FormGroup>
                          </Form>
                        </div>
                        <div className="sub-cont">
                          <div className="img">
                            <div className="img__text m--up">
                              <h2>Novo Usuário?</h2>
                              <p>Se você é um novo usuário, Cadastre-se clicando no botão abaixo.</p>
                            </div>
                            <div className="img__text m--in">
                              <h2>Já é um de nós?</h2>
                              <p>Se você já possiu conta clique no botão abaixo para realizar o login.</p>
                            </div>
                            <div className="img__btn" style={{ bottom: 30 }} onClick={toggleform}><span className="m--up">Cadastrar-se</span><span className="m--in">Fazer Login</span></div>
                          </div>
                          <div>
                            <Register />
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
const mapStateToProps = ({ Auth }) => {
  const { loading } = Auth;
  return { loading }
}

export default connect(mapStateToProps, {
  loginUser
})(Login)
