import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';

import { Typeahead } from 'react-bootstrap-typeahead';
import { format } from 'date-fns'

import Breadcrumb from '../../layout/breadcrumb';

import { desvincularGrupos } from '../../redux/actions';

const Desvincular = ({
    item = null,
    onDismiss = () => { },
    user,
    loadingGrupos,
    grupos,
    tiposFluxo,
    tiposTransacoes,
    eventos,
    desvincularGrupos
}) => {

    const [fields, setFields] = useState({
        subs: [],
    });

    useEffect(() => {
        if (!item) {
            setFields({
                subs: [],
            });
        }
    }, [item]);

    const handleSubmit = e => {
        e.preventDefault();

        if (!!fields.subs.length) {

            desvincularGrupos(fields);
            onDismiss();
        }
    }

    const list = eventos
        .filter(({ transacoes_grupo_id, tipos_fluxos_id, tipos_transacoes_id }) =>
            transacoes_grupo_id == item?.id &&
            tipos_fluxos_id == item?.tipos_fluxos_id &&
            tipos_transacoes_id == item?.tipos_transacoes_id
        )
        .map(
            e => ({
                ...e,
                nome: item?.tipos_fluxos_id != 2
                    ? e.nome
                    : `${e.nome} - ${e.bloco?.nome}`
            })
        );

    return (
        <Modal
            size="lg"
            isOpen={!!item}
            toggle={onDismiss}
            backdrop="static"
        >
            <Form
                onSubmit={handleSubmit}
            >
                <ModalHeader
                    toggle={onDismiss}
                >
                    Desvincular Sub-grupos
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col md="12" xs="12">
                            <FormGroup>
                                <Label>Sub-grupos</Label>
                                <Typeahead
                                    id="groups"
                                    labelKey="nome"
                                    clearButton
                                    placeholder="Selecione os sub-grupos"
                                    options={list}
                                    onChange={data => {
                                        setFields({
                                            subs: data.map(({ id }) => id)
                                        });
                                    }}
                                    emptyLabel="Nenhum resultado encontrado"
                                    multiple
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        color="danger"
                        onClick={onDismiss}
                    >
                        Fechar
                    </Button>

                    <Button
                        color="primary"
                        disabled={loadingGrupos}
                    >
                        {loadingGrupos ? 'Carregando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Consolidado, Transacoes, Auth }) => {
    const { loadingGrupos, grupos } = Consolidado;
    const { user } = Auth;

    const {
        tiposFluxo,
        tiposTransacoes,
        eventos,
    } = Transacoes;

    return {
        user,
        loadingGrupos,
        grupos,
        tiposFluxo,
        tiposTransacoes,
        eventos,
    };
}

export default connect(mapStateToProps, {
    desvincularGrupos
})(Desvincular);