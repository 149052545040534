import { format, parseISO, compareAsc } from "date-fns";

export const toMoneyFormat = (valor, negative = false) => {
  return new Intl.NumberFormat("pt-Br", {
    currency: "BRL",
    style: "currency",
  }).format(negative ? parseFloat(valor) * -1 : parseFloat(valor));
};

const tiposTransferencias = {
  E: "Empréstimo",
  T: "Transferência",
};

export const adaptReceitas = (receitas = []) => {
  return receitas.map((receita) => ({
    id: receita.id,
    descricao: receita.evento?.nome,
    valor: toMoneyFormat(receita.valor),
    valor_float: parseFloat(receita.valor),
    data: format(parseISO(receita.data), "dd/MM/yyyy"),
    data_db: receita.data
  }));
};

export const adaptDespesas = (despesas = []) => {
  return despesas.map((despesa) => ({
    id: despesa.id,
    descricao: despesa.evento?.nome,
    valor: toMoneyFormat(despesa.valor, true),
    valor_float: parseFloat(despesa.valor) * -1,
    data: format(parseISO(despesa.data), "dd/MM/yyyy"),
    data_db: despesa.data
  }));
};

export const adaptRepasseReceitas = (receitas = []) => {
  return receitas.map((receita) => ({
    id: receita.id,
    descricao: `${tiposTransferencias[receita.tipo]} de ${receita.saida.conta}`,
    valor: toMoneyFormat(receita.valor),
    valor_float: parseFloat(receita.valor),
    data: format(parseISO(receita.data), "dd/MM/yyyy"),
    data_db: receita.data
  }));
};

export const adaptRepasseDespesas = (despesas = []) => {
  return despesas.map((despesa) => ({
    id: despesa.id,
    descricao: `${tiposTransferencias[despesa.tipo]} de ${
      despesa.entrada.conta
    }`,
    valor: toMoneyFormat(despesa.valor, true),
    valor_float: parseFloat(despesa.valor) * -1,
    data: format(parseISO(despesa.data), "dd/MM/yyyy"),
    data_db: despesa.data
  }));
};

export const orderExtratoTransactions = (
  receitas,
  receitasRepasses,
  despesas,
  despesasRepasses
) => {
  return [...receitas, ...receitasRepasses, ...despesas, ...despesasRepasses]
    .map((transaction) => {
      return transaction;
    })
    .sort((a, b) => compareAsc(parseISO(a.data_db), parseISO(b.data_db)));
};
