import {
  ADD_BANK_ACCOUNT_FILES,
  GET_BANK_ACCOUNT_FILES,
  SET_BANK_ACCOUNT_FILES,
  DOWNLOAD_BANK_ACCOUNT_FILES,
} from '../actionTypes';

export const addBankAccountFile = (payload) => ({
  type: ADD_BANK_ACCOUNT_FILES,
  payload
});

export const getBankAccountFiles = (payload) => ({
  type: GET_BANK_ACCOUNT_FILES,
  payload,
});

export const setBankAccountFiles = (payload) => ({
  type: SET_BANK_ACCOUNT_FILES,
  payload,
});

export const downloadBankAccountFiles = (payload) => ({
  type: DOWNLOAD_BANK_ACCOUNT_FILES,
  payload,
});
