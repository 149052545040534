import {
  GET_BANK_STATEMENT_SHEET,
  SET_BANK_STATEMENT_SHEET,
} from "../actionTypes";

const INITIAL_STATE = {
  loadingBankStatementSheet: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BANK_STATEMENT_SHEET:
      return { ...state, loadingBankStatementSheet: true };

    case SET_BANK_STATEMENT_SHEET:
      return { ...state, loadingBankStatementSheet: false };

    default:
      return { ...state };
  }
};
