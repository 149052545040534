import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import sweetalert2 from "sweetalert2";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  CardHeader,
  CardTitle,
} from "reactstrap";

import { Typeahead } from "react-bootstrap-typeahead";
import { format, parseISO } from "date-fns";
import { ThumbsDown, Edit, List, Delete } from "react-feather";

import Breadcrumb from "../../layout/breadcrumb";

import {
  getPrevisoes,
  addPrevisoes,
  editPrevisao,
  deletePrevisao,
} from "../../redux/actions";

import "react-datepicker/dist/react-datepicker.css";

const INITIAL_VALUES = {
  tipos_transacoes_id: "",
  transacoes_eventos_id: "",
  tipos_fluxos_id: "",
  transacoes_blocos_id: null,
  transacoes_grupo_id: null,
};

const INITAL_VALUES_SECONDARY = {
  valor: 0,
  data: format(new Date(), "yyyy-MM-dd"),
  fornecedor_id: null,
  observacao: "",
}

const AddPrevisto = ({
  add = false,
  onDismiss = () => { },
  tiposFluxo,
  tiposTransacoes,
  blocos,
  eventos,
  loadingPrevisoes,
  addPrevisoes,
  user,
  grupos,
  loadingFornecedores,
  fornecedores,
}) => {
  const { tipo } = useParams();

  const getInitialFields = () => {
    return {
      ...INITIAL_VALUES,
      tipos_fluxos_id: tipo,
    }
  }

  const [fields, setFields] = useState(getInitialFields());
  const [fieldsSecondary, setFieldsSecondary] = useState([]);

  const isFirstStep = !fieldsSecondary.length;

  useEffect(() => {
    if (!add) {
      setFields(getInitialFields());
      setFieldsSecondary([]);
    }
  }, [add]);

  const checkAllFieldsProvided = () => {
    const { tipos_transacoes_id, tipos_fluxos_id } = fields;

    const requiredFields = [
      'tipos_transacoes_id',
      'transacoes_eventos_id',
      'tipos_fluxos_id',
    ];

    if (tipos_fluxos_id == 2 && tipos_transacoes_id == 1) {
      requiredFields.push('transacoes_blocos_id');
    }

    let notProvidedFields = 0;
    requiredFields.forEach((fieldName) => {
      if (!fields[fieldName]) notProvidedFields++;
    });

    return notProvidedFields;
  };

  const checkHasFieldsNotProvided = () => {
    const notProvidedFields = checkAllFieldsProvided();

    return Boolean(notProvidedFields);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const notProvidedFields = checkHasFieldsNotProvided();

    if (isFirstStep && notProvidedFields) {
      toast.error("Preencha todos os campos para prosseguir", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    if (isFirstStep && !notProvidedFields) {
      setFieldsSecondary([INITAL_VALUES_SECONDARY]);
    }

    if (!isFirstStep) {
      const transactions = [];

      fieldsSecondary.forEach((value) => {
        transactions.push({ ...value, ...fields });
      });

      addPrevisoes({ transactions });
      onDismiss();
      setFields(getInitialFields());
      setFieldsSecondary([]);
    }
  };

  const filtroEventos = eventos.filter((e) => {
    if (fields.tipos_fluxos_id != 2) {
      return (
        e.tipos_transacoes_id == fields.tipos_transacoes_id &&
        e.transacoes_grupo_id == fields.transacoes_grupo_id &&
        e.tipos_fluxos_id == fields.tipos_fluxos_id
      );
    } else {
      if (fields.tipos_transacoes_id == 1)
        return (
          e.tipos_transacoes_id == fields.tipos_transacoes_id &&
          e.transacoes_grupo_id == fields.transacoes_grupo_id &&
          e.tipos_fluxos_id == fields.tipos_fluxos_id
        );
      else
        return (
          e.tipos_transacoes_id == fields.tipos_transacoes_id &&
          e.transacoes_grupo_id == fields.transacoes_grupo_id &&
          e.tipos_fluxos_id == fields.tipos_fluxos_id
        );
    }
  });

  const optionsGrupos = grupos.filter(
    ({ tipos_fluxos_id, tipos_transacoes_id }) =>
      tipos_fluxos_id == fields.tipos_fluxos_id &&
      tipos_transacoes_id == fields.tipos_transacoes_id
  );

  const getSubgroupTitle = (subgroupId) => eventos.find(({ id }) => id === subgroupId)?.nome;

  return (
    <Modal
      size="lg"
      isOpen={add}
      toggle={() => {
        onDismiss();
      }}
      backdrop="static"
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader
          toggle={() => {
            onDismiss();
          }}
        >
          {isFirstStep
            ? 'Incluir Previsto'
            : `Incluindo Previsto em ${getSubgroupTitle(fields.transacoes_eventos_id)}`
          }
        </ModalHeader>

        <ModalBody>
          <Row>
            {isFirstStep && (
              <>
                <Col md="6" xs="12">
                  <FormGroup>
                    <Label>Fluxo</Label>
                    <Typeahead
                      id="fluxo"
                      labelKey="nome"
                      clearButton
                      placeholder="Selecione o fluxo"
                      options={
                        user?.groups[0]?.id == 4
                          ? tiposFluxo.filter((t) =>
                            user?.fluxos.map((f) => f.id).includes(t.id)
                          )
                          : tiposFluxo
                      }
                      onChange={(data) => {
                        if (data?.length) {
                          setFields({
                            ...fields,
                            tipos_fluxos_id: data[0].id,
                          });
                        } else {
                          setFields({
                            ...fields,
                            tipos_fluxos_id: null,
                          });
                        }
                      }}
                      selected={tiposFluxo.filter(
                        ({ id }) => id == fields.tipos_fluxos_id
                      )}
                      emptyLabel="Nenhum resultado encontrado"
                      disabled
                    />
                  </FormGroup>
                </Col>

                <Col md="6" xs="12">
                  <FormGroup>
                    <Label>Tipo de Transação</Label>
                    <Typeahead
                      id="transacao"
                      labelKey="nome"
                      clearButton
                      placeholder="Selecione o tipo"
                      options={tiposTransacoes}
                      onChange={(data) => {
                        if (data?.length) {
                          setFields({
                            ...fields,
                            tipos_transacoes_id: data[0].id,
                          });
                        } else {
                          setFields({
                            ...fields,
                            tipos_transacoes_id: null,
                          });
                        }
                      }}
                      emptyLabel="Nenhum resultado encontrado"
                    />
                  </FormGroup>
                </Col>

                {fields.tipos_fluxos_id == 2 && fields.tipos_transacoes_id == 1 && (
                  <Col md="6" xs="12">
                    <FormGroup>
                      <Label>Bloco</Label>
                      <Typeahead
                        id="bloco"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o bloco"
                        options={blocos}
                        onChange={(data) => {
                          if (data?.length) {
                            setFields({
                              ...fields,
                              transacoes_blocos_id: data[0].id,
                            });
                          } else {
                            setFields({
                              ...fields,
                              transacoes_blocos_id: null,
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </FormGroup>
                  </Col>
                )}

                {!!fields.tipos_fluxos_id && !!fields.tipos_transacoes_id && (
                  <Col md="12" xs="12">
                    <FormGroup>
                      <Label>Grupo</Label>
                      <Typeahead
                        id="tipo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o tipo"
                        options={optionsGrupos}
                        onChange={(data) => {
                          if (data?.length) {
                            setFields({
                              ...fields,
                              transacoes_grupo_id: data[0].id,
                              transacoes_eventos_id: null,
                            });
                          } else {
                            setFields({
                              ...fields,
                              transacoes_grupo_id: null,
                              transacoes_eventos_id: null,
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </FormGroup>
                  </Col>
                )}

                {!!fields.tipos_fluxos_id &&
                  !!fields.tipos_transacoes_id &&
                  !!fields.transacoes_grupo_id && (
                    <Col md="12" xs="12">
                      <FormGroup>
                        <Label>Subgrupo</Label>
                        <Typeahead
                          id="tipo"
                          labelKey="nome"
                          clearButton
                          placeholder="Selecione o tipo"
                          options={filtroEventos}
                          onChange={(data) => {
                            if (data?.length) {
                              setFields({
                                ...fields,
                                transacoes_eventos_id: data[0].id,
                              });
                            } else {
                              setFields({
                                ...fields,
                                transacoes_eventos_id: null,
                              });
                            }
                          }}
                          emptyLabel="Nenhum resultado encontrado"
                        />
                      </FormGroup>
                    </Col>
                  )}


              </>
            )}

            {!isFirstStep && (
              <>
                <Col md="12" xs="12" className="mb-3">
                  <Button
                    color="primary"
                    style={{
                      float: 'right'
                    }}
                    onClick={() => {
                      setFieldsSecondary([
                        ...fieldsSecondary,
                        INITAL_VALUES_SECONDARY,
                      ])
                    }}
                  >
                    Adicionar Valor e Data
                  </Button>
                </Col>

                {fieldsSecondary.map(({ data, valor }, key) => (
                  <Fragment key={key}>
                    <Col md="12" xs="12" className="mb-2 mt-3">
                      <Row>
                        <Col md="8">
                          <h5>Transação #{key + 1}</h5>
                        </Col>

                        <Col md="4">
                          <Button
                            outline
                            color="danger"
                            size="small"
                            style={{ float: 'right' }}
                            onClick={() => {
                              if (fieldsSecondary === 1) {
                                setFieldsSecondary([]);
                                setFields(getInitialFields());
                              } else {
                                const list = [];

                                fieldsSecondary.forEach((value, keyItem) => {
                                  if (keyItem !== key) {
                                    list.push(value);
                                  }
                                });

                                setFieldsSecondary(list);
                              }
                            }}
                          >
                            Apagar
                          </Button>
                        </Col>
                      </Row>
                    </Col>

                    <Col md="6" xs="12">
                      <FormGroup>
                        <Label>Valor Previsto</Label>
                        <NumberFormat
                          placeholder="Informe o valor"
                          thousandSeparator="."
                          customInput={Input}
                          decimalSeparator=","
                          decimalScale={2}
                          fixedDecimalScale
                          value={valor}
                          onValueChange={({ floatValue }) => {
                            const list = [];

                            fieldsSecondary.forEach((value, keyItem) => {
                              if (keyItem === key) {
                                list.push({ ...value, valor: floatValue });
                              } else {
                                list.push(value);
                              }
                            });

                            setFieldsSecondary(list);
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" xs="12">
                      <FormGroup>
                        <Label>Data Prevista</Label>
                        <DatePicker
                          locale={br}
                          autoComplete="off"
                          placeholderText="Selecione a Data"
                          selected={parseISO(data)}
                          onCalendarClose={() => { }}
                          dateFormat="dd/MM/yyyy"
                          className="form-control digits"
                          onChange={(selected) => {
                            const list = [];

                            fieldsSecondary.forEach((value, keyItem) => {
                              if (keyItem === key) {
                                list.push({ ...value, data: format(selected, "yyyy-MM-dd") });
                              } else {
                                list.push(value);
                              }
                            });

                            setFieldsSecondary(list);
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" xs="12">
                      <FormGroup>
                        <Label>Fornecedor</Label>
                        <Typeahead
                          id="fornecedor"
                          labelKey="nome"
                          clearButton
                          placeholder="Selecione o fornecedor"
                          options={fornecedores}
                          onChange={(data) => {
                            const list = [];

                            fieldsSecondary.forEach((value, keyItem) => {
                              if (keyItem === key) {
                                if (data?.length) {
                                  list.push({ ...value, fornecedor_id: data[0].id });
                                } else {
                                  list.push({ ...value, fornecedor_id: null });
                                }
                              } else {
                                list.push(value);
                              }
                            });

                            setFieldsSecondary(list);
                          }}
                          emptyLabel="Nenhum resultado encontrado"
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12" xs="12">
                      <FormGroup>
                        <Label>Observação/Discriminação</Label>
                        <Input
                          type="textarea"
                          name="observacao"
                          value={fieldsSecondary[key].observacao || ""}
                          onChange={({ target: { value: observacao } }) => {
                            const list = [];

                            fieldsSecondary.forEach((value, keyItem) => {
                              if (keyItem === key) {
                                list.push({ ...value, observacao });
                              } else {
                                list.push(value);
                              }
                            });

                            setFieldsSecondary(list);
                          }}
                          placeholder="Informe a observação"
                        />
                      </FormGroup>
                    </Col>
                  </Fragment>
                ))}
              </>
            )}
          </Row>
        </ModalBody>

        <ModalFooter
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            color="danger"
            onClick={() => {
              onDismiss();
            }}
          >
            Fechar
          </Button>

          <Button color="primary" disabled={loadingPrevisoes}>
            {loadingPrevisoes
              ? "Carregando..."
              : isFirstStep ? "Avançar" : "Salvar"
            }
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({
  Transacoes,
  Previsoes,
  Auth,
  Consolidado,
  Fornecedores,
}) => {
  const { tiposFluxo, tiposTransacoes, blocos, eventos } = Transacoes;

  const { user } = Auth;
  const { previsoes, loadingPrevisoes } = Previsoes;
  const { grupos } = Consolidado;
  const { loadingFornecedores, fornecedores } = Fornecedores;

  return {
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    previsoes,
    loadingPrevisoes,
    user,
    grupos,
    loadingFornecedores,
    fornecedores,
  };
};

export default connect(mapStateToProps, {
  addPrevisoes,
})(AddPrevisto);
