import { format } from "date-fns";
import {
  GET_PREVISOES,
  SET_PREVISOES,
  ADD_PREVISOES,
  EDIT_PREVISOES,
  DELETE_PREVISOES,
} from "../actionTypes";

const INITIAL_STATE = {
  loadingPrevisoes: false,
  previsoes: [],
  lastSearchMes: format(new Date(), "MM"),
  lastSearchAno: format(new Date(), "yyyy"),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PREVISOES:
      return {
        ...state,
        loadingPrevisoes: true,
      };

    case SET_PREVISOES:
      return {
        ...state,
        loadingPrevisoes: false,
        previsoes: !!action.payload?.previsoes
          ? action.payload.previsoes
          : state.previsoes,
        lastSearchMes:
          action.payload?.lastSearchMes || format(new Date(), "MM"),
        lastSearchAno:
          action.payload?.lastSearchAno || format(new Date(), "yyyy"),
      };

    case ADD_PREVISOES:
      return {
        ...state,
        loadingPrevisoes: true,
      };

    case EDIT_PREVISOES:
      return {
        ...state,
        loadingPrevisoes: true,
      };

    case DELETE_PREVISOES:
      return {
        ...state,
        loadingPrevisoes: true,
      };

    default:
      return state;
  }
};
