import {
    GET_GRUPOS,
    ADD_GRUPOS,
    SET_GRUPOS,
    DESVINCULAR_GRUPOS,
    VINCULAR_GRUPOS,
    GET_CONSOLIDADO,
    SET_CONSOLIDADO,
    EDIT_GRUPOS,
    DELETE_GRUPOS
} from '../actionTypes'

export const getGrupos = payload => ({
    type: GET_GRUPOS,
    payload
})

export const setGrupos = payload => ({
    type: SET_GRUPOS,
    payload
})

export const editGrupos = payload => ({
    type: EDIT_GRUPOS,
    payload
})

export const deleteGrupos = payload => ({
    type: DELETE_GRUPOS,
    payload
})

export const addGrupos = payload => ({
    type: ADD_GRUPOS,
    payload
})

export const vincularGrupos = payload => ({
    type: VINCULAR_GRUPOS,
    payload
})

export const desvincularGrupos = payload => ({
    type: DESVINCULAR_GRUPOS,
    payload
})

export const getConsolidado = payload => ({
    type: GET_CONSOLIDADO,
    payload
})

export const setConsolidado = payload => ({
    type: SET_CONSOLIDADO,
    payload
})