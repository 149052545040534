import {
  GET_GRUPOS_CONTA,
  SET_GRUPOS_CONTA,
  ADD_GRUPOS_CONTA,
  GET_CONTAS,
  SET_CONTAS,
  ADD_CONTA,
  EDIT_GRUPOS_CONTA,
  DELETE_GRUPOS_CONTA,
  EDIT_CONTA,
  TRANSFERIR,
  GET_REPASSES,
  SET_REPASSES,
  EDIT_REPASSE,
  REMOVE_REPASSE,
  VINCULAR_GRUPOS_CONTA,
  DESVINCULAR_GRUPOS_CONTA,
} from "../actionTypes";

const INITIAL_STATE = {
  gruposContas: [],
  loadingContas: false,
  contas: [],
  repasses: [],
  loadingRepasses: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONTAS:
      return {
        ...state,
        loadingContas: true,
      };

    case SET_CONTAS:
      return {
        ...state,
        loadingContas: false,
        contas: !!action.payload ? action.payload : state.contas,
      };

    case ADD_CONTA:
      return {
        ...state,
        loadingContas: true,
      };

    case EDIT_CONTA:
      return {
        ...state,
        loadingContas: true,
      };

    case GET_GRUPOS_CONTA:
      return {
        ...state,
        loadingContas: true,
      };

    case EDIT_GRUPOS_CONTA:
      return {
        ...state,
        loadingContas: true,
      };

    case DELETE_GRUPOS_CONTA:
      return {
        ...state,
        loadingContas: true,
      };

    case VINCULAR_GRUPOS_CONTA:
      return {
        ...state,
        loadingContas: true,
      };

    case DESVINCULAR_GRUPOS_CONTA:
      return {
        ...state,
        loadingContas: true,
      };

    case SET_GRUPOS_CONTA:
      return {
        ...state,
        loadingContas: false,
        gruposContas: !!action.payload ? action.payload : state.gruposContas,
      };

    case ADD_GRUPOS_CONTA:
      return {
        ...state,
        loadingContas: true,
      };

    case TRANSFERIR:
      return {
        ...state,
        loadingRepasses: true,
      };

    case EDIT_REPASSE:
      return {
        ...state,
        loadingRepasses: true,
      };

    case REMOVE_REPASSE:
      return {
        ...state,
        loadingRepasses: true,
      };

    case GET_REPASSES:
      return {
        ...state,
        loadingRepasses: true,
      };

    case SET_REPASSES:
      return {
        ...state,
        loadingRepasses: false,
        repasses: !!action.payload ? action.payload : state.repasses,
      };

    default:
      return state;
  }
};
