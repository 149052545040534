import React, { Fragment, useEffect, useState, useRef } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";

import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { Edit, List, Delete } from "react-feather";
import sweetalert2 from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import { Typeahead } from "react-bootstrap-typeahead";

import Print from "../../components/Print";
import Breadcrumb from "../../layout/breadcrumb";
import NotAuthorized from "../../components/NotAuthorized";

import { getGrupos, getEventos, getConsolidado } from "../../redux/actions";

import { useCashFlowTypeStore } from "../../utils/cashflowType";

const GruposConta = ({
  user,
  eventos,
  loadingGrupos,
  grupos,
  loadingConsolidado,
  loadingTransacoes,
  transacoes,
  previsoes,
  dataInicio,
  dataFim,
  getGrupos,
  getEventos,
  getConsolidado,
}) => {
  const { tipo } = useParams();

  const printRef = useRef();

  const { tiposFluxo } = useCashFlowTypeStore();

  const [search, setSearch] = useState({
    dataInicio: format(new Date(), "yyyy-MM-dd"),
    dataFim: format(new Date(), "yyyy-MM-dd"),
    transacoes_grupo_id: null,
  });

  useEffect(() => {
    getGrupos();
    getEventos();

    getConsolidado({ tipo, ...search });
  }, []);

  useEffect(() => {
    getConsolidado({ tipo, ...search });
  }, [tipo]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Relatório Consolidado`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const calcDivergencia = (previsto = 0, realizado = 0, isReceita = true) => {
    let moeda = realizado - previsto;

    let percentual;
    let percentualFloat = moeda / previsto * 100;

    if (!isReceita) {
      percentualFloat = percentualFloat * -1;
      moeda = moeda * -1;

    }

    let color = percentualFloat > 0 ? 'success' : 'danger';
    let colorPercentual = color;

    percentual = `${new Intl.NumberFormat('pt-br', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(percentualFloat)}%`;

    if ((previsto === 0 || realizado === 0) || previsto == realizado) {
      percentual = '0%';
      colorPercentual = 'dark';
      color = 'dark';
    }

    return {
      moeda,
      percentual,
      color,
      colorPercentual
    };
  }

  if (loadingGrupos || loadingConsolidado || loadingTransacoes)
    return (
      <Fragment>
        <Breadcrumb
          parent="Relatório Consolidado"
          title={tiposFluxo.find(({ id }) => id == tipo)?.nome}
        />

        <Container fluid={true}></Container>
        <div className="loader-box">
          <div className="loader-1"></div>
        </div>
      </Fragment>
    );

  const totalReceitaPrev = previsoes
    .filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)
    .reduce((prev, current) => {
      return prev + parseFloat(current.total);
    }, 0);

  const totalReceita = transacoes
    .filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)
    .reduce((prev, current) => {
      return prev + parseFloat(current.total);
    }, 0);

  const totalDespesaPrev = previsoes
    .filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)
    .reduce((prev, current) => {
      return prev + parseFloat(current.total);
    }, 0);

  const totalDespesa = transacoes
    .filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)
    .reduce((prev, current) => {
      return prev + parseFloat(current.total);
    }, 0);

  if (!tiposFluxo.find(({ id }) => id === Number(tipo)))
    return (
      <Fragment>
        <Breadcrumb parent="Relatório Consolidado" title="Não autorizado" />

        <NotAuthorized />
      </Fragment>
    );

  const {
    moeda: divergenciaReceitasEmMoeda,
    percentual: divergenciaReceitasPercentual,
    color: colorReceitas,
    colorPercentual: colorPercentualReceitas,
  } = calcDivergencia(totalReceitaPrev, totalReceita);

  const {
    moeda: divergenciaDespesasEmMoeda,
    percentual: divergenciaDespesasPercentual,
    color: colorDespesas,
    colorPercentual: colorPercentualDespesas,
  } = calcDivergencia(totalDespesaPrev, totalDespesa, false);

  const {
    moeda: divergenciaTotalEmMoeda,
    percentual: divergenciaTotalPercentual,
  } = calcDivergencia(totalReceitaPrev - totalDespesaPrev, totalReceita - totalDespesa);

  const optionsGrupos = grupos.filter(
    ({ tipos_fluxos_id }) => tipos_fluxos_id == tipo
  );

  return (
    <Fragment>
      <Breadcrumb
        parent="Relatório Consolidado"
        title={tiposFluxo.find(({ id }) => id == tipo)?.nome}
      />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                getConsolidado({ tipo, ...search });
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col md="12" xs="12">
                      <FormGroup>
                        <Label>Grupo</Label>
                        <Typeahead
                          id="tipo"
                          labelKey="nome"
                          clearButton
                          placeholder="Selecione o grupo"
                          options={optionsGrupos}
                          onChange={data => {
                            if (data?.length) {
                              setSearch({
                                ...search,
                                transacoes_grupo_id: data[0].id,
                              });
                            } else {
                              setSearch({
                                ...search,
                                transacoes_grupo_id: null,
                              });
                            }
                          }}
                          selected={optionsGrupos.filter(({ id }) => id == search.transacoes_grupo_id)}
                          emptyLabel="Nenhum resultado encontrado"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Inicial"
                        selected={
                          !!search.dataInicio ? parseISO(search.dataInicio) : ""
                        }
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataInicio: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Final"
                        selected={
                          !!search.dataFim ? parseISO(search.dataFim) : ""
                        }
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataFim: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12">
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>

          <Col sm="12">
            <Button
              color="primary"
              className="mt-5 float-right"
              onClick={handlePrint}
            >
              Imprimir
            </Button>
          </Col>

          <Col sm="12">
            <Print ref={printRef}>
              <Card className="font-arial-rlt">
                <CardBody>
                  <Col
                    sm="12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <p>
                      Relatório {tiposFluxo.find(({ id }) => id == tipo)?.nome}
                    </p>

                    <img
                      className="img-fluid for-light"
                      src={require("../../assets/images/logo/logo.jpg")}
                      alt=""
                      style={{
                        width: "130px",
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />

                    <p style={{ alignSelf: "flex-end" }}>
                      Período: {`${format(parseISO(dataInicio), "dd/MM/yyyy")}`}{" "}
                      à {`${format(parseISO(dataFim), "dd/MM/yyyy")}`}
                    </p>

                    <h5>{user?.cidade?.name}</h5>
                  </Col>

                  <h6
                    style={{
                      textAlign: "center",
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    Resumo Geral -{" "}
                    {tiposFluxo.find(({ id }) => id == tipo)?.nome}
                  </h6>

                  <Table bordered style={{ marginTop: 20, marginBottom: 20 }}>
                    <thead>
                      <tr>
                        <th style={{ width: "40%" }}>Descrição</th>
                        <th style={{ width: "15%", textAlign: "center" }}>
                          Previsto
                        </th>
                        <th style={{ width: "15%", textAlign: "center" }}>
                          Realizado
                        </th>
                        <th style={{ width: "15%", textAlign: "center" }}>
                          Divergência (R$)
                        </th>
                        <th style={{ width: "15%", textAlign: "center" }}>
                          Divergência (%)
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>RECEITA TOTAL</td>

                        <td style={{ textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalReceitaPrev)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalReceita)}
                        </td>

                        <td style={{ textAlign: "center" }} className={`text-${divergenciaReceitasEmMoeda > 0 ? 'success' : 'danger'}`}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(divergenciaReceitasEmMoeda)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {divergenciaReceitasPercentual}
                        </td>
                      </tr>

                      <tr>
                        <td>DESPESAS / INVESTIMENTOS / PROVISÕES TOTAL</td>

                        <td style={{ textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalDespesaPrev)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalDespesa)}
                        </td>

                        <td style={{ textAlign: "center" }} className={`text-${divergenciaDespesasEmMoeda > 0 ? 'success' : 'danger'}`}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(divergenciaDespesasEmMoeda)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {divergenciaDespesasPercentual}
                        </td>
                      </tr>

                      <tr>
                        <th>RESULTADO DO MÊS</th>

                        <td style={{ textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalReceitaPrev - totalDespesaPrev)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalReceita - totalDespesa)}
                        </td>

                        <td style={{ textAlign: "center" }} className={`text-${divergenciaTotalEmMoeda > 0 ? 'success' : 'danger'}`}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(divergenciaTotalEmMoeda)}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {divergenciaTotalPercentual}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <h6
                    style={{
                      textAlign: "center",
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    Receitas
                  </h6>

                  {grupos
                    .filter(
                      ({ tipos_transacoes_id, tipos_fluxos_id, id }) =>
                        tipos_transacoes_id == 1 && tipos_fluxos_id == tipo
                    )
                    .map(({ id, nome }) => {
                      let transacaoGrupo = 0;
                      let previsaoGrupo = 0;

                      const subGrupos = eventos.filter(
                        ({ transacoes_grupo_id }) => transacoes_grupo_id == id
                      );

                      const totalTransacoes = transacoes
                        .filter(({ transacoes_eventos_id }) =>
                          subGrupos
                            .map(({ id }) => id)
                            .includes(transacoes_eventos_id)
                        )
                        .reduce((prev, current) => {
                          return prev + parseFloat(current.total);
                        }, 0);

                      const totalPrevisoes = previsoes
                        .filter(({ transacoes_eventos_id }) =>
                          subGrupos
                            .map(({ id }) => id)
                            .includes(transacoes_eventos_id)
                        )
                        .reduce((prev, current) => {
                          return prev + parseFloat(current.total);
                        }, 0);

                      if (totalTransacoes === 0 && totalPrevisoes === 0)
                        return null;

                      return (
                        <Table
                          bordered
                          key={id}
                          style={{ marginTop: 20, marginBottom: 20 }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "40%" }}>{nome}</th>
                              <th style={{ width: "15%", textAlign: "center" }}>
                                Previsto
                              </th>
                              <th style={{ width: "15%", textAlign: "center" }}>
                                Realizado
                              </th>
                              <th style={{ width: "15%", textAlign: "center" }}>
                                Divergência (R$)
                              </th>
                              <th style={{ width: "15%", textAlign: "center" }}>
                                Divergência (%)
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {subGrupos.map(({ nome, id, bloco }, key) => {
                              const totalTransacoes = transacoes
                                .filter(
                                  ({ transacoes_eventos_id }) =>
                                    transacoes_eventos_id == id
                                )
                                .reduce((prev, current) => {
                                  return prev + parseFloat(current.total);
                                }, 0);

                              const totalPrevisoes = previsoes
                                .filter(
                                  ({ transacoes_eventos_id }) =>
                                    transacoes_eventos_id == id
                                )
                                .reduce((prev, current) => {
                                  return prev + parseFloat(current.total);
                                }, 0);

                              const { moeda, percentual, color, colorPercentual } = calcDivergencia(totalPrevisoes, totalTransacoes);

                              transacaoGrupo += totalTransacoes;
                              previsaoGrupo += totalPrevisoes;

                              if (totalPrevisoes === 0 && totalTransacoes === 0)
                                return null;

                              return (
                                <tr key={key}>
                                  <td>{nome}</td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(totalPrevisoes)}
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(totalTransacoes)}
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                    className={`text-${color}`}
                                  >
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(moeda)}
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                    className={`text-${colorPercentual}`}
                                  >
                                    {percentual}
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <th>Total</th>

                              <th style={{ textAlign: "center" }}>
                                {new Intl.NumberFormat("pt-Br", {
                                  currency: "BRL",
                                  style: "currency",
                                }).format(previsaoGrupo)}
                              </th>

                              <th style={{ textAlign: "center" }}>
                                {new Intl.NumberFormat("pt-Br", {
                                  currency: "BRL",
                                  style: "currency",
                                }).format(transacaoGrupo)}
                              </th>

                              <th style={{ textAlign: "center" }} className={`text-${calcDivergencia(previsaoGrupo, transacaoGrupo).color}`}>
                                {new Intl.NumberFormat("pt-Br", {
                                  currency: "BRL",
                                  style: "currency",
                                }).format(calcDivergencia(previsaoGrupo, transacaoGrupo).moeda)}
                              </th>

                              <th style={{ textAlign: "center" }} className={`text-${calcDivergencia(previsaoGrupo, transacaoGrupo).colorPercentual}`}>
                                {calcDivergencia(previsaoGrupo, transacaoGrupo).percentual}
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    })}

                  <Table bordered style={{ marginBottom: 20 }}>
                    <tbody>
                      <tr>
                        <th style={{ width: "40%" }}>
                          Total Geral de Receitas
                        </th>

                        <th style={{ width: "15%", textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalReceitaPrev)}
                        </th>

                        <th style={{ width: "15%", textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalReceita)}
                        </th>

                        <th style={{ width: "15%", textAlign: "center" }} className={`text-${colorReceitas}`}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(divergenciaReceitasEmMoeda)}
                        </th>

                        <th style={{ width: "15%", textAlign: "center" }} className={`text-${colorPercentualReceitas}`}>
                          {divergenciaReceitasPercentual}
                        </th>
                      </tr>
                    </tbody>
                  </Table>

                  <h6
                    style={{
                      textAlign: "center",
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    Despesas
                  </h6>

                  {grupos
                    .filter(
                      ({ tipos_transacoes_id, tipos_fluxos_id }) =>
                        tipos_transacoes_id != 1 && tipos_fluxos_id == tipo
                    )
                    .map(({ id, nome }) => {
                      let transacaoGrupo = 0;
                      let previsaoGrupo = 0;

                      const subGrupos = eventos.filter(
                        ({ transacoes_grupo_id }) => transacoes_grupo_id == id
                      );

                      const totalTransacoes = transacoes
                        .filter(({ transacoes_eventos_id }) =>
                          subGrupos
                            .map(({ id }) => id)
                            .includes(transacoes_eventos_id)
                        )
                        .reduce((prev, current) => {
                          return prev + parseFloat(current.total);
                        }, 0);

                      const totalPrevisoes = previsoes
                        .filter(({ transacoes_eventos_id }) =>
                          subGrupos
                            .map(({ id }) => id)
                            .includes(transacoes_eventos_id)
                        )
                        .reduce((prev, current) => {
                          return prev + parseFloat(current.total);
                        }, 0);

                      if (totalTransacoes === 0 && totalPrevisoes === 0)
                        return null;

                      return (
                        <Table
                          bordered
                          key={id}
                          style={{ marginTop: 20, marginBottom: 20 }}
                        >
                          <thead>
                            <tr>
                              <th style={{ width: "40%" }}>{nome}</th>
                              <th style={{ width: "15%", textAlign: "center" }}>
                                Previsto
                              </th>
                              <th style={{ width: "15%", textAlign: "center" }}>
                                Realizado
                              </th>
                              <th style={{ width: "15%", textAlign: "center" }}>
                                Divergência (R$)
                              </th>
                              <th style={{ width: "15%", textAlign: "center" }}>
                                Divergência (%)
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {subGrupos.map(({ nome, id, bloco }, key) => {
                              const totalTransacoes = transacoes
                                .filter(
                                  ({ transacoes_eventos_id }) =>
                                    transacoes_eventos_id == id
                                )
                                .reduce((prev, current) => {
                                  return prev + parseFloat(current.total);
                                }, 0);

                              const totalPrevisoes = previsoes
                                .filter(
                                  ({ transacoes_eventos_id }) =>
                                    transacoes_eventos_id == id
                                )
                                .reduce((prev, current) => {
                                  return prev + parseFloat(current.total);
                                }, 0);

                              const { moeda, percentual, color, colorPercentual } = calcDivergencia(totalPrevisoes, totalTransacoes, false);

                              transacaoGrupo += totalTransacoes;
                              previsaoGrupo += totalPrevisoes;

                              if (totalPrevisoes === 0 && totalTransacoes === 0)
                                return null;

                              return (
                                <tr key={key}>
                                  <td>{nome}</td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(totalPrevisoes)}
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                  >
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(totalTransacoes)}
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                    className={`text-${color}`}
                                  >
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(moeda)}
                                  </td>

                                  <td
                                    style={{
                                      textAlign: "center",
                                    }}
                                    className={`text-${colorPercentual}`}
                                  >
                                    {percentual}
                                  </td>
                                </tr>
                              );
                            })}

                            <tr>
                              <th style={{ width: "40%" }}>Total</th>

                              <th style={{ width: "15%", textAlign: "center" }}>
                                {new Intl.NumberFormat("pt-Br", {
                                  currency: "BRL",
                                  style: "currency",
                                }).format(previsaoGrupo)}
                              </th>

                              <th style={{ width: "15%", textAlign: "center" }}>
                                {new Intl.NumberFormat("pt-Br", {
                                  currency: "BRL",
                                  style: "currency",
                                }).format(transacaoGrupo)}
                              </th>

                              <th style={{ textAlign: "center" }} className={`text-${calcDivergencia(previsaoGrupo, transacaoGrupo, false).color}`}>
                                {new Intl.NumberFormat("pt-Br", {
                                  currency: "BRL",
                                  style: "currency",
                                }).format(calcDivergencia(previsaoGrupo, transacaoGrupo, false).moeda)}
                              </th>

                              <th style={{ textAlign: "center" }} className={`text-${calcDivergencia(previsaoGrupo, transacaoGrupo, false).colorPercentual}`}>
                                {calcDivergencia(previsaoGrupo, transacaoGrupo, false).percentual}
                              </th>
                            </tr>
                          </tbody>
                        </Table>
                      );
                    })}

                  <Table bordered style={{ marginBottom: 20 }}>
                    <tbody>
                      <tr>
                        <th style={{ width: "40%" }}>
                          Total Geral de Despesas
                        </th>

                        <th style={{ width: "15%", textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalDespesaPrev)}
                        </th>

                        <th style={{ width: "15%", textAlign: "center" }}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(totalDespesa)}
                        </th>

                        <th style={{ width: "15%", textAlign: "center" }} className={`text-${colorDespesas}`}>
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(divergenciaDespesasEmMoeda)}
                        </th>

                        <th style={{ width: "15%", textAlign: "center" }} className={`text-${colorPercentualDespesas}`}>
                          {divergenciaDespesasPercentual}
                        </th>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Print>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({ Transacoes, Auth, Consolidado }) => {
  const { tiposFluxo, eventos, loadingTransacoes } = Transacoes;
  const { user } = Auth;
  const {
    loadingGrupos,
    grupos,
    loadingConsolidado,
    transacoes,
    previsoes,
    dataInicio,
    dataFim,
  } = Consolidado;

  return {
    user,
    tiposFluxo,
    eventos,
    loadingGrupos,
    grupos,
    loadingConsolidado,
    transacoes,
    previsoes,
    loadingTransacoes,
    dataInicio,
    dataFim,
  };
};

export default connect(mapStateToProps, {
  getGrupos,
  getEventos,
  getConsolidado,
})(GruposConta);
