import fileDownload from "js-file-download";

import { all, fork, call, put, takeEvery, select } from "redux-saga/effects";

import apiSheet from "../../services/apiSheet";  

import { GET_BANK_STATEMENT_SHEET } from "../actionTypes";
import { setBankStatementSheet } from "../actions";

function* getBankStatementSheetSaga({ payload }) {
  const { contas } = yield select((state) => state.Contas);

  const conta = contas.find(({ id }) => id == payload.accountId)?.conta;

  yield call(apiGetBankStatementSheet, payload, conta);

  yield put(setBankStatementSheet());
}

const apiGetBankStatementSheet = async (params, accountName = "") => {
  const { data } = await apiSheet.get("/api/v1/bankstatement", {
      params,
      responseType: "blob",
    });

  fileDownload(data, "Extrato " + accountName + ".xlsx");
};

function* watchGetBankStatementSaga() {
  yield takeEvery(GET_BANK_STATEMENT_SHEET, getBankStatementSheetSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetBankStatementSaga)]);
}
