import React, { useState } from "react";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
} from "reactstrap";

import { format, parseISO, compareAsc } from "date-fns";
import { toast } from "react-toastify";

import { useGeneralBankStatementStore } from "../../../utils/generalBankStatement";
import { useCashFlowTypeStore } from "../../../utils/cashflowType";
import { useAccountStore } from "../../../utils/accounts";


function SearchForm({ }) {
  const [search, setSearch] = useState({
    dataInicio: format(new Date(), "yyyy-MM-dd"),
    dataFim: format(new Date(), "yyyy-MM-dd"),
    tipos_fluxos_id: '',
    conta_id: ''
  });

  const { tiposFluxo } = useCashFlowTypeStore();
  const { dispatchGetGeneralBankStatement } = useGeneralBankStatementStore();
  const { contas } = useAccountStore();

  const onSubmitHandler = e => {
    e.preventDefault();

    if (
      !!search.dataInicio &&
      !!search.dataFim
    ) {
      if (
        compareAsc(
          parseISO(search.dataInicio),
          parseISO(search.dataFim)
        ) > 0
      ) {
        toast.error("Data Inicial não pode ser maior que a final", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        const dataSearch = { ...search };

        for (let key in dataSearch) {
          if (!dataSearch[key]) {
            delete dataSearch[key];
          }
        }

        dispatchGetGeneralBankStatement(dataSearch);
      }
    } else {
      toast.error("Preencha o formulário", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <Col sm="12">
      <Form
        onSubmit={onSubmitHandler}
      >
        <Card>
          <CardBody>
            <Row>
              <Col sm="6" className="mb-3">
                <Typeahead
                  id="fluxo"
                  labelKey="nome"
                  clearButton
                  placeholder="Selecione o fluxo"
                  options={tiposFluxo}
                  onChange={data => {
                    if (data?.length) {
                      setSearch({
                        ...search,
                        tipos_fluxos_id: data[0].id
                      });
                    } else {
                      setSearch({
                        ...search,
                        tipos_fluxos_id: null
                      });
                    }
                  }}
                  selected={tiposFluxo.filter(({ id }) => id === search.tipos_fluxos_id)}
                  emptyLabel="Nenhum resultado encontrado"
                />
              </Col>

              <Col sm="6" className="mb-3">
                <Typeahead
                  id="conta"
                  labelKey="conta"
                  clearButton
                  placeholder="Selecione a conta"
                  options={
                    contas
                      .filter(({ tipos_fluxos_id }) => {
                        if (search.tipos_fluxos_id) {
                          return tipos_fluxos_id == search.tipos_fluxos_id;
                        }

                        return true;
                      })
                      .map(
                        ({ conta, ...c }) => ({
                          ...c,
                          conta: String(conta),
                        })
                      )
                  }
                  onChange={(data) => {
                    if (data?.length) {
                      setSearch({
                        ...search,
                        conta_id: data[0]?.id,
                      });
                    } else {
                      setSearch({
                        ...search,
                        conta_id: "",
                      });
                    }
                  }}
                  selected={
                    contas
                      .map(
                        ({ conta, ...c }) => ({
                          ...c,
                          conta: String(conta),
                        })
                      )
                      .filter(({ id }) => id == search.conta_id)
                  }
                  emptyLabel="Nenhum resultado encontrado"
                />
              </Col>

              <Col sm="6">
                <DatePicker
                  locale={br}
                  autoComplete="off"
                  placeholderText="Selecione a Data Inicial"
                  selected={
                    !!search.dataInicio ? parseISO(search.dataInicio) : ""
                  }
                  onCalendarClose={() => { }}
                  dateFormat="dd/MM/yyyy"
                  className="form-control digits"
                  onChange={(selected) => {
                    setSearch({
                      ...search,
                      dataInicio: !!selected
                        ? format(selected, "yyyy-MM-dd")
                        : "",
                    });
                  }}
                />
              </Col>

              <Col sm="6">
                <DatePicker
                  locale={br}
                  autoComplete="off"
                  placeholderText="Selecione a Data Final"
                  selected={
                    !!search.dataFim ? parseISO(search.dataFim) : ""
                  }
                  onCalendarClose={() => { }}
                  dateFormat="dd/MM/yyyy"
                  className="form-control digits"
                  onChange={(selected) => {
                    setSearch({
                      ...search,
                      dataFim: !!selected
                        ? format(selected, "yyyy-MM-dd")
                        : "",
                    });
                  }}
                />
              </Col>

              <Col sm="12" style={{ marginTop: 10 }}>
                <Button color="primary" className="float-right">
                  Pesquisar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </Col>
  );
}

export default SearchForm;