import React, { useRef, Fragment } from "react";
import { Table, Card, CardBody, Row, Col, Button } from "reactstrap";
import { format, parseISO } from 'date-fns';

import { useCashFlowTypeStore } from '../../../utils/cashflowType';
import { moneyConvert } from '../../../utils/moneyConvert';

const List = ({
  user,
  dataInicio,
  dataFim,
  transacoes = [],
  previsoes = [],
  grupos = [],
  eventos = [],
  prefeitura,
  tiposTransacoes = []
}) => {

  const { tiposFluxo } = useCashFlowTypeStore();

  const eventosPrevisoesIds = previsoes.map(({ transacoes_eventos_id, mes, ano }) => ({
    transacoes_eventos_id, mes, ano
  })).map(JSON.stringify);

  const eventosTransacoesIds = transacoes.map(({ transacoes_eventos_id, mes, ano }) => ({
    transacoes_eventos_id, mes, ano
  })).map(JSON.stringify);

  const mergedList = [...eventosPrevisoesIds, ...eventosTransacoesIds];

  const eventosIds = Array.from(new Set(mergedList)).map(JSON.parse);

  const optionsMes = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];

  return (
    <Card className="font-arial-rlt">
      <CardBody>
        <Col
          sm="12"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <p>
            Relatório Consolidado Tabelado
          </p>

          <img
            className="img-fluid for-light"
            src={require("../../../assets/images/logo/logo.jpg")}
            alt=""
            style={{
              width: "130px",
              height: "130px",
              objectFit: "contain",
            }}
          />

          <p style={{ alignSelf: "flex-end" }}>
            Período: {`${format(parseISO(dataInicio), "dd/MM/yyyy")}`}{" "}
            à {`${format(parseISO(dataFim), "dd/MM/yyyy")}`}
          </p>

          <Table bordered>
            <tbody>
              <tr>
                <th>Prefeitura</th>
                <th>Ano</th>
                <th>Mês</th>
                <th>Fluxo</th>
                <th>Operação</th>
                <th>Descrição</th>
                <th>Previsto</th>
                <th>Realizado</th>
                <th>Grupo</th>
              </tr>

              {eventosIds.length
                ? eventosIds.map(({ transacoes_eventos_id, mes, ano }, key) => {
                  const eventoData = eventos.find(({ id }) => transacoes_eventos_id === id);
                  const descricao = eventoData?.nome;
                  const nomeMes = optionsMes.find(({ id }) => id === mes)?.nome;
                  const fluxoNome = tiposFluxo.find(({ id }) => id === eventoData?.tipos_fluxos_id)?.nome;
                  const grupoNome = grupos.find(({ id }) => id === eventoData?.transacoes_grupo_id)?.nome;
                  const operacao = tiposTransacoes.find(({ id }) => id === eventoData?.tipos_transacoes_id)?.nome;
  
                  const valorPrevisto = previsoes
                    .filter(
                      (previsao) =>
                        previsao.transacoes_eventos_id === eventoData?.id &&
                        previsao.mes === mes &&
                        previsao.ano === ano
                    )
                    .reduce((previous, current) => previous + parseFloat(current.total), 0);
  
                  const valorRealizado = transacoes
                    .filter(
                      (transacao) =>
                        transacao.transacoes_eventos_id === eventoData?.id &&
                        transacao.mes === mes &&
                        transacao.ano === ano
                    )
                    .reduce((previous, current) => previous + parseFloat(current.total), 0);
  
                  return (
                    <tr key={key}>
                      <td>{prefeitura}</td>
                      <td>{ano}</td>
                      <td>{nomeMes}</td>
                      <td>{fluxoNome}</td>
                      <td>{operacao}</td>
                      <td>{descricao}</td>
                      <td>{moneyConvert(valorPrevisto)}</td>
                      <td>{moneyConvert(valorRealizado)}</td>
                      <td>{grupoNome || "Sem grupo vinculado"}</td>
                    </tr>
                  )
                })
                : (
                  <tr>
                    <td colSpan={9} className="text-center">Não há registros</td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </Col>
      </CardBody>
    </Card>
  );
};

export default List;
