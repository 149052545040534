import React, { Fragment, useEffect, useState } from "react";

import { connect } from "react-redux";

import { format, parseISO } from 'date-fns';

import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";

import { Download } from 'react-feather';

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Form,
  Table
} from "reactstrap";

import Breadcrumb from "../../layout/breadcrumb";

import { useCashFlowTypeStore } from "../../utils/cashflowType";
import { useAccountStore } from "../../utils/accounts";

import { getContas, getGruposConta, getBankAccountFiles, downloadBankAccountFiles } from "../../redux/actions";

import Upload from "./upload";

const BankAccountFiles = ({
  getContas,
  getGruposConta,
  files,
  loadingFiles,
  getBankAccountFiles,
  downloadBankAccountFiles
}) => {

  const [isAddingFile, setIsAddingFile] = useState(false);

  const [form, setForm] = useState({
    flow: null,
    account_id: null,
    created_at: format(new Date(), 'yyyy-MM-dd'),
  });

  const { tiposFluxo } = useCashFlowTypeStore();
  const { contas, loadingContas } = useAccountStore();

  useEffect(() => {
    getContas();
    getGruposConta();
  }, [getContas, getGruposConta]);

  const onSubmit = (event) => {
    event.preventDefault();

    getBankAccountFiles(form);
  };

  if (loadingFiles || loadingContas)
    return (
      <Fragment>
        <Breadcrumb parent="Contas Bancárias" title="Anexos" />
        <Container fluid={true}></Container>
        <div className="loader-box">
          <div className="loader-1"></div>
        </div>
      </Fragment>
    );

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Contas Bancárias" title="Anexos" />

        <Button
          color="primary"
          className="float-right"
          onClick={() => setIsAddingFile(true)}
        >
          Incluir
        </Button>
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col md="6" xs="12">
                      <Typeahead
                        id="fluxo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o fluxo"
                        options={tiposFluxo}
                        onChange={data => {
                          if (data?.length) {
                            setForm({
                              ...form,
                              flow: data[0].id
                            });
                          } else {
                            setForm({
                              ...form,
                              flow: null
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col md="6" xs="12">
                      <Typeahead
                        id="conta"
                        labelKey="conta"
                        clearButton
                        placeholder="Selecione a conta"
                        options={contas.filter(({ tipos_fluxos_id }) => tipos_fluxos_id === form.flow)
                          .map(({ conta, ...c }) => ({
                            ...c,
                            conta: String(conta),
                          }))}
                        onChange={(data) => {
                          if (data?.length) {
                            setForm({
                              ...form,
                              account_id: data[0]?.id,
                            });
                          } else {
                            setForm({
                              ...form,
                              account_id: "",
                            });
                          }
                        }}
                        selected={contas
                          .map(({ conta, ...c }) => ({
                            ...c,
                            conta: String(conta),
                          }))
                          .filter(({ id }) => id == form.account_id)}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col md="6" xs="12" className="mt-3">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Data do upload"
                        selected={!!form?.created_at ? parseISO(form.created_at) : null}
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setForm({
                            ...form,
                            created_at: format(selected, 'yyyy-MM-dd')
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12" className="mt-3">
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col sm="12">
            <Card>
              <CardBody>
                <Table bordered>
                  <tbody>
                    <tr>
                      <th>Nome do arquivo</th>
                      <th>Responsável</th>
                      <th>Conta</th>
                      <th style={{ width: '10%', textAlign: 'center' }}>Ações</th>
                    </tr>

                    {files.map(({ filename, file_path, user: { email }, account: { conta } }, key) => (
                      <tr key={key}>
                        <td>{filename}</td>
                        <td>{email}</td>
                        <td>{conta}</td>
                        <td style={{ textAlign: 'center' }}>
                          <span
                            style={{
                              cursor: 'pointer',
                              marginLeft: 5
                            }}
                            onClick={() => {
                              downloadBankAccountFiles(file_path);
                            }}
                          >
                            <Download
                              size={18}
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Upload
        onDismiss={() => setIsAddingFile(false)}
        open={isAddingFile}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ Auth, BankAccountFiles }) => {
  const { user } = Auth;
  const { files, loadingFiles } = BankAccountFiles;

  return {
    user,
    files,
    loadingFiles
  };
};

export default connect(mapStateToProps, {
  getContas,
  getGruposConta,
  getBankAccountFiles,
  downloadBankAccountFiles,
})(BankAccountFiles);
