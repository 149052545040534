import { all, takeEvery, fork, call, put, select } from 'redux-saga/effects'
import { toast } from 'react-toastify';

import {
    GET_GRUPOS,
    VINCULAR_GRUPOS,
    ADD_GRUPOS,
    DESVINCULAR_GRUPOS,
    GET_CONSOLIDADO,
    EDIT_GRUPOS,
    DELETE_GRUPOS
} from '../actionTypes'

import {
    setGrupos,
    getEventos,
    setConsolidado
} from '../actions'

import api from '../../services/api'

function* sagaGetGrupos() {
    try {
        const data = yield call(apiGetGrupos)

        yield put(setGrupos(data))
    } catch (err) {
        console.log(err)
        yield put(setGrupos())
    }
}

const apiGetGrupos = async () => {
    const { data } = await api.get('/api/auth/transacoesGrupos')

    return data
}

function* sagaAddGrupos({ payload }) {
    const { grupos } = yield select(state => state.Consolidado)

    try {
        const data = yield call(apiAddGrupos, payload)

        if (data.ok) {
            yield put(setGrupos([
                ...grupos,
                data.grupo
            ]))

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            yield put(setGrupos())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } catch (err) {
        yield put(setGrupos())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiAddGrupos = async payload => {
    const { data } = await api.post('/api/auth/transacoesGrupos/add', payload)

    return data
}

function* sagaEditGrupos({ payload }) {
    const { grupos } = yield select(state => state.Consolidado)

    try {
        const data = yield call(apiEditGrupos, payload)

        if (data.ok) {
            yield put(
                setGrupos(grupos.map(
                    g => {
                        if (g.id == data.grupo?.id) {
                            return data.grupo
                        } else {
                            return g
                        }
                    }
                ))
            )

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            yield put(setGrupos())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } catch (err) {
        yield put(setGrupos())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiEditGrupos = async payload => {
    const { data } = await api.put('/api/auth/transacoesGrupos/edit', payload)

    return data
}

function* sagaDeleteGrupos({ payload }) {
    const { grupos } = yield select(state => state.Consolidado)

    try {
        const data = yield call(apiDeleteGrupos, payload)

        if (data.ok) {
            yield put(
                setGrupos(grupos.filter(
                    ({ id }) => id != payload
                ))
            )

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        } else {
            yield put(setGrupos())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } catch (err) {
        yield put(setGrupos())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiDeleteGrupos = async payload => {
    const { data } = await api.delete(`/api/auth/transacoesGrupos/delete/${payload}`)

    return data
}

function* sagaVincularGrupos({ payload }) {
    try {
        const data = yield call(apiVincularGrupos, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getEventos());
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        yield put(setGrupos())
    } catch (err) {
        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        });

        yield put(setGrupos())
    }
}

const apiVincularGrupos = async payload => {
    const { data } = await api.put('/api/auth/transacoesGrupos/vincular', payload);

    return data;
}

function* sagaDesvincularGrupos({ payload }) {
    try {
        const data = yield call(apiDesvincularGrupos, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getEventos());
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        yield put(setGrupos());
    } catch (err) {
        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        });

        yield put(setGrupos());
    }
}

const apiDesvincularGrupos = async payload => {
    const { data } = await api.put('/api/auth/transacoesGrupos/desvincular', payload);

    return data;
}

function* sagaGetConsolidado({ payload }) {
    try {
        const data = yield call(apiGetConsolidado, payload);

        yield put(setConsolidado(data));

    } catch (err) {
        yield put(setConsolidado());
    }
}

const apiGetConsolidado = async params => {
    const { data } = await api.get('/api/auth/consolidadoPorData', { params });

    return data;
}

function* watchGetGrupos() {
    yield takeEvery(GET_GRUPOS, sagaGetGrupos);
}

function* watchAddGrupos() {
    yield takeEvery(ADD_GRUPOS, sagaAddGrupos);
}

function* watchVincularGrupos() {
    yield takeEvery(VINCULAR_GRUPOS, sagaVincularGrupos);
}

function* watchDesvincularGrupos() {
    yield takeEvery(DESVINCULAR_GRUPOS, sagaDesvincularGrupos);
}

function* watchGetConsolidado() {
    yield takeEvery(GET_CONSOLIDADO, sagaGetConsolidado);
}

function* watchEditGrupos() {
    yield takeEvery(EDIT_GRUPOS, sagaEditGrupos)
}

function* watchDeleteGrupos() {
    yield takeEvery(DELETE_GRUPOS, sagaDeleteGrupos)
}

export default function* rootSaga() {
    yield all([
        fork(watchGetGrupos),
        fork(watchAddGrupos),
        fork(watchVincularGrupos),
        fork(watchDesvincularGrupos),
        fork(watchGetConsolidado),
        fork(watchEditGrupos),
        fork(watchDeleteGrupos)
    ]);
}