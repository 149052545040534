import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { setTransacoesFuturas, getTransacoesFuturas } from '../actions';

import {
    GET_TRANSACOES_FUTURAS,
    ADD_TRANSACOES_FUTURAS,
    DELETE_TRANSACOES_FUTURAS,
    EFETIVAR_TRANSACOES_FUTURAS
} from '../actionTypes';

import api from '../../services/api';

function* sagaGetTransacoesFuturas({ payload }) {
    try {
        const data = yield call(apiGetTransacoesFuturas, payload);

        yield put(setTransacoesFuturas(data.transacoes));
    } catch (err) {
        yield put(setTransacoesFuturas());
    }
}

const apiGetTransacoesFuturas = async params => {
    const { data } = await api.get('/api/auth/transacoesFuturas', { params });

    return data;
}

function* sagaAddTransacoesFuturas({ payload }) {
    try {
        const data = yield call(apiAddTransacoesFuturas, payload);

        if (data.ok) {
            yield put(getTransacoesFuturas());
        }
    } catch (err) {
        yield put(setTransacoesFuturas());
    }
}

const apiAddTransacoesFuturas = async params => {
    const { data } = await api.post('/api/auth/transacoesFuturas/add', params);

    return data;
}

function* sagaDeleteTransacoesFuturas({ payload }) {
    try {
        const data = yield call(apiDeleteTransacoesFuturas, payload);

        if (data.ok) {
            yield put(getTransacoesFuturas());
        }
    } catch (err) {
        yield put(setTransacoesFuturas());
    }
}

const apiDeleteTransacoesFuturas = async params => {
    const { data } = await api.delete('/api/auth/transacoesFuturas/delete/' + params);

    return data;
}

function* sagaEfetivarTransacoesFuturas({ payload }) {
    try {
        const data = yield call(apiEfetivarTransacoesFuturas, payload);

        if (data.ok) {
            yield put(getTransacoesFuturas());
        }
    } catch (err) {
        yield put(setTransacoesFuturas());
    }
}

const apiEfetivarTransacoesFuturas = async params => {
    const { data } = await api.post('/api/auth/transacoesFuturas/efetivar/' + params);

    return data;
}

function* watchGetTransacoesFuturas() {
    yield takeEvery(GET_TRANSACOES_FUTURAS, sagaGetTransacoesFuturas);
}

function* watchAddTransacoesFuturas() {
    yield takeEvery(ADD_TRANSACOES_FUTURAS, sagaAddTransacoesFuturas);
}

function* watchDeleteTransacoesFuturas() {
    yield takeEvery(DELETE_TRANSACOES_FUTURAS, sagaDeleteTransacoesFuturas);
}

function* watchEfetivarTransacoesFuturas() {
    yield takeEvery(EFETIVAR_TRANSACOES_FUTURAS, sagaEfetivarTransacoesFuturas);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetTransacoesFuturas),
        fork(watchAddTransacoesFuturas),
        fork(watchDeleteTransacoesFuturas),
        fork(watchEfetivarTransacoesFuturas),
    ]);
}