import {
    GET_MENSAGENS,
    SET_MENSAGENS,
    ADD_MENSAGENS,
    EDIT_MENSAGENS,
    DELETE_MENSAGENS
} from '../actionTypes'

export const getMensagens = () => ({
    type: GET_MENSAGENS
})

export const setMensagens = payload => ({
    type: SET_MENSAGENS,
    payload
})

export const addMensagens = payload => ({
    type: ADD_MENSAGENS,
    payload
})

export const editMensagens = payload => ({
    type: EDIT_MENSAGENS,
    payload
})

export const deleteMensagens = payload => ({
    type: DELETE_MENSAGENS,
    payload
})