import React, { Component } from 'react';

class ComponentToPrint extends Component {
    render() {
        const { children } = this.props;

        return (
            <div className="p-10">
                {children}
            </div>
        )
    }
}

export default ComponentToPrint