import {
    GET_EXTRATO,
    SET_EXTRATO
} from '../actionTypes'

export const getExtrato = payload => ({
    type: GET_EXTRATO,
    payload
})

export const setExtrato = payload => ({
    type: SET_EXTRATO,
    payload
})