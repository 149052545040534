import { useDispatch, useSelector } from "react-redux";

import { getUpdateReport } from "../redux/actions";

export const useUpdateReport = () => {
  const dispatch = useDispatch();

  const getReport = () => {
    dispatch(getUpdateReport());
  };

  const { loadingUpdateReport, updateReport } = useSelector(
    (state) => state.UpdateReport
  );

  return {
    getReport,
    loadingUpdateReport,
    updateReport,
  };
};
