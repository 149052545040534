import {
  GET_GENERAL_BANK_STATEMENT,
  SET_GENERAL_BANK_STATEMENT,
} from "../actionTypes";

export const getGeneralBankStatement = (payload) => ({
  type: GET_GENERAL_BANK_STATEMENT,
  payload,
});

export const setGeneralBankStatement = (payload) => ({
  type: SET_GENERAL_BANK_STATEMENT,
  payload,
});
