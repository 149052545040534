import {
    GET_FUNCIONALIDADES_ASYNC,
    GET_FUNCIONALIDADES,
    LIST_FUNCIONALIDADES_ASYNC,
    LIST_FUNCIONALIDADES,
    STORE_FUNCIONALIDADES_ASYNC,
    STORE_FUNCIONALIDADES,
    UPDATE_FUNCIONALIDADES,
    UPDATE_FUNCIONALIDADES_ASYNC,
    DESTROY_FUNCIONALIDADES_ASYNC,
    DESTROY_FUNCIONALIDADES
} from "../actionTypes";


const initial_state = {
    loading: false,
    loadingTrue: false,
    loadingStoreFunc: false,
    loadingUpdateFunc: false,
    loadingDeleteFunc: false,
    funcionalidades: null,
    list: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_FUNCIONALIDADES_ASYNC:
            return { ...state, loading: true };
        case GET_FUNCIONALIDADES:
            return { ...state, ...action.payload, loading: false };
        case LIST_FUNCIONALIDADES_ASYNC:
            return { ...state, loadingList: true };
        case LIST_FUNCIONALIDADES:
            return { ...state, ...action.payload, loadingList: false };
        case STORE_FUNCIONALIDADES_ASYNC:
            return { ...state, loadingStoreFunc: true };
        case STORE_FUNCIONALIDADES:
            return { ...state, ...action.payload, loadingStoreFunc: false };
        case UPDATE_FUNCIONALIDADES_ASYNC:
            return { ...state, loadingUpdateFunc: true };
        case UPDATE_FUNCIONALIDADES:
            return { ...state, ...action.payload, loadingUpdateFunc: false };
        case DESTROY_FUNCIONALIDADES_ASYNC:
            return { ...state, loadingDeleteFunc: true };
        case DESTROY_FUNCIONALIDADES:
            return { ...state, ...action.payload, loadingDeleteFunc: false }

        default: return state;;
    }
}
