import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { Typeahead } from "react-bootstrap-typeahead";
import { useReactToPrint } from "react-to-print";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";

import { format, parseISO, compareAsc } from "date-fns";
import { toast } from "react-toastify";
import { Edit, Trash } from "react-feather";
import sweetalert2 from "sweetalert2";

import Breadcrumb from "../../layout/breadcrumb";
import Print from "../../components/Print";

import { useAccountStore } from "../../utils/accounts";

import {
  adaptDespesas,
  adaptReceitas,
  adaptRepasseDespesas,
  adaptRepasseReceitas,
  orderExtratoTransactions,
  toMoneyFormat,
} from "../../utils/extratoFunctions";

import { getExtrato, getBankStatementSheet } from "../../redux/actions";

const Extrato = ({
  getExtrato,
  loadingExtrato,
  extrato,
  loadingBankStatementSheet,
  getBankStatementSheet
}) => {
  const printRef = useRef();

  const { contas, loadingContas } = useAccountStore();

  const [search, setSearch] = useState({
    conta_id: "",
    dataInicio: format(new Date(), "yyyy-MM-dd"),
    dataFim: format(new Date(), "yyyy-MM-dd"),
  });

  useEffect(() => { }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Relatório Consolidado`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  if (loadingContas || loadingExtrato)
    return (
      <Fragment>
        <Breadcrumb parent="Extrato" title="Contas" />
        <Container fluid={true}></Container>
        <div className="loader-box">
          <div className="loader-1"></div>
        </div>
      </Fragment>
    );

  const receitas = adaptReceitas(extrato?.receitas || []);
  const receitasRepasses = adaptRepasseReceitas(
    extrato?.repassesReceitas || []
  );
  const despesas = adaptDespesas(extrato?.despesas || []);
  const despesasRepasses = adaptRepasseDespesas(
    extrato?.repassesDespesas || []
  );

  const dataExtrato = orderExtratoTransactions(
    receitas,
    receitasRepasses,
    despesas,
    despesasRepasses
  );

  let saldoAtual = parseFloat(extrato.saldoInicial);

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Extrato" title="Contas" />
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                if (
                  !!search.dataInicio &&
                  !!search.dataFim &&
                  !!search.conta_id
                ) {
                  const conta = contas.find(({ id }) => search.conta_id == id);
                  if (
                    compareAsc(
                      parseISO(search.dataInicio),
                      parseISO(search.dataFim)
                    ) > 0
                  ) {
                    toast.error("Data Inicial não pode ser maior que a final", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  } else {
                    if (
                      compareAsc(
                        parseISO(conta.data_inicial),
                        parseISO(search.dataInicio)
                      ) > 0
                    ) {
                      toast.error(
                        `A data inicial da conta ${conta.conta} é ${format(
                          parseISO(conta.data_inicial),
                          "dd/MM/yyyy"
                        )}. Filtre a partir dessa data.`,
                        {
                          position: toast.POSITION.TOP_RIGHT,
                        }
                      );
                    } else {
                      getExtrato(search);
                    }
                  }
                } else {
                  toast.error("Preencha o formulário", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="conta"
                        labelKey="conta"
                        clearButton
                        placeholder="Selecione a conta"
                        options={contas.map(({ conta, ...c }) => ({
                          ...c,
                          conta: String(conta),
                        }))}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              conta_id: data[0]?.id,
                            });
                          } else {
                            setSearch({
                              ...search,
                              conta_id: "",
                            });
                          }
                        }}
                        selected={contas.filter(
                          ({ id }) => id == search.conta_id
                        )}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Inicial"
                        selected={
                          !!search.dataInicio ? parseISO(search.dataInicio) : ""
                        }
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataInicio: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Final"
                        selected={
                          !!search.dataFim ? parseISO(search.dataFim) : ""
                        }
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataFim: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12" style={{ marginTop: 10 }}>
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>

          {!!Object.keys(extrato).length && (
            <Fragment>
              <Col sm="12">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    minWidth: 300,
                  }}
                  className="mb-3"
                >
                  <Button
                    color="primary"
                    onClick={handlePrint}
                  >
                    Imprimir
                  </Button>

                  <Button
                    color="primary"
                    style={{ marginLeft: 15 }}
                    onClick={() => getBankStatementSheet({
                      accountId: search.conta_id,
                      periodStart: search.dataInicio,
                      periodEnd: search.dataFim
                    })}
                  >
                    Gerar Planilha
                  </Button>
                </div>
              </Col>

              <Col sm="12">
                <Print ref={printRef}>
                  <Card className="font-arial-rlt">
                    <CardBody>
                      <Col
                        sm="12"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: 20,
                        }}
                      >
                        <img
                          className="img-fluid for-light"
                          src={require("../../assets/images/logo/logo.jpg")}
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            objectFit: "contain",
                          }}
                        />

                        <h5>Extrato da Conta</h5>
                      </Col>

                      <Table bordered>
                        <tbody>
                          <tr>
                            <th>Descrição</th>
                            <th className="text-center">Data</th>
                            <th className="text-center">Valor</th>
                            <th className="text-center">Saldo</th>
                          </tr>

                          <tr>
                            <td>Saldo no início do período</td>
                            <td className="text-center">-</td>
                            <td className="text-center">-</td>
                            <td
                              className={`${parseFloat(extrato.saldoInicial) > 0
                                ? "text-success"
                                : "text-danger"
                                } text-center`}
                            >
                              {toMoneyFormat(extrato.saldoInicial)}
                            </td>
                          </tr>

                          {dataExtrato.map((transaction, key) => {
                            saldoAtual += transaction.valor_float;

                            return (
                              <tr key={key}>
                                <td>{transaction.descricao}</td>
                                <td className="text-center">{transaction.data}</td>
                                <td
                                  className={`${transaction.valor_float < 0
                                    ? "text-danger"
                                    : "text-success"
                                    } text-center`}
                                >
                                  {transaction.valor}
                                </td>
                                <td
                                  className={`${saldoAtual > 0 ? "text-success" : "text-danger"
                                    } text-center`}
                                >
                                  {toMoneyFormat(saldoAtual)}
                                </td>
                              </tr>
                            );
                          })}

                          <tr>
                            <td>Saldo no fim do período</td>
                            <td className="text-center">-</td>
                            <td className="text-center">-</td>
                            <td
                              className={`${saldoAtual > 0 ? "text-success" : "text-danger"
                                } text-center`}
                            >
                              {toMoneyFormat(saldoAtual)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                </Print>
              </Col>
            </Fragment>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({ Contas, Extrato, BankStatementSheet }) => {
  const { contas, loadingContas } = Contas;
  const { loadingExtrato, extrato } = Extrato;
  const { loadingBankStatementSheet } = BankStatementSheet;

  return {
    contas,
    loadingContas,
    loadingExtrato,
    extrato,
    loadingBankStatementSheet
  };
};

export default connect(mapStateToProps, {
  getExtrato,
  getBankStatementSheet
})(Extrato);
