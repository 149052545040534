import React, { useRef, Fragment } from "react";
import { Table, Card, CardBody, Row, Col, Button } from "reactstrap";
import { useReactToPrint } from "react-to-print";

import Print from "../../../components/Print";

import { useReportOneStore } from "../../../utils/reportOne";

const List = ({ user }) => {
  const { repasses } = useReportOneStore();

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Relatório de Repasses - ${user?.cidade?.name}`,
  });

  const TableRow = ({ descricao, data, valor, conta, prefixo, fornecedor }) => (
    <tr>
      <td>{descricao}</td>
      <td>{data}</td>
      <td>{valor}</td>
      <td>{conta}</td>
      <td>
        {prefixo}
        {fornecedor}
      </td>
    </tr>
  );

  if (!repasses.length) return null;

  return (
    <Fragment>
      <Col sm="12">
        <Button
          color="primary"
          className="mb-3 float-right"
          onClick={handlePrint}
        >
          Imprimir
        </Button>
      </Col>

      <Col sm="12">
        <Print ref={printRef}>
          <Card className="font-arial-rlt">
            <CardBody>
              <Row>
                <Col
                  sm="12"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <img
                    className="img-fluid for-light"
                    src={require("../../../assets/images/logo/logo.jpg")}
                    alt=""
                    style={{
                      width: "130px",
                      height: "130px",
                      objectFit: "contain",
                    }}
                  />

                  <h5>{user?.cidade?.name}</h5>
                </Col>
              </Row>
              <Table bordered>
                <tbody>
                  <tr>
                    <th className="text-center">Descrição - Fluxo</th>
                    <th className="text-center">Data</th>
                    <th className="text-center">Valor</th>
                    <th className="text-center">Conta Bancária</th>
                    <th className="text-center">Histórico / Fornecedor</th>
                  </tr>

                  {repasses.map(
                    (
                      { descricao, data, valor, conta, prefixo, fornecedor },
                      key
                    ) => (
                      <TableRow
                        conta={conta}
                        data={data}
                        descricao={descricao}
                        fornecedor={fornecedor}
                        prefixo={prefixo}
                        valor={valor}
                        key={key}
                      />
                    )
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Print>
      </Col>
    </Fragment>
  );
};

export default List;
