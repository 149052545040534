import {
    GET_SOLICITACOES_USER,
    SET_SOLICITACOES_USER,
    REMOVER_USER,
    GET_USERS,
    SET_USERS
} from '../actionTypes';

const INITIAL_STATE = {
    solicitacoes: [],
    loadingSolicitacoes: false,
    users: [],
    loadingUsers: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_SOLICITACOES_USER:
            return {
                ...state,
                loadingSolicitacoes: true
            };

        case SET_SOLICITACOES_USER:
            return {
                ...state,
                loadingSolicitacoes: false,
                solicitacoes: !!action.payload
                    ? action.payload
                    : state.solicitacoes
            };

        case REMOVER_USER:
            return {
                ...state,
                loadingSolicitacoes: true
            };

        case GET_USERS:
            return {
                ...state,
                loadingUsers: true
            };

        case SET_USERS:
            return {
                ...state,
                loadingUsers: false,
                users: !!action.payload
                    ? action.payload
                    : state.users
            };

        default:
            return state;
    }
};