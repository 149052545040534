import { GET_UPDATE_REPORT, SET_UPDATE_REPORT } from "../actionTypes";

const INITIAL_STATE = {
  loadingUpdateReport: false,
  updateReport: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_UPDATE_REPORT:
      return {
        ...state,
        loadingUpdateReport: true,
      };

    case SET_UPDATE_REPORT:
      return {
        ...state,
        loadingUpdateReport: false,
        updateReport: action.payload || state.updateReport,
      };

    default:
      return state;
  }
};
