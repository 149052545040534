import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb';
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from 'date-fns';
import { connect } from 'react-redux';
import { ThumbsUp, ThumbsDown } from 'react-feather';
import swal from 'sweetalert2';

import { getSolicitacoesUser, updateProfile, removerUser } from '../../redux/actions';

const SolicitacoesUser = ({
  solicitacoes,
  loadingSolicitacoes,
  getSolicitacoesUser,
  updateProfile,
  removerUser,
  groups,
  tiposFluxo,
  auth
}) => {

  const [user, setUser] = useState(null);
  const [group, setGroup] = useState(null);
  const [fluxos, setFluxos] = useState([]);

  useEffect(() => {
    getSolicitacoesUser();
  }, []);

  if (loadingSolicitacoes) return (
    <Fragment>
      <Breadcrumb parent="Gestão" title="Solicitações de Acesso" />
      <Container fluid={true}></Container>
      <div className="loader-box">
        <div className="loader-1"></div>
      </div>
    </Fragment>
  );

  let optionsGroups = groups;

  if (auth?.groups[0]?.id != 1) {
    optionsGroups = groups.filter(g => g.id != 1 && g.id != 2);
  }

  if (auth?.groups[0]?.id == 4) return (
    <Fragment>
      <Breadcrumb parent="Gestão" title="Solicitações de Acesso" />
      <Container fluid={true}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
            width: '100%',
          }}
        >
          <ThumbsDown
            color="red"
          />

          <h4 style={{ marginTop: 10 }}>Usuário não autorizado</h4>
        </div>
      </Container>
    </Fragment>
  );

  return (
    <Fragment>
      <Breadcrumb parent="Gestão" title="Solicitações de Acesso" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Estado</th>
                      <th>Cidade</th>
                      <th>Data</th>
                      <th>Ações</th>
                    </tr>
                  </thead>

                  <tbody>
                    {!solicitacoes.length && (
                      <tr>
                        <td colSpan={6} style={{ textAlign: 'center' }}>Não existem solicitações</td>
                      </tr>
                    )}

                    {solicitacoes.map((s, key) => (
                      <tr key={key}>
                        <td>{s.dados_pessoais.name}</td>
                        <td>{s.email}</td>
                        <td>{s.estado.name}</td>
                        <td>{s.cidade.name}</td>
                        <td>{format(parseISO(s.created_at), "dd/MM/yyyy 'às' HH:mm:ss")}</td>
                        <td>
                          <span
                            style={{
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setUser(s);
                            }}
                          >
                            <ThumbsUp
                              color="green"
                            />
                          </span>

                          <span
                            style={{
                              cursor: 'pointer',
                              marginLeft: 5
                            }}
                            onClick={async () => {
                              const { value } = await swal.fire({
                                title: 'Deseja realmente cancelar a solicitação ?',
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Sim',
                                cancelButtonText: 'Não',
                                reverseButtons: true
                              });

                              if (!!value) {
                                removerUser(s.id);
                              }
                            }}
                          >
                            <ThumbsDown
                              color="red"
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal
        size="lg"
        isOpen={!!user}
        toggle={() => setUser(null)}
        backdrop="static"
      >
        <Form
          onSubmit={e => {
            e.preventDefault();

            if (!!group && !!user?.id) {
              updateProfile(
                {
                  user_id: user?.id,
                  group_id: group,
                  active: 1,
                  fluxos
                },
                () => {
                  getSolicitacoesUser();
                }
              );

              setUser(null);
              setGroup(null);
            }
          }}
        >

          <ModalHeader toggle={() => setUser(null)}>
            Aceitar {user?.dados_pessoais?.name}
          </ModalHeader>

          <ModalBody>
            <Row>
              <Col md="6" xs="12">
                <FormGroup>
                  <Label>Perfil</Label>
                  <Typeahead
                    id="groups"
                    labelKey="name"
                    clearButton
                    placeholder="Selecione o perfil de acesso"
                    options={optionsGroups}
                    onChange={data => {
                      if (data?.length) {
                        setGroup(data[0].id);
                      }
                    }}
                    emptyLabel="Nenhum resultado encontrado"
                  />
                </FormGroup>
              </Col>

              <Col md="6" xs="12">
                <FormGroup>
                  <Label>Fluxos Permitidos</Label>
                  <Typeahead
                    id="fluxos"
                    labelKey="nome"
                    clearButton
                    placeholder="Selecione os fluxos"
                    options={tiposFluxo}
                    onChange={data => {
                      if (data?.length) {
                        setFluxos(data.map(d => d.id));
                      } else {
                        setFluxos([]);
                      }
                    }}
                    emptyLabel="Nenhum resultado encontrado"
                    multiple
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button color="danger" onClick={() => setUser(null)}>
              Fechar
            </Button>

            <Button color="primary">
              Salvar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

    </Fragment>
  );
}

const mapStateToProps = ({ Users, Groups, Transacoes, Auth }) => {
  const { solicitacoes, loadingSolicitacoes } = Users;
  const { groups } = Groups;
  const { tiposFluxo } = Transacoes;
  const { user } = Auth;

  return {
    solicitacoes,
    loadingSolicitacoes,
    groups,
    tiposFluxo,
    auth: user
  };
}

export default connect(mapStateToProps, {
  getSolicitacoesUser,
  updateProfile,
  removerUser
})(SolicitacoesUser);