import { call, put, takeLatest, fork, all, debounce } from 'redux-saga/effects'
import Api from "../../services/api";
import {
    GET_FUNCIONALIDADES,
    GET_FUNCIONALIDADES_ASYNC,
    LIST_FUNCIONALIDADES_ASYNC,
    LIST_FUNCIONALIDADES,
    STORE_FUNCIONALIDADES,
    STORE_FUNCIONALIDADES_ASYNC,
    UPDATE_FUNCIONALIDADES_ASYNC,
    UPDATE_FUNCIONALIDADES,
    DESTROY_FUNCIONALIDADES_ASYNC,
    DESTROY_FUNCIONALIDADES,
    GET_MODULOS,
    GET_PERMISSIONS
} from '../actionTypes';
import { toast } from 'react-toastify';

toast.configure();

function* getFuncionalidadesSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetFuncionalidade, fields);
        if (data.ok) {
            const { funcionalidades } = data;
            yield put({ type: GET_FUNCIONALIDADES, payload: { funcionalidades } });
        }
    } catch (error) {
        yield put({ type: GET_FUNCIONALIDADES, payload: {} });
    }
}
const apiGetFuncionalidade = async fields => {
    const { data } = await Api.get('/api/auth/funcionalidades', { params: fields });

    return data;
}

function* getListFuncionalidadesSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetListFuncionalidades, fields);
        if (data.ok) {
            let { list } = data;
            yield put({ type: LIST_FUNCIONALIDADES, payload: { list } });
        }
    } catch (error) {
        yield put({ type: LIST_FUNCIONALIDADES, payload: {} });
    }
}
const apiGetListFuncionalidades = async fields => {
    const { data } = await Api.get(`/api/auth/funcionalidades/list`, { params: { ...fields } });

    return data;
}

function* storeFuncionalidadesSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreFuncionalidades, fields);
        if (data.ok) {
            const { funcionalidades, modulos, permissions } = data;
            yield put({ type: GET_PERMISSIONS, payload: { permissions } });
            yield put({ type: GET_MODULOS, payload: { modulos } });
            yield put({ type: STORE_FUNCIONALIDADES, payload: { funcionalidades } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            })
            onSave();
        }
        if (!!errors) {
            yield put({ type: STORE_FUNCIONALIDADES, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_FUNCIONALIDADES, payload: {} });
    }
}
const apiStoreFuncionalidades = async fields => {
    const data = await Api.post(`/api/auth/funcionalidades/store`, { ...fields });

    return data;
}

function* updateFuncionalidadesSaga({ payload }) {
    try {
        const { fields, onUpdate } = payload;
        const { data, errors } = yield call(apiUpdateFuncionalidades, fields);
        if (data.ok) {
            const { funcionalidades, modulos, permissions } = data;

            yield put({ type: UPDATE_FUNCIONALIDADES, payload: { funcionalidades } });
            yield put({ type: GET_PERMISSIONS, payload: { permissions } });
            yield put({ type: GET_MODULOS, payload: { modulos } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            })
            onUpdate()
        }
        if (!!errors) {
            yield put({ type: UPDATE_FUNCIONALIDADES, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_FUNCIONALIDADES, payload: {} });
    }
}
const apiUpdateFuncionalidades = async fields => {
    const id = fields.id;
    delete fields.id;
    const data = Api.put(`/api/auth/funcionalidades/update/${id}`, { ...fields });

    return data;
}

function* destroyFuncionalidadesSaga({ payload }) {
    try {

        const { fields, onDelete } = payload;

        const data = yield call(apiDestroyFuncionalidades, fields);
        if (data.ok) {
            const { funcionalidades, modulos, permissions } = data;
            yield put({ type: GET_PERMISSIONS, payload: { permissions } });
            yield put({ type: GET_MODULOS, payload: { modulos } });
            yield put({ type: DESTROY_FUNCIONALIDADES, payload: { funcionalidades } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            })
            onDelete();
        }
    } catch (error) {

    }
}
const apiDestroyFuncionalidades = async fields => {
    const { data } = await Api.delete(`/api/auth/funcionalidades/destroy`, { data: { ...fields } });

    return data;
}

function* watchGetFuncionalidades() {
    yield takeLatest(GET_FUNCIONALIDADES_ASYNC, getFuncionalidadesSaga);
}

function* watchListFuncionalidades() {
    yield takeLatest(LIST_FUNCIONALIDADES_ASYNC, getListFuncionalidadesSaga);
}

function* watchStoreFuncionalidades() {
    yield takeLatest(STORE_FUNCIONALIDADES_ASYNC, storeFuncionalidadesSaga);
}

function* watchUpdateFuncionalidades() {
    yield takeLatest(UPDATE_FUNCIONALIDADES_ASYNC, updateFuncionalidadesSaga);
}

function* watchDestroyFuncionalidades() {
    yield takeLatest(DESTROY_FUNCIONALIDADES_ASYNC, destroyFuncionalidadesSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetFuncionalidades),
        fork(watchStoreFuncionalidades),
        fork(watchUpdateFuncionalidades),
        fork(watchDestroyFuncionalidades),
        fork(watchListFuncionalidades)
    ]);
}