import React, { Fragment, useState } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Form, Button } from 'reactstrap';
import { connect } from "react-redux";

import { changePassword } from '../../redux/actions'

const AlterarSenha = ({
    user,
    changePassword,
    loading
}) => {
    const [fields, setFields] = useState({
        atual: '',
        confirm_password: '',
        password: ''
    });

    const handleChange = e => {
        const { name, value } = e.target;

        setFields({ ...fields, [name]: value });
    }

    const handleSubmit = e => {
        e.preventDefault();

        changePassword(fields);
    }

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form onSubmit={handleSubmit}>
                            <Row style={{ textAlign: 'initial' }}>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="inputSenhaAtual">Senha Atual</Label>
                                        <Input
                                            id="inputSenhaAtual"
                                            type="password"
                                            name="atual"
                                            value={fields.atual}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="6">
                                    <FormGroup>
                                        <Label for="inputNovaSenha">Nova Senha</Label>
                                        <Input
                                            id="inputNovaSenha"
                                            type="password"
                                            name="password"
                                            value={fields.password}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" lg="6">
                                    <FormGroup>
                                        <Label for="inputCNovaSenha">Confirmar Nova Senha</Label>
                                        <Input
                                            id="inputCNovaSenha"
                                            type="password"
                                            name="confirm_password"
                                            value={fields.confirm_password}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" style={{ justifyContent: 'flex-end', display: "flex" }}>
                                    <Button color="primary" disabled={loading}>
                                        {!loading ? 'Salvar' : 'Carregando...'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Auth }) => {
    const { user, loading } = Auth;

    return { user, loading }
}

export default connect(mapStateToProps, {
    changePassword
})(AlterarSenha);