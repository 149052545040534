import { all, takeEvery, call, put, fork } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';

import api from '../../services/api';

import {
    GET_TIPOS_FLUXO,
    GET_TIPOS_TRANSACOES,
    GET_BLOCOS,
    GET_EVENTOS,
    ADD_BLOCO,
    ADD_EVENTO,
    GET_TRANSACOES,
    ADD_TRANSACAO,
    EDIT_TRANSACAO,
    DELETE_TRANSACAO,
    EDIT_EVENTO,
    DELETE_EVENTO,
    EDIT_BLOCO,
    DELETE_BLOCO
} from '../actionTypes';

import {
    setTiposFluxo,
    setTiposTransacoes,
    setBlocos,
    setEventos,
    setAddBloco,
    setAddEvento,
    setTransacoes,
    getTransacoes,
    notAuthorized,
    getBlocos,
    getEventos,
    getContas,
    getPrevisoes
} from '../actions';

function* sagaGetTiposFluxo() {
    try {
        const data = yield call(apiGetTiposFluxo);

        yield put(setTiposFluxo(data));
    } catch (err) {
        yield put(setTiposFluxo());
    }
}

const apiGetTiposFluxo = async () => {
    const { data } = await api.get('/api/auth/transacoes/tiposFluxo');

    return data;
}

function* sagaGetTiposTransacoes() {
    try {
        const data = yield call(apiGetTiposTransacoes);

        yield put(setTiposTransacoes(data));
    } catch (err) {
        yield put(setTiposTransacoes());
    }
}

const apiGetTiposTransacoes = async () => {
    const { data } = await api.get('/api/auth/transacoes/tiposTransacao');

    return data;
}

function* sagaGetBlocos() {
    try {
        const data = yield call(apiGetBlocos);

        yield put(setBlocos(data));
    } catch (err) {
        yield put(setBlocos());
    }
}

const apiGetBlocos = async () => {
    const { data } = await api.get('/api/auth/transacoes/blocos');

    return data;
}

function* sagaGetEventos() {
    try {
        const data = yield call(apiGetEventos);

        yield put(setEventos(data));
    } catch (err) {
        yield put(setEventos());
    }
}

const apiGetEventos = async () => {
    const { data } = await api.get('/api/auth/transacoes/eventos');

    return data;
}

function* sagaAddBloco({ payload }) {
    try {
        const data = yield call(apiAddBloco, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddBloco(data.data));
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddBloco());
        }

    } catch (err) {
        yield put(setAddBloco());

        toast.error('Não foi possível incluir o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiAddBloco = async payload => {
    const { data } = await api.post('/api/auth/transacoes/addBloco', payload);

    return data;
}

function* sagaAddEvento({ payload }) {
    try {
        const data = yield call(apiAddEvento, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento(data.data));
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento());
        }
    } catch (err) {
        yield put(setAddEvento());

        toast.error('Não foi possível incluir o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiAddEvento = async payload => {
    const { data } = await api.post('/api/auth/transacoes/addEvento', payload);

    return data;
}

function* sagaGetTransacoes({ payload }) {
    try {
        const data = yield call(apiGetTransacoes, payload);

        yield put(setTransacoes(data));
    } catch (err) {
        yield put(setTransacoes());
        yield put(notAuthorized());
    }
}

const apiGetTransacoes = async params => {
    const { data } = await api.get('/api/auth/transacoesPorData', { params });

    return data;
}

function* sagaAddTransacao({ payload, callback }) {

    try {
        const data = yield call(apiAddTransacao, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getTransacoes({
                tipo: payload.tipos_fluxos_id,
                dataInicio: payload.data,
                dataFim: payload.data
            }));

            yield put(getContas());

            yield put(
                getPrevisoes({
                    mes: format(new Date, 'MM'),
                    ano: format(new Date, 'yyyy')
                })
            );

            !!callback && typeof callback == 'function' && callback();
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento());
        }
    } catch (err) {
        yield put(setAddEvento());

        toast.error('Não foi possível incluir o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiAddTransacao = async payload => {
    const { data } = await api.post('/api/auth/transacoes/addTransacao', payload);

    return data;
}

function* sagaEditTransacao({ payload, callback }) {
    try {
        const data = yield call(apiEditTransacao, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getTransacoes({
                tipo: payload.tipos_fluxos_id,
                dataInicio: payload.data,
                dataFim: payload.data
            }));

            if (typeof callback == 'function') {
                callback();
            }
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento());
        }
    } catch (err) {
        yield put(setAddEvento());

        toast.error('Não foi possível atualizar o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiEditTransacao = async payload => {
    const { data } = await api.post(`/api/auth/transacoes/editTransacao/${payload.id}`, payload);

    return data;
}

function* sagaDeleteTransacao({ payload, callback }) {
    try {
        const data = yield call(apiDeleteTransacao, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getTransacoes({
                tipo: payload.tipos_fluxos_id,
                dataInicio: format(new Date, 'yyyy-MM-dd'),
                dataFim: format(new Date, 'yyyy-MM-dd'),
            }));

            if (typeof callback == 'function') {
                callback();
            }
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento());
        }
    } catch (err) {
        yield put(setAddEvento());

        toast.error('Não foi possível remover o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiDeleteTransacao = async payload => {
    const { data } = await api.delete(`/api/auth/transacoes/deleteTransacao/${payload.id}`);

    return data;
}

function* sagaEditEvento({ payload }) {
    try {
        const data = yield call(apiEditEvento, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getEventos());
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento());
        }
    } catch (err) {
        yield put(setAddEvento());

        toast.error('Não foi possível remover o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiEditEvento = async payload => {
    const { data } = await api.put(`/api/auth/transacoes/editEvento/${payload.id}`, payload);

    return data;
}

function* sagaDeleteEvento({ payload }) {
    try {
        const data = yield call(apiDeleteEvento, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getEventos());
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento());
        }
    } catch (err) {
        yield put(setAddEvento());

        toast.error('Não foi possível remover o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiDeleteEvento = async payload => {
    const { data } = await api.delete(`/api/auth/transacoes/deleteEvento/${payload.id}`);

    return data;
}

function* sagaEditBloco({ payload }) {
    try {
        const data = yield call(apiEditBloco, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getBlocos());
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento());
        }
    } catch (err) {
        yield put(setAddEvento());

        toast.error('Não foi possível remover o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiEditBloco = async payload => {
    const { data } = await api.put(`/api/auth/transacoes/editBloco/${payload.id}`, payload);

    return data;
}

function* sagaDeleteBloco({ payload }) {
    try {
        const data = yield call(apiDeleteBloco, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getBlocos());
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(setAddEvento());
        }
    } catch (err) {
        yield put(setAddEvento());

        toast.error('Não foi possível remover o registro.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiDeleteBloco = async payload => {
    const { data } = await api.delete(`/api/auth/transacoes/deleteBloco/${payload.id}`);

    return data;
}

function* watchGetTiposFluxo() {
    yield takeEvery(GET_TIPOS_FLUXO, sagaGetTiposFluxo);
}

function* watchGetTiposTransacoes() {
    yield takeEvery(GET_TIPOS_TRANSACOES, sagaGetTiposTransacoes);
}

function* watchGetBlocos() {
    yield takeEvery(GET_BLOCOS, sagaGetBlocos);
}

function* watchGetEventos() {
    yield takeEvery(GET_EVENTOS, sagaGetEventos);
}

function* watchAddBloco() {
    yield takeEvery(ADD_BLOCO, sagaAddBloco);
}

function* watchAddEvento() {
    yield takeEvery(ADD_EVENTO, sagaAddEvento);
}

function* watchGetTransacoes() {
    yield takeEvery(GET_TRANSACOES, sagaGetTransacoes);
}

function* watchAddTransacao() {
    yield takeEvery(ADD_TRANSACAO, sagaAddTransacao);
}

function* watchEditTransacao() {
    yield takeEvery(EDIT_TRANSACAO, sagaEditTransacao);
}

function* watchDeleteTransacao() {
    yield takeEvery(DELETE_TRANSACAO, sagaDeleteTransacao);
}

function* watchEditEvento() {
    yield takeEvery(EDIT_EVENTO, sagaEditEvento);
}

function* watchDeleteEvento() {
    yield takeEvery(DELETE_EVENTO, sagaDeleteEvento);
}

function* watchEditBloco() {
    yield takeEvery(EDIT_BLOCO, sagaEditBloco);
}

function* watchDeleteBloco() {
    yield takeEvery(DELETE_BLOCO, sagaDeleteBloco);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetTiposFluxo),
        fork(watchGetTiposTransacoes),
        fork(watchGetBlocos),
        fork(watchGetEventos),
        fork(watchAddBloco),
        fork(watchAddEvento),
        fork(watchGetTransacoes),
        fork(watchAddTransacao),
        fork(watchEditTransacao),
        fork(watchDeleteTransacao),
        fork(watchEditEvento),
        fork(watchDeleteEvento),
        fork(watchEditBloco),
        fork(watchDeleteBloco),
    ]);
}