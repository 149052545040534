import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Input, Button } from 'reactstrap'
import { connect } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import api from '../../services/api';
import { registerUserAsync } from '../../redux/actions';

const Register = ({
    loading,
    registerUserAsync
}) => {
    const [fields, setFields] = useState({
        email: '',
        password: '',
        estado_id: '',
        cidade_id: '',
        name: ''
    })

    const [estados, setEstados] = useState([])
    const [cidades, setCidades] = useState([])

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    useEffect(() => {
        getEstados()
    }, [])

    const getEstados = async () => {
        try {
            const { data } = await api.get('/api/auth/estados/show')

            setEstados(data?.estados)
        } catch (err) {
            console.log(err)
        }
    }

    const getCidades = async payload => {
        try {
            const { data } = await api.get(`/api/auth/cidades/show/${payload}`)

            setCidades(data?.cidades)
        } catch (err) {
            console.log(err)
        }
    }

    const handleSubmit = e => {
        e.preventDefault();

        registerUserAsync(fields);
    }

    return (
        <Form
            className="theme-form"
            onSubmit={handleSubmit}
        >
            <h4 className="text-center">CADASTRO</h4>
            <h6 className="text-center">Preencha os campos abaixo para cadastrar-se</h6>

            <Row>
                <Col md="12">
                    <FormGroup>
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="Nome Completo"
                            name="name"
                            onChange={handleChange}
                            required=""
                        />
                    </FormGroup>
                </Col>
                <Col md="12">
                    <FormGroup>
                        <Input
                            className="form-control"
                            type="text"
                            placeholder="E-mail"
                            name="email"
                            onChange={handleChange}
                            required=""
                        />
                    </FormGroup>
                </Col>

                <Col md="12">
                    <FormGroup>
                        <Input
                            className="form-control"
                            type="password"
                            onChange={handleChange}
                            name="password"
                            required=""
                            placeholder="Senha"
                        />
                    </FormGroup>
                </Col>

                <Col md="6" xs="12">
                    <FormGroup>
                        <Typeahead
                            id="selectEstado"
                            labelKey="name"
                            clearButton
                            placeholder="Estado"
                            options={estados.map(({ name, id }) => ({ name, id }))}
                            onChange={(estado) => {
                                if (!!estado.length) {
                                    const { id } = estado[0]
                                    setFields({
                                        ...fields,
                                        estado_id: id
                                    })
                                    getCidades(id)
                                }
                            }}
                            emptyLabel="Nenhum resultado encontrado"
                        />
                    </FormGroup>
                </Col>

                <Col md="6" xs="12">
                    <FormGroup>
                        <Typeahead
                            id="selectCidade"
                            labelKey="name"
                            clearButton
                            placeholder="Cidade"
                            options={cidades.map(({ name, id }) => ({ name, id }))}
                            onChange={(cidade) => {
                                if (!!cidade.length) {
                                    const { id } = cidade[0]
                                    setFields({
                                        ...fields,
                                        cidade_id: id
                                    })
                                }
                            }}
                            emptyLabel="Nenhum resultado encontrado"
                        />
                    </FormGroup>
                </Col>

                <Col sm="12">
                    <Button color="primary" type="submit" className="pull-right">Cadastrar-se</Button>
                </Col>
            </Row>

        </Form>
    );
}

const mapStateToProps = ({ Auth }) => {
    const { loading } = Auth;
    return { loading }
}

export default connect(mapStateToProps, {
    registerUserAsync
})(Register);