import { call, put, takeLatest, fork, all, takeEvery } from 'redux-saga/effects'
import { toast } from 'react-toastify';
import {
    LOGIN_USER,
    LOGIN_USER_ASYNC,
    LOGOUT_USER_ASYNC,
    LOGOUT_USER,
    REGISTER_USER_ASYNC,
    UPDATE_PROFILE,
    CHANGE_PASSWORD
} from '../actionTypes';

import {
    registerUser,
    setUpdateProfile
} from '../actions'
import api from '../../services/api';

function* loginUserSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiLoginUser, fields);
        if (data.ok) {
            const { token, user } = data;
            yield put({ type: LOGIN_USER, payload: { token, user } });
            window.location.replace('/home');
        }
    } catch (error) {
        yield put({ type: LOGIN_USER, payload: {} })
    }
}
const apiLoginUser = async fields => {
    const { data } = await api.post(`/api/auth/login`, { ...fields });

    return data;
}

function* logoutUserSaga() {
    try {
        const data = yield call(apiLogoutUser);
        if (data.ok) {
            localStorage.removeItem('persist:root');
            window.location.replace('/login');

        }
    } catch (error) {

    }
}
const apiLogoutUser = async () => {
    const { data } = await api.post(`/api/auth/logout`);

    return data;
}

function* sagaRegisterUser({ payload }) {
    try {
        const data = yield call(apiRegisterUser, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            })
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }

        yield put(registerUser());
    } catch (err) {
        yield put(registerUser());
    }
}

const apiRegisterUser = async payload => {
    const { data } = await api.post('/api/auth/user/add', payload);

    return data;
}

function* sagaUpdateProfile({ payload, callback }) {
    try {
        const data = yield call(apiUpdateProfile, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });

            if (!payload.user_id)
                yield put(setUpdateProfile(data.user));
            else
                yield put(setUpdateProfile());

            if (!!callback)
                callback()

        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });

            yield put(setUpdateProfile());
        }
    } catch (err) {
        yield put(setUpdateProfile());
    }
}

const apiUpdateProfile = async payload => {
    const { data } = await api.post('/api/auth/updateProfile', payload);

    return data;
}

function* sagaChangePassword({ payload }) {
    try {
        const data = yield call(apiChangePassword, payload)

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

        yield put(setUpdateProfile());
    } catch (err) {
        yield put(setUpdateProfile());

        toast.error('Não foi possível alterar a senha. Tente novamente mais tarde.', {
            position: toast.POSITION.TOP_RIGHT,
        });
    }
}

const apiChangePassword = async payload => {
    const { data } = await api.post('/api/auth/changePassword', payload);

    return data;
}

function* watchLoginUser() {
    yield takeLatest(LOGIN_USER_ASYNC, loginUserSaga);
}
function* watchLogoutUser() {
    yield takeLatest(LOGOUT_USER_ASYNC, logoutUserSaga);
}

function* watchRegisterUser() {
    yield takeLatest(REGISTER_USER_ASYNC, sagaRegisterUser);
}

function* watchUpdateProfile() {
    yield takeEvery(UPDATE_PROFILE, sagaUpdateProfile);
}

function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, sagaChangePassword)
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchUpdateProfile),
        fork(watchChangePassword)
    ]);
}