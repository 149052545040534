import { format } from "date-fns";

import {
  GET_GRUPOS,
  ADD_GRUPOS,
  SET_GRUPOS,
  DESVINCULAR_GRUPOS,
  VINCULAR_GRUPOS,
  GET_CONSOLIDADO,
  SET_CONSOLIDADO,
  EDIT_GRUPOS,
  DELETE_GRUPOS,
} from "../actionTypes";

const INITIAL_STATE = {
  grupos: [],
  loadingGrupos: false,
  loadingConsolidado: false,
  transacoes: [],
  previsoes: [],
  dataInicio: format(new Date(), "yyyy-MM-dd"),
  dataFim: format(new Date(), "yyyy-MM-dd"),
  tipo: '',
  prefeitura: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONSOLIDADO:
      return {
        ...state,
        loadingConsolidado: true,
      };

    case SET_CONSOLIDADO:
      return {
        ...state,
        loadingConsolidado: false,
        transacoes: !!action.payload?.transacoes
          ? action.payload.transacoes
          : [],
        previsoes: !!action.payload?.previsoes ? action.payload.previsoes : [],
        dataInicio: action.payload?.dataInicio,
        dataFim: action.payload?.dataFim,
        tipo: action.payload?.tipo,
        prefeitura: action.payload?.prefeitura,
      };

    case GET_GRUPOS:
      return {
        ...state,
        loadingGrupos: true,
      };

    case SET_GRUPOS:
      return {
        ...state,
        loadingGrupos: false,
        grupos: !!action.payload ? action.payload : state.grupos,
      };

    case ADD_GRUPOS:
      return {
        ...state,
        loadingGrupos: true,
      };

    case EDIT_GRUPOS:
      return {
        ...state,
        loadingGrupos: true,
      };

    case DELETE_GRUPOS:
      return {
        ...state,
        loadingGrupos: true,
      };

    case VINCULAR_GRUPOS:
      return {
        ...state,
        loadingGrupos: true,
      };

    case DESVINCULAR_GRUPOS:
      return {
        ...state,
        loadingGrupos: true,
      };

    default:
      return state;
  }
};
