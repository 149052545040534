import { Settings, DollarSign } from 'react-feather'

export const MENUITEMS = [
    {
        title: 'Gestão', icon: Settings, type: 'sub', active: true, children: [
            { path: `${process.env.PUBLIC_URL}/home`, title: 'Página Inicial', type: 'link' },
            // { path: `${process.env.PUBLIC_URL}/projetado`, title: 'Fluxo Projetado', type: 'link' },
            // { path: `${process.env.PUBLIC_URL}/solicitacoesUser`, title: 'Solicitaçoes de Acesso', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/users`, title: 'Usuários Ativos', type: 'link', groups: [1, 3] },
            { path: `${process.env.PUBLIC_URL}/blocos`, title: 'Blocos da Saúde', type: 'link' },
        ]
    },
    {
        title: 'Fluxo de Caixa', icon: DollarSign, type: 'sub', active: false, children: [
            { path: `${process.env.PUBLIC_URL}/transacoes/1`, title: 'Administração', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/transacoes/2`, title: 'Saúde', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/transacoes/3`, title: 'Educação', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/transacoes/4`, title: 'Assistência Social', type: 'link' },
        ]
    },
    {
        title: 'Relatório Consolidado', icon: Settings, type: 'sub', active: false, children: [
            { path: `${process.env.PUBLIC_URL}/consolidado/1`, title: 'Administração', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/consolidado/2`, title: 'Saúde', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/consolidado/3`, title: 'Educação', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/consolidado/4`, title: 'Assistência Social', type: 'link' },
        ]
    },
    {
        title: 'Contas a Pagar/Receber', icon: Settings, type: 'sub', active: false, children: [
            { path: `${process.env.PUBLIC_URL}/pagar/1`, title: 'Administração', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/pagar/2`, title: 'Saúde', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/pagar/3`, title: 'Educação', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/pagar/4`, title: 'Assistência Social', type: 'link' },
        ]
    },
    {
        title: 'Contas Bancárias', icon: Settings, type: 'sub', active: false, children: [
            { path: `${process.env.PUBLIC_URL}/bankAccountFiles`, title: 'Anexos', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/bank_balance`, title: 'Saldo Bancário', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/check_balance`, title: 'Checar Saldo', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/conciliar`, title: 'Confrontação de Saldo', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/contasGrupos`, title: 'Grupos', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/transferencias`, title: 'Transferências/Empréstimos', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/extrato`, title: 'Extrato', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/contas/1`, title: 'Administração', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/contas/2`, title: 'Saúde', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/contas/3`, title: 'Educação', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/contas/4`, title: 'Assistência Social', type: 'link' },
        ]
    },
    {
        title: 'Novos Cadastros', icon: Settings, type: 'sub', active: false, children: [
            { path: `${process.env.PUBLIC_URL}/tipos`, title: 'Receitas/Despesas', type: 'link', groups: [1] },
            { path: `${process.env.PUBLIC_URL}/transacoesGrupos`, title: 'Grupos', type: 'link', groups: [1] },
            { path: `${process.env.PUBLIC_URL}/fornecedores`, title: 'Fornecedores', type: 'link' },
            { path: `${process.env.PUBLIC_URL}/mensagens`, title: 'Mensagens', type: 'link', groups: [1] },
        ]
    },
    {
        title: 'Relatórios', icon: Settings, type: 'sub', active: false, children: [
            { path: `${process.env.PUBLIC_URL}/report1`, title: 'Repasses', type: 'link', groups: [1, 5, 6] },
            { path: `${process.env.PUBLIC_URL}/consolidated_tabled`, title: 'Consolidado Tabelado', type: 'link', groups: [1, 5, 6] },
            { path: `${process.env.PUBLIC_URL}/update_report`, title: 'Atualizações', type: 'link', groups: [1, 5, 6] },
            { path: `${process.env.PUBLIC_URL}/generalBankStatement`, title: 'Extrato Geral', type: 'link', groups: [1, 5, 6] },
            { path: `${process.env.PUBLIC_URL}/quarterlyConsolidated`, title: 'Consolidado Trimestral', type: 'link', groups: [1, 5, 6] },
        ]
    },
]