import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import sweetalert2 from "sweetalert2";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
  CardHeader,
  CardTitle,
} from "reactstrap";

import { Typeahead } from "react-bootstrap-typeahead";
import { format, parseISO } from "date-fns";
import { ThumbsDown, Edit, List, Delete } from "react-feather";

import Breadcrumb from "../../layout/breadcrumb";

import {
  getPrevisoes,
  addPrevisoes,
  editPrevisao,
  deletePrevisao,
} from "../../redux/actions";

import "react-datepicker/dist/react-datepicker.css";
import { es } from "date-fns/esm/locale";

const EditPrevisto = ({
  add = null,
  onDismiss = () => {},
  tiposFluxo,
  tiposTransacoes,
  blocos,
  eventos,
  loadingPrevisoes,
  editPrevisao,
  user,
  grupos,
  loadingFornecedores,
  fornecedores,
}) => {
  const { tipo } = useParams();

  const [fields, setFields] = useState({
    tipos_transacoes_id: "",
    transacoes_eventos_id: "",
    tipos_fluxos_id: "",
    transacoes_blocos_id: null,
    valor: 0,
    data: format(new Date(), "yyyy-MM-dd"),
    transacoes_grupo_id: null,
    fornecedor_id: null,
  });

  useEffect(() => {
    if (!add) {
      setFields({
        tipos_transacoes_id: "",
        transacoes_eventos_id: "",
        tipos_fluxos_id: tipo,
        transacoes_blocos_id: null,
        valor: 0,
        mes: format(new Date(), "MM"),
        ano: format(new Date(), "yyyy"),
        transacoes_grupo_id: null,
        fornecedor_id: null,
      });
    } else {
      setFields({
        ...add,
        valor: parseFloat(add.valor),
      });
    }
  }, [add]);

  useEffect(() => {
    setFields({
      ...fields,
      tipos_fluxos_id: tipo,
    });
  }, [tipo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      data,
      tipos_transacoes_id,
      transacoes_blocos_id,
      transacoes_eventos_id,
      valor,
      tipos_fluxos_id,
    } = fields;

    if (tipos_fluxos_id == 2) {
      const conditions =
        tipos_transacoes_id == 1
          ? !!data &&
            !!tipos_transacoes_id &&
            !!transacoes_blocos_id &&
            !!transacoes_eventos_id &&
            !!valor &&
            tipos_fluxos_id
          : !!data &&
            !!tipos_transacoes_id &&
            !!transacoes_eventos_id &&
            !!valor &&
            tipos_fluxos_id;

      if (conditions) {
        editPrevisao(fields);

        onDismiss();

        setFields({
          tipos_transacoes_id: "",
          transacoes_eventos_id: "",
          tipos_fluxos_id: tipo,
          transacoes_blocos_id: null,
          valor: 0,
          mes: format(new Date(), "MM"),
          ano: format(new Date(), "yyyy"),
          transacoes_grupo_id: null,
          fornecedor_id: null,
        });
      } else {
        toast.error("Preencha todos os campos para prosseguir", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      if (
        !!data &&
        !!tipos_transacoes_id &&
        !!transacoes_eventos_id &&
        !!valor &&
        !!tipos_fluxos_id
      ) {
        editPrevisao(fields);

        onDismiss();

        setFields({
          tipos_transacoes_id: "",
          transacoes_eventos_id: "",
          tipos_fluxos_id: tipo,
          transacoes_blocos_id: null,
          valor: 0,
          mes: format(new Date(), "MM"),
          ano: format(new Date(), "yyyy"),
          transacoes_grupo_id: null,
          fornecedor_id: null,
        });
      } else {
        toast.error("Preencha todos os campos para prosseguir", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const filtroEventos = eventos.filter((e) => {
    if (fields.tipos_fluxos_id != 2) {
      return (
        e.tipos_transacoes_id == fields.tipos_transacoes_id &&
        e.transacoes_grupo_id == fields.transacoes_grupo_id &&
        e.tipos_fluxos_id == fields.tipos_fluxos_id
      );
    } else {
      if (fields.tipos_transacoes_id == 1)
        return (
          e.tipos_transacoes_id == fields.tipos_transacoes_id &&
          e.transacoes_grupo_id == fields.transacoes_grupo_id &&
          e.tipos_fluxos_id == fields.tipos_fluxos_id
        );
      else
        return (
          e.tipos_transacoes_id == fields.tipos_transacoes_id &&
          e.transacoes_grupo_id == fields.transacoes_grupo_id &&
          e.tipos_fluxos_id == fields.tipos_fluxos_id
        );
    }
  });

  const optionsGrupos = grupos.filter(
    ({ tipos_fluxos_id, tipos_transacoes_id }) =>
      tipos_fluxos_id == fields.tipos_fluxos_id &&
      tipos_transacoes_id == fields.tipos_transacoes_id
  );

  return (
    <Modal
      size="lg"
      isOpen={add}
      toggle={() => {
        onDismiss();
      }}
      backdrop="static"
    >
      <Form onSubmit={handleSubmit}>
        <ModalHeader
          toggle={() => {
            onDismiss();
          }}
        >
          Incluir
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col md="6" xs="12">
              <FormGroup>
                <Label>Fluxo</Label>
                <Typeahead
                  id="fluxo"
                  labelKey="nome"
                  clearButton
                  placeholder="Selecione o fluxo"
                  options={
                    user?.groups[0]?.id == 4
                      ? tiposFluxo.filter((t) =>
                          user?.fluxos.map((f) => f.id).includes(t.id)
                        )
                      : tiposFluxo
                  }
                  onChange={(data) => {
                    if (data?.length) {
                      setFields({
                        ...fields,
                        tipos_fluxos_id: data[0].id,
                      });
                    } else {
                      setFields({
                        ...fields,
                        tipos_fluxos_id: null,
                      });
                    }
                  }}
                  emptyLabel="Nenhum resultado encontrado"
                  selected={tiposFluxo.filter(
                    ({ id }) => id == fields.tipos_fluxos_id
                  )}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col md="6" xs="12">
              <FormGroup>
                <Label>Tipo de Transação</Label>
                <Typeahead
                  id="transacao"
                  labelKey="nome"
                  clearButton
                  placeholder="Selecione o tipo"
                  options={tiposTransacoes}
                  onChange={(data) => {
                    if (data?.length) {
                      setFields({
                        ...fields,
                        tipos_transacoes_id: data[0].id,
                      });
                    } else {
                      setFields({
                        ...fields,
                        tipos_transacoes_id: null,
                      });
                    }
                  }}
                  emptyLabel="Nenhum resultado encontrado"
                  selected={tiposTransacoes.filter(
                    ({ id }) => id == fields.tipos_transacoes_id
                  )}
                />
              </FormGroup>
            </Col>

            {fields.tipos_fluxos_id == 2 && fields.tipos_transacoes_id == 1 && (
              <Col md="6" xs="12">
                <FormGroup>
                  <Label>Bloco</Label>
                  <Typeahead
                    id="bloco"
                    labelKey="nome"
                    clearButton
                    placeholder="Selecione o bloco"
                    options={blocos}
                    onChange={(data) => {
                      if (data?.length) {
                        setFields({
                          ...fields,
                          transacoes_blocos_id: data[0].id,
                        });
                      } else {
                        setFields({
                          ...fields,
                          transacoes_blocos_id: null,
                        });
                      }
                    }}
                    emptyLabel="Nenhum resultado encontrado"
                    selected={blocos.filter(
                      ({ id }) => id == fields.transacoes_blocos_id
                    )}
                  />
                </FormGroup>
              </Col>
            )}

            {!!fields.tipos_fluxos_id && !!fields.tipos_transacoes_id && (
              <Col md="12" xs="12">
                <FormGroup>
                  <Label>Grupo</Label>
                  <Typeahead
                    id="tipo"
                    labelKey="nome"
                    clearButton
                    placeholder="Selecione o tipo"
                    options={optionsGrupos}
                    onChange={(data) => {
                      if (data?.length) {
                        setFields({
                          ...fields,
                          transacoes_grupo_id: data[0].id,
                          transacoes_eventos_id: null,
                        });
                      } else {
                        setFields({
                          ...fields,
                          transacoes_grupo_id: null,
                          transacoes_eventos_id: null,
                        });
                      }
                    }}
                    emptyLabel="Nenhum resultado encontrado"
                    selected={optionsGrupos.filter(
                      ({ id }) => id == fields.transacoes_grupo_id
                    )}
                  />
                </FormGroup>
              </Col>
            )}

            {!!fields.tipos_fluxos_id &&
              !!fields.tipos_transacoes_id &&
              !!fields.transacoes_grupo_id && (
                <Col md="12" xs="12">
                  <FormGroup>
                    <Label>Subgrupo</Label>
                    <Typeahead
                      id="tipo"
                      labelKey="nome"
                      clearButton
                      placeholder="Selecione o tipo"
                      options={filtroEventos}
                      onChange={(data) => {
                        if (data?.length) {
                          setFields({
                            ...fields,
                            transacoes_eventos_id: data[0].id,
                          });
                        } else {
                          setFields({
                            ...fields,
                            transacoes_eventos_id: null,
                          });
                        }
                      }}
                      emptyLabel="Nenhum resultado encontrado"
                      selected={filtroEventos.filter(
                        ({ id }) => id == fields.transacoes_eventos_id
                      )}
                    />
                  </FormGroup>
                </Col>
              )}

            <Col md="6" xs="12">
              <FormGroup>
                <Label>Valor Previsto</Label>
                <NumberFormat
                  placeholder="Informe o valor"
                  thousandSeparator="."
                  customInput={Input}
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                  onValueChange={({ floatValue }) => {
                    setFields({
                      ...fields,
                      valor: floatValue || 0,
                    });
                  }}
                  value={fields.valor}
                />
              </FormGroup>
            </Col>

            <Col md="6" xs="12">
              <FormGroup>
                <Label>Data Prevista</Label>
                <DatePicker
                  locale={br}
                  autoComplete="off"
                  placeholderText="Selecione a Data"
                  // minDate={new Date(1930, 0, 1)}
                  // maxDate={new Date()}
                  selected={!!fields?.data ? parseISO(fields.data) : null}
                  onCalendarClose={() => {}}
                  dateFormat="dd/MM/yyyy"
                  className="form-control digits"
                  onChange={(selected) => {
                    setFields({
                      ...fields,
                      data: format(selected, "yyyy-MM-dd"),
                    });
                  }}
                />
              </FormGroup>
            </Col>

            <Col md="6" xs="12">
              <FormGroup>
                <Label>Fornecedor</Label>
                <Typeahead
                  id="fornecedor"
                  labelKey="nome"
                  clearButton
                  placeholder="Selecione o fornecedor"
                  options={fornecedores}
                  onChange={(data) => {
                    if (data?.length) {
                      setFields({
                        ...fields,
                        fornecedor_id: data[0].id,
                      });
                    } else {
                      setFields({
                        ...fields,
                        fornecedor_id: null,
                      });
                    }
                  }}
                  emptyLabel="Nenhum resultado encontrado"
                  selected={fornecedores.filter(
                    ({ id }) => id == fields.fornecedor_id
                  )}
                />
              </FormGroup>
            </Col>

            <Col md="12" xs="12">
              <FormGroup>
                <Label>Observação/Discriminação</Label>
                <Input
                  type="textarea"
                  name="observacao"
                  value={fields.observacao || ""}
                  onChange={handleChange}
                  placeholder="Informe a observação"
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            color="danger"
            onClick={() => {
              onDismiss();
            }}
          >
            Fechar
          </Button>

          <Button color="primary" disabled={loadingPrevisoes}>
            {loadingPrevisoes ? "Carregando..." : "Salvar"}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({
  Transacoes,
  Previsoes,
  Auth,
  Consolidado,
  Fornecedores,
}) => {
  const { tiposFluxo, tiposTransacoes, blocos, eventos } = Transacoes;

  const { user } = Auth;
  const { previsoes, loadingPrevisoes } = Previsoes;
  const { grupos } = Consolidado;
  const { loadingFornecedores, fornecedores } = Fornecedores;

  return {
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    previsoes,
    loadingPrevisoes,
    user,
    grupos,
    loadingFornecedores,
    fornecedores,
  };
};

export default connect(mapStateToProps, {
  editPrevisao,
})(EditPrevisto);
