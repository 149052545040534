import { format } from "date-fns";

import {
  ADD_BANK_ACCOUNT_FILES,
  GET_BANK_ACCOUNT_FILES,
  SET_BANK_ACCOUNT_FILES,
  DOWNLOAD_BANK_ACCOUNT_FILES,
} from "../actionTypes";

const INITIAL_STATE = {
  files: [],
  loadingFiles: false,
  lastSearch: {
    flow: null,
    account_id: null,
    created_at: format(new Date(), "yyyy-MM-dd"),
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_BANK_ACCOUNT_FILES:
      return {
        ...state,
        loadingFiles: true,
      };

    case GET_BANK_ACCOUNT_FILES:
      return {
        ...state,
        loadingFiles: true,
        lastSearch: action.payload,
      };

    case DOWNLOAD_BANK_ACCOUNT_FILES:
      return {
        ...state,
        loadingFiles: true,
      };

    case SET_BANK_ACCOUNT_FILES:
      return {
        ...state,
        ...action.payload,
        loadingFiles: false,
      };

    default:
      return state;
  }
};
