/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, useRef } from "react";

import { connect } from "react-redux";
import sweetalert2 from "sweetalert2";
import { useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { Check, Delete, Edit } from "react-feather";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { Typeahead } from "react-bootstrap-typeahead";
import { useReactToPrint } from "react-to-print";

import NotAuthorized from "../../components/NotAuthorized";
import Breadcrumb from "../../layout/breadcrumb";
import Print from "../../components/Print";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Badge,
  Form,
  Input,
} from "reactstrap";

import { getPrevisoes, deletePrevisao } from "../../redux/actions";

import { useCashFlowTypeStore } from "../../utils/cashflowType";
import { moneyConvert } from "../../utils/moneyConvert";

import Add from "./add";
import AddPrevisto from "./addPrevisto";
import EditPrevisto from "./editPrevisto";
import Efetuadas from "./efetuadas";

const Pagar = ({
  user,
  previsoes,
  loadingPrevisoes,
  getPrevisoes,
  deletePrevisao,
}) => {
  const printRef = useRef();

  const { tipo } = useParams();

  const [add, setAdd] = useState(false);
  const [open, setOpen] = useState(null);
  const [edit, setEdit] = useState(null);
  const [editTransacao, setEditTransacao] = useState(null);
  const [efetuada, setEfetuada] = useState(null);

  const [search, setSearch] = useState({
    mes: format(new Date(), "MM"),
    ano: format(new Date(), "yyyy"),
  });

  useEffect(() => {
    getPrevisoes({
      mes: format(new Date(), "MM"),
      ano: format(new Date(), "yyyy"),
    });
  }, []);

  const { tiposFluxo } = useCashFlowTypeStore();

  useEffect(() => {
    if (!!efetuada) {
      setEfetuada(previsoes.find(({ id }) => id === efetuada.id));
    }
  }, [previsoes]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Contas a pagar/receber`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  if (loadingPrevisoes)
    return (
      <Fragment>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Breadcrumb parent="Cadastros" title="Pagar/Receber" />

          <Button
            color="primary"
            onClick={() => {
              setAdd(true);
            }}
            disabled
          >
            Incluir
          </Button>
        </div>

        <div className="loader-box">
          <div className="loader-1"></div>
        </div>
      </Fragment>
    );

  const optionsMes = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];

  const dev = [1, 5].includes(user?.groups[0]?.id);

  const fluxoAtualNome = tiposFluxo.find(({ id }) => id == tipo)?.nome;
  const listPredicted = previsoes.filter(
    ({ tipos_fluxos_id }) => tipos_fluxos_id == tipo
  );

  if (!fluxoAtualNome)
    return (
      <Fragment>
        <Breadcrumb parent="Pagar/Receber" title="Não autorizado" />

        <NotAuthorized />
      </Fragment>
    );

  const ListPayment = ({ transactions, title }) => (
    <Table bordered>
      <tbody>
        <tr>
          <th colSpan={7} className="text-center">
            {title}
          </th>
        </tr>
        <tr>
          <th style={{ width: "20%" }}>Recurso</th>
          <th style={{ width: "15%" }}>Fornecedor</th>
          <th
            style={{
              width: "15%",
              textAlign: "center",
            }}
          >
            Data Prevista
          </th>
          <th
            style={{
              width: "15%",
              textAlign: "center",
            }}
          >
            Valor Previsto
          </th>
          <th
            style={{
              width: "10%",
              textAlign: "center",
            }}
          >
            Situação
          </th>
          <th
            style={{
              width: "15%",
              textAlign: "center",
            }}
          >
            Valor Realizado
          </th>
          <th
            className="noPrint"
            style={{
              width: "10%",
              textAlign: "center",
            }}
          >
            Ações
          </th>
        </tr>

        {transactions.map((t, key) => (
          <tr key={key}>
            <td>{t.evento?.nome}</td>
            <td>{t.fornecedor?.nome || "Não informado"}</td>
            <td style={{ textAlign: "center" }}>
              {format(parseISO(t.data), "dd/MM/yyyy")}
            </td>
            <td style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("pt-Br", {
                currency: "BRL",
                style: "currency",
              }).format(parseFloat(t.valor))}
            </td>
            <td style={{ textAlign: "center" }}>
              <Badge
                color={!!t.transacoes.length ? "success" : "danger"}
                style={{
                  cursor: !!t.transacoes.length ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (!!t.transacoes.length) {
                    setEfetuada(t);
                  }
                }}
              >
                {!!t.transacoes.length
                  ? `${t.transacoes.length} ${t.tipos_transacoes_id == 1 ? "recebido" : "pago"
                  }${t.transacoes.length > 1 ? "s" : ""}`
                  : t.tipos_transacoes_id == 1
                    ? "receber"
                    : "pagar"}
              </Badge>
            </td>
            <td style={{ textAlign: "center" }}>
              {new Intl.NumberFormat("pt-Br", {
                currency: "BRL",
                style: "currency",
              }).format(
                t.transacoes.reduce(
                  (prev, current) => prev + parseFloat(current.valor),
                  0
                )
              )}
            </td>
            <td className="noPrint" style={{ textAlign: "center" }}>
              <span
                style={{
                  cursor: "pointer",
                  marginLeft: 5,
                }}
                onClick={async () => {
                  const confirm = await sweetalert2.fire({
                    title: "Deseja realmente realizar a conta ?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Sim",
                    cancelButtonText: "Não",
                    reverseButtons: true,
                  });

                  if (confirm.value) {
                    const data = { ...t };
                    data.previsao_id = data.id;
                    delete data.id;
                    setOpen(data);
                  }
                }}
              >
                <Check size={18} />
              </span>

              {dev && (
                <span
                  style={{
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                  onClick={async () => {
                    setEdit(t);
                  }}
                >
                  <Edit size={18} />
                </span>
              )}

              {dev && !t.transacoes.length && (
                <span
                  style={{
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                  onClick={async () => {
                    const confirm = await sweetalert2.fire({
                      title: "Deseja realmente excluir ?",
                      text: `${t.evento?.nome} previsto para dia ${format(parseISO(t.data), "dd/MM/yyyy")} no valor de ${moneyConvert(parseFloat(t.valor))}`,
                      icon: "question",
                      showCancelButton: true,
                      confirmButtonText: "Sim",
                      cancelButtonText: "Não",
                      reverseButtons: true,
                    });

                    if (confirm.value) {
                      deletePrevisao(t);
                    }
                  }}
                >
                  <Delete size={18} />
                </span>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Cadastros" title="Pagar/Receber" />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: 300,
          }}
        >
          {dev && (
            <Button
              color="primary"
              onClick={() => {
                setAdd(true);
              }}
            >
              Incluir Previsto
            </Button>
          )}

          <Button
            color="primary"
            onClick={() => {
              setOpen({});
            }}
            style={{ marginLeft: 15 }}
          >
            Incluir
          </Button>
        </div>
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                getPrevisoes(search);
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="5">
                      <Typeahead
                        id="mes"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o mês"
                        options={optionsMes}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              mes: data[0].id,
                            });
                          }
                        }}
                        defaultSelected={optionsMes.filter(
                          (o) => o.id == search.mes
                        )}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="5">
                      <Input
                        type="number"
                        value={search.ano}
                        onChange={({ target: { value } }) => {
                          setSearch({
                            ...search,
                            ano: value,
                          });
                        }}
                        placeholder="Informe o ano"
                      />
                    </Col>

                    <Col sm="2">
                      <Button color="primary">Pesquisar</Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Button
              color="primary"
              className="mb-3 float-right"
              onClick={handlePrint}
            >
              Imprimir
            </Button>
          </Col>

          <Col sm="12">
            <Print ref={printRef}>
              <Card className="font-arial-rlt">
                <CardBody>
                  <Col
                    sm="12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <img
                      className="img-fluid for-light"
                      src={require("../../assets/images/logo/logo.jpg")}
                      alt=""
                      style={{
                        width: "130px",
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />

                    <h5>
                      {user?.cidade?.name} - {fluxoAtualNome}
                    </h5>
                  </Col>

                  <div className="table-responsive">
                    <ListPayment
                      title="Contas a Receber"
                      transactions={listPredicted.filter(
                        ({ tipos_transacoes_id }) => tipos_transacoes_id == 1
                      )}
                    />
                  </div>

                  <div className="table-responsive" style={{ marginTop: 30 }}>
                    <ListPayment
                      title="Contas a Pagar"
                      transactions={listPredicted.filter(
                        ({ tipos_transacoes_id }) => tipos_transacoes_id != 1
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
            </Print>
          </Col>
        </Row>
      </Container>

      <AddPrevisto onDismiss={() => setAdd(false)} add={add} />

      <Add
        onDismiss={() => {
          if (!!editTransacao) {
            setEditTransacao(null);
          } else {
            setOpen(null);
          }
        }}
        open={editTransacao || open}
        reload={() => getPrevisoes(search)}
      />

      <EditPrevisto add={edit} onDismiss={() => setEdit(null)} />

      <Efetuadas
        onChosen={(t) => setEditTransacao(t)}
        onDismiss={() => setEfetuada(null)}
        open={efetuada}
        reload={() => getPrevisoes(search)}
      />
    </Fragment>
  );
};

const mapStateToProps = ({ Auth, Previsoes, Transacoes }) => {
  const { user } = Auth;
  const { previsoes, loadingPrevisoes } = Previsoes;

  return {
    user,
    previsoes,
    loadingPrevisoes,
  };
};

export default connect(mapStateToProps, {
  getPrevisoes,
  deletePrevisao,
})(Pagar);
