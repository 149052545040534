import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';

import DatePicker from "react-datepicker";
import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import br from "date-fns/locale/pt-BR";

import api from '../../services/api';

import Breadcrumb from '../../layout/breadcrumb';

import { addTransacao, editTransacao } from '../../redux/actions';

const Add = ({
    open = null,
    onDismiss = () => { },
    reload = () => { },
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    loadingTransacoes,
    fornecedores,
    contas,
    grupos,
    addTransacao,
    user,
    editTransacao
}) => {
    const { tipo } = useParams();

    const [previstoOptions, setPrevistoOptions] = useState([]);

    const [fields, setFields] = useState({
        tipos_transacoes_id: '',
        transacoes_eventos_id: '',
        transacoes_blocos_id: null,
        valor: 0,
        data: format(new Date, 'yyyy-MM-dd'),
        observacao: '',
        tipos_fluxos_id: null,
        transacoes_grupo_id: null,
        conta_id: null,
        fornecedor_id: ''
    });

    const handleSubmit = e => {
        e.preventDefault();

        const { data, tipos_transacoes_id, transacoes_blocos_id, transacoes_eventos_id, valor, conta_id, fornecedor_id, tipos_fluxos_id } = fields;

        if (tipos_fluxos_id == 2) {
            const conditions =
                tipos_transacoes_id == 1
                    ? !!data && !!tipos_transacoes_id && !!transacoes_blocos_id && !!transacoes_eventos_id && !!valor && !!conta_id
                    : !!data && !!tipos_transacoes_id && !!transacoes_eventos_id && !!valor && !!conta_id && fornecedor_id

            if (conditions) {
                if (!!open?.id) {
                    editTransacao(fields, reload);
                } else {
                    addTransacao(fields);
                }

                setFields({
                    tipos_transacoes_id: '',
                    transacoes_eventos_id: '',
                    transacoes_blocos_id: null,
                    valor: 0,
                    data: format(new Date, 'yyyy-MM-dd'),
                    observacao: '',
                    tipos_fluxos_id: tipo,
                    conta_id: null,
                    transacoes_grupo_id: null,
                    fornecedor_id: ''
                });

                onDismiss();
            } else {
                toast.error('Preencha todos os campos para prosseguir', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } else {
            const conditions =
                tipos_transacoes_id == 1
                    ? !!data && !!tipos_transacoes_id && !!transacoes_eventos_id && !!valor && !!conta_id
                    : !!data && !!tipos_transacoes_id && !!transacoes_eventos_id && !!valor && !!conta_id && fornecedor_id


            if (conditions) {
                if (!!open?.id) {
                    editTransacao(fields, reload);
                } else {
                    addTransacao(fields);
                }

                setFields({
                    tipos_transacoes_id: '',
                    transacoes_eventos_id: '',
                    transacoes_blocos_id: null,
                    valor: 0,
                    data: format(new Date, 'yyyy-MM-dd'),
                    observacao: '',
                    tipos_fluxos_id: tipo,
                    conta_id: null,
                    transacoes_grupo_id: null,
                    fornecedor_id: ''
                });

                onDismiss();
            } else {
                toast.error('Preencha todos os campos para prosseguir', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const getPrevisoesOptions = async params => {
        try {
            const { data } = await api.get('/api/auth/previsoes/getPrevisaoOptions', { params });

            setPrevistoOptions(data);
        } catch (err) {

        }
    }

    useEffect(() => {
        if (!!open) {
            setFields({
                tipos_transacoes_id: '',
                transacoes_eventos_id: '',
                transacoes_blocos_id: null,
                observacao: '',
                tipos_fluxos_id: tipo,
                conta_id: null,
                fornecedor_id: '',
                ...open,
                valor: parseFloat(open.valor),
                data: format(new Date, 'yyyy-MM-dd'),
                transacoes_grupo_id: !!open.transacoes_grupo_id
                    ? open.transacoes_grupo_id
                    : eventos.find(({ id }) => id == open.transacoes_eventos_id)?.transacoes_grupo_id,
            });
        } else {
            setFields({
                tipos_transacoes_id: '',
                transacoes_eventos_id: '',
                transacoes_blocos_id: null,
                valor: 0,
                data: format(new Date, 'yyyy-MM-dd'),
                observacao: '',
                tipos_fluxos_id: tipo,
                conta_id: null,
                transacoes_grupo_id: null,
                fornecedor_id: ''
            });
        }
    }, [open]);

    useEffect(() => {
        if (!!fields.transacoes_eventos_id && !!fields.data) {
            getPrevisoesOptions({
                transacoes_eventos_id: fields.transacoes_eventos_id,
                mes: format(parseISO(fields.data), 'MM'),
                ano: format(parseISO(fields.data), 'yyyy')
            });
        } else {
            setPrevistoOptions([])
        }
    }, [fields.transacoes_eventos_id, fields.data]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const filtroEventos = eventos.filter(
        e => {
            if (fields.tipos_fluxos_id != 2) {
                return e.tipos_transacoes_id == fields.tipos_transacoes_id
                    && e.transacoes_grupo_id == fields.transacoes_grupo_id
                    && e.tipos_fluxos_id == fields.tipos_fluxos_id;
            } else {
                if (fields.tipos_transacoes_id == 1)
                    return e.tipos_transacoes_id == fields.tipos_transacoes_id
                        && e.transacoes_grupo_id == fields.transacoes_grupo_id
                        && e.tipos_fluxos_id == fields.tipos_fluxos_id;
                else
                    return e.tipos_transacoes_id == fields.tipos_transacoes_id
                        && e.transacoes_grupo_id == fields.transacoes_grupo_id
                        && e.tipos_fluxos_id == fields.tipos_fluxos_id;
            }
        }
    );

    const optionsGrupos = grupos
        .filter(
            ({ tipos_fluxos_id, tipos_transacoes_id }) =>
                tipos_fluxos_id == fields.tipos_fluxos_id
                && tipos_transacoes_id == fields.tipos_transacoes_id
        );

    const addSemPrevisao = !!open && !Object.keys(open).length;

    return (
        <Modal
            size="lg"
            isOpen={!!open}
            toggle={onDismiss}
            backdrop="static"
        >
            <Form
                onSubmit={handleSubmit}
            >
                <ModalHeader
                    toggle={onDismiss}
                >
                    {!!open?.id
                        ? 'Editar Transação'
                        : `Incluir Transação`
                    }
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Fluxo</Label>
                                <Typeahead
                                    id="fluxo"
                                    labelKey="nome"
                                    clearButton
                                    placeholder="Selecione o fluxo"
                                    options={
                                        user?.groups[0]?.id == 4
                                            ? tiposFluxo.filter(t =>
                                                user?.fluxos.map(f => f.id).includes(t.id)
                                            )
                                            : tiposFluxo
                                    }
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                tipos_fluxos_id: data[0].id
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                tipos_fluxos_id: null
                                            });
                                        }
                                    }}
                                    selected={tiposFluxo.filter(({ id }) => id == fields.tipos_fluxos_id)}
                                    emptyLabel="Nenhum resultado encontrado"
                                    disabled
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Tipo de Transação</Label>
                                <Typeahead
                                    id="transacao"
                                    labelKey="nome"
                                    clearButton
                                    placeholder="Selecione o tipo"
                                    options={tiposTransacoes}
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                tipos_transacoes_id: data[0].id
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                tipos_transacoes_id: null,
                                                transacoes_eventos_id: null,
                                                transacoes_blocos_id: null
                                            });
                                        }
                                    }}
                                    selected={tiposTransacoes.filter(({ id }) => id == fields.tipos_transacoes_id)}
                                    emptyLabel="Nenhum resultado encontrado"
                                    disabled={!open?.id && !addSemPrevisao}
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Data Realizado</Label>
                                <DatePicker
                                    locale={br}
                                    autoComplete="off"
                                    placeholderText="Selecione a Data"
                                    // minDate={new Date(1930, 0, 1)}
                                    maxDate={new Date()}
                                    selected={!!fields.data ? parseISO(fields.data) : null}
                                    onCalendarClose={() => { }}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control digits"
                                    onChange={(selected) => {
                                        setFields({
                                            ...fields,
                                            data: format(selected, 'yyyy-MM-dd')
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        {fields.tipos_fluxos_id == 2 && fields.tipos_transacoes_id == 1 && (
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Bloco</Label>
                                    <Typeahead
                                        id="bloco"
                                        labelKey="nome"
                                        clearButton
                                        placeholder="Selecione o bloco"
                                        options={blocos}
                                        onChange={data => {
                                            if (data?.length) {
                                                setFields({
                                                    ...fields,
                                                    transacoes_blocos_id: data[0].id
                                                });
                                            } else {
                                                setFields({
                                                    ...fields,
                                                    transacoes_blocos_id: null,
                                                    transacoes_eventos_id: fields.tipos_fluxos_id == 2
                                                        ? null
                                                        : fields.transacoes_eventos_id
                                                });
                                            }
                                        }}
                                        selected={blocos.filter(({ id }) => id == fields.transacoes_blocos_id)}
                                        emptyLabel="Nenhum resultado encontrado"
                                        disabled={!open?.id && !addSemPrevisao}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor Realizado</Label>
                                <NumberFormat
                                    placeholder="Informe o valor"
                                    thousandSeparator='.'
                                    customInput={Input}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    onValueChange={({ floatValue }) => {
                                        setFields({
                                            ...fields,
                                            valor: floatValue || 0
                                        });
                                    }}
                                    value={fields.valor}
                                />
                            </FormGroup>
                        </Col>

                        {!!fields.tipos_transacoes_id && (
                            <Col md="12" xs="12">
                                <FormGroup>
                                    <Label>Grupo</Label>
                                    <Typeahead
                                        id="tipo"
                                        labelKey="nome"
                                        clearButton
                                        placeholder="Selecione o grupo"
                                        options={optionsGrupos}
                                        onChange={data => {
                                            if (data?.length) {
                                                setFields({
                                                    ...fields,
                                                    transacoes_grupo_id: data[0].id,
                                                    transacoes_eventos_id: null
                                                });
                                            } else {
                                                setFields({
                                                    ...fields,
                                                    transacoes_grupo_id: null,
                                                    transacoes_eventos_id: null
                                                });
                                            }
                                        }}
                                        selected={optionsGrupos.filter(({ id }) => id == fields.transacoes_grupo_id)}
                                        emptyLabel="Nenhum resultado encontrado"
                                        disabled={!open?.id && !addSemPrevisao}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {!!fields.tipos_transacoes_id && !!fields.transacoes_grupo_id && (
                            <Col md="12" xs="12">
                                <FormGroup>
                                    <Label>Subgrupo</Label>
                                    <Typeahead
                                        id="tipo"
                                        labelKey="nome"
                                        clearButton
                                        placeholder="Selecione o subgrupo"
                                        options={filtroEventos}
                                        onChange={data => {
                                            if (data?.length) {
                                                setFields({
                                                    ...fields,
                                                    transacoes_eventos_id: data[0].id
                                                });
                                            } else {
                                                setFields({
                                                    ...fields,
                                                    transacoes_eventos_id: null
                                                });
                                            }
                                        }}
                                        selected={filtroEventos.filter(({ id }) => id == fields.transacoes_eventos_id)}
                                        emptyLabel="Nenhum resultado encontrado"
                                        disabled={!open?.id && !addSemPrevisao}
                                    />
                                </FormGroup>
                            </Col>
                        )}

                        {!!previstoOptions.length && (
                            <Fragment>
                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Data Prevista</Label>
                                        <Typeahead
                                            id="tipo"
                                            labelKey="data"
                                            clearButton
                                            placeholder="Selecione o tipo"
                                            options={previstoOptions.map(
                                                p => ({
                                                    ...p,
                                                    data: format(parseISO(p.data), 'dd/MM/yyyy')
                                                })
                                            )}
                                            onChange={data => {
                                                if (data?.length) {
                                                    setFields({
                                                        ...fields,
                                                        previsao_id: data[0].id
                                                    });
                                                }
                                            }}
                                            emptyLabel="Nenhum resultado encontrado"
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Valor Previsto</Label>
                                        <NumberFormat
                                            placeholder="Informe o valor"
                                            thousandSeparator='.'
                                            disabled
                                            customInput={Input}
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            onValueChange={({ floatValue }) => {

                                            }}
                                            value={parseFloat(previstoOptions.find(p => p.id == fields.previsao_id)?.valor)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Fragment>
                        )}

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Conta</Label>
                                <Typeahead
                                    id="conta"
                                    labelKey="nome"
                                    clearButton
                                    placeholder="Selecione o tipo"
                                    options={contas
                                        .filter(
                                            ({ tipos_fluxos_id }) => tipos_fluxos_id == fields.tipos_fluxos_id
                                        )
                                        .map(
                                            (c) => ({
                                                ...c,
                                                nome: `${c.conta} - ${c.banco}`
                                            })
                                        )
                                    }
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                conta_id: data[0].id
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                conta_id: null,
                                            });
                                        }
                                    }}
                                    selected={contas
                                        .filter(({ id }) => id == fields.conta_id)
                                        .map(
                                            (c) => ({
                                                ...c,
                                                nome: `${c.conta} - ${c.banco}`
                                            })
                                        )
                                    }
                                    emptyLabel="Nenhum resultado encontrado"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Fornecedor</Label>
                                <Typeahead
                                    id="fornecedor"
                                    labelKey="nome"
                                    clearButton
                                    placeholder="Selecione o fornecedor"
                                    options={fornecedores}
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                fornecedor_id: data[0].id
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                fornecedor_id: null,
                                            });
                                        }
                                    }}
                                    selected={fornecedores.filter(({ id }) => id == fields.fornecedor_id)}
                                    emptyLabel="Nenhum resultado encontrado"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="12" xs="12">
                            <FormGroup>
                                <Label>Observação/Discriminação</Label>
                                <Input
                                    type="textarea"
                                    name="observacao"
                                    value={fields.observacao || ''}
                                    onChange={handleChange}
                                    placeholder="Informe a observação"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        color="danger"
                        onClick={onDismiss}
                    >
                        Fechar
                    </Button>

                    <Button
                        color="primary"
                        disabled={loadingTransacoes}
                    >
                        {loadingTransacoes ? 'Carregando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Contas, Transacoes, Auth, Fornecedores, Consolidado }) => {
    const { loadingContas, gruposContas } = Contas;
    const {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes,
        dadosTransacoes,
        notAuthorized,
        previsto,
        previsoes,
        evento
    } = Transacoes;

    const { loadingFornecedores, fornecedores } = Fornecedores;
    const { user } = Auth;
    const { contas } = Contas;
    const { grupos } = Consolidado;

    return {
        loadingContas,
        tiposFluxo,
        user,
        gruposContas,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes,
        dadosTransacoes,
        notAuthorized,
        previsto,
        previsoes,
        evento,
        fornecedores,
        loadingFornecedores,
        contas,
        grupos
    };
}

export default connect(mapStateToProps, {
    addTransacao,
    editTransacao
})(Add);