import { all, call, put, fork, takeEvery } from "redux-saga/effects";

import { toast } from "react-toastify";

import { GET_BANK_BALANCE } from "../actionTypes";
import { setBalance } from "../actions";

import api from "../../services/api";

function* sagaGetBalance({ payload }) {
  try {
    const data = yield call(apiGetBalance, payload);

    yield put(
      setBalance({
        balance: data.list,
        date: data.date,
      })
    );
  } catch (error) {
    yield put(setBalance());

    toast.error("Erro ao carregar os dados. Tente novamente.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiGetBalance = async (params) => {
  const { data } = await api.get("/api/auth/bankStatement", { params });

  return data;
};

function* watchGetBalance() {
  yield takeEvery(GET_BANK_BALANCE, sagaGetBalance);
}

export default function* rootSaga() {
  yield all([fork(watchGetBalance)]);
}
