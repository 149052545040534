import {
    GET_PREVISOES,
    SET_PREVISOES,
    ADD_PREVISOES,
    EDIT_PREVISOES,
    DELETE_PREVISOES
} from '../actionTypes';

export const getPrevisoes = payload => ({
    type: GET_PREVISOES,
    payload
});

export const setPrevisoes = payload => ({
    type: SET_PREVISOES,
    payload
});

export const addPrevisoes = payload => ({
    type: ADD_PREVISOES,
    payload
});

export const editPrevisao = payload => ({
    type: EDIT_PREVISOES,
    payload
});

export const deletePrevisao = payload => ({
    type: DELETE_PREVISOES,
    payload
})