import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Input
} from 'reactstrap';

import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Edit, List, Delete } from 'react-feather';
import sweetalert2 from "sweetalert2";

import Breadcrumb from '../../layout/breadcrumb';
import {
    addBloco,
    editBloco,
    deleteBloco
} from '../../redux/actions';

const Blocos = ({
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    transacoes,
    user,
    loadingTransacoes,
    addBloco,
    editBloco,
    deleteBloco
}) => {

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(null);

    const [fields, setFields] = useState({
        nome: ''
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const handleChangeEdit = e => {
        const { name, value } = e.target;
        setEdit({ ...edit, [name]: value });
    }

    const handleSubmit = e => {
        e.preventDefault();

        if (!!fields.nome) {
            addBloco(fields);
        } else {
            toast.error('Preencha o nome', {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        setFields({ nome: '' });
    }

    useEffect(() => {

    });

    if (loadingTransacoes) return (
        <Fragment>
            <Breadcrumb parent="Novos Cadastros" title="Blocos da Saúde" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    const dev = user?.groups[0]?.id == 1

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Breadcrumb parent="Novos Cadastros" title="Blocos da Saúde" />

                {!!dev && (
                    <Button
                        color="primary"
                        onClick={() => {
                            setAdd(true);
                        }}
                    >
                        Incluir
                    </Button>
                )}

            </div>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            {!!dev && <th style={{ textAlign: 'center', width: '15%' }}>Ações</th>}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {blocos.map((b, key) => (
                                            <tr key={key}>
                                                <td>{b.nome}</td>
                                                {!!dev && (
                                                    <td style={{ textAlign: 'center' }}>
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginLeft: 5
                                                            }}
                                                            onClick={() => {
                                                                setEdit(b);
                                                            }}
                                                        >
                                                            <Edit
                                                                size={18}
                                                            />
                                                        </span>

                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginLeft: 5
                                                            }}
                                                            onClick={async () => {
                                                                const confirm = await sweetalert2.fire({
                                                                    title: 'Deseja realmente excluir?',
                                                                    icon: 'question',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Sim',
                                                                    cancelButtonText: 'Não',
                                                                    reverseButtons: true
                                                                });

                                                                if (confirm.value) {
                                                                    deleteBloco(b);
                                                                }
                                                            }}
                                                        >
                                                            <Delete
                                                                size={18}
                                                            />
                                                        </span>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal
                size="lg"
                isOpen={!!edit}
                toggle={() => {
                    setEdit(null);
                }}
                backdrop="static"
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();

                        if (!!edit?.nome) {
                            editBloco(edit);

                            setEdit(null);
                        }
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            setEdit(null);
                        }}
                    >
                        Editar Bloco
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        type="text"
                                        name="nome"
                                        value={edit?.nome}
                                        onChange={handleChangeEdit}
                                        placeholder="Informe o nome"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            color="danger"
                            onClick={() => {
                                setEdit(null);
                            }}
                        >
                            Fechar
                        </Button>

                        <Button
                            color="primary"
                            disabled={loadingTransacoes}
                        >
                            {loadingTransacoes ? 'Carregando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal
                size="lg"
                isOpen={add}
                toggle={() => {
                    setAdd(false);
                }}
                backdrop="static"
            >
                <Form
                    onSubmit={handleSubmit}
                >

                    <ModalHeader
                        toggle={() => {
                            setAdd(false);
                        }}
                    >
                        Incluir Bloco
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Nome do Bloco</Label>
                                    <Input
                                        type="text"
                                        name="nome"
                                        value={fields.nome}
                                        onChange={handleChange}
                                        placeholder="Informe o nome do bloco"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            color="danger"
                            onClick={() => {
                                setAdd(false);
                            }}
                        >
                            Fechar
                        </Button>

                        <Button
                            color="primary"
                            disabled={loadingTransacoes}
                        >
                            {loadingTransacoes ? 'Carregando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = ({ Transacoes, Auth }) => {
    const {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes
    } = Transacoes;

    const { user } = Auth;

    return {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes,
        user
    };
}

export default connect(mapStateToProps, {
    addBloco,
    editBloco,
    deleteBloco
})(Blocos);