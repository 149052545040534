import React, { useState } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { useDispatch } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
  FormGroup,
  Label,
} from "reactstrap";

import { format, parseISO } from "date-fns";

import { useCashFlowTypeStore } from "../../../utils/cashflowType";
import { useAuthStore } from "../../../utils/user";
import { onSubmitSearch } from "./onSubmitSearch";

import "react-datepicker/dist/react-datepicker.css";

const SearchForm = () => {
  const dispatch = useDispatch();

  const { tiposFluxo } = useCashFlowTypeStore();

  const [search, setSearch] = useState({
    dataInicio: format(new Date(), "yyyy-MM-dd"),
    dataFim: format(new Date(), "yyyy-MM-dd"),
    tipos_fluxos_id: null,
  });

  return (
    <Col sm="12">
      <Form
        onSubmit={(e) => {
          e.preventDefault();

          onSubmitSearch(search, dispatch);
        }}
      >
        <Card>
          <CardBody>
            <Row>
              <Col md="12" xs="12">
                <FormGroup>
                  <Typeahead
                    id="fluxo"
                    labelKey="nome"
                    clearButton
                    placeholder="Selecione o fluxo"
                    options={tiposFluxo}
                    onChange={(data) => {
                      if (data?.length) {
                        setSearch({
                          ...search,
                          tipos_fluxos_id: data[0].id,
                        });
                      } else {
                        setSearch({
                          ...search,
                          tipos_fluxos_id: null,
                        });
                      }
                    }}
                    selected={tiposFluxo.filter(
                      ({ id }) => id == search.tipos_fluxos_id
                    )}
                    emptyLabel="Nenhum resultado encontrado"
                  />
                </FormGroup>
              </Col>
              <Col sm="6">
                <DatePicker
                  locale={br}
                  autoComplete="off"
                  placeholderText="Selecione a Data Inicial"
                  selected={
                    !!search.dataInicio ? parseISO(search.dataInicio) : ""
                  }
                  onCalendarClose={() => {}}
                  dateFormat="dd/MM/yyyy"
                  className="form-control digits"
                  onChange={(selected) => {
                    setSearch({
                      ...search,
                      dataInicio: !!selected
                        ? format(selected, "yyyy-MM-dd")
                        : "",
                    });
                  }}
                />
              </Col>

              <Col sm="6">
                <DatePicker
                  locale={br}
                  autoComplete="off"
                  placeholderText="Selecione a Data Final"
                  selected={!!search.dataFim ? parseISO(search.dataFim) : ""}
                  onCalendarClose={() => {}}
                  dateFormat="dd/MM/yyyy"
                  className="form-control digits"
                  onChange={(selected) => {
                    setSearch({
                      ...search,
                      dataFim: !!selected ? format(selected, "yyyy-MM-dd") : "",
                    });
                  }}
                />
              </Col>

              <Col sm="12" style={{ marginTop: 10 }}>
                <Button color="primary" className="float-right">
                  Pesquisar
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Form>
    </Col>
  );
};

export default SearchForm;
