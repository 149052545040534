import { GET_UPDATE_REPORT, SET_UPDATE_REPORT } from "../actionTypes";

export const getUpdateReport = () => ({
  type: GET_UPDATE_REPORT,
});

export const setUpdateReport = (payload) => ({
  type: SET_UPDATE_REPORT,
  payload,
});
