import { all, takeEvery, fork, put, call } from 'redux-saga/effects';

import {
    GET_SOLICITACOES_USER,
    REMOVER_USER,
    GET_USERS
} from '../actionTypes';

import {
    setSolicitacoesUser,
    getSolicitacoesUser,
    setUsers
} from '../actions';

import api from '../../services/api';
import { toast } from 'react-toastify';

function* sagaGetSolicitacoes() {
    try {
        const data = yield call(apiGetSolicitacoes);

        yield put(setSolicitacoesUser(data));
    } catch (err) {
        yield put(setSolicitacoesUser());
    }
}

const apiGetSolicitacoes = async () => {
    const { data } = await api.get('/api/auth/getSolicitacoesUser');

    return data;
}

function* sagaRemoverUser({ payload }) {
    try {
        const data = yield call(apiRemoverUser, payload);

        if (data.ok) {
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });

            yield put(getSolicitacoesUser());
        } else {
            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    } catch (err) {
        yield put(setSolicitacoesUser());

        toast.error('Não foi possível remover. Tente novamente mais tarde.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

const apiRemoverUser = async payload => {
    const { data } = await api.delete(`/api/auth/user/delete/${payload}`);

    return data;
}

function* sagaGetUsers() {
    try {
        const data = yield call(apiGetUsers);

        yield put(setUsers(data));
    } catch (err) {
        yield put(setUsers());
    }
}

const apiGetUsers = async () => {
    const { data } = await api.get('/api/auth/user/getAll');

    return data;
}

function* watchGetSolicitacoes() {
    yield takeEvery(GET_SOLICITACOES_USER, sagaGetSolicitacoes);
}

function* watchRemoverUser() {
    yield takeEvery(REMOVER_USER, sagaRemoverUser)
}

function* watchGetUsers() {
    yield takeEvery(GET_USERS, sagaGetUsers);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetSolicitacoes),
        fork(watchRemoverUser),
        fork(watchGetUsers)
    ]);
}