import { GET_BANK_BALANCE, SET_BANK_BALANCE } from "../actionTypes";

export const getBalance = (payload) => ({
  type: GET_BANK_BALANCE,
  payload,
});

export const setBalance = (payload) => ({
  type: SET_BANK_BALANCE,
  payload,
});
