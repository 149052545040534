import {
    GET_FORNECEDORES,
    SET_FORNECEDORES,
    ADD_FORNECEDORES,
    EDIT_FORNECEDORES,
    DELETE_FORNECEDORES
} from '../actionTypes'

export const getFornecedores = () => ({
    type: GET_FORNECEDORES
})

export const setFornecedores = payload => ({
    type: SET_FORNECEDORES,
    payload
})

export const addFornecedor = payload => ({
    type: ADD_FORNECEDORES,
    payload
})

export const editFornecedores = payload => ({
    type: EDIT_FORNECEDORES,
    payload
})

export const deleteFornecedores = payload => ({
    type: DELETE_FORNECEDORES,
    payload
})