import { format, parseISO, compareAsc } from "date-fns";
import { useSelector, useDispatch } from "react-redux";

import { getGeneralBankStatement } from "../redux/actions";
import { useGruposStore } from "./grupos";
import { moneyConvert } from "./moneyConvert";
import { useProvidersStore } from "./providers";
import { useCashFlowTypeStore } from "./cashflowType";
import { useAccountStore } from "./accounts";

export function useGeneralBankStatementStore() {
  const dispatch = useDispatch();

  const dispatchGetGeneralBankStatement = (payload) => {
    dispatch(getGeneralBankStatement(payload));
  };

  const {
    loadingGeneralBankStatement,
    generalBankStatement: { transacoes, repasses, dataInicio, dataFim },
  } = useSelector((state) => state.GeneralBankStatement);

  const { grupos } = useGruposStore();
  const { fornecedores } = useProvidersStore();
  const { tiposFluxo } = useCashFlowTypeStore();
  const { gruposContas } = useAccountStore();

  const transactions = [];

  transacoes.forEach((transacao) => {
    transactions.push({
      descricao: transacao.evento.nome || "Sem descrição",
      fluxo: transacao.tipo_fluxo.nome,
      grupo: grupos.find(
        ({ id }) => id === transacao.evento.transacoes_grupo_id
      )?.nome,
      operacao: transacao.tipo_transacao.nome,
      data: transacao.data,
      valor: moneyConvert(parseFloat(transacao.valor)),
      conta: transacao.conta.conta,
      tipo: transacao.tipos_transacoes_id == 1 ? "C" : "D",
      fornecedor:
        fornecedores.find(({ id }) => id == transacao.fornecedor_id)?.nome ||
        "Sem fornecedor",
    });
  });

  const repassesTipos = {
    E: "Empréstimo",
    T: "Transferência",
  };

  repasses.forEach((repasse) => {
    transactions.push({
      fluxo: tiposFluxo.find(({ id }) => id == repasse.saida_fluxo).nome,
      grupo: gruposContas.find(({ id }) => id == repasse.saida_grupo)?.nome,
      descricao: `Da conta: ${repasse.saida_conta} - para: ${repasse.entrada_conta}`,
      operacao: repassesTipos[repasse.tipo],
      data: repasse.data,
      valor: moneyConvert(parseFloat(repasse.valor)),
      conta: repasse.saida_conta,
      tipo: "D",
      fornecedor: repasse.entrada_conta,
    });

    transactions.push({
      fluxo: tiposFluxo.find(({ id }) => id == repasse.entrada_fluxo).nome,
      grupo: gruposContas.find(({ id }) => id == repasse.entrada_grupo)?.nome,
      descricao: `Para a conta: ${repasse.entrada_conta} da conta: ${repasse.saida_conta}`,
      operacao: repassesTipos[repasse.tipo],
      data: repasse.data,
      valor: moneyConvert(parseFloat(repasse.valor)),
      conta: repasse.entrada_conta,
      tipo: "C",
      fornecedor: repasse.saida_conta,
    });
  });

  return {
    dispatchGetGeneralBankStatement,
    loadingGeneralBankStatement,
    transacoes,
    repasses,
    dataInicio,
    dataFim,
    transactions: transactions
      .sort((a, b) => compareAsc(parseISO(a.data), parseISO(b.data)))
      .map((repasse) => ({
        ...repasse,
        data: format(parseISO(repasse.data), "dd/MM/yyyy"),
      })),
  };
}
