/* CUSTOMIZER */
export const ADD_COSTOMIZER = "ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS = "ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ADD_SIDEBAR_BACKGROUND = "ADD_SIDEBAR_BACKGROUND";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";

//AUTH
export const LOGIN_USER_ASYNC = "LOGIN_USER_ASYNC";
export const LOGIN_USER = "LOGIN_USER";
export const REGISTER_USER_ASYNC = "REGISTER_USER_ASYNC";
export const REGISTER_USER = "REGISTER_USER";
export const LOGOUT_USER_ASYNC = "LOGOUT_USER_ASYNC";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const SET_UPDATE_PROFILE = "SET_UPDATE_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//FUNCIONALIDADES
export const GET_FUNCIONALIDADES_ASYNC = "GET_FUNCIONALIDADES_ASYNC";
export const GET_FUNCIONALIDADES = "GET_FUNCIONALIDADES";
export const LIST_FUNCIONALIDADES_ASYNC = "LIST_FUNCIONALIDADES_ASYNC";
export const LIST_FUNCIONALIDADES = "LIST_FUNCIONALIDADES";
export const STORE_FUNCIONALIDADES_ASYNC = "STORE_FUNCIONALIDADES_ASYNC";
export const STORE_FUNCIONALIDADES = "STORE_FUNCIONALIDADES";
export const UPDATE_FUNCIONALIDADES_ASYNC = "UPDATE_FUNCIONALIDADES_ASYNC";
export const UPDATE_FUNCIONALIDADES = "UPDATE_FUNCIONALIDADES";
export const DESTROY_FUNCIONALIDADES_ASYNC = "DESTROY_FUNCIONALIDADES_ASYNC";
export const DESTROY_FUNCIONALIDADES = "DESTROY_FUNCIONALIDADES";

//MODULOS
export const GET_MODULOS_ASYNC = "GET_MODULOS_ASYNC";
export const GET_MODULOS = "GET_MODULOS";
export const LIST_MODULOS_ASYNC = "LIST_MODULOS_ASYNC";
export const LIST_MODULOS = "LIST_MODULOS";
export const STORE_MODULOS_ASYNC = "STORE_MODULOS_ASYNC";
export const STORE_MODULOS = "STORE_MODULOS";
export const UPDATE_MODULOS_ASYNC = "UPDATE_MODULOS_ASYNC";
export const UPDATE_MODULOS = "UPDATE_MODULOS";
export const DESTORY_MODULOS_ASYNC = "DESTORY_MODULOS_ASYNC";
export const DESTORY_MODULOS = "DESTORY_MODULOS";

//GROUPS
export const GET_GROUPS_ASYNC = "GET_GROUPS_ASYNC";
export const GET_GROUPS = "GET_GROUPS";
export const LIST_GROUPS_ASYNC = "LIST_GROUPS_ASYNC";
export const LIST_GROUPS = "LIST_GROUPS";
export const STORE_GROUPS_ASYNC = "STORE_GROUPS_ASYNC";
export const STORE_GROUPS = "STORE_GROUPS";
export const UPDATE_GROUPS_ASYNC = "UPDATE_GROUPS_ASYNC";
export const UPDATE_GROUPS = "UPDATE_GROUPS";
export const DESTROY_GROUPS_ASYNC = "DESTROY_GROUPS_ASYNC";
export const DESTROY_GROUPS = "DESTROY_GROUPS";

//PERMISSIONS
export const GET_PERMISSIONS_ASYNC = "GET_PERMISSIONS_ASYNC";
export const GET_PERMISSIONS = "GET_PERMISSIONS";
export const UPDATE_PERMISSIONS_ASYNC = "UPDATE_PERMISSIONS_ASYNC";
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS";

/* USERS */
export const GET_SOLICITACOES_USER = "GET_SOLICITACOES_USER";
export const SET_SOLICITACOES_USER = "SET_SOLICITACOES_USER";
export const REMOVER_USER = "REMOVER_USER";
export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";

/* FLUXO DE CAIXA */
export const GET_TIPOS_FLUXO = "GET_TIPOS_FLUXO";
export const SET_TIPOS_FLUXO = "SET_TIPOS_FLUXO";
export const GET_TIPOS_TRANSACOES = "GET_TIPOS_TRANSACOES";
export const SET_TIPOS_TRANSACOES = "SET_TIPOS_TRANSACOES";
export const GET_BLOCOS = "GET_BLOCOS";
export const SET_BLOCOS = "SET_BLOCOS";
export const GET_EVENTOS = "GET_EVENTOS";
export const SET_EVENTOS = "SET_EVENTOS";
export const ADD_BLOCO = "ADD_BLOCO";
export const SET_ADD_BLOCO = "SET_ADD_BLOCO";
export const ADD_EVENTO = "ADD_EVENTO";
export const SET_ADD_EVENTO = "SET_ADD_EVENTO";
export const GET_TRANSACOES = "GET_TRANSACOES";
export const SET_TRANSACOES = "SET_TRANSACOES";
export const ADD_TRANSACAO = "ADD_TRANSACAO";
export const EDIT_TRANSACAO = "EDIT_TRANSACAO";
export const DELETE_TRANSACAO = "DELETE_TRANSACAO";
export const NOT_AUTHORIZED = "NOT_AUTHORIZED";
export const EDIT_EVENTO = "EDIT_EVENTO";
export const DELETE_EVENTO = "DELETE_EVENTO";
export const EDIT_BLOCO = "EDIT_BLOCO";
export const DELETE_BLOCO = "DELETE_BLOCO";

/* PREVISÕES */
export const GET_PREVISOES = "GET_PREVISOES";
export const SET_PREVISOES = "SET_PREVISOES";
export const ADD_PREVISOES = "ADD_PREVISOES";
export const EDIT_PREVISOES = "EDIT_PREVISOES";
export const DELETE_PREVISOES = "DELETE_PREVISOES";

/* CONSOLIDADO */
export const GET_GRUPOS = "GET_GRUPOS";
export const SET_GRUPOS = "SET_GRUPOS";
export const ADD_GRUPOS = "ADD_GRUPOS";
export const EDIT_GRUPOS = "EDIT_GRUPOS";
export const DELETE_GRUPOS = "DELETE_GRUPOS";
export const VINCULAR_GRUPOS = "VINCULAR_GRUPOS";
export const DESVINCULAR_GRUPOS = "DESVINCULAR_GRUPOS";
export const GET_CONSOLIDADO = "GET_CONSOLIDADO";
export const SET_CONSOLIDADO = "SET_CONSOLIDADO";

/* CONTAS */
export const GET_GRUPOS_CONTA = "GET_GRUPOS_CONTA";
export const SET_GRUPOS_CONTA = "SET_GRUPOS_CONTA";
export const ADD_GRUPOS_CONTA = "ADD_GRUPOS_CONTA";
export const EDIT_GRUPOS_CONTA = "EDIT_GRUPOS_CONTA";
export const DELETE_GRUPOS_CONTA = "DELETE_GRUPOS_CONTA";
export const VINCULAR_GRUPOS_CONTA = "VINCULAR_GRUPOS_CONTA";
export const DESVINCULAR_GRUPOS_CONTA = "DESVINCULAR_GRUPOS_CONTA";
export const GET_CONTAS = "GET_CONTAS";
export const ADD_CONTA = "ADD_CONTA";
export const SET_CONTAS = "SET_CONTAS";
export const EDIT_CONTA = "EDIT_CONTA";
export const TRANSFERIR = "TRANSFERIR";
export const GET_REPASSES = "GET_REPASSES";
export const SET_REPASSES = "SET_REPASSES";
export const EDIT_REPASSE = "EDIT_REPASSE";
export const REMOVE_REPASSE = "REMOVE_REPASSE";

/* FORNECEDORES */
export const GET_FORNECEDORES = "GET_FORNECEDORES";
export const SET_FORNECEDORES = "SET_FORNECEDORES";
export const ADD_FORNECEDORES = "ADD_FORNECEDORES";
export const EDIT_FORNECEDORES = "EDIT_FORNECEDORES";
export const DELETE_FORNECEDORES = "DELETE_FORNECEDORES";

/* EXTRATO */
export const GET_EXTRATO = "GET_EXTRATO";
export const SET_EXTRATO = "SET_EXTRATO";

/* MENSAGENS */
export const GET_MENSAGENS = "GET_MENSAGENS";
export const SET_MENSAGENS = "SET_MENSAGENS";
export const ADD_MENSAGENS = "ADD_MENSAGENS";
export const EDIT_MENSAGENS = "EDIT_MENSAGENS";
export const DELETE_MENSAGENS = "DELETE_MENSAGENS";

// CONCILIAÇÃO BANCÁRIA
export const GET_CONCILIACAO = "GET_CONCILIACAO";
export const SET_CONCILIACAO = "SET_CONCILIACAO";

// TRANSAÇÕES FUTURAS
export const GET_TRANSACOES_FUTURAS = "GET_TRANSACOES_FUTURAS";
export const SET_TRANSACOES_FUTURAS = "SET_TRANSACOES_FUTURAS";
export const ADD_TRANSACOES_FUTURAS = "ADD_TRANSACOES_FUTURAS";
export const DELETE_TRANSACOES_FUTURAS = "DELETE_TRANSACOES_FUTURAS";
export const EFETIVAR_TRANSACOES_FUTURAS = "EFETIVAR_TRANSACOES_FUTURAS";

// REPORTS
export const GET_REPORT_ONE = "GET_REPORT_ONE";
export const SET_REPORT_ONE = "SET_REPORT_ONE";
export const GET_UPDATE_REPORT = "GET_UPDATE_REPORT";
export const SET_UPDATE_REPORT = "SET_UPDATE_REPORT";

// GENERAL BANK STATEMENT
export const GET_GENERAL_BANK_STATEMENT = "GET_GENERAL_BANK_STATEMENT";
export const SET_GENERAL_BANK_STATEMENT = "SET_GENERAL_BANK_STATEMENT";

// BANK ACCOUNT FILES
export const GET_BANK_ACCOUNT_FILES = "GET_BANK_ACCOUNT_FILES";
export const SET_BANK_ACCOUNT_FILES = "SET_BANK_ACCOUNT_FILES";
export const ADD_BANK_ACCOUNT_FILES = "ADD_BANK_ACCOUNT_FILES";
export const DOWNLOAD_BANK_ACCOUNT_FILES = "DOWNLOAD_BANK_ACCOUNT_FILES";

export const GET_BANK_BALANCE = "GET_BANK_BALANCE";
export const SET_BANK_BALANCE = "SET_BANK_BALANCE";

// TRANSACTION LAST SEARCH
export const SET_TRANSACTIONS_LAST_SEARCH_PERIOD =
  "SET_TRANSACTIONS_LAST_SEARCH_PERIOD";

export const GET_BANK_STATEMENT_SHEET = "GET_BANK_STATEMENT_SHEET";
export const SET_BANK_STATEMENT_SHEET = "SET_BANK_STATEMENT_SHEET";

export const GET_TABLED_SHEET = "GET_TABLED_SHEET";
export const SET_TABLED_SHEET = "SET_TABLED_SHEET";