import React, { Fragment } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import Breadcrumb from "../../../layout/breadcrumb";

import SearchForm from "./form";
import List from "./list";

import { useReportOneStore } from "../../../utils/reportOne";
import { useAuthStore } from "../../../utils/user";

const Report1 = () => {
  const { loadingReportOne } = useReportOneStore();
  const { user } = useAuthStore();

  return (
    <Fragment>
      <Breadcrumb parent="Relatório" title="Relatório de Repasses" />
      <Container fluid={true}>
        <Row>
          <SearchForm />

          {loadingReportOne ? (
            <Col sm="12">
              <div className="loader-box">
                <div className="loader-1"></div>
              </div>
            </Col>
          ) : (
            <Fragment>
              <List user={user} />
            </Fragment>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default Report1;
