import { format, subMonths, eachMonthOfInterval, lastDayOfMonth } from 'date-fns';
import pt from 'date-fns/locale/pt';
import item from 'react-bootstrap-typeahead/lib/behaviors/item';

export const getLastThreeMonths = () => {
  const lastDayOfPeriod = lastDayOfMonth(new Date());
  const firstDayOfPeriod = subMonths(lastDayOfPeriod, 2);

  const lastDayOfPeriodFormatted = format(lastDayOfPeriod, 'dd/MM/yyyy');
  const firstDayOfPeriodFormatted = '01/' + format(firstDayOfPeriod, 'MM/yyyy');

  const months = eachMonthOfInterval({ start: firstDayOfPeriod, end: lastDayOfPeriod })
    .map((date) => ({
      label: format(date, 'MMMM', {locale: pt}),
      value: format(date, 'yyyy-MM'),
      month: format(date, 'MM'),
      year: format(date, 'yyyy'),
    }));

  return {
    firstDayOfPeriod: format(firstDayOfPeriod, 'yyyy-MM') + '-01',
    lastDayOfPeriod: format(lastDayOfPeriod, 'yyyy-MM-dd'),
    lastDayOfPeriodFormatted,
    firstDayOfPeriodFormatted,
    months,
  };
};

export const getPeriodFromMonthAndYear = (month, year) => {
  const monthChecked = month ? month : format(new Date(), 'MM');
  const yearChecked = year ? year : format(new Date(), 'yyyy');

  const lastDayOfPeriod = lastDayOfMonth(new Date(parseInt(yearChecked), parseInt(monthChecked) - 1));
  const firstDayOfPeriod = subMonths(lastDayOfPeriod, 2);

  return {
    firstDayOfPeriod: format(firstDayOfPeriod, 'yyyy-MM') + '-01',
    lastDayOfPeriod: format(lastDayOfPeriod, 'yyyy-MM-dd')
  };
};

export const getMonthsFromPeriod = ({ start, end }) => {
  const months = eachMonthOfInterval({ start, end })
    .map((date) => ({
      label: format(date, 'MMMM/yyyy', {locale: pt}),
      value: format(date, 'yyyy-MM'),
      month: format(date, 'MM'),
      year: format(date, 'yyyy'),
    }));
  
  return { months };
};
