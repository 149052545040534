import { all, call, put, takeEvery, fork, select } from "redux-saga/effects";

import fileDownload from "js-file-download";

import { GET_TABLED_SHEET } from "../actionTypes";

import apiSheet from "../../services/apiSheet";

import { setTabledSheet } from "./actions";

function* getTabledSheetSaga({ payload }) {
  const { user } = yield select((state) => state.Auth);

  yield call(apiGetTabledSheet, {
    ...payload,
    cityId: user.cidade_id,
  });

  yield put(setTabledSheet());
}

const apiGetTabledSheet = async (params) => {
  const { data } = await apiSheet.get("/api/v1/consolidated_tabulated", {
      params,
      responseType: "blob",
    });

  fileDownload(data, "Consolidado Tabelado.xlsx");
};

function* watchGetTabledSheetSaga() {
  yield takeEvery(GET_TABLED_SHEET, getTabledSheetSaga);
}

export default function* rootSaga() {
  yield all([fork(watchGetTabledSheetSaga)]);
}
