import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Container,
    Row,
    Col,
    Card,
    CardTitle,
    Table,
    CardBody,
    Button
} from 'reactstrap';

import sweetalert2 from "sweetalert2";
import { Edit, Trash, Plus, X } from 'react-feather';

import Breadcrumb from '../../layout/breadcrumb';
import Add from './add';
import EditGrupo from './edit';
import Vincular from './vincular';
import Desvincular from './desvincular';

import { getGrupos, deleteGrupos } from '../../redux/actions';

const Grupos = ({
    user,
    loadingGrupos,
    grupos,
    tiposFluxo,
    tiposTransacoes,
    eventos,
    getGrupos,
    deleteGrupos
}) => {

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(null);
    const [vincular, setVincular] = useState(null);
    const [desvincular, setDesvincular] = useState(null);

    useEffect(() => {
        getGrupos();
    }, []);

    if (loadingGrupos) return (
        <Fragment>
            <Breadcrumb parent="Novos Cadastros" title="Grupos" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    const dev = user?.groups[0]?.id == 1

    return (
        <Fragment>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Breadcrumb
                    parent="Novos Cadastros"
                    title="Grupos"
                />

                {!!dev && (
                    <Button
                        color="primary"
                        onClick={() => {
                            setAdd(true)
                        }}
                    >
                        Incluir
                    </Button>
                )}

            </div>

            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr>
                                    <th colSpan={4} style={{ textAlign: 'center' }}>
                                        Grupos do município {user?.cidade?.name}
                                    </th>
                                </tr>

                                <tr>
                                    <th style={{ width: '30%' }}>Grupo</th>
                                    <th style={{ width: '30%' }}>Fluxo</th>
                                    <th style={{ width: '30%' }}>Transação</th>
                                    {!!dev && <th style={{ width: '10%' }}>Ações</th>}
                                </tr>
                            </thead>

                            <tbody>
                                {grupos.map(
                                    ({ nome, tipo_fluxo, tipo_transacao, ...grupo }, key) => (
                                        <tr key={key}>
                                            <td>{nome}</td>
                                            <td>{tipo_fluxo.nome}</td>
                                            <td>{tipo_transacao.nome}</td>
                                            {!!dev && (
                                                <td>
                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginLeft: 5
                                                        }}
                                                        onClick={() => {
                                                            setVincular({
                                                                ...grupo,
                                                                nome,
                                                                tipo_fluxo,
                                                                tipo_transacao
                                                            });
                                                        }}
                                                    >
                                                        <Plus
                                                            size={18}
                                                        />
                                                    </span>

                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginLeft: 5
                                                        }}
                                                        onClick={() => {
                                                            setDesvincular({
                                                                ...grupo,
                                                                nome,
                                                                tipo_fluxo,
                                                                tipo_transacao
                                                            });
                                                        }}
                                                    >
                                                        <X
                                                            size={18}
                                                        />
                                                    </span>

                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginLeft: 5
                                                        }}
                                                        onClick={() => {
                                                            setEdit({
                                                                ...grupo,
                                                                nome,
                                                                tipo_fluxo,
                                                                tipo_transacao
                                                            });
                                                        }}
                                                    >
                                                        <Edit
                                                            size={18}
                                                        />
                                                    </span>

                                                    <span
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginLeft: 5
                                                        }}
                                                        onClick={async () => {
                                                            const confirm = await sweetalert2.fire({
                                                                title: 'Deseja realmente excluir?',
                                                                icon: 'question',
                                                                showCancelButton: true,
                                                                confirmButtonText: 'Sim',
                                                                cancelButtonText: 'Não',
                                                                reverseButtons: true
                                                            });

                                                            if (confirm.value) {
                                                                deleteGrupos(grupo.id);
                                                            }
                                                        }}
                                                    >
                                                        <Trash
                                                            size={18}
                                                        />
                                                    </span>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Container>

            <Add
                open={add}
                onDismiss={() => setAdd(false)}
            />

            <EditGrupo
                item={edit}
                onDismiss={() => setEdit(null)}
            />

            <Vincular
                item={vincular}
                onDismiss={() => setVincular(null)}
            />

            <Desvincular
                item={desvincular}
                onDismiss={() => setDesvincular(null)}
            />
        </Fragment>
    );
}

const mapStateToProps = ({ Consolidado, Transacoes, Auth }) => {
    const { user } = Auth;
    const { loadingGrupos, grupos } = Consolidado;

    const {
        tiposFluxo,
        tiposTransacoes,
        eventos,
    } = Transacoes;

    return {
        loadingGrupos,
        grupos,
        tiposFluxo,
        tiposTransacoes,
        eventos,
        user
    };
}

export default connect(mapStateToProps, {
    getGrupos,
    deleteGrupos
})(Grupos);