import {
    GET_CONCILIACAO,
    SET_CONCILIACAO
} from '../actionTypes';

export const getConciliacao = payload => ({
    type: GET_CONCILIACAO,
    payload
});

export const setConciliacao = payload => ({
    type: SET_CONCILIACAO,
    payload
});