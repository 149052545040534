import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import Loader from "../layout/loader";
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Footer from '../layout/footer'
import Banner from './Banner';
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer } from 'react-toastify';

import {
  getGroups,
  getTiposFluxo,
  getTiposTransacoes,
  getEventos,
  getBlocos,
  getContas,
  getGruposConta,
  getGrupos,
  getFornecedores
} from '../redux/actions';

const App = ({
  children,
  groups,
  getGroups,
  getTiposFluxo,
  getTiposTransacoes,
  getEventos,
  getBlocos,
  getContas,
  getGruposConta,
  user,
  getGrupos,
  getFornecedores
}) => {
  console.warn = () => { };

  useEffect(() => {
    getGroups();
    getTiposFluxo();
    getTiposTransacoes();
    getEventos();
    getBlocos();
    getContas();
    getGruposConta();
    getGrupos();
    getFornecedores();
  }, [user]);

  return (
    <Fragment>
      <Loader />
      <div className="page-wrapper compact-wrapper" id="pageWrapper">
        <Header />
        <div className="page-body-wrapper sidebar-icon">
          <Sidebar />
          <div className="page-body">
            {children}
          </div>
          {/* <Footer /> */}
          {/* <ThemeCustomize/> */}
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
}

const mapStateToProps = ({ Groups, Auth }) => {
  const { groups } = Groups;
  const { user } = Auth;

  return {
    groups,
    user
  };
}

export default connect(mapStateToProps, {
  getGroups,
  getTiposFluxo,
  getTiposTransacoes,
  getEventos,
  getBlocos,
  getContas,
  getGruposConta,
  getGrupos,
  getFornecedores
})(App);