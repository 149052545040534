import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';

import DatePicker from "react-datepicker";
import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import br from "date-fns/locale/pt-BR";

import Breadcrumb from '../../layout/breadcrumb';

import { transferir } from '../../redux/actions';

const Add = ({
    open = false,
    onDismiss = () => { },
    loadingContas,
    tiposFluxo,
    contas,
    transferir
}) => {

    const [fields, setFields] = useState({
        fluxo_saida: '',
        fluxo_entrada: '',
        saindo_de: '',
        entrando_em: '',
        valor: '',
        data: '',
        tipo: ''
    })

    const handleSubmit = e => {
        e.preventDefault();

        let totalVazios = 0;

        for (let i in fields) {
            if (!fields[i]) {
                totalVazios++;
            }
        }

        if (!totalVazios) {
            transferir(fields);

            setFields({
                fluxo_saida: '',
                fluxo_entrada: '',
                saindo_de: '',
                entrando_em: '',
                valor: '',
                data: '',
                tipo: ''
            });

            onDismiss();
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const optionsTipos = [
        { label: 'Empréstimo', value: 'E' },
        { label: 'Transferência', value: 'T' },
    ]

    return (
        <Modal
            size="lg"
            isOpen={open}
            toggle={onDismiss}
            backdrop="static"
        >
            <Form
                onSubmit={handleSubmit}
            >
                <ModalHeader
                    toggle={onDismiss}
                >
                    Incluir Transferência/Empréstimo
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Fluxo da conta de saída</Label>
                                <Typeahead
                                    id="fluxo"
                                    labelKey="nome"
                                    clearButton
                                    placeholder="Selecione o fluxo"
                                    options={tiposFluxo}
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                fluxo_saida: data[0].id
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                fluxo_saida: null
                                            });
                                        }
                                    }}
                                    emptyLabel="Nenhum resultado encontrado"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Conta de saída</Label>
                                <Typeahead
                                    id="conta"
                                    labelKey="conta"
                                    clearButton
                                    placeholder="Selecione a conta"
                                    options={contas
                                        .filter(({ tipos_fluxos_id }) => tipos_fluxos_id == fields.fluxo_saida)
                                        .map(
                                            ({ conta, ...c }) => ({
                                                ...c,
                                                conta: String(conta)
                                            })
                                        )
                                    }
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                saindo_de: data[0].id
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                saindo_de: null
                                            });
                                        }
                                    }}
                                    emptyLabel="Nenhum resultado encontrado"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Fluxo da conta de entrada</Label>
                                <Typeahead
                                    id="fluxo"
                                    labelKey="nome"
                                    clearButton
                                    placeholder="Selecione o fluxo"
                                    options={tiposFluxo}
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                fluxo_entrada: data[0].id
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                fluxo_entrada: null
                                            });
                                        }
                                    }}
                                    emptyLabel="Nenhum resultado encontrado"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Conta de entrada</Label>
                                <Typeahead
                                    id="conta"
                                    labelKey="conta"
                                    clearButton
                                    placeholder="Selecione a conta"
                                    options={contas
                                        .filter(({ tipos_fluxos_id }) => tipos_fluxos_id == fields.fluxo_entrada)
                                        .map(
                                            ({ conta, ...c }) => ({
                                                ...c,
                                                conta: String(conta)
                                            })
                                        )
                                    }
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                entrando_em: data[0].id
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                entrando_em: null
                                            });
                                        }
                                    }}
                                    emptyLabel="Nenhum resultado encontrado"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Valor</Label>
                                <NumberFormat
                                    placeholder="Informe o valor"
                                    thousandSeparator='.'
                                    customInput={Input}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={parseFloat(fields.valor)}
                                    onValueChange={({ floatValue }) => {
                                        setFields({
                                            ...fields,
                                            valor: floatValue || 0,
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Data</Label>
                                <DatePicker
                                    locale={br}
                                    autoComplete="off"
                                    placeholderText="Selecione a Data"
                                    selected={!!fields.data ? parseISO(fields.data) : null}
                                    onCalendarClose={() => { }}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control digits"
                                    onChange={(selected) => {
                                        setFields({
                                            ...fields,
                                            data: format(selected, 'yyyy-MM-dd')
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Tipo</Label>
                                <Typeahead
                                    id="tipo"
                                    labelKey="label"
                                    clearButton
                                    placeholder="Selecione o tipo"
                                    options={optionsTipos}
                                    onChange={data => {
                                        if (data?.length) {
                                            setFields({
                                                ...fields,
                                                tipo: data[0]?.value
                                            });
                                        } else {
                                            setFields({
                                                ...fields,
                                                tipo: ''
                                            });
                                        }
                                    }}
                                    emptyLabel="Nenhum resultado encontrado"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        color="danger"
                        onClick={onDismiss}
                    >
                        Fechar
                    </Button>

                    <Button
                        color="primary"
                        disabled={loadingContas}
                    >
                        {loadingContas ? 'Carregando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Contas, Transacoes, Auth }) => {
    const { loadingContas, contas } = Contas;
    const { tiposFluxo } = Transacoes;
    const { user } = Auth;

    return {
        loadingContas,
        tiposFluxo,
        user,
        contas
    };
}

export default connect(mapStateToProps, {
    transferir
})(Add);