import React, { Fragment, useEffect, useRef } from "react";
import { Container, Row, Col, Card, CardBody, Table, Button } from "reactstrap";
import { format } from 'date-fns';
import { useReactToPrint } from "react-to-print";

import Breadcrumb from "../../../layout/breadcrumb";
import Print from "../../../components/Print";

import SearchForm from "./searchForm";

import { useGeneralBankStatementStore } from "../../../utils/generalBankStatement";
import { useAuthStore } from "../../../utils/user";

const Report1 = () => {
  const printRef = useRef();

  const { user } = useAuthStore();
  const { loadingGeneralBankStatement, dispatchGetGeneralBankStatement, transactions } = useGeneralBankStatementStore();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Relatório de Repasses - ${user?.cidade?.name}`,
  });

  useEffect(() => {
    dispatchGetGeneralBankStatement({
      dataInicio: format(new Date(), "yyyy-MM-dd"),
      dataFim: format(new Date(), "yyyy-MM-dd"),
    });
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Relatório" title="Extrato Geral" />
      <Container fluid={true}>
        <Row>
          <SearchForm />

          {loadingGeneralBankStatement ? (
            <Col sm="12">
              <div className="loader-box">
                <div className="loader-1"></div>
              </div>
            </Col>
          ) : (
            <Fragment>
              <Col sm="12">
                <Button
                  color="primary"
                  className="mb-3 float-right"
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
              </Col>

              <Col sm="12">
                <Print ref={printRef}>
                  <Card className="font-arial-rlt">
                    <CardBody>
                      <Col
                        sm="12"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: 20,
                        }}
                      >
                        <img
                          className="img-fluid for-light"
                          src={require("../../../assets/images/logo/logo.jpg")}
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            objectFit: "contain",
                          }}
                        />

                        <h5>{user?.cidade?.name}</h5>
                      </Col>

                      <div className="table-responsive">
                        <Table bordered>
                          <tbody>
                            <tr>
                              <th>Fluxo</th>
                              <th>Grupo</th>
                              <th>Descrição</th>
                              <th>Operação</th>
                              <th>Data</th>
                              <th>Valor</th>
                              <th>Tipo</th>
                              <th>Conta</th>
                              <th>Fornecedor</th>
                            </tr>

                            {transactions.map((transaction, key) => (
                              <tr key={key}>
                                <td>{transaction.fluxo}</td>
                                <td>{transaction.grupo}</td>
                                <td>{transaction.descricao}</td>
                                <td>{transaction.operacao}</td>
                                <td>{transaction.data}</td>
                                <td>{transaction.valor}</td>
                                <td className={`text-${transaction.tipo == 'C' ? 'info' : 'danger'} text-bold`}>{transaction.tipo}</td>
                                <td>{transaction.conta}</td>
                                <td>{transaction.fornecedor}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>

                    </CardBody>
                  </Card>
                </Print>
              </Col>
            </Fragment>
          )}
        </Row>
      </Container>
    </Fragment >
  );
};

export default Report1;
