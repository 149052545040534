import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb';
import {
    Container,
    Row,
    Col,
    Card,
    Table,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Input
} from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from 'date-fns';
import { connect } from 'react-redux';
import { Edit } from 'react-feather';
import swal from 'sweetalert2';
import { ThumbsUp, ThumbsDown } from 'react-feather';

import api from '../../services/api';
import { updateProfile, removerUser, getUsers, registerUserAsync } from '../../redux/actions';

const Users = ({
    updateProfile,
    removerUser,
    groups,
    getUsers,
    users,
    loading,
    loadingUsers,
    tiposFluxo,
    auth,
    registerUserAsync
}) => {

    const [user, setUser] = useState(null);
    const [fluxos, setFluxos] = useState([]);
    const [add, setAdd] = useState(false);
    const [fields, setFields] = useState({
        email: '',
        password: '',
        estado_id: '',
        cidade_id: '',
        name: '',
        group_id: null
    });
    const [trocarSenha, setTrocarSenha] = useState(false);

    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);

    useEffect(() => {
        getUsers();
        getEstados();
    }, []);

    const handleChange = e => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const handleChangeAdd = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    };

    const getEstados = async () => {
        try {
            const { data } = await api.get('/api/auth/estados/show')

            setEstados(data?.estados)
        } catch (err) {
            console.log(err)
        }
    };

    const getCidades = async payload => {
        try {
            const { data } = await api.get(`/api/auth/cidades/show/${payload}`)

            setCidades(data?.cidades)
        } catch (err) {
            console.log(err)
        }
    };

    if (loadingUsers) return (
        <Fragment>
            <Breadcrumb parent="Gestão" title="Usuários Ativos" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    if (auth?.groups[0]?.id == 4) return (
        <Fragment>
            <Breadcrumb parent="Gestão" title="Usuários Ativos" />
            <Container fluid={true}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 300,
                        width: '100%',
                    }}
                >
                    <ThumbsDown
                        color="red"
                    />

                    <h4 style={{ marginTop: 10 }}>Usuário não autorizado</h4>
                </div>
            </Container>
        </Fragment>
    );

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Breadcrumb parent="Gestão" title="Usuários Ativos" />

                {auth?.groups[0]?.id == 1 && (
                    <Button
                        color="primary"
                        onClick={() => {
                            setAdd(true);
                        }}
                    >
                        Incluir
                    </Button>
                )}
            </div>

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>E-mail</th>
                                            <th>Estado</th>
                                            <th>Cidade</th>
                                            <th>Perfil</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {!users.length && (
                                            <tr>
                                                <td colSpan={6} style={{ textAlign: 'center' }}>Não existem usuários ativos</td>
                                            </tr>
                                        )}

                                        {users.map((u, key) => (
                                            <tr key={key}>
                                                <td>{u.dados_pessoais?.name}</td>
                                                <td>{u.email}</td>
                                                <td>{u.estado?.name}</td>
                                                <td>{u.cidade?.name}</td>
                                                <td>{u.groups[0]?.name}</td>
                                                <td>
                                                    <span
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => {
                                                            setUser({
                                                                name: u.dados_pessoais?.name,
                                                                group_id: u.groups[0]?.id,
                                                                email: u.email,
                                                                active: u.active,
                                                                user_id: u.id,
                                                                fluxos: u.fluxos
                                                            });
                                                        }}
                                                    >
                                                        <Edit />
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal
                size="lg"
                isOpen={add}
                toggle={() => setAdd(false)}
                backdrop="static"
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();

                        registerUserAsync(fields);
                    }}
                >
                    <ModalHeader toggle={() => setAdd(false)}>
                        Incluir Usuário
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Nome Completo"
                                        name="name"
                                        onChange={handleChangeAdd}
                                        required=""
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup>
                                    <Label>E-mail</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="E-mail"
                                        name="email"
                                        onChange={handleChangeAdd}
                                        required=""
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="12">
                                <FormGroup>
                                    <Label>Senha</Label>
                                    <Input
                                        className="form-control"
                                        type="password"
                                        onChange={handleChangeAdd}
                                        name="password"
                                        required=""
                                        placeholder="Senha"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Estado</Label>
                                    <Typeahead
                                        id="selectEstado"
                                        labelKey="name"
                                        clearButton
                                        placeholder="Estado"
                                        options={estados.map(({ name, id }) => ({ name, id }))}
                                        onChange={(estado) => {
                                            if (!!estado.length) {
                                                const { id } = estado[0]
                                                setFields({
                                                    ...fields,
                                                    estado_id: id
                                                })
                                                getCidades(id)
                                            }
                                        }}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Cidade</Label>
                                    <Typeahead
                                        id="selectCidade"
                                        labelKey="name"
                                        clearButton
                                        placeholder="Cidade"
                                        options={cidades.map(({ name, id }) => ({ name, id }))}
                                        onChange={(cidade) => {
                                            if (!!cidade.length) {
                                                const { id } = cidade[0]
                                                setFields({
                                                    ...fields,
                                                    cidade_id: id
                                                })
                                            }
                                        }}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Perfil</Label>
                                    <Typeahead
                                        id="groups"
                                        labelKey="name"
                                        clearButton
                                        placeholder="Selecione o perfil de acesso"
                                        options={groups}
                                        onChange={data => {
                                            if (data?.length) {
                                                setFields({
                                                    ...fields,
                                                    group_id: data[0]?.id
                                                });
                                            } else {
                                                setFields({
                                                    ...fields,
                                                    group_id: null
                                                });
                                            }
                                        }}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button color="danger" onClick={() => setAdd(false)}>
                            Fechar
                        </Button>

                        <Button
                            color="primary"
                            disabled={loading}
                        >
                            {loading ? 'Carregando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal
                size="lg"
                isOpen={!!user}
                toggle={() => setUser(null)}
                backdrop="static"
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();

                        updateProfile({ ...user, fluxos }, () => {
                            getUsers();
                            setUser(null);
                        });
                    }}
                >

                    <ModalHeader toggle={() => setUser(null)}>
                        Editar Usuário
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="Nome Completo"
                                        name="name"
                                        onChange={handleChange}
                                        required=""
                                        value={user?.name}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>E-mail</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        placeholder="E-mail"
                                        name="email"
                                        onChange={handleChange}
                                        required=""
                                        value={user?.email}
                                    />
                                </FormGroup>
                            </Col>

                            {/* <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Perfil</Label>
                                    <Typeahead
                                        id="groups"
                                        labelKey="name"
                                        clearButton
                                        placeholder="Selecione o perfil de acesso"
                                        options={groups}
                                        onChange={data => {
                                            if (data?.length) {
                                            }
                                        }}
                                        defaultSelected={groups.filter(
                                            g => g.id == user?.group_id
                                        )}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col> */}

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Fluxos Permitidos</Label>
                                    <Typeahead
                                        id="fluxos"
                                        labelKey="nome"
                                        clearButton
                                        placeholder="Selecione os fluxos"
                                        options={tiposFluxo}
                                        onChange={data => {
                                            if (data?.length) {
                                                setFluxos(data.map(d => d.id));
                                            } else {
                                                setFluxos([]);
                                            }
                                        }}
                                        defaultSelected={user?.fluxos}
                                        emptyLabel="Nenhum resultado encontrado"
                                        multiple
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Ativo</Label>
                                    <Typeahead
                                        id="groups"
                                        labelKey="name"
                                        clearButton
                                        placeholder="Selecione status de ativação"
                                        options={[
                                            { id: 0, name: 'Não' },
                                            { id: 1, name: 'Sim' }
                                        ]}
                                        onChange={data => {
                                            if (data?.length) {
                                                setUser({
                                                    ...user,
                                                    active: data[0].id
                                                })
                                            }
                                        }}
                                        defaultSelected={[
                                            {
                                                id: user?.active,
                                                name: user?.active == 1 ? 'Sim' : 'Não'
                                            }
                                        ]}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Trocar Senha</Label>
                                    <Typeahead
                                        id="groups"
                                        labelKey="name"
                                        clearButton
                                        placeholder="Trocar senha do usuário"
                                        options={[
                                            { id: 0, name: 'Não' },
                                            { id: 1, name: 'Sim' }
                                        ]}
                                        onChange={data => {
                                            let canChange = !!data?.length && !!data[0].id

                                            setTrocarSenha(canChange)

                                            if (!canChange) {
                                                const tmpUser = { ...user }
                                                delete tmpUser.password

                                                setUser(tmpUser)
                                            }
                                        }}
                                        defaultSelected={[
                                            {
                                                id: 0,
                                                name: 'Não'
                                            }
                                        ]}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>

                            {!!trocarSenha && (
                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Senha</Label>
                                        <Input
                                            className="form-control"
                                            type="password"
                                            onChange={handleChange}
                                            name="password"
                                            required=""
                                            placeholder="Senha"
                                            value={user?.password || ''}
                                        />
                                    </FormGroup>
                                </Col>
                            )}

                        </Row>
                    </ModalBody>

                    <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button color="danger" onClick={() => setUser(null)}>
                            Fechar
                        </Button>

                        <Button
                            color="primary"
                            disabled={loading}
                        >
                            {loading ? 'Carregando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = ({ Users, Groups, Auth, Transacoes }) => {
    const { users, loadingUsers } = Users;
    const { groups } = Groups;
    const { loading, user } = Auth;
    const { tiposFluxo } = Transacoes;

    return {
        users,
        loadingUsers,
        groups,
        loading,
        tiposFluxo,
        auth: user
    };
}

export default connect(mapStateToProps, {
    updateProfile,
    removerUser,
    getUsers,
    registerUserAsync
})(Users);