import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';

import { Typeahead } from 'react-bootstrap-typeahead';
import { format } from 'date-fns'

import Breadcrumb from '../../layout/breadcrumb';

import { editGrupos } from '../../redux/actions';

const Edit = ({
    item = null,
    onDismiss = () => { },
    loadingGrupos,
    grupos,
    editGrupos
}) => {

    const [fields, setFields] = useState({
        id: '',
        nome: ''
    })

    useEffect(() => {
        if (!!item)
            setFields({
                id: item.id,
                nome: item.nome
            })
    }, [item])

    const handleSubmit = e => {
        e.preventDefault();

        let totalVazios = 0;

        for (let i in fields) {
            if (!fields[i]) {
                totalVazios++;
            }
        }

        if (!totalVazios) {
            editGrupos(fields);

            setFields({
                nome: ''
            });

            onDismiss();
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    return (
        <Modal
            size="lg"
            isOpen={!!item}
            toggle={onDismiss}
            backdrop="static"
        >
            <Form
                onSubmit={handleSubmit}
            >
                <ModalHeader
                    toggle={onDismiss}
                >
                    Incluir Grupo
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input
                                    type="text"
                                    name="nome"
                                    value={fields.nome}
                                    onChange={handleChange}
                                    placeholder="Informe o nome"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        color="danger"
                        onClick={onDismiss}
                    >
                        Fechar
                    </Button>

                    <Button
                        color="primary"
                        disabled={loadingGrupos}
                    >
                        {loadingGrupos ? 'Carregando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Consolidado, Transacoes, Auth }) => {
    const { loadingGrupos, grupos } = Consolidado;
    const { user } = Auth;

    const {
        tiposFluxo,
        tiposTransacoes,
        eventos,
    } = Transacoes;

    return {
        user,
        loadingGrupos,
        grupos,
        tiposFluxo,
        tiposTransacoes,
        eventos,
    };
}

export default connect(mapStateToProps, {
    editGrupos
})(Edit);