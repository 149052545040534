import React, { Fragment, useEffect } from 'react';

import { Table } from 'reactstrap';

import { getMonthsFromPeriod } from '../../../utils/quarterly';
import { moneyConvert } from '../../../utils/moneyConvert';

const QuarterlyTable = ({ transactions = [], predictions = [], subgroups = [], title = "", period = {} }) => {

  const { months } = getMonthsFromPeriod(period);

  const getPredictionFromMonthAndSubgroup = (month, year, subgroup) => {
    const total = predictions
      .filter(
        ({ mes, ano, transacoes_eventos_id }) => {
          const periodCondition =
            mes == parseInt(month) &&
            ano == parseInt(year);

          return !subgroup ? periodCondition : periodCondition && transacoes_eventos_id == subgroup;
        })
      .reduce((prev, current) => prev + parseFloat(current.total), 0);

    return moneyConvert(total);
  }

  const getTransactionFromMonthAndSubgroup = (month, year, subgroup) => {
    const total = transactions
      .filter(
        ({ mes, ano, transacoes_eventos_id }) => {
          const periodCondition =
            mes == parseInt(month) &&
            ano == parseInt(year);

          return !subgroup ? periodCondition : periodCondition && transacoes_eventos_id == subgroup;
        })
      .reduce((prev, current) => prev + parseFloat(current.total), 0);

    return moneyConvert(total);
  }

  return (
    <div className='table-responsive'>
      <Table bordered className='mb-5'>
        <tbody>
          <tr>
            <th style={{ width: '25%' }}>{title}</th>

            {months.map(({ label }) => (
              <Fragment key={label}>
                <th className='text-center'>Previsto - {label}</th>
                <th className='text-center'>Realizado - {label}</th>
              </Fragment>
            ))}
          </tr>

          {subgroups.map(({ id, nome }) => (
            <tr key={id}>
              <td>{nome}</td>
              {months.map(({ value, month, year }) => (
                <Fragment key={value}>
                  <td className='text-center'>{getPredictionFromMonthAndSubgroup(month, year, id)}</td>
                  <td className='text-center'>{getTransactionFromMonthAndSubgroup(month, year, id)}</td>
                </Fragment>
              ))}
            </tr>
          ))}

          <tr style={{ backgroundColor: '#f1f1f1' }}>
            <th style={{ width: '25%' }}>Total</th>

            {months.map(({ value, month, year }) => (
              <Fragment key={value}>
                <th className='text-center'>{getPredictionFromMonthAndSubgroup(month, year)}</th>
                <th className='text-center'>{getTransactionFromMonthAndSubgroup(month, year)}</th>
              </Fragment>
            ))}
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default QuarterlyTable;
