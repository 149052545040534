import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { Typeahead } from "react-bootstrap-typeahead";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";

import { format, parseISO, compareAsc } from "date-fns";
import { toast } from "react-toastify";
import { Edit, Trash } from "react-feather";
import sweetalert2 from "sweetalert2";

import Breadcrumb from "../../layout/breadcrumb";

import { useAccountStore } from "../../utils/accounts";

import { getRepasses, removeRepasse } from "../../redux/actions";

import Transferir from "./transferir";
import EditRepasse from "./editarRepasses";

const Transferencia = ({ getRepasses, removeRepasse }) => {
  const { contas, loadingContas, loadingRepasses, repasses } =
    useAccountStore();

  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(null);

  const [search, setSearch] = useState({
    conta: "",
    dataInicio: format(new Date(), "yyyy-MM-dd"),
    dataFim: format(new Date(), "yyyy-MM-dd"),
  });

  useEffect(() => {
    getRepasses(search);
  }, []);

  if (loadingRepasses || loadingContas)
    return (
      <Fragment>
        <Breadcrumb parent="Transferências/Empréstimos" title="Contas" />
        <Container fluid={true}></Container>
        <div className="loader-box">
          <div className="loader-1"></div>
        </div>
      </Fragment>
    );

  const tipos = {
    T: "Transferência",
    E: "Empréstimo",
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Transferências/Empréstimos" title="Contas" />

        <Button
          color="primary"
          onClick={() => {
            setAdd(true);
          }}
        >
          Incluir
        </Button>
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                if (!!search.dataInicio && !!search.dataFim) {
                  if (
                    compareAsc(
                      parseISO(search.dataInicio),
                      parseISO(search.dataFim)
                    ) > 0
                  ) {
                    toast.error("Data Inicial não pode ser maior que a final", {
                      position: toast.POSITION.TOP_RIGHT,
                    });
                  } else {
                    getRepasses(search);
                  }
                } else {
                  toast.error("Selecione o período", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="conta"
                        labelKey="conta"
                        clearButton
                        placeholder="Selecione a conta"
                        options={contas.map(({ conta, ...c }) => ({
                          ...c,
                          conta: String(conta),
                        }))}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              conta: data[0]?.id,
                            });
                          } else {
                            setSearch({
                              ...search,
                              conta: "",
                            });
                          }
                        }}
                        selected={contas
                          .map(({ conta, ...c }) => ({
                            ...c,
                            conta: String(conta),
                          }))
                          .filter(({ id }) => id == search.conta)}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Inicial"
                        selected={
                          !!search.dataInicio ? parseISO(search.dataInicio) : ""
                        }
                        onCalendarClose={() => {}}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataInicio: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Final"
                        selected={
                          !!search.dataFim ? parseISO(search.dataFim) : ""
                        }
                        onCalendarClose={() => {}}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataFim: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12" style={{ marginTop: 10 }}>
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <h5 style={{ textAlign: "center" }}>Lista de Transferências</h5>

                <Table bordered style={{ marginTop: 15 }}>
                  <thead>
                    <tr>
                      <th>Valor Transferido</th>
                      <th>Origem</th>
                      <th>Destino</th>
                      <th>Data</th>
                      <th>Tipo</th>
                      <th style={{ textAlign: "center" }}>Ações</th>
                    </tr>
                  </thead>

                  <tbody>
                    {repasses.map(
                      ({ valor, saida, entrada, data, ...rest }, key) => (
                        <tr key={key}>
                          <td>
                            {new Intl.NumberFormat("pt-Br", {
                              currency: "BRL",
                              style: "currency",
                            }).format(parseFloat(valor))}
                          </td>

                          <td>{saida?.conta}</td>
                          <td>{entrada?.conta}</td>
                          <td>{format(parseISO(data), "dd/MM/yyyy")}</td>
                          <td>{tipos[rest.tipo]}</td>
                          <td
                            className="noPrint"
                            style={{ textAlign: "center" }}
                          >
                            <span
                              style={{
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={() => {
                                setEdit({
                                  ...rest,
                                  valor,
                                  saida,
                                  entrada,
                                  data,
                                });
                              }}
                            >
                              <Edit size={18} />
                            </span>

                            <span
                              style={{
                                cursor: "pointer",
                                marginLeft: 5,
                              }}
                              onClick={async () => {
                                const confirm = await sweetalert2.fire({
                                  title: "Deseja realmente excluir?",
                                  icon: "question",
                                  showCancelButton: true,
                                  confirmButtonText: "Sim",
                                  cancelButtonText: "Não",
                                  reverseButtons: true,
                                });

                                if (confirm.value) {
                                  removeRepasse(rest);
                                }
                              }}
                            >
                              <Trash size={18} />
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <Transferir onDismiss={() => setAdd(false)} open={add} />

      <EditRepasse item={edit} onDismiss={() => setEdit(null)} />
    </Fragment>
  );
};

const mapStateToProps = ({ Contas }) => {
  const { repasses, loadingRepasses, contas, loadingContas } = Contas;

  return {
    repasses,
    loadingRepasses,
    contas,
    loadingContas,
  };
};

export default connect(mapStateToProps, {
  getRepasses,
  removeRepasse,
})(Transferencia);
