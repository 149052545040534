import {
    GET_PERMISSIONS_ASYNC,
    GET_PERMISSIONS,
    UPDATE_PERMISSIONS_ASYNC,
    UPDATE_PERMISSIONS
} from "../actionTypes";

const initial_state = {
    loading: false,
    loadingUpdPermissions: false,
    permissions: null,
    adicionadas: [],
    removidas: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_PERMISSIONS_ASYNC:
            return { ...state, loading: true };
        case GET_PERMISSIONS:
            return { ...state, ...action.payload, loading: false };
        case UPDATE_PERMISSIONS_ASYNC:
            return { ...state, loadingUpdPermissions: true };
        case UPDATE_PERMISSIONS:
            return { ...state, ...action.payload, loadingUpdPermissions: false }
        default: return state;;
    }
}
