import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col, FormGroup, Label, Input, Form, Button } from 'reactstrap';
import { connect } from "react-redux";
import { Typeahead } from 'react-bootstrap-typeahead';
import { updateProfile } from '../../redux/actions'
import api from '../../services/api';

const DadosGerais = ({
    user,
    updateProfile,
    loading
}) => {
    const [fields, setFields] = useState({ ...user });
    const [estados, setEstados] = useState([]);
    const [cidades, setCidades] = useState([]);

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const handleSubmit = e => {
        e.preventDefault();

        const data = { ...fields };

        delete data.fluxos;

        updateProfile(data);
    }

    useEffect(() => {
        if (!!user) {
            setFields({ ...user });

            if ([1, 5, 6].includes(user?.groups[0]?.id)) {
                getEstados();
                getCidades(user?.estado_id);
            }
        }
    }, [user]);

    const getEstados = async () => {
        try {
            const { data } = await api.get('/api/auth/estados/show')

            setEstados(data?.estados)
        } catch (err) {
            console.log(err)
        }
    };

    const getCidades = async payload => {
        try {
            const { data } = await api.get(`/api/auth/cidades/show/${payload}`)

            setCidades(data?.cidades)
        } catch (err) {
            console.log(err)
        }
    };

    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form onSubmit={handleSubmit}>
                            <Row style={{ textAlign: 'initial' }}>
                                <Col sm="12">
                                    <FormGroup>
                                        <Label for="inputNome">Nome Completo</Label>
                                        <Input
                                            id="inputNome"
                                            type="text"
                                            value={fields.name}
                                            name="name"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col sm="12" lg="12">
                                    <FormGroup>
                                        <Label for="inputEamil">Email</Label>
                                        <Input
                                            id="inputEamil"
                                            type="email"
                                            defaultValue={fields.email}
                                            name="email"
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                </Col>

                                {[1, 5, 6].includes(user?.groups[0]?.id) && (
                                    <Fragment>
                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Label>Estado</Label>
                                                <Typeahead
                                                    id="selectEstado"
                                                    labelKey="name"
                                                    clearButton
                                                    placeholder="Estado"
                                                    options={estados.map(({ name, id }) => ({ name, id }))}
                                                    onChange={(estado) => {
                                                        if (!!estado.length) {
                                                            const { id } = estado[0]
                                                            setFields({
                                                                ...fields,
                                                                estado_id: id
                                                            })
                                                            getCidades(id)
                                                        } else {
                                                            setFields({
                                                                ...fields,
                                                                estado_id: null
                                                            })
                                                        }
                                                    }}
                                                    selected={estados.filter(e => e.id == fields.estado_id)}
                                                    emptyLabel="Nenhum resultado encontrado"
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6" xs="12">
                                            <FormGroup>
                                                <Label>Cidade</Label>
                                                <Typeahead
                                                    id="selectCidade"
                                                    labelKey="name"
                                                    clearButton
                                                    placeholder="Cidade"
                                                    options={cidades.map(({ name, id }) => ({ name, id }))}
                                                    onChange={(cidade) => {
                                                        if (!!cidade.length) {
                                                            const { id } = cidade[0]
                                                            setFields({
                                                                ...fields,
                                                                cidade_id: id
                                                            })
                                                        } else {
                                                            setFields({
                                                                ...fields,
                                                                cidade_id: null
                                                            })
                                                        }
                                                    }}
                                                    selected={cidades.filter(e => e.id == fields.cidade_id)}
                                                    emptyLabel="Nenhum resultado encontrado"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Fragment>
                                )}

                                <Col sm="12" style={{ justifyContent: 'flex-end', display: "flex" }}>
                                    <Button color="primary" disabled={loading}>
                                        {!loading ? 'Salvar' : 'Carregando...'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Auth }) => {
    const { user, loading } = Auth;
    return { user, loading }
}

export default connect(mapStateToProps, {
    updateProfile
})(DadosGerais);