import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Input
} from 'reactstrap';

import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Edit, Delete } from 'react-feather';
import sweetalert2 from "sweetalert2";

import Breadcrumb from '../../layout/breadcrumb';

import {
    deleteFornecedores
} from '../../redux/actions';

import Add from './add'
import EditFornecedores from './edit'

const Fornecedores = ({
    loadingFornecedores,
    fornecedores,
    deleteFornecedores
}) => {

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(null);

    if (loadingFornecedores) return (
        <Fragment>
            <Breadcrumb parent="Cadastros" title="Fornecedores" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Breadcrumb parent="Cadastros" title="Fornecedores" />

                <Button
                    color="primary"
                    onClick={() => {
                        setAdd(true)
                    }}
                >
                    Incluir
                </Button>
            </div>

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>Lista de Fornecedores</th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>Ações</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {fornecedores.map(
                                            ({ nome, id }, key) => (
                                                <tr key={key}>
                                                    <td>{nome}</td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginLeft: 5
                                                            }}
                                                            onClick={() => {
                                                                setEdit({ id, nome })
                                                            }}
                                                        >
                                                            <Edit
                                                                size={18}
                                                            />
                                                        </span>

                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginLeft: 5
                                                            }}
                                                            onClick={async () => {
                                                                const confirm = await sweetalert2.fire({
                                                                    title: 'Deseja realmente excluir ?',
                                                                    icon: 'question',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Sim',
                                                                    cancelButtonText: 'Não',
                                                                    reverseButtons: true
                                                                });

                                                                if (confirm.value) {
                                                                    deleteFornecedores(id);
                                                                }
                                                            }}
                                                        >
                                                            <Delete
                                                                size={18}
                                                            />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Add
                onDismiss={() => setAdd(false)}
                open={add}
            />

            <EditFornecedores
                onDismiss={() => setEdit(null)}
                open={edit}
            />
        </Fragment>
    );
}

const mapStateToProps = ({ Fornecedores }) => {
    const { loadingFornecedores, fornecedores } = Fornecedores

    return {
        loadingFornecedores,
        fornecedores
    };
}

export default connect(mapStateToProps, {
    deleteFornecedores
})(Fornecedores);