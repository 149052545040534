import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import Auth from "./auth/reducer";
import Funcionalidades from "./funcionalidades/reducer";
import Modulos from "./modulos/reducer";
import Groups from "./groups/reducer";
import Permissions from "./permissions/reducer";
import Users from "./users/reducer";
import Transacoes from "./transacoes/reducer";
import Previsoes from "./previsoes/reducer";
import Consolidado from "./consolidado/reducer";
import Contas from "./contas/reducer";
import Fornecedores from "./fornecedores/reducer";
import Extrato from "./extrato/reducer";
import Mensagens from "./mensagens/reducer";
import Conciliacao from "./conciliacao/reducer";
import TransacoesFuturas from "./transacoesFuturas/reducer";
import ReportOne from "./reportOne/reducer";
import UpdateReport from "./updateReport/reducer";
import GeneralBankStatement from "./generalBankStatement/reducer";
import BankAccountFiles from "./bankAccountFiles/reducer";
import BankBalance from "./bankBalance/reducer";
import TransactionsLastSearchPeriod from "./transactionsLastSearchPeriod/reducer";
import BankStatementSheet from "./bankStatementSheet/reducer";
import ConsolidatedTabledSheet from "./consolidatedTabled/reducer";

const reducers = combineReducers({
  Customizer,
  Auth,
  Funcionalidades,
  Modulos,
  Groups,
  Permissions,
  Users,
  Transacoes,
  Previsoes,
  Consolidado,
  Contas,
  Fornecedores,
  Extrato,
  Mensagens,
  Conciliacao,
  TransacoesFuturas,
  ReportOne,
  UpdateReport,
  GeneralBankStatement,
  BankAccountFiles,
  BankBalance,
  TransactionsLastSearchPeriod,
  BankStatementSheet,
  ConsolidatedTabledSheet,
});

export default reducers;
