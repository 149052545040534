import { useSelector } from "react-redux";

import { getContas } from "../redux/actions";
import { useCashFlowTypeStore } from "./cashflowType";
import { useAuthStore } from "./user";
import { useIsAssessor } from "./userAssessor";

export function getAccounts(dispatch = () => {}) {
  dispatch(getContas());
}

export function useAccountStore() {
  const { user } = useAuthStore();
  const { tiposFluxo } = useCashFlowTypeStore();
  const userAssessor = useIsAssessor();

  const {
    gruposContas,
    loadingContas,
    contas: contasStore,
    repasses,
    loadingRepasses,
  } = useSelector((state) => state.Contas);

  const listIdsCashTypes = tiposFluxo.map(({ id }) => Number(id));

  const contas = userAssessor
    ? contasStore.filter(({ tipos_fluxos_id }) =>
        listIdsCashTypes.includes(Number(tipos_fluxos_id))
      )
    : contasStore;

  return {
    gruposContas,
    loadingContas,
    contas,
    repasses,
    loadingRepasses,
  };
}
