import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Form,
  Button,
} from "reactstrap";

import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

import { compareAsc, format, parseISO } from "date-fns";

import br from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";

import Breadcrumb from "../../layout/breadcrumb";

import { useAccountStore } from "../../utils/accounts";
import { useCashFlowTypeStore } from "../../utils/cashflowType";
import { moneyConvert } from "../../utils/moneyConvert";

import { getConciliacao } from "../../redux/actions";

const CheckBalance = ({
  requested_date,
  final_value,
  conta,
  loadingConciliar,
  getConciliacao,
  user
}) => {
  const { contas } = useAccountStore();
  const { tiposFluxo } = useCashFlowTypeStore();

  const [form, setForm] = useState({
    fluxo: null,
    conta_id: null,
    requested_date: format(new Date(), 'yyyy-MM-dd'),
    requested_value: 0
  });

  const onSubmit = (e) => {
    e.preventDefault();

    const currentAccount = contas.find(
      ({ id }) => id === form.conta_id
    );

    const minimunDateToSearch = !!currentAccount
      ? parseISO(currentAccount.data_inicial)
      : new Date();

    const fields = ['conta_id', 'requested_date'];
    let fieldsNotProvided = 0;

    for (let i of fields) {
      if (!form[i]) {
        fieldsNotProvided++;
      }
    }

    if (fieldsNotProvided) {
      toast.error("Preencha todos os campos para prosseguir", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dateCompare = compareAsc(parseISO(form.requested_date), minimunDateToSearch);

      if (dateCompare >= 0) {
        const data = { ...form };

        getConciliacao(data);
      } else {
        toast.error("A data selecionada é anterior a data inicial da conta " + currentAccount.conta, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const total = parseFloat(final_value);

  const currencyColor = total === 0
    ? "text-dark"
    : total > 0
      ? "text-success"
      : "text-danger"

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Contas Bancárias" title="Checar Saldo" />
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col md="6" xs="12">
                      <Typeahead
                        id="fluxo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o fluxo"
                        options={tiposFluxo}
                        onChange={data => {
                          if (data?.length) {
                            setForm({
                              ...form,
                              fluxo: data[0].id
                            });
                          } else {
                            setForm({
                              ...form,
                              fluxo: null
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col md="6" xs="12">
                      <Typeahead
                        id="conta"
                        labelKey="conta"
                        clearButton
                        placeholder="Selecione a conta"
                        options={contas.filter(({ tipos_fluxos_id }) => tipos_fluxos_id === form.fluxo)
                          .map(({ conta, ...c }) => ({
                            ...c,
                            conta: String(conta),
                          }))}
                        onChange={(data) => {
                          if (data?.length) {
                            setForm({
                              ...form,
                              conta_id: data[0]?.id,
                            });
                          } else {
                            setForm({
                              ...form,
                              conta_id: "",
                            });
                          }
                        }}
                        selected={contas
                          .map(({ conta, ...c }) => ({
                            ...c,
                            conta: String(conta),
                          }))
                          .filter(({ id }) => id == form.conta_id)}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col md="6" xs="12" className="mt-3">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data"
                        selected={!!form?.requested_date ? parseISO(form.requested_date) : null}
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setForm({
                            ...form,
                            requested_date: format(selected, 'yyyy-MM-dd')
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12" className="mt-3">
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>

            {loadingConciliar
              ? (
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              )
              : !!conta
                ? (
                  <Card>
                    <CardBody>
                      <Col
                        sm="12"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginBottom: 20,
                        }}
                      >

                        <img
                          className="img-fluid for-light"
                          src={require("../../assets/images/logo/logo.jpg")}
                          alt=""
                          style={{
                            width: "130px",
                            height: "130px",
                            objectFit: "contain",
                          }}
                        />

                        <h4>Prefeitura de {user?.cidade?.name}</h4>
                      </Col>

                      <Col sm="12">
                        <Table bordered>
                          <tbody>
                            <tr>
                              <th style={{ width: '50%' }}>Conta</th>
                              <th className="text-center" style={{ width: '25%' }}>Data pesquisada</th>
                              <th className="text-center" style={{ width: '25%' }}>Saldo</th>
                            </tr>

                            <tr>
                              <td>{conta.conta}</td>
                              <td className="text-center">{format(parseISO(requested_date), 'dd/MM/yyyy')}</td>
                              <td className="text-center">{moneyConvert(parseFloat(final_value))}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </CardBody>
                  </Card>
                )
                : null
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({ Conciliacao, Contas, Transacoes, Auth }) => {
  const { loadingContas, contas } = Contas;
  const { tiposFluxo } = Transacoes;

  const { user } = Auth;

  const {
    requested_value,
    requested_date,
    final_value,
    loadingConciliar,
    conta
  } = Conciliacao;

  return {
    requested_value,
    requested_date,
    final_value,
    loadingConciliar,
    conta,
    loadingContas,
    contas,
    tiposFluxo,
    user
  };
};

export default connect(mapStateToProps, {
  getConciliacao,
})(CheckBalance);
