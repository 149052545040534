import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';

import { Typeahead } from 'react-bootstrap-typeahead';
import { format } from 'date-fns'

import Breadcrumb from '../../layout/breadcrumb';

import { editMensagens } from '../../redux/actions';

const Edit = ({
    item = null,
    onDismiss = () => { },
    editMensagens,
    loadingMensagens,
}) => {

    const [fields, setFields] = useState({
        titulo: '',
        mensagem: ''
    })

    useEffect(() => {
        if (!!item) {
            setFields(item)
        } else {
            setFields({
                titulo: '',
                mensagem: ''
            })
        }
    }, [item]);

    const handleSubmit = e => {
        e.preventDefault();

        let totalVazios = 0;

        for (let i in fields) {
            if (!fields[i]) {
                totalVazios++;
            }
        }

        if (!totalVazios) {
            editMensagens(fields)

            setFields({
                titulo: '',
                mensagem: ''
            });

            onDismiss();
        }
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    return (
        <Modal
            size="lg"
            isOpen={!!item}
            toggle={onDismiss}
            backdrop="static"
        >
            <Form
                onSubmit={handleSubmit}
            >
                <ModalHeader
                    toggle={onDismiss}
                >
                    Incluir Mensagem
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <Label>Título</Label>
                                <Input
                                    type="text"
                                    name="titulo"
                                    value={fields.titulo}
                                    onChange={handleChange}
                                    placeholder="Informe o título"
                                />
                            </FormGroup>
                        </Col>

                        <Col sm="12">
                            <FormGroup>
                                <Label>Mensagem</Label>
                                <Input
                                    type="textarea"
                                    name="mensagem"
                                    value={fields.mensagem}
                                    onChange={handleChange}
                                    placeholder="Descreva a mensagem"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        color="danger"
                        onClick={onDismiss}
                    >
                        Fechar
                    </Button>

                    <Button
                        color="primary"
                        disabled={loadingMensagens}
                    >
                        {loadingMensagens ? 'Carregando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Mensagens }) => {
    const { loadingMensagens } = Mensagens

    return {
        loadingMensagens,
    };
}

export default connect(mapStateToProps, {
    editMensagens
})(Edit);