import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { DollarSign } from 'react-feather';
import { format } from 'date-fns'

import Breadcrumb from '../../layout/breadcrumb';

import { getTransacoes } from '../../redux/actions';

const Projetado = ({
    getTransacoes,
    loadingTransacoes,
    resumo,
    tiposFluxo,
    projetadoPrevisto,
    projetadoRealizado
}) => {

    useEffect(() => {
        getTransacoes();
    }, []);

    if (loadingTransacoes) return (
        <Fragment>
            <Breadcrumb parent="Gerencial" title={`Fluxo de Caixa Projetado ${format(new Date, 'yyyy')}`} />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    const totalPrev = projetadoPrevisto.reduce((previous, current) => {
        return previous + parseFloat(current.saldo);
    }, 0);

    const totalReal = projetadoRealizado.reduce((previous, current) => {
        return previous + parseFloat(current.saldo);
    }, 0);

    return (
        <Fragment>
            <Breadcrumb parent="Gerencial" title={`Fluxo de Caixa Projetado ${format(new Date, 'yyyy')}`} />
            <Container fluid={true}>
                <Row>
                    {tiposFluxo.map((tipo, key) => {
                        const prev = parseFloat(projetadoPrevisto.find(p => p.id == tipo.id)?.saldo) || 0
                        const real = parseFloat(projetadoRealizado.find(p => p.id == tipo.id)?.saldo) || 0

                        return (
                            <Col sm="12" key={key}>
                                <Card>
                                    <CardBody>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <h4>{tipo.nome}</h4>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                    alignItems: 'center',
                                                    marginTop: 15
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: 50,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <h5 style={{ color: prev > 0 ? 'green' : 'red' }}>Previsto</h5>
                                                    <h5 style={{ color: prev > 0 ? 'green' : 'red' }}>
                                                        {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(prev)}
                                                    </h5>
                                                </div>

                                                <div
                                                    style={{
                                                        marginLeft: 50,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <h5 style={{ color: real > 0 ? 'green' : 'red' }}>Realizado</h5>
                                                    <h5 style={{ color: real > 0 ? 'green' : 'red' }}>
                                                        {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(real)}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}

                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <h4>Projeção Geral</h4>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            marginTop: 15
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: 50,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <h5 style={{ color: totalPrev > 0 ? 'green' : 'red' }}>Previsto</h5>
                                            <h5 style={{ color: totalPrev > 0 ? 'green' : 'red' }}>
                                                {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' })
                                                    .format(totalPrev)
                                                }
                                            </h5>
                                        </div>

                                        <div
                                            style={{
                                                marginLeft: 50,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <h5 style={{ color: totalReal > 0 ? 'green' : 'red' }}>Realizado</h5>
                                            <h5 style={{ color: totalReal > 0 ? 'green' : 'red' }}>
                                                {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' })
                                                    .format(totalReal)
                                                }
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Transacoes }) => {
    const {
        loadingTransacoes,
        resumo,
        tiposFluxo,
        projetadoPrevisto,
        projetadoRealizado
    } = Transacoes;

    return {
        loadingTransacoes,
        resumo,
        tiposFluxo,
        projetadoPrevisto,
        projetadoRealizado
    };
}

export default connect(mapStateToProps, {
    getTransacoes
})(Projetado);