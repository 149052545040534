import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button
} from 'reactstrap';

import DatePicker from "react-datepicker";
import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import br from "date-fns/locale/pt-BR";

import Breadcrumb from '../../layout/breadcrumb';

import { editConta } from '../../redux/actions';

const Edit = ({
    item = null,
    onDismiss = () => { },
    loadingContas,
    editConta
}) => {

    const [fields, setFields] = useState({
        tipos_fluxos_id: '',
        contas_grupos_id: '',
        agencia: '',
        conta: '',
        digito: '',
        banco: '',
        saldo_inicial: '',
        saldo_atual: '',
        data_inicial: ''
    })

    const handleSubmit = e => {
        e.preventDefault();

        editConta({
            ...fields,
            saldo_inicial: fields.saldo_inicial || 0,
            saldo_atual: fields.saldo_atual || 0,
        });

        setFields({
            tipos_fluxos_id: '',
            contas_grupos_id: '',
            agencia: '',
            conta: '',
            digito: '',
            banco: '',
            saldo_inicial: 0,
            saldo_atual: 0,
            data_inicial: ''
        });

        onDismiss();
    }

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    useEffect(() => {
        if (!!item)
            setFields(item);
    }, [item]);

    return (
        <Modal
            size="lg"
            isOpen={!!item}
            toggle={onDismiss}
            backdrop="static"
        >
            <Form
                onSubmit={handleSubmit}
            >
                <ModalHeader
                    toggle={onDismiss}
                >
                    Incluir Conta
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label>Banco</Label>
                                <Input
                                    type="text"
                                    name="banco"
                                    value={fields.banco}
                                    onChange={handleChange}
                                    placeholder="Informe o banco"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label>Agência</Label>
                                <Input
                                    type="text"
                                    name="agencia"
                                    value={fields.agencia}
                                    onChange={handleChange}
                                    placeholder="Informe a agência"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" sm="12">
                            <FormGroup>
                                <Label>Nome/Conta</Label>
                                <Input
                                    type="text"
                                    name="conta"
                                    value={fields.conta}
                                    onChange={handleChange}
                                    placeholder="Informe o número da conta"
                                />
                            </FormGroup>
                        </Col>

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Saldo Inicial</Label>
                                <NumberFormat
                                    placeholder="Informe o saldo"
                                    thousandSeparator='.'
                                    customInput={Input}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={!!fields?.saldo_inicial ? parseFloat(fields.saldo_inicial) : 0}
                                    onValueChange={({ floatValue }) => {
                                        setFields({
                                            ...fields,
                                            saldo_inicial: floatValue || 0,
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col>

                        {/* <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Saldo Atual</Label>
                                <NumberFormat
                                    placeholder="Informe o saldo"
                                    thousandSeparator='.'
                                    customInput={Input}
                                    decimalSeparator=","
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={!!fields?.saldo_atual ? parseFloat(fields.saldo_atual) : 0}
                                    onValueChange={({ floatValue }) => {
                                        setFields({
                                            ...fields,
                                            saldo_atual: floatValue || 0,
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col> */}

                        <Col md="6" xs="12">
                            <FormGroup>
                                <Label>Data Inicial</Label>
                                <DatePicker
                                    locale={br}
                                    autoComplete="off"
                                    placeholderText="Selecione a Data"
                                    selected={!!fields?.data_inicial ? parseISO(fields.data_inicial) : null}
                                    onCalendarClose={() => { }}
                                    dateFormat="dd/MM/yyyy"
                                    className="form-control digits"
                                    onChange={(selected) => {
                                        setFields({
                                            ...fields,
                                            data_inicial: format(selected, 'yyyy-MM-dd')
                                        });
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        color="danger"
                        onClick={onDismiss}
                    >
                        Fechar
                    </Button>

                    <Button
                        color="primary"
                        disabled={loadingContas}
                    >
                        {loadingContas ? 'Carregando...' : 'Salvar'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
}

const mapStateToProps = ({ Contas, Transacoes, Auth }) => {
    const { loadingContas, gruposContas } = Contas;
    const { tiposFluxo } = Transacoes;
    const { user } = Auth;

    return {
        loadingContas,
        tiposFluxo,
        user,
        gruposContas
    };
}

export default connect(mapStateToProps, {
    editConta
})(Edit);