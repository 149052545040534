import React, { Fragment, useEffect, useState, useRef } from "react";

import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";

import { format, parseISO, lastDayOfMonth } from "date-fns";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import { Typeahead } from "react-bootstrap-typeahead";

import Print from "../../../components/Print";
import Breadcrumb from "../../../layout/breadcrumb";

import { getGrupos, getEventos, getConsolidado } from "../../../redux/actions";

import { useCashFlowTypeStore } from "../../../utils/cashflowType";
import { getLastThreeMonths, getPeriodFromMonthAndYear } from '../../../utils/quarterly';

import QuarterlyTable from './quarterlyTable';

const QuarterlyConsolidated = ({
  user,
  eventos,
  loadingGrupos,
  grupos,
  loadingConsolidado,
  loadingTransacoes,
  transacoes,
  previsoes,
  dataInicio,
  dataFim,
  getGrupos,
  getEventos,
  getConsolidado,
  prefeitura,
  tiposTransacoes,
  tipo
}) => {

  const printRef = useRef();

  const { tiposFluxo } = useCashFlowTypeStore();
  const { firstDayOfPeriod, lastDayOfPeriod } = getLastThreeMonths();

  const [search, setSearch] = useState({
    dataInicio: firstDayOfPeriod,
    dataFim: lastDayOfPeriod,
    tipo: null,
    transacoes_grupo_id: null,
    transacoes_eventos_id: null,
    mes: null,
    ano: ''
  });

  useEffect(() => {
    getGrupos();
    getEventos();
  }, [getEventos, getGrupos]);

  useEffect(() => {
    const { firstDayOfPeriod, lastDayOfPeriod } = getPeriodFromMonthAndYear(search.mes, search.ano);

    setSearch({
      ...search,
      dataFim: lastDayOfPeriod,
      dataInicio: firstDayOfPeriod,
    });
  }, [search.mes, search.ano]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Relatório Consolidado Trimestral`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const receitasEventos = eventos
    .filter(
      ({ id }) => Array.from(new Set([
        ...transacoes
          .filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)
          .map(({ transacoes_eventos_id }) => transacoes_eventos_id),
        ...previsoes
          .filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)
          .map(({ transacoes_eventos_id }) => transacoes_eventos_id),
      ])).includes(id)
    );

  const despesasEventos = eventos
    .filter(
      ({ id }) => Array.from(new Set([
        ...transacoes
          .filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)
          .map(({ transacoes_eventos_id }) => transacoes_eventos_id),
        ...previsoes
          .filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)
          .map(({ transacoes_eventos_id }) => transacoes_eventos_id),
      ])).includes(id)
    );

  const optionsMes = [
    { id: 1, nome: "Janeiro" },
    { id: 2, nome: "Fevereiro" },
    { id: 3, nome: "Março" },
    { id: 4, nome: "Abril" },
    { id: 5, nome: "Maio" },
    { id: 6, nome: "Junho" },
    { id: 7, nome: "Julho" },
    { id: 8, nome: "Agosto" },
    { id: 9, nome: "Setembro" },
    { id: 10, nome: "Outubro" },
    { id: 11, nome: "Novembro" },
    { id: 12, nome: "Dezembro" },
  ];

  let buttonDisabled = !search.tipo;

  const condition = Boolean(search.mes && !search.ano) || Boolean(!search.mes && search.ano);

  if (!buttonDisabled && condition) {
    buttonDisabled = true;
  }

  const fluxo = tiposFluxo.find(({ id }) => id == tipo)?.nome;

  return (
    <Fragment>
      <Breadcrumb
        parent="Relatório"
        title="Consolidado Trimestral"
      />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                getConsolidado(search);
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="fluxo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o fluxo"
                        options={
                          user?.groups[0]?.id == 4
                            ? tiposFluxo.filter(t =>
                              user?.fluxos.map(f => f.id).includes(t.id)
                            )
                            : tiposFluxo
                        }
                        onChange={data => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              tipo: data[0].id
                            });
                          } else {
                            setSearch({
                              ...search,
                              tipo: null
                            });
                          }
                        }}
                        selected={tiposFluxo.filter(({ id }) => id === search.tipo)}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="tipo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o grupo"
                        options={grupos.filter(
                          ({ tipos_fluxos_id }) => tipos_fluxos_id === search.tipo
                        )}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              transacoes_grupo_id: data[0].id,
                              transacoes_eventos_id: null,
                            });
                          } else {
                            setSearch({
                              ...search,
                              transacoes_grupo_id: null,
                              transacoes_eventos_id: null,
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                        selected={grupos.filter(
                          ({ id }) => id == search.transacoes_grupo_id
                        )}
                        disabled={!search.tipo}
                      />
                    </Col>

                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="tipo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o subgrupo"
                        options={eventos.filter(
                          ({ transacoes_grupo_id }) => transacoes_grupo_id === search.transacoes_grupo_id
                        )}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              transacoes_eventos_id: data[0].id,
                            });
                          } else {
                            setSearch({
                              ...search,
                              transacoes_eventos_id: null,
                            });
                          }
                        }}
                        selected={eventos.filter(
                          ({ id }) => id == search.transacoes_eventos_id
                        )}
                        emptyLabel="Nenhum resultado encontrado"
                        disabled={!search.transacoes_grupo_id}
                      />
                    </Col>

                    <Col sm="6" className="mb-3">
                      <Typeahead
                        id="mes"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o mês do final do período"
                        options={optionsMes}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              mes: data[0].id
                            });
                          }
                        }}
                        defaultSelected={optionsMes.filter(({ id }) =>
                          id === parseInt(search.mes)
                        )}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="6" className="mb-3">
                      <Input
                        type="number"
                        value={search.ano}
                        onChange={({ target: { value } }) => {
                          setSearch({
                            ...search,
                            ano: value
                          });
                        }}
                        placeholder="Informe o ano do final do período"
                      />
                    </Col>

                    <Col sm="12" className="mt-3">
                      <Button
                        color="primary"
                        className="float-right"
                        disabled={buttonDisabled}
                      >
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>

          {loadingGrupos || loadingConsolidado || loadingTransacoes
            ? (
              <Col sm="12">
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              </Col>
            )
            : (
              <Fragment>
                {!!previsoes.length || transacoes.length ? (
                  <Fragment>
                    <Col sm="12">
                      <Button
                        color="primary"
                        className="mt-2 mb-4 float-right"
                        onClick={handlePrint}
                      >
                        Imprimir
                      </Button>
                    </Col>

                    <Col sm="12">
                      <Print ref={printRef}>
                        <Card className="font-arial-rlt">
                          <CardBody>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: 30,
                              }}
                            >
                              <img
                                className="img-fluid for-light"
                                src={require("../../../assets/images/logo/logo.jpg")}
                                alt=""
                                style={{
                                  width: "130px",
                                  height: "130px",
                                  objectFit: "contain",
                                }}
                              />

                              <h5 className="text-bold">Relatório Consolidado Trimestral</h5>
                              <p className="mt-2 text-bold">{fluxo}</p>
                              <p style={{ marginTop: '-10px' }}>
                                {format(parseISO(dataInicio), "MMMM 'de' yyyy", { locale: br })} - {format(parseISO(dataFim), "MMMM 'de' yyyy", { locale: br })}
                              </p>
                            </div>

                            <QuarterlyTable
                              title="Receitas - Origem"
                              subgroups={receitasEventos}
                              transactions={transacoes.filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)}
                              predictions={previsoes.filter(({ tipos_transacoes_id }) => tipos_transacoes_id == 1)}
                              period={{
                                start: parseISO(dataInicio),
                                end: parseISO(dataFim),
                              }}
                            />

                            <QuarterlyTable
                              title="Despesas - Origem"
                              subgroups={despesasEventos}
                              transactions={transacoes.filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)}
                              predictions={previsoes.filter(({ tipos_transacoes_id }) => tipos_transacoes_id != 1)}
                              period={{
                                start: parseISO(dataInicio),
                                end: parseISO(dataFim),
                              }}
                            />
                          </CardBody>
                        </Card>
                      </Print>
                    </Col>
                  </Fragment>
                ) : null}
              </Fragment>
            )
          }
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({ Transacoes, Auth, Consolidado }) => {
  const { tiposFluxo, eventos, loadingTransacoes, tiposTransacoes } = Transacoes;
  const { user } = Auth;
  const {
    loadingGrupos,
    grupos,
    loadingConsolidado,
    transacoes,
    previsoes,
    dataInicio,
    dataFim,
    prefeitura,
    tipo
  } = Consolidado;

  return {
    user,
    tiposFluxo,
    eventos,
    loadingGrupos,
    grupos,
    loadingConsolidado,
    transacoes,
    previsoes,
    loadingTransacoes,
    dataInicio,
    dataFim,
    prefeitura,
    tipo,
    tiposTransacoes
  };
};

export default connect(mapStateToProps, {
  getGrupos,
  getEventos,
  getConsolidado,
})(QuarterlyConsolidated);
