import { format } from 'date-fns';
import {
    GET_CONCILIACAO,
    SET_CONCILIACAO
} from '../actionTypes';

export const INITIAL_STATE = {
    requested_value: 0,
    requested_date: format(new Date, 'yyyy-MM-dd'),
    final_value: 0,
    loadingConciliar: false,
    conta: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_CONCILIACAO:
            return {
                ...state,
                loadingConciliar: true
            };

        case SET_CONCILIACAO:
            return {
                ...state,
                ...action.payload,
                loadingConciliar: false
            };

        default:
            return state;

    }
}