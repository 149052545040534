import {
    GET_FORNECEDORES,
    SET_FORNECEDORES,
    ADD_FORNECEDORES,
    EDIT_FORNECEDORES,
    DELETE_FORNECEDORES
} from '../actionTypes'

const INITIAL_STATE = {
    fornecedores: [],
    loadingFornecedores: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_FORNECEDORES:
            return {
                ...state,
                loadingFornecedores: true
            }

        case ADD_FORNECEDORES:
            return {
                ...state,
                loadingFornecedores: true
            }

        case EDIT_FORNECEDORES:
            return {
                ...state,
                loadingFornecedores: true
            }

        case DELETE_FORNECEDORES:
            return {
                ...state,
                loadingFornecedores: true
            }

        case SET_FORNECEDORES:
            return {
                ...state,
                loadingFornecedores: false,
                fornecedores: !!action.payload
                    ? action.payload
                    : state.fornecedores
            }

        default:
            return state
    }
}