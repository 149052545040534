import { GET_REPORT_ONE, SET_REPORT_ONE } from "../actionTypes";

export const getReportOne = payload => ({
    type: GET_REPORT_ONE,
    payload,
});

export const setReportOne = payload => ({
    type: SET_REPORT_ONE,
    payload,
});
