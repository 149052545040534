import {
    GET_EXTRATO,
    SET_EXTRATO
} from '../actionTypes'

const INITIAL_STATE = {
    loadingExtrato: false,
    extrato: {}
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_EXTRATO:
            return {
                ...state,
                loadingExtrato: true
            }

        case SET_EXTRATO:
            return {
                ...state,
                loadingExtrato: false,
                extrato: !!action.payload
                    ? action.payload
                    : state.extrato
            }

        default:
            return state
    }
}