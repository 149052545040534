import { all, call, takeEvery, put, fork } from "redux-saga/effects";

import { GET_UPDATE_REPORT } from "../actionTypes";

import { setUpdateReport } from "./actions";
import { apiGetUpdateReport } from "./endpoints";

function* sagaGetUpdateReport() {
  try {
    const data = yield call(apiGetUpdateReport);

    yield put(setUpdateReport(data));
  } catch (error) {
    yield put(setUpdateReport());
  }
}

function* watchUpdateReport() {
  yield takeEvery(GET_UPDATE_REPORT, sagaGetUpdateReport);
}

export default function* rootSaga() {
  yield all([fork(watchUpdateReport)]);
}
