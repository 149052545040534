import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { GET_CONCILIACAO } from '../actionTypes';
import { setConciliacao } from './actions';

import { INITIAL_STATE } from './reducer';

import api from '../../services/api';

function* sagaGetConciliacao({ payload }) {
    try {

        const data = yield call(apiGetConciliacao, payload);

        yield put(setConciliacao(data));

    } catch (err) {
        yield put(setConciliacao(INITIAL_STATE));
    }
}

const apiGetConciliacao = async payload => {
    const formData = new FormData();

    for (let i in payload) {
        formData.append(i, payload[i]);
    }

    const { data } = await api.post('/api/auth/conciliar', formData, {
        
    });

    return data;
}

function* watchGetConciliacao() {
    yield takeEvery(GET_CONCILIACAO, sagaGetConciliacao);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetConciliacao)
    ]);
}