import { all, takeEvery, fork, call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_GRUPOS_CONTA,
  ADD_GRUPOS_CONTA,
  GET_CONTAS,
  ADD_CONTA,
  EDIT_GRUPOS_CONTA,
  DELETE_GRUPOS_CONTA,
  EDIT_CONTA,
  TRANSFERIR,
  GET_REPASSES,
  EDIT_REPASSE,
  REMOVE_REPASSE,
  VINCULAR_GRUPOS_CONTA,
  DESVINCULAR_GRUPOS_CONTA,
} from "../actionTypes";

import {
  setGruposConta,
  setContas,
  setRepasses,
  getRepasses,
  getContas,
} from "../actions";

import api from "../../services/api";

function* sagaDesvincularGruposConta({ payload }) {
  try {
    const data = yield call(apiDesvincularGruposConta, payload);

    if (data.ok) {
      yield put(getContas());

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setGruposConta());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiDesvincularGruposConta = async (payload) => {
  const { data } = await api.put("/api/auth/contasGrupos/desvincular", payload);

  return data;
};

function* sagaVincularGruposConta({ payload }) {
  try {
    const data = yield call(apiVincularGruposConta, payload);

    if (data.ok) {
      yield put(getContas());

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setGruposConta());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiVincularGruposConta = async (payload) => {
  const { data } = await api.put("/api/auth/contasGrupos/vincular", payload);

  return data;
};

function* sagaGetGrupos() {
  try {
    const data = yield call(apiGetGrupos);

    yield put(setGruposConta(data));
  } catch (err) {
    console.log(err);
    yield put(setGruposConta());
  }
}

const apiGetGrupos = async () => {
  const { data } = await api.get("/api/auth/contasGrupos");

  return data;
};

function* sagaAddGrupos({ payload }) {
  const { gruposContas } = yield select((state) => state.Contas);

  try {
    const data = yield call(apiAddGrupos, payload);

    if (data.ok) {
      yield put(setGruposConta([...gruposContas, data.grupo]));

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setGruposConta());

      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setGruposConta());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiAddGrupos = async (payload) => {
  const { data } = await api.post("/api/auth/contasGrupos/add", payload);

  return data;
};

function* sagaEditGrupos({ payload }) {
  const { gruposContas } = yield select((state) => state.Contas);

  try {
    const data = yield call(apiEditGrupos, payload);

    if (data.ok) {
      yield put(
        setGruposConta(
          gruposContas.map((g) => {
            if (g.id == data.grupo.id) {
              return data.grupo;
            } else {
              return g;
            }
          })
        )
      );

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setGruposConta());

      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setGruposConta());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiEditGrupos = async (payload) => {
  const { data } = await api.put("/api/auth/contasGrupos/edit", payload);

  return data;
};

function* sagaDeleteGrupos({ payload }) {
  const { gruposContas } = yield select((state) => state.Contas);

  try {
    const data = yield call(apiDeleteGrupos, payload);

    if (data.ok) {
      yield put(setGruposConta(gruposContas.filter(({ id }) => id != payload)));

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setGruposConta());

      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setGruposConta());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiDeleteGrupos = async (payload) => {
  const { data } = await api.delete(`/api/auth/contasGrupos/delete/${payload}`);

  return data;
};

function* sagaGetContas() {
  try {
    const data = yield call(apiGetContas);

    yield put(setContas(data));
  } catch (err) {
    console.log(err);
    yield put(setContas());
  }
}

const apiGetContas = async () => {
  const { data } = await api.get("/api/auth/contas");

  return data;
};

function* sagaAddConta({ payload }) {
  const { contas } = yield select((state) => state.Contas);

  try {
    const data = yield call(apiAddContas, payload);

    if (data.ok) {
      yield put(setContas([...contas, data.conta]));

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setContas());

      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setContas());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiAddContas = async (payload) => {
  const { data } = await api.post("/api/auth/contas/add", payload);

  return data;
};

function* sagaEditConta({ payload }) {
  const { contas } = yield select((state) => state.Contas);

  try {
    const data = yield call(apiEditConta, payload);

    if (data.ok) {
      yield put(
        setContas(
          contas
            .map((g) => {
              if (g.id == data.conta.id) {
                return data.conta;
              } else {
                return g;
              }
            })
            .filter(({ active }) => active == 1)
        )
      );

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setContas());

      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setContas());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiEditConta = async (payload) => {
  const { data } = await api.post("/api/auth/contas/edit", payload);

  return data;
};

function* sagaGetRepasse({ payload }) {
  try {
    const data = yield call(apiGetRepasses, payload);

    yield put(setRepasses(data));
  } catch (err) {
    yield put(setRepasses());
  }
}

const apiGetRepasses = async (params) => {
  const { data } = await api.get("/api/auth/contas/repasses", { params });

  return data;
};

function* sagaTransferir({ payload }) {
  try {
    const data = yield call(apiTransferir, payload);

    if (data.ok) {
      yield put(getRepasses());

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setRepasses());

      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setRepasses());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiTransferir = async (payload) => {
  const { data } = await api.post("/api/auth/contas/transferir", payload);

  return data;
};

function* sagaEditRepasse({ payload }) {
  try {
    const data = yield call(apiEditRepasse, payload);

    if (data.ok) {
      yield put(getRepasses());

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setRepasses());

      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setRepasses());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiEditRepasse = async (payload) => {
  const { data } = await api.put(
    `/api/auth/contas/editRepasse/${payload.id}`,
    payload
  );

  return data;
};

function* sagaRemoveRepasse({ payload }) {
  try {
    const data = yield call(apiRemoveRepasse, payload);

    if (data.ok) {
      yield put(getRepasses());

      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      yield put(setRepasses());

      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (err) {
    yield put(setRepasses());

    toast.error("Ocorreu um erro", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiRemoveRepasse = async (payload) => {
  const { data } = await api.delete(
    `/api/auth/contas/removeRepasse/${payload.id}`
  );

  return data;
};

function* watchGetGrupos() {
  yield takeEvery(GET_GRUPOS_CONTA, sagaGetGrupos);
}

function* watchAddGrupos() {
  yield takeEvery(ADD_GRUPOS_CONTA, sagaAddGrupos);
}

function* watchGetContas() {
  yield takeEvery(GET_CONTAS, sagaGetContas);
}

function* watchAddConta() {
  yield takeEvery(ADD_CONTA, sagaAddConta);
}

function* watchEditConta() {
  yield takeEvery(EDIT_CONTA, sagaEditConta);
}

function* watchEditGrupos() {
  yield takeEvery(EDIT_GRUPOS_CONTA, sagaEditGrupos);
}

function* watchDeleteGrupos() {
  yield takeEvery(DELETE_GRUPOS_CONTA, sagaDeleteGrupos);
}

function* watchGetRepasses() {
  yield takeEvery(GET_REPASSES, sagaGetRepasse);
}

function* watchTransferir() {
  yield takeEvery(TRANSFERIR, sagaTransferir);
}

function* watchEditRepasse() {
  yield takeEvery(EDIT_REPASSE, sagaEditRepasse);
}

function* watchRemoveRepasse() {
  yield takeEvery(REMOVE_REPASSE, sagaRemoveRepasse);
}

function* watchVincularGruposConta() {
  yield takeEvery(VINCULAR_GRUPOS_CONTA, sagaVincularGruposConta);
}

function* watchDesvincularGruposConta() {
  yield takeEvery(DESVINCULAR_GRUPOS_CONTA, sagaDesvincularGruposConta);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetGrupos),
    fork(watchAddGrupos),
    fork(watchGetContas),
    fork(watchAddConta),
    fork(watchEditGrupos),
    fork(watchDeleteGrupos),
    fork(watchEditConta),
    fork(watchGetRepasses),
    fork(watchTransferir),
    fork(watchEditRepasse),
    fork(watchRemoveRepasse),
    fork(watchVincularGruposConta),
    fork(watchDesvincularGruposConta),
  ]);
}
