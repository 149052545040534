import { useSelector } from "react-redux";
import { format, parseISO } from "date-fns";

import { getReportOne } from "../redux/actions";

import { moneyConvert } from "./moneyConvert";

export function getReportOneStore(payload, dispatch = () => {}) {
  dispatch(getReportOne(payload));
}

function getDescription(repasse) {
  const labelType = repasse.tipo === "T" ? "Transferência" : "Empréstimo";

  const isSameFlow =
    repasse.entrada.tipos_fluxos_id === repasse.saida.tipos_fluxos_id;

  const flowLabel = isSameFlow ? "mesmo fluxo" : "outro fluxo";

  return `${labelType} entre contas ${flowLabel}`;
}

function getAccountColumnValue(repasse, fluxo) {
  let account = repasse.saida;
  let fornecedor = repasse.entrada;
  let prefixo = "Para: ";

  if (
    repasse.entrada.tipos_fluxos_id === fluxo &&
    repasse.saida.tipos_fluxos_id !== fluxo
  ) {
    account = repasse.entrada;
    fornecedor = repasse.saida;
    prefixo = "De: ";
  }

  return {
    conta: account.conta,
    fornecedor: fornecedor.conta,
    prefixo,
  };
}

export function useReportOneStore() {
  const {
    loadingReportOne,
    repassesReceitas,
    repassesDespesas,
    fluxo,
  } = useSelector((state) => state.ReportOne);

  const allRepasses = [...repassesDespesas, ...repassesReceitas];

  const unionRepasses = [];

  allRepasses.forEach((item) => {
    const hasAlreadyId = unionRepasses.some(({ id }) => id === item.id);

    if (!hasAlreadyId) {
      const repasse = allRepasses.find(({ id }) => item.id === id);

      unionRepasses.push(repasse);
    }
  });

  const repasses = [];

  unionRepasses.forEach(({ valor, data, ...repasse }) => {
    const { conta, prefixo, fornecedor } = getAccountColumnValue(
      repasse,
      fluxo
    );

    repasses.push({
      descricao: getDescription({ ...repasse, valor, data }),
      valor: moneyConvert(parseFloat(valor)),
      data: format(parseISO(data), "dd/MM/yyyy"),
      conta,
      prefixo,
      fornecedor,
    });
  });

  return { loadingReportOne, repasses };
}
