import { toast } from "react-toastify";
import { parseISO, compareAsc } from "date-fns";

import { getReportOneStore } from "../../../utils/reportOne";

export const onSubmitSearch = (requestBody, dispatch) => {
  if (
    !!requestBody.dataInicio &&
    !!requestBody.dataFim &&
    requestBody.tipos_fluxos_id
  ) {
    if (
      compareAsc(
        parseISO(requestBody.dataInicio),
        parseISO(requestBody.dataFim)
      ) > 0
    ) {
      toast.error("Data Inicial não pode ser maior que a final", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      getReportOneStore(requestBody, dispatch);
    }
  } else {
    toast.error("Preencha o formulário", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};
