import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Input
} from 'reactstrap';

import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Edit, Trash, Plus, X } from 'react-feather';
import sweetalert2 from "sweetalert2";

import Breadcrumb from '../../layout/breadcrumb';

import Add from './addGrupos';
import EditGrupos from './editGrupos';
import VincularGrupos from './vincularGrupos';
import DesvincularGrupos from './desvincularGrupos';

import {
    getGruposConta,
    deleteGruposConta
} from '../../redux/actions';

import { useIsDev } from '../../utils/userDev';

const GruposConta = ({
    gruposContas,
    loadingContas,
    getGruposConta,
    deleteGruposConta
}) => {

    const dev = useIsDev();

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(null);
    const [vincular, setVincular] = useState(null);
    const [desvincular, setDesvincular] = useState(null);

    useEffect(() => {
        getGruposConta()
    }, []);

    if (loadingContas) return (
        <Fragment>
            <Breadcrumb parent="Contas Bancárias" title="Grupos" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Breadcrumb parent="Contas Bancárias" title="Grupos" />

                <Button
                    color="primary"
                    onClick={() => {
                        setAdd(true)
                    }}
                >
                    Incluir
                </Button>
            </div>

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th style={{ width: '10%' }} className="text-center">Ações</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {gruposContas.map(
                                            ({ nome, id }, key) => (
                                                <tr key={key}>
                                                    <td>{nome}</td>
                                                    <td>
                                                        {dev && (
                                                            <span
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    marginLeft: 5
                                                                }}
                                                                onClick={() => {
                                                                    setVincular({
                                                                        grupo: id
                                                                    });
                                                                }}
                                                            >
                                                                <Plus
                                                                    size={18}
                                                                />
                                                            </span>
                                                        )}

                                                        {dev && (
                                                            <span
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    marginLeft: 5
                                                                }}
                                                                onClick={() => {
                                                                    setDesvincular({
                                                                        grupo: id
                                                                    });
                                                                }}
                                                            >
                                                                <X
                                                                    size={18}
                                                                />
                                                            </span>
                                                        )}

                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginLeft: 5
                                                            }}
                                                            onClick={() => {
                                                                setEdit({
                                                                    nome,
                                                                    id
                                                                })
                                                            }}
                                                        >
                                                            <Edit
                                                                size={18}
                                                            />
                                                        </span>

                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginLeft: 5
                                                            }}
                                                            onClick={async () => {
                                                                const confirm = await sweetalert2.fire({
                                                                    title: 'Deseja realmente excluir?',
                                                                    icon: 'question',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Sim',
                                                                    cancelButtonText: 'Não',
                                                                    reverseButtons: true
                                                                });

                                                                if (confirm.value) {
                                                                    deleteGruposConta(id);
                                                                }
                                                            }}
                                                        >
                                                            <Trash
                                                                size={18}
                                                            />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Add
                onDismiss={() => setAdd(false)}
                open={add}
            />

            <EditGrupos
                item={edit}
                onDismiss={() => setEdit(null)}
            />

            <VincularGrupos
                item={vincular}
                onDismiss={() => setVincular(null)}
            />

            <DesvincularGrupos
                item={desvincular}
                onDismiss={() => setDesvincular(null)}
            />
        </Fragment>
    );
}

const mapStateToProps = ({ Contas }) => {
    const { gruposContas, loadingContas } = Contas

    return {
        gruposContas,
        loadingContas
    };
}

export default connect(mapStateToProps, {
    getGruposConta,
    deleteGruposConta
})(GruposConta);