import {
    LOGIN_USER_ASYNC,
    LOGIN_USER,
    LOGOUT_USER_ASYNC,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_ASYNC,
    UPDATE_PROFILE,
    SET_UPDATE_PROFILE,
    CHANGE_PASSWORD
} from "../actionTypes";

const initial_state = {
    loading: false,
    user: null,
    token: null
};

export default (state = initial_state, action) => {
    switch (action.type) {

        case LOGIN_USER_ASYNC:
            return { ...state, loading: true };

        case LOGIN_USER:
            return { ...state, ...action.payload, loading: false };

        case LOGOUT_USER_ASYNC:
            return { ...state, loading: true };

        case LOGOUT_USER:
            return { ...state, ...action.payload, loading: false };

        case REGISTER_USER_ASYNC:
            return {
                ...state,
                loading: true
            }

        case REGISTER_USER:
            return {
                ...state,
                loading: false
            }

        case UPDATE_PROFILE:
            return {
                ...state,
                loading: true
            }

        case SET_UPDATE_PROFILE:
            return {
                ...state,
                loading: false,
                user: !!action.payload
                    ? action.payload
                    : state.user
            }

        case CHANGE_PASSWORD:
            return {
                ...state,
                loading: true
            }

        default:
            return state;
    }
}
