import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { DollarSign } from 'react-feather';

import Breadcrumb from '../../layout/breadcrumb';

import { getTransacoes } from '../../redux/actions';

const Home = ({
    getTransacoes,
    loadingTransacoes,
    resumo,
    tiposFluxo
}) => {

    useEffect(() => {
        getTransacoes();
    }, []);

    if (loadingTransacoes) return (
        <Fragment>
            <Breadcrumb parent="Gerencial" title="Fluxo de Caixa Saldo Atual" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    const somaTotal = resumo.reduce((previous, current) => {
        return previous + parseFloat(current.saldo);
    }, 0);

    return (
        <Fragment>
            <Breadcrumb parent="Gerencial" title="Fluxo de Caixa Saldo Atual" />
            <Container fluid={true}>
                <Row>
                    {tiposFluxo.map((tipo, key) => {
                        const saldo = resumo.find(r => r.id == tipo.id)?.saldo || 0
                        return (
                            <Col sm="12" key={key}>
                                <Card>
                                    <CardBody>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <h4>Saldo {tipo.nome}</h4>
                                            <h5
                                                style={{
                                                    color: parseFloat(saldo) > 0
                                                        ? 'green'
                                                        : 'red'
                                                }}
                                            >
                                                {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(parseFloat(saldo))}
                                            </h5>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}

                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <h4>Saldo Total</h4>
                                    <h5
                                        style={{
                                            color: somaTotal > 0
                                                ? 'green'
                                                : 'red'
                                        }}
                                    >
                                        {new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(parseFloat(somaTotal))}
                                    </h5>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Transacoes }) => {
    const {
        loadingTransacoes,
        resumo,
        tiposFluxo
    } = Transacoes;

    return {
        loadingTransacoes,
        resumo,
        tiposFluxo
    };
}

export default connect(mapStateToProps, {
    getTransacoes
})(Home);