import {
    GET_MODULOS_ASYNC,
    GET_MODULOS,
    LIST_MODULOS_ASYNC,
    LIST_MODULOS,
    STORE_MODULOS_ASYNC,
    STORE_MODULOS,
    UPDATE_MODULOS_ASYNC,
    UPDATE_MODULOS,
    DESTORY_MODULOS_ASYNC,
    DESTORY_MODULOS
} from "../actionTypes";

const initial_state = {
    loading: false,
    loadingList: false,
    loadingStoreModulo: false,
    loadingDelModulo: false,
    loadingUpdModulo: false,
    modulos: null,
    list: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_MODULOS_ASYNC:
            return { ...state, loading: true };
        case GET_MODULOS:
            return { ...state, ...action.payload, loading: false };
        case LIST_MODULOS_ASYNC:
            return { ...state, loadingList: true };
        case LIST_MODULOS:
            return { ...state, ...action.payload, loadingList: false };
        case STORE_MODULOS_ASYNC:
            return { ...state, loadingStoreModulo: true };
        case STORE_MODULOS:
            return { ...state, ...action.payload, loadingStoreModulo: false };
        case UPDATE_MODULOS_ASYNC:
            return { ...state, loadingUpdModulo: true };
        case UPDATE_MODULOS:
            return { ...state, ...action.payload, loadingUpdModulo: false }
        case DESTORY_MODULOS_ASYNC:
            return { ...state, loadingDelModulo: true };
        case DESTORY_MODULOS:
            return { ...state, ...action.payload, loadingDelModulo: false };
        default:
            return state;
    }
}
