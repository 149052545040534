import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Input
} from 'reactstrap';

import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from 'date-fns';
import { Edit, List, Delete } from 'react-feather';
import { toast } from 'react-toastify';
import sweetalert2 from "sweetalert2";

import Breadcrumb from '../../layout/breadcrumb';
import {
    addEvento,
    editEvento,
    deleteEvento
} from '../../redux/actions';

const Eventos = ({
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    transacoes,
    user,
    loadingTransacoes,
    addEvento,
    editEvento,
    deleteEvento
}) => {

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(null);

    const [fields, setFields] = useState({
        nome: '',
        tipos_fluxos_id: null,
        transacoes_blocos_id: null,
        tipos_transacoes_id: null
    });

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const handleChangeEdit = e => {
        const { name, value } = e.target;
        setEdit({ ...edit, [name]: value });
    }

    const handleSubmit = e => {
        e.preventDefault();

        const { nome, tipos_transacoes_id, tipos_fluxos_id, transacoes_blocos_id } = fields;

        if (!!nome && !!tipos_transacoes_id && tipos_fluxos_id) {
            if (tipos_fluxos_id == 2 && !transacoes_blocos_id) {
                toast.error('Preencha todos os campos', {
                    position: toast.POSITION.TOP_RIGHT
                });
            } else {
                addEvento(fields);

                setFields({
                    nome: '',
                    tipos_fluxos_id: null,
                    transacoes_blocos_id: null,
                    tipos_transacoes_id: null
                });
            }

        } else {
            toast.error('Preencha todos os campos', {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    useEffect(() => {

    });

    if (loadingTransacoes) return (
        <Fragment>
            <Breadcrumb parent="Novos Cadastros" title="Receitas/Despesas" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    const dev = user?.groups[0]?.id == 1

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Breadcrumb parent="Novos Cadastros" title="Receitas/Despesas" />

                {!!dev && (
                    <Button
                        color="primary"
                        onClick={() => {
                            setAdd(true);
                        }}
                    >
                        Incluir
                    </Button>
                )}
            </div>

            <Container fluid={true}>
                <Row>

                    {tiposFluxo.map(fluxo => (
                        <Col sm="12" key={fluxo.id}>
                            <h5 style={{ textAlign: 'center', marginBottom: 10, marginTop: 10 }}>{fluxo.nome}</h5>

                            {tiposTransacoes.map(tipo => {
                                const ev = eventos
                                    .filter(e => e.tipos_fluxos_id == fluxo.id && e.tipos_transacoes_id == tipo.id)

                                return (
                                    <Card key={tipo.id}>
                                        <CardBody>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>{tipo.nome}</th>
                                                        {!!dev && <th style={{ textAlign: 'center', width: '15%' }}>Ações</th>}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {!!ev.length && ev.map(
                                                        e => (
                                                            <tr key={e.id}>
                                                                <td>{e.nome}{fluxo.id == 2 ? ` - ${e.bloco?.nome}` : ''}</td>
                                                                {!!dev && (
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        <span
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                marginLeft: 5
                                                                            }}
                                                                            onClick={() => {
                                                                                setEdit(e);
                                                                            }}
                                                                        >
                                                                            <Edit
                                                                                size={18}
                                                                            />
                                                                        </span>

                                                                        <span
                                                                            style={{
                                                                                cursor: 'pointer',
                                                                                marginLeft: 5
                                                                            }}
                                                                            onClick={async () => {
                                                                                const confirm = await sweetalert2.fire({
                                                                                    title: 'Deseja realmente excluir?',
                                                                                    icon: 'question',
                                                                                    showCancelButton: true,
                                                                                    confirmButtonText: 'Sim',
                                                                                    cancelButtonText: 'Não',
                                                                                    reverseButtons: true
                                                                                });

                                                                                if (confirm.value) {
                                                                                    deleteEvento(e);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <Delete
                                                                                size={18}
                                                                            />
                                                                        </span>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        )
                                                    )}

                                                    {!ev.length && (
                                                        <tr>
                                                            <td style={{ textAlign: 'center' }}>Sem registros cadastrados</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>
                                )
                            })}
                        </Col>
                    ))}
                </Row>
            </Container>

            <Modal
                size="lg"
                isOpen={!!edit}
                toggle={() => {
                    setEdit(null);
                }}
                backdrop="static"
            >
                <Form
                    onSubmit={e => {
                        e.preventDefault();

                        if (!!edit?.nome) {
                            editEvento(edit);

                            setEdit(null);
                        }
                    }}
                >
                    <ModalHeader
                        toggle={() => {
                            setEdit(null);
                        }}
                    >
                        Editar Receita/Despesa
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        type="text"
                                        name="nome"
                                        value={edit?.nome}
                                        onChange={handleChangeEdit}
                                        placeholder="Informe o nome"
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            color="danger"
                            onClick={() => {
                                setEdit(null);
                            }}
                        >
                            Fechar
                        </Button>

                        <Button
                            color="primary"
                            disabled={loadingTransacoes}
                        >
                            {loadingTransacoes ? 'Carregando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <Modal
                size="lg"
                isOpen={add}
                toggle={() => {
                    setAdd(false);
                }}
                backdrop="static"
            >
                <Form
                    onSubmit={handleSubmit}
                >

                    <ModalHeader
                        toggle={() => {
                            setAdd(false);
                        }}
                    >
                        Incluir Receita/Despesa
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Nome</Label>
                                    <Input
                                        type="text"
                                        name="nome"
                                        value={fields.nome}
                                        onChange={handleChange}
                                        placeholder="Informe o nome"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Fluxo</Label>
                                    <Typeahead
                                        id="fluxo"
                                        labelKey="nome"
                                        clearButton
                                        placeholder="Selecione o fluxo"
                                        options={tiposFluxo}
                                        onChange={data => {
                                            if (data?.length) {
                                                setFields({
                                                    ...fields,
                                                    tipos_fluxos_id: data[0].id,
                                                    transacoes_blocos_id: null
                                                });
                                            } else {
                                                setFields({
                                                    ...fields,
                                                    tipos_fluxos_id: null,
                                                    transacoes_blocos_id: null
                                                });
                                            }
                                        }}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Tipo de Transação</Label>
                                    <Typeahead
                                        id="transacao"
                                        labelKey="nome"
                                        clearButton
                                        placeholder="Selecione o tipo"
                                        options={tiposTransacoes}
                                        onChange={data => {
                                            if (data?.length) {
                                                setFields({
                                                    ...fields,
                                                    tipos_transacoes_id: data[0].id
                                                });
                                            } else {
                                                setFields({
                                                    ...fields,
                                                    tipos_transacoes_id: null
                                                });
                                            }
                                        }}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>

                            {fields.tipos_fluxos_id == 2 && (
                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Bloco</Label>
                                        <Typeahead
                                            id="bloco"
                                            labelKey="nome"
                                            clearButton
                                            placeholder="Selecione o tipo"
                                            options={blocos}
                                            onChange={data => {
                                                if (data?.length) {
                                                    setFields({
                                                        ...fields,
                                                        transacoes_blocos_id: data[0].id
                                                    });
                                                } else {
                                                    setFields({
                                                        ...fields,
                                                        transacoes_blocos_id: null
                                                    });
                                                }
                                            }}
                                            emptyLabel="Nenhum resultado encontrado"
                                        />
                                    </FormGroup>
                                </Col>
                            )}
                        </Row>
                    </ModalBody>

                    <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            color="danger"
                            onClick={() => {
                                setAdd(false);
                            }}
                        >
                            Fechar
                        </Button>

                        <Button
                            color="primary"
                            disabled={loadingTransacoes}
                        >
                            {loadingTransacoes ? 'Carregando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = ({ Transacoes, Auth }) => {
    const {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes
    } = Transacoes;

    const { user } = Auth;

    return {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes,
        user
    };
}

export default connect(mapStateToProps, {
    addEvento,
    editEvento,
    deleteEvento
})(Eventos);