import React, { Fragment, useEffect, useRef } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

import Breadcrumb from "../../../layout/breadcrumb";
import Print from "../../../components/Print";

import { useUpdateReport } from "../../../utils/updateReport";

import List from './list';

const UpdateReport = () => {
  const printRef = useRef();

  const { getReport, loadingUpdateReport, updateReport } = useUpdateReport();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Relatório Consolidado Tabelado`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  useEffect(() => {
    getReport();
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Relatório" title="Últimas Atualizações" />
      <Container fluid={true}>
        <Row>

          {loadingUpdateReport ? (
            <Col sm="12">
              <div className="loader-box">
                <div className="loader-1"></div>
              </div>
            </Col>
          ) : (
            <Fragment>
              <Col sm="12">
                <Button
                  color="primary"
                  className="mt-5 float-right"
                  onClick={handlePrint}
                >
                  Imprimir
                </Button>
              </Col>
              <Col sm="12">
                <Print ref={printRef}>
                  <Card className="font-arial-rlt">
                    <CardBody>
                      <List updateReport={updateReport.filter(({ cidade_id }) => ![4491, 5397, 616].includes(cidade_id))} />
                    </CardBody>
                  </Card>
                </Print>
              </Col>
            </Fragment>
          )}
        </Row>
      </Container>
    </Fragment>
  );
};

export default UpdateReport;
