import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
import Api from "../../services/api";
import {
    GET_GROUPS,
    GET_GROUPS_ASYNC,
    LIST_GROUPS_ASYNC,
    LIST_GROUPS,
    STORE_GROUPS_ASYNC,
    STORE_GROUPS,
    GET_FUNCIONALIDADES,
    STORE_MODULOS,
    UPDATE_GROUPS_ASYNC,
    UPDATE_GROUPS,
    DESTROY_GROUPS_ASYNC,
    DESTROY_GROUPS,
    GET_MODULOS
} from '../actionTypes';
import { toast } from 'react-toastify';

toast.configure();

function* getGroupsSaga({ payload }) {
    try {
        const data = yield call(apiGetGroups);
        yield put({ type: GET_GROUPS, payload: { groups: data } });
    } catch (error) {
        yield put({ type: GET_GROUPS, payload: {} });
    }
}
const apiGetGroups = async fields => {
    const { data } = await Api.get('/api/auth/groups', { params: fields });

    return data;
}

function* listGroupsSaga() {
    try {
        const data = yield call(apiListGroups);
        if (data.ok) {
            const { list } = data;
            yield put({ type: LIST_GROUPS, payload: { list } });
        }
    } catch (error) {
        yield put({ type: LIST_GROUPS, payload: {} });
    }
}
const apiListGroups = async () => {
    const { data } = await Api.get(`/api/auth/groups/list`);

    return data;
}

function* storeGroupsSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreGroups, fields);
        if (data.ok) {
            const { groups, modulos, funcionalidades } = data;
            yield put({ type: STORE_GROUPS, payload: { groups } });
            yield put({ type: GET_FUNCIONALIDADES, payload: { funcionalidades } });
            yield put({ type: STORE_MODULOS, payload: { modulos } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            onSave();
        }
        if (!!errors) {
            yield put({ type: STORE_GROUPS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_GROUPS, payload: {} });
    }
}
const apiStoreGroups = async fields => {
    const data = await Api.post(`/api/auth/groups/store`, { ...fields });

    return data;
}

function* updateGroupsSaga({ payload }) {
    try {

        const { fields, onUpdate } = payload;
        const { data, errors } = yield call(apiUpdateGroups, fields);
        if (data.ok) {
            const { groups, modulos, funcionalidades } = data;
            yield put({ type: UPDATE_GROUPS, payload: { groups } });
            yield put({ type: GET_FUNCIONALIDADES, payload: { funcionalidades } });
            yield put({ type: STORE_MODULOS, payload: { modulos } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            onUpdate();
        }
        if (!!errors) {
            yield put({ type: UPDATE_GROUPS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: UPDATE_GROUPS, payload: {} });
    }
}
const apiUpdateGroups = async fields => {
    const id = fields.id;
    delete fields.id;
    const data = await Api.put(`/api/auth/groups/update/${id}`, { ...fields });

    return data;
}

function* destroyGroupsSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyGroups, fields);
        if (data.ok) {
            const { groups, funcionalidades, modulos } = data;
            yield put({ type: DESTROY_GROUPS, payload: { groups } });
            yield put({ type: GET_FUNCIONALIDADES, payload: { funcionalidades } });
            yield put({ type: GET_MODULOS, payload: { modulos } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            onDelete();
        }

    } catch (error) {

    }
}
const apiDestroyGroups = async fields => {
    const { data } = await Api.delete(`/api/auth/groups/destroy`, { data: { ...fields } });

    return data;
}

function* watchGetGroups() {
    yield takeLatest(GET_GROUPS_ASYNC, getGroupsSaga);
}
function* watchListGroups() {
    yield takeLatest(LIST_GROUPS_ASYNC, listGroupsSaga);
}
function* watchStoreGroups() {
    yield takeLatest(STORE_GROUPS_ASYNC, storeGroupsSaga);
}
function* watchUpdateGroups() {
    yield takeLatest(UPDATE_GROUPS_ASYNC, updateGroupsSaga);
}
function* watchDestroyGroups() {
    yield takeLatest(DESTROY_GROUPS_ASYNC, destroyGroupsSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetGroups),
        fork(watchListGroups),
        fork(watchStoreGroups),
        fork(watchUpdateGroups),
        fork(watchDestroyGroups)
    ]);
}