import { GET_REPORT_ONE, SET_REPORT_ONE } from "../actionTypes";

const INITIAL_STATE = {
  loadingReportOne: false,
  transacoes: [],
  repassesReceitas: [],
  repassesDespesas: [],
  fluxo: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_ONE:
      return { ...state, loadingReportOne: true, fluxo: action.payload.tipos_fluxos_id };

    case SET_REPORT_ONE:
      return {
        ...state,
        loadingReportOne: false,
        transacoes: !!action.payload
          ? action.payload.transacoes
          : state.transacoes,
        repassesReceitas: !!action.payload
          ? action.payload.repassesReceitas
          : state.repassesReceitas,
        repassesDespesas: !!action.payload
          ? action.payload.repassesDespesas
          : state.repassesDespesas,
      };

    default:
      return state;
  }
};
