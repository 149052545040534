import React, { Fragment, useEffect, useState, useRef } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";

import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { Edit, List, Delete } from "react-feather";
import sweetalert2 from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import { Typeahead } from "react-bootstrap-typeahead";

import api from '../../../services/api';

import Print from "../../../components/Print";
import Breadcrumb from "../../../layout/breadcrumb";

import { getGrupos, getEventos, getConsolidado, getTabledSheet } from "../../../redux/actions";

import { useCashFlowTypeStore } from "../../../utils/cashflowType";

import ListConsolidated from './list';

const GruposConta = ({
  user,
  eventos,
  loadingGrupos,
  grupos,
  loadingConsolidado,
  loadingTransacoes,
  transacoes,
  previsoes,
  dataInicio,
  dataFim,
  getGrupos,
  getEventos,
  getConsolidado,
  prefeitura,
  tiposTransacoes,
  getTabledSheet,
}) => {

  const printRef = useRef();

  const { tiposFluxo } = useCashFlowTypeStore();

  const [search, setSearch] = useState({
    dataInicio: format(new Date(), "yyyy-MM-dd"),
    dataFim: format(new Date(), "yyyy-MM-dd"),
    tipo: null,
    transacoes_grupo_id: null,
    transacoes_eventos_id: null,
    cidade_id: null,
  });

  useEffect(() => {
    getGrupos();
    getEventos();

    setSearch({
      ...search,
      cidade_id: user.cidade_id
    });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Relatório Consolidado Tabelado`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  return (
    <Fragment>
      <Breadcrumb
        parent="Relatório"
        title="Consolidado Tabelado"
      />

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();

                getConsolidado(search);
              }}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col sm="12" className="mt-3 mb-3">
                      <Typeahead
                        id="fluxo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o fluxo"
                        options={
                          user?.groups[0]?.id == 4
                            ? tiposFluxo.filter(t =>
                              user?.fluxos.map(f => f.id).includes(t.id)
                            )
                            : tiposFluxo
                        }
                        onChange={data => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              tipo: data[0].id
                            });
                          } else {
                            setSearch({
                              ...search,
                              tipo: null
                            });
                          }
                        }}
                        selected={tiposFluxo.filter(({ id }) => id === search.tipo)}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="tipo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o grupo"
                        options={grupos.filter(
                          ({ tipos_fluxos_id }) => tipos_fluxos_id === search.tipo
                        )}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              transacoes_grupo_id: data[0].id,
                              transacoes_eventos_id: null,
                            });
                          } else {
                            setSearch({
                              ...search,
                              transacoes_grupo_id: null,
                              transacoes_eventos_id: null,
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                        selected={grupos.filter(
                          ({ id }) => id == search.transacoes_grupo_id
                        )}
                        disabled={!search.tipo}
                      />
                    </Col>

                    <Col sm="12" className="mb-3">
                      <Typeahead
                        id="tipo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o subgrupo"
                        options={eventos.filter(
                          ({ transacoes_grupo_id }) => transacoes_grupo_id === search.transacoes_grupo_id
                        )}
                        onChange={(data) => {
                          if (data?.length) {
                            setSearch({
                              ...search,
                              transacoes_eventos_id: data[0].id,
                            });
                          } else {
                            setSearch({
                              ...search,
                              transacoes_eventos_id: null,
                            });
                          }
                        }}
                        selected={eventos.filter(
                          ({ id }) => id == search.transacoes_eventos_id
                        )}
                        emptyLabel="Nenhum resultado encontrado"
                        disabled={!search.transacoes_grupo_id}
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Inicial"
                        selected={
                          !!search.dataInicio ? parseISO(search.dataInicio) : ""
                        }
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataInicio: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="6">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data Final"
                        selected={
                          !!search.dataFim ? parseISO(search.dataFim) : ""
                        }
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setSearch({
                            ...search,
                            dataFim: !!selected
                              ? format(selected, "yyyy-MM-dd")
                              : "",
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12" className="mt-3">
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Form>
          </Col>

          {loadingGrupos || loadingConsolidado || loadingTransacoes
            ? (
              <Col sm="12">
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              </Col>
            )
            : (
              <Fragment>
                {!!transacoes.length || !!previsoes.length ? (
                  <Fragment>
                    <Col sm="12">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          minWidth: 300,
                        }}
                        className="mb-3"
                      >
                        <Button
                          color="primary"
                          onClick={handlePrint}
                        >
                          Imprimir
                        </Button>

                        <Button
                          color="primary"
                          style={{ marginLeft: 15 }}
                          onClick={() => {
                            getTabledSheet({
                              periodStart: search.dataInicio,
                              periodEnd: search.dataFim,
                              flow: search.tipo,
                              group: search.transacoes_grupo_id,
                              subgroup: search.transacoes_eventos_id,
                            });
                          }}
                        >
                          Gerar Planilha
                        </Button>
                      </div>
                    </Col>

                    <Col sm="12">
                      <Print ref={printRef}>
                        <ListConsolidated
                          dataFim={dataFim}
                          dataInicio={dataInicio}
                          user={user}
                          transacoes={transacoes}
                          previsoes={previsoes}
                          grupos={grupos}
                          eventos={eventos}
                          prefeitura={prefeitura}
                          tiposTransacoes={tiposTransacoes}
                        />
                      </Print>
                    </Col>
                  </Fragment>
                ): null}
              </Fragment>
            )
          }
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({ Transacoes, Auth, Consolidado }) => {
  const { tiposFluxo, eventos, loadingTransacoes, tiposTransacoes } = Transacoes;
  const { user } = Auth;
  const {
    loadingGrupos,
    grupos,
    loadingConsolidado,
    transacoes,
    previsoes,
    dataInicio,
    dataFim,
    prefeitura
  } = Consolidado;

  return {
    user,
    tiposFluxo,
    eventos,
    loadingGrupos,
    grupos,
    loadingConsolidado,
    transacoes,
    previsoes,
    loadingTransacoes,
    dataInicio,
    dataFim,
    prefeitura,
    tiposTransacoes
  };
};

export default connect(mapStateToProps, {
  getGrupos,
  getEventos,
  getConsolidado,
  getTabledSheet,
})(GruposConta);
