import {
    GET_TRANSACOES_FUTURAS,
    SET_TRANSACOES_FUTURAS,
    ADD_TRANSACOES_FUTURAS,
    DELETE_TRANSACOES_FUTURAS,
    EFETIVAR_TRANSACOES_FUTURAS
} from '../actionTypes';

const INITIAL_STATE = {
    loadingTransacoesFuturas: false,
    transacoesFuturas: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_TRANSACOES_FUTURAS:
            return {
                ...state,
                loadingTransacoesFuturas: true
            };

        case ADD_TRANSACOES_FUTURAS:
            return {
                ...state,
                loadingTransacoesFuturas: true
            };

        case DELETE_TRANSACOES_FUTURAS:
            return {
                ...state,
                loadingTransacoesFuturas: true
            };

        case EFETIVAR_TRANSACOES_FUTURAS:
            return {
                ...state,
                loadingTransacoesFuturas: true
            };

        case SET_TRANSACOES_FUTURAS:
            return {
                ...state,
                loadingTransacoesFuturas: false,
                transacoesFuturas: !!action.payload
                    ? action.payload
                    : state.transacoesFuturas
            };

        default:
            return state;
    }
}