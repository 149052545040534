import {
    GET_TRANSACOES_FUTURAS,
    SET_TRANSACOES_FUTURAS,
    ADD_TRANSACOES_FUTURAS,
    DELETE_TRANSACOES_FUTURAS,
    EFETIVAR_TRANSACOES_FUTURAS
} from '../actionTypes';

export const getTransacoesFuturas = payload => ({
    type: GET_TRANSACOES_FUTURAS,
    payload
});

export const addTransacoesFuturas = payload => ({
    type: ADD_TRANSACOES_FUTURAS,
    payload
});

export const deleteTransacoesFuturas = payload => ({
    type: DELETE_TRANSACOES_FUTURAS,
    payload
});

export const setTransacoesFuturas = payload => ({
    type: SET_TRANSACOES_FUTURAS,
    payload
});

export const efetivarTransacoesFuturas = payload => ({
    type: EFETIVAR_TRANSACOES_FUTURAS,
    payload
});