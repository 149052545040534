import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

import { Typeahead } from 'react-bootstrap-typeahead';

import { desvincularGruposConta } from '../../redux/actions';

const DesvincularGrupos = ({
  item = null,
  onDismiss = () => { },
  loadingContas,
  desvincularGruposConta,
  contas
}) => {

  const [fields, setFields] = useState({
    contas: [],
    grupo: null
  })

  useEffect(() => {
    if (!!item) {
      setFields({
        grupo: item.grupo,
        contas: []
      })
    }
  }, [item])

  const handleSubmit = e => {
    e.preventDefault();

    let totalVazios = 0;

    for (let i in fields) {
      if (!fields[i]) {
        totalVazios++;
      }
    }

    if (!totalVazios) {
      desvincularGruposConta(fields);

      setFields({
        id: '',
        nome: ''
      });

      onDismiss();
    }
  }

  return (
    <Modal
      size="lg"
      isOpen={!!item}
      toggle={onDismiss}
      backdrop="static"
    >
      <Form
        onSubmit={handleSubmit}
      >
        <ModalHeader
          toggle={onDismiss}
        >
          Desvincular Contas ao Grupo
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label>Contas</Label>
                <Typeahead
                  id="conta"
                  labelKey="conta"
                  clearButton
                  placeholder="Selecione as contas"
                  options={contas
                    .filter(({ contas_grupos_id }) => contas_grupos_id == item?.grupo)
                    .map(
                      ({ conta, ...c }) => ({
                        ...c,
                        conta: String(conta)
                      })
                    )
                  }
                  onChange={data => {
                    if (data?.length) {
                      setFields({
                        ...fields,
                        contas: data.map(({ id }) => id)
                      });
                    } else {
                      setFields({
                        ...fields,
                        contas: []
                      });
                    }
                  }}
                  emptyLabel="Nenhum resultado encontrado"
                  multiple
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            color="danger"
            onClick={onDismiss}
          >
            Fechar
          </Button>

          <Button
            color="primary"
            disabled={loadingContas}
          >
            {loadingContas ? 'Carregando...' : 'Salvar'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

const mapStateToProps = ({ Contas }) => {
  const { loadingContas, contas } = Contas

  return {
    loadingContas,
    contas
  };
}

export default connect(mapStateToProps, {
  desvincularGruposConta
})(DesvincularGrupos);