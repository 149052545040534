import { all, takeEvery, fork, call, put } from 'redux-saga/effects'

import { GET_EXTRATO } from '../actionTypes'
import { setExtrato } from '../actions'

import api from '../../services/api'

function* sagaGetExtrato({ payload }) {
    try {
        const data = yield call(apiGetExtrato, payload)

        yield put(setExtrato(data))
    } catch (err) {
        yield put(setExtrato())
    }
}

const apiGetExtrato = async params => {
    const { data } = await api.get('/api/auth/contas/extrato', { params })

    return data
}

function* watchGetExtrato() {
    yield takeEvery(GET_EXTRATO, sagaGetExtrato)
}

export default function* rootSaga() {
    yield all([
        fork(watchGetExtrato)
    ])
}