import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { connect } from "react-redux";
import { Container, Row, Col, Card, CardHeader, Media, Nav, NavItem, NavLink, TabContent, TabPane, CardBody } from 'reactstrap'
import DadosGerais from "./DadosGerais";
import AlterarSenha from './AlterarSenha';
const UserProfile = ({ user }) => {

  const [url, setUrl] = useState();
  const [activeTab, setActiveTab] = useState(1);
  const readUrl = (event) => {
    if (event.target.files.length === 0)
      return;
    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      setUrl(reader.result)
    }
  }
  return (
    <Fragment>
      <Breadcrumb parent="Início" title="Perfil" />
      <Container fluid={true}>
        <div className="user-profile">
          <Row>
            <Col sm="12">
              <Card className="card hovercard text-center">
                <div className="info">
                  <hr />
                  <Card>
                    <CardBody className="p-0">
                      <Row>
                        <Col sm="3" xs="12" className="b-r-primary">
                          <Nav tabs className="flex-column nav-pills" vertical={true} >
                            <NavItem>
                              <NavLink href="#javascript" className={activeTab === 1 ? "active" : ""} onClick={() => setActiveTab(1)}>
                                Dados Gerais
                            </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink href="#javascript" className={activeTab === 2 ? "active" : ""} onClick={() => setActiveTab(2)}>
                                Alterar Senha
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                        <Col xs="9">
                          <TabContent activeTab={activeTab}>
                            <TabPane tabId={1}>
                              <DadosGerais />
                            </TabPane>
                            <TabPane tabId={2}>
                              <AlterarSenha />
                            </TabPane>
                          </TabContent>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
}
const mapStateToProps = ({ Auth }) => {
  const { user } = Auth;
  return { user }
}

export default connect(mapStateToProps, {

})(UserProfile);