import {
    GET_TIPOS_FLUXO,
    SET_TIPOS_FLUXO,
    GET_TIPOS_TRANSACOES,
    SET_TIPOS_TRANSACOES,
    GET_BLOCOS,
    SET_BLOCOS,
    GET_EVENTOS,
    SET_EVENTOS,
    ADD_BLOCO,
    SET_ADD_BLOCO,
    ADD_EVENTO,
    SET_ADD_EVENTO,
    GET_TRANSACOES,
    SET_TRANSACOES,
    ADD_TRANSACAO,
    NOT_AUTHORIZED,
    EDIT_TRANSACAO,
    DELETE_TRANSACAO,
    EDIT_EVENTO,
    DELETE_EVENTO,
    EDIT_BLOCO,
    DELETE_BLOCO
} from '../actionTypes';

const INITIAL_STATE = {
    tiposFluxo: [],
    tiposTransacoes: [],
    blocos: [],
    eventos: [],
    transacoes: [],
    previsoes: [],
    dadosTransacoes: [],
    resumo: [],
    previsto: [],
    loadingTransacoes: false,
    notAuthorized: false,
    projetadoRealizado: [],
    projetadoPrevisto: [],
    evento: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_TIPOS_FLUXO:
            return {
                ...state,
                loadingTransacoes: true
            };

        case SET_TIPOS_FLUXO:
            return {
                ...state,
                loadingTransacoes: false,
                tiposFluxo: !!action.payload
                    ? action.payload
                    : state.tiposFluxo
            };

        case GET_TIPOS_TRANSACOES:
            return {
                ...state,
                loadingTransacoes: true
            };

        case SET_TIPOS_TRANSACOES:
            return {
                ...state,
                loadingTransacoes: false,
                tiposTransacoes: !!action.payload
                    ? action.payload
                    : state.tiposTransacoes
            };

        case GET_BLOCOS:
            return {
                ...state,
                loadingTransacoes: true
            };

        case SET_BLOCOS:
            return {
                ...state,
                loadingTransacoes: false,
                blocos: !!action.payload
                    ? action.payload
                    : state.blocos
            };

        case GET_EVENTOS:
            return {
                ...state,
                loadingTransacoes: true
            };

        case SET_EVENTOS:
            return {
                ...state,
                loadingTransacoes: false,
                eventos: !!action.payload
                    ? action.payload
                    : state.eventos
            };

        case ADD_BLOCO:
            return {
                ...state,
                loadingTransacoes: true
            };

        case SET_ADD_BLOCO:
            return {
                ...state,
                loadingTransacoes: false,
                blocos: !!action.payload
                    ? [...state.blocos, action.payload]
                    : state.blocos
            };

        case ADD_EVENTO:
            return {
                ...state,
                loadingTransacoes: true
            };

        case SET_ADD_EVENTO:
            return {
                ...state,
                loadingTransacoes: false,
                eventos: !!action.payload
                    ? [...state.eventos, action.payload]
                    : state.eventos
            };

        case GET_TRANSACOES:
            return {
                ...state,
                loadingTransacoes: true,
                notAuthorized: false,
            };

        case SET_TRANSACOES:
            return {
                ...state,
                loadingTransacoes: false,
                transacoes: !!action.payload?.transacoes
                    ? action.payload.transacoes
                    : [],
                dadosTransacoes: !!action.payload?.dadosTransacoes
                    ? action.payload.dadosTransacoes
                    : [],
                resumo: !!action.payload?.resumo
                    ? action.payload.resumo
                    : [],
                previsto: !!action.payload?.previsto
                    ? action.payload.previsto
                    : [],
                projetadoPrevisto: !!action.payload?.projetadoPrevisto
                    ? action.payload.projetadoPrevisto
                    : [],
                projetadoRealizado: !!action.payload?.projetadoRealizado
                    ? action.payload.projetadoRealizado
                    : [],
                previsoes: !!action.payload?.previsoes
                    ? action.payload.previsoes
                    : [],
                evento: !!action.payload?.evento
                    ? action.payload.evento
                    : null,
            }

        case ADD_TRANSACAO:
            return {
                ...state,
                loadingTransacoes: true,
            }

        case EDIT_TRANSACAO:
            return {
                ...state,
                loadingTransacoes: true,
            }

        case DELETE_TRANSACAO:
            return {
                ...state,
                loadingTransacoes: true,
            }

        case EDIT_EVENTO:
            return {
                ...state,
                loadingTransacoes: true,
            }

        case DELETE_EVENTO:
            return {
                ...state,
                loadingTransacoes: true,
            }

        case EDIT_BLOCO:
            return {
                ...state,
                loadingTransacoes: true,
            }

        case DELETE_BLOCO:
            return {
                ...state,
                loadingTransacoes: true,
            }

        case NOT_AUTHORIZED:
            return {
                ...state,
                notAuthorized: true,
                transacoes: [],
                dadosTransacoes: [],
                resumo: [],
            }

        default:
            return state;
    }
}