import { GET_GROUPS_ASYNC, LIST_GROUPS_ASYNC, STORE_GROUPS_ASYNC, UPDATE_GROUPS_ASYNC, DESTROY_GROUPS_ASYNC } from "../actionTypes";

export const getGroups = payload => ({
    type: GET_GROUPS_ASYNC,
    payload
});

export const listGroups = () => ({
    type: LIST_GROUPS_ASYNC
});

export const storeGroups = payload => ({
    type: STORE_GROUPS_ASYNC,
    payload
});

export const updateGroups = payload => ({
    type: UPDATE_GROUPS_ASYNC,
    payload
});

export const destroyGroups = payload => ({
    type: DESTROY_GROUPS_ASYNC,
    payload
});