import { format } from "date-fns";
import { SET_TRANSACTIONS_LAST_SEARCH_PERIOD } from "../actionTypes";

const INITIAL_STATE = {
  initialDate: format(new Date(), "yyyy-MM-dd"),
  endDate: format(new Date(), "yyyy-MM-dd"),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TRANSACTIONS_LAST_SEARCH_PERIOD:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
