import React, { Fragment, useEffect, useState, useRef } from "react";

import { connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Button,
} from "reactstrap";

import { format, parseISO } from "date-fns";
import br from "date-fns/locale/pt-BR";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import DatePicker from "react-datepicker";

import { Typeahead } from "react-bootstrap-typeahead";

import Print from "../../components/Print";
import Breadcrumb from "../../layout/breadcrumb";

import { getContas, getGruposConta, editConta, getBalance } from "../../redux/actions";

import { useCashFlowTypeStore } from "../../utils/cashflowType";

import BalanceByFlow from './balance-by-flow';
import BalanceByAccounts from './balance-by-accounts';

const BankBalance = ({
  user,
  getContas,
  getGruposConta,
  loadingBalance,
  balance,
  date,
  getBalance,
}) => {

  const { tiposFluxo } = useCashFlowTypeStore();

  const [form, setForm] = useState({
    date: format(new Date(), 'yyyy-MM-dd'),
    cashflow: null,
  })

  const printRef = useRef();

  useEffect(() => {
    getContas();
    getGruposConta();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  const onSubmit = (event) => {
    event.preventDefault();

    if (!form.cashflow) {
      toast.error("Selecione um fluxo para prosseguir.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      getBalance(form);
    }
  }

  if (loadingBalance)
    return (
      <Fragment>
        <Breadcrumb parent="Contas Bancárias" title="Saldo Bancário" />
        <Container fluid={true}></Container>
        <div className="loader-box">
          <div className="loader-1"></div>
        </div>
      </Fragment>
    );

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Contas Bancárias" title="Saldo Bancário" />

        <Button
          color="primary"
          className="float-right"
          onClick={handlePrint}
        >
          Imprimir
        </Button>
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col md="6" xs="12">
                      <Typeahead
                        id="fluxo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o fluxo"
                        options={tiposFluxo}
                        onChange={data => {
                          if (data?.length) {
                            setForm({
                              ...form,
                              cashflow: data[0].id
                            });
                          } else {
                            setForm({
                              ...form,
                              cashflow: null
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col md="6" xs="12">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data"
                        selected={!!form?.date ? parseISO(form.date) : null}
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setForm({
                            ...form,
                            date: format(selected, 'yyyy-MM-dd')
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12" className="mt-3">
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>

          <Col sm="12">
            <Print ref={printRef}>
              <Card className="font-arial-rlt">
                <CardBody>
                  <Col
                    sm="12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <img
                      className="img-fluid for-light"
                      src={require("../../assets/images/logo/logo.jpg")}
                      alt=""
                      style={{
                        width: "130px",
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />

                    <h5>Prefeitura de {user?.cidade?.name}</h5>
                    <p style={{ alignSelf: 'flex-end' }}>Data: {format(parseISO(date), 'dd/MM/yyyy')}</p>
                  </Col>

                  {/* <BalanceByFlow
                    accounts={contas}
                    flowTypes={tiposFluxo}
                  /> */}

                  <BalanceByAccounts balance={balance} />

                </CardBody>
              </Card>
            </Print>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({ Contas, Transacoes, Auth, BankBalance }) => {
  const { contas, loadingContas, gruposContas } = Contas;
  const { tiposFluxo, eventos } = Transacoes;
  const { user } = Auth;
  const { loadingBalance, balance, date } = BankBalance

  return {
    contas,
    tiposFluxo,
    loadingContas,
    user,
    gruposContas,
    eventos,
    loadingBalance,
    balance,
    date
  };
};

export default connect(mapStateToProps, {
  getContas,
  getGruposConta,
  editConta,
  getBalance
})(BankBalance);
