import React, { Fragment, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { useReactToPrint } from 'react-to-print';
import ChartistGraph from 'react-chartist';

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Input,
    CardHeader,
    CardTitle,
    CardText
} from 'reactstrap';

import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO, compareAsc } from 'date-fns';
import { ThumbsDown, Edit, List } from 'react-feather';
import Breadcrumb from '../../layout/breadcrumb';
import Print from '../../components/Print';
import {
    getTransacoes,
    addTransacao,
    editTransacao
} from '../../redux/actions';

import { moneyConvert } from '../../utils/moneyConvert';

import api from '../../services/api'

import "react-datepicker/dist/react-datepicker.css";

const Transacoes = ({
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    user
}) => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({
        previsto: [],
        dadosTransacoes: [],
    });

    const [search, setSearch] = useState({
        dataInicio: format(new Date, 'yyyy-MM-dd'),
        dataFim: format(new Date, 'yyyy-MM-dd'),
    });

    const printRef = useRef();

    useEffect(() => {
        getGeral(search);
    }, []);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        documentTitle: `Relatório Geral ${user?.cidade?.name}`,
        onPrintError: () => toast.error("Ocorreu um erro ao tentar imprimir relatório", toast.POSITION.TOP_RIGHT)
    });

    const getGeral = async params => {
        setLoading(true);

        try {
            const { data } = await api.get('/api/auth/transacoes/geralPorData', { params });

            setData(data);
        } catch (err) {
            setData({
                previsto: [],
                dadosTransacoes: [],
            });
        }

        setLoading(false);
    }

    const { dadosTransacoes, previsto } = data;

    if (loading) return (
        <Fragment>
            <Breadcrumb parent="Fluxo de Caixa" title="Resumo Geral" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    const calcDivergencia = (previsto = 0, realizado = 0) => {
        const moeda = realizado - previsto;

        let percentual;
        const diferenca = realizado - previsto;
        const percentualFloat = diferenca / previsto * 100;

        let color = percentualFloat > 0 ? 'success' : 'danger';
        let colorPercentual = color;

        percentual = `${new Intl.NumberFormat('pt-br', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(percentualFloat)}%`;

        if ((previsto === 0 || realizado === 0) || previsto == realizado) {
            percentual = '0%';
            colorPercentual = 'dark';
        }

        return {
            moeda,
            percentual,
            color,
            colorPercentual
        };
    }

    const graficoDivergenciaReceitas = {
        labels: [],
        series: [[], []]
    };

    const graficoDivergenciaDespesas = {
        labels: [],
        series: [[], []]
    };

    const graficoPrevisaoPeriodoPorFluxoReceitas = {
        labels: [],
        series: []
    };

    const graficoRealizadoPeriodoPorFluxoReceitas = {
        labels: [],
        series: []
    };

    const graficoPrevisaoPeriodoPorFluxoDespesas = {
        labels: [],
        series: []
    };

    const graficoRealizadoPeriodoPorFluxoDespesas = {
        labels: [],
        series: []
    };

    tiposFluxo.forEach(tipo => {

        const prevDespesa = previsto
            .filter(p => p.tipos_transacoes_id != 1 && p.tipos_fluxos_id == tipo.id)
            .reduce((prev, current) => prev + parseFloat(current.total || 0), 0);

        const prevReceita = previsto
            .filter(p => p.tipos_transacoes_id == 1 && p.tipos_fluxos_id == tipo.id)
            .reduce((prev, current) => prev + parseFloat(current.total || 0), 0);

        const realizadoDespesa = dadosTransacoes
            .filter(p => p.tipos_transacoes_id != 1 && p.tipos_fluxos_id == tipo.id)
            .reduce((prev, current) => prev + parseFloat(current.total || 0), 0);

        const realizadoReceita = dadosTransacoes
            .filter(p => p.tipos_transacoes_id == 1 && p.tipos_fluxos_id == tipo.id)
            .reduce((prev, current) => prev + parseFloat(current.total || 0), 0);

        const divergenciaReceitaPorcentagem = calcDivergencia(prevReceita, realizadoReceita).percentual;
        const divergenciaDespesaPorcentagem = calcDivergencia(prevDespesa, realizadoDespesa).percentual;

        graficoDivergenciaReceitas.labels = [...graficoDivergenciaReceitas.labels, `${tipo.nome} (${divergenciaReceitaPorcentagem})`];
        graficoDivergenciaReceitas.series = [
            [...graficoDivergenciaReceitas.series[0], prevReceita],
            [...graficoDivergenciaReceitas.series[1], realizadoReceita],
        ];

        graficoDivergenciaDespesas.labels = [...graficoDivergenciaDespesas.labels, `${tipo.nome} (${divergenciaDespesaPorcentagem})`];
        graficoDivergenciaDespesas.series = [
            [...graficoDivergenciaDespesas.series[0], prevDespesa],
            [...graficoDivergenciaDespesas.series[1], realizadoDespesa],
        ];

        if (prevReceita > 0) {
            graficoPrevisaoPeriodoPorFluxoReceitas.labels = [...graficoPrevisaoPeriodoPorFluxoReceitas.labels, tipo.nome];
            graficoPrevisaoPeriodoPorFluxoReceitas.series = [...graficoPrevisaoPeriodoPorFluxoReceitas.series, prevReceita];
        }

        if (realizadoReceita > 0) {
            graficoRealizadoPeriodoPorFluxoReceitas.labels = [...graficoRealizadoPeriodoPorFluxoReceitas.labels, tipo.nome];
            graficoRealizadoPeriodoPorFluxoReceitas.series = [...graficoRealizadoPeriodoPorFluxoReceitas.series, realizadoReceita];
        }

        if (prevDespesa > 0) {
            graficoPrevisaoPeriodoPorFluxoDespesas.labels = [...graficoPrevisaoPeriodoPorFluxoDespesas.labels, tipo.nome];
            graficoPrevisaoPeriodoPorFluxoDespesas.series = [...graficoPrevisaoPeriodoPorFluxoDespesas.series, prevDespesa];
        };

        if (realizadoDespesa > 0) {
            graficoRealizadoPeriodoPorFluxoDespesas.labels = [...graficoRealizadoPeriodoPorFluxoDespesas.labels, tipo.nome];
            graficoRealizadoPeriodoPorFluxoDespesas.series = [...graficoRealizadoPeriodoPorFluxoDespesas.series, realizadoDespesa];
        };
    });

    const sum = (a, b) => a + b;

    const graficoRealizadoPeriodoPorFluxoReceitasSum = graficoRealizadoPeriodoPorFluxoReceitas.series.reduce(sum, 0);
    const graficoPrevisaoPeriodoPorFluxoReceitasSum = graficoPrevisaoPeriodoPorFluxoReceitas.series.reduce(sum, 0);
    const graficoPrevisaoPeriodoPorFluxoDespesasSum = graficoPrevisaoPeriodoPorFluxoDespesas.series.reduce(sum, 0);
    const graficoRealizadoPeriodoPorFluxoDespesasSum = graficoRealizadoPeriodoPorFluxoDespesas.series.reduce(sum, 0);

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Breadcrumb parent="Fluxo de Caixa" title="Resumo Geral" />
            </div>

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();

                                if (!!search.dataInicio && !!search.dataFim) {
                                    if (compareAsc(parseISO(search.dataInicio), parseISO(search.dataFim)) > 0) {
                                        toast.error('Data Inicial não pode ser maior que a final', {
                                            position: toast.POSITION.TOP_RIGHT
                                        });
                                    } else {
                                        getGeral(search);
                                    }
                                } else {
                                    toast.error('Selecione o período', {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }}
                        >
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="6">
                                            <DatePicker
                                                locale={br}
                                                autoComplete="off"
                                                placeholderText="Selecione a Data Inicial"
                                                selected={!!search.dataInicio
                                                    ? parseISO(search.dataInicio)
                                                    : ''
                                                }
                                                onCalendarClose={() => { }}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control digits"
                                                onChange={(selected) => {
                                                    setSearch({
                                                        ...search,
                                                        dataInicio: format(selected, 'yyyy-MM-dd')
                                                    })
                                                }}
                                            />
                                        </Col>

                                        <Col sm="6">
                                            <DatePicker
                                                locale={br}
                                                autoComplete="off"
                                                placeholderText="Selecione a Data Inicial"
                                                selected={!!search.dataFim
                                                    ? parseISO(search.dataFim)
                                                    : ''
                                                }
                                                onCalendarClose={() => { }}
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control digits"
                                                onChange={(selected) => {
                                                    setSearch({
                                                        ...search,
                                                        dataFim: format(selected, 'yyyy-MM-dd')
                                                    })
                                                }}
                                            />
                                        </Col>

                                        <Col sm="12" style={{ marginTop: 10 }}>
                                            <Button
                                                color="primary"
                                                className="float-right"
                                            >
                                                Pesquisar
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </Col>
                </Row>

                <Row>
                    <Col sm="12">
                        <Button
                            color="primary"
                            className="float-right"
                            onClick={handlePrint}
                        >
                            Imprimir
                        </Button>
                    </Col>
                </Row>

                <Print
                    ref={printRef}
                >
                    <div id="toPrint" className="font-arial-rlt">
                        <Row>
                            <Col
                                sm="12"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: 30
                                }}
                            >
                                <img
                                    className="img-fluid for-light"
                                    src={require("../../assets/images/logo/logo.jpg")}
                                    alt=""
                                    style={{
                                        width: '130px',
                                        height: '130px',
                                        objectFit: 'contain',
                                    }}
                                />

                                <h5>{user?.cidade?.name}</h5>
                            </Col>

                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        <h6 className='mb-5'>Projeção prevista de receitas</h6>
                                        <ChartistGraph
                                            className="grafico-previsto-projecao"
                                            data={graficoPrevisaoPeriodoPorFluxoReceitas}
                                            options={{
                                                labelInterpolationFnc: function (value) {
                                                    const indexDoValor = graficoPrevisaoPeriodoPorFluxoReceitas.labels.indexOf(value);
                                                    const valor = graficoPrevisaoPeriodoPorFluxoReceitas.series[indexDoValor];
                                                    const percentual = Math.round(valor / graficoPrevisaoPeriodoPorFluxoReceitasSum * 100);
                                                    const percentualFormatado =
                                                        `${new Intl.NumberFormat('pt-br', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(percentual)}%`;

                                                    return `${percentualFormatado} - ${value}`;
                                                }
                                            }}
                                            type="Pie"
                                            style={{
                                                width: '100%',
                                                height: 300
                                            }}
                                            responsiveOptions={[
                                                ['screen and (min-width: 640px)', {
                                                    chartPadding: 30,
                                                    labelOffset: 100,
                                                    labelDirection: 'explode',
                                                }],
                                                ['screen and (min-width: 1024px)', {
                                                    labelOffset: 80,
                                                    chartPadding: 20
                                                }]
                                            ]}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        <h6 className='mb-5'>Projeção realizada de receitas</h6>
                                        <ChartistGraph
                                            className="grafico-realizado-projecao"
                                            data={graficoRealizadoPeriodoPorFluxoReceitas}
                                            options={{
                                                labelInterpolationFnc: function (value) {
                                                    const indexDoValor = graficoRealizadoPeriodoPorFluxoReceitas.labels.indexOf(value);
                                                    const valor = graficoRealizadoPeriodoPorFluxoReceitas.series[indexDoValor];
                                                    const percentual = Math.round(valor / graficoRealizadoPeriodoPorFluxoReceitasSum * 100);
                                                    const percentualFormatado =
                                                        `${new Intl.NumberFormat('pt-br', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(percentual)}%`;

                                                    return `${percentualFormatado} - ${value}`;
                                                }
                                            }}
                                            type="Pie"
                                            style={{
                                                width: '100%',
                                                height: 300
                                            }}
                                            responsiveOptions={[
                                                ['screen and (min-width: 640px)', {
                                                    chartPadding: 30,
                                                    labelOffset: 100,
                                                    labelDirection: 'explode',
                                                }],
                                                ['screen and (min-width: 1024px)', {
                                                    labelOffset: 80,
                                                    chartPadding: 20
                                                }]
                                            ]}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        <h6 className='mb-5'>Projeção prevista de despesas</h6>
                                        <ChartistGraph
                                            className="grafico-previsto-projecao"
                                            data={graficoPrevisaoPeriodoPorFluxoDespesas}
                                            options={{
                                                labelInterpolationFnc: function (value) {
                                                    const indexDoValor = graficoPrevisaoPeriodoPorFluxoDespesas.labels.indexOf(value);
                                                    const valor = graficoPrevisaoPeriodoPorFluxoDespesas.series[indexDoValor];
                                                    const percentual = Math.round(valor / graficoPrevisaoPeriodoPorFluxoDespesasSum * 100);
                                                    const percentualFormatado =
                                                        `${new Intl.NumberFormat('pt-br', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(percentual)}%`;

                                                    return `${percentualFormatado} - ${value}`;
                                                }
                                            }}
                                            type="Pie"
                                            style={{
                                                width: '100%',
                                                height: 300
                                            }}
                                            responsiveOptions={[
                                                ['screen and (min-width: 640px)', {
                                                    chartPadding: 30,
                                                    labelOffset: 100,
                                                    labelDirection: 'explode',
                                                }],
                                                ['screen and (min-width: 1024px)', {
                                                    labelOffset: 80,
                                                    chartPadding: 20
                                                }]
                                            ]}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="6">
                                <Card>
                                    <CardBody>
                                        <h6 className='mb-5'>Projeção realizada de despesas</h6>
                                        <ChartistGraph
                                            className="grafico-realizado-projecao"
                                            data={graficoRealizadoPeriodoPorFluxoDespesas}
                                            options={{
                                                labelInterpolationFnc: function (value) {
                                                    const indexDoValor = graficoRealizadoPeriodoPorFluxoDespesas.labels.indexOf(value);
                                                    const valor = graficoRealizadoPeriodoPorFluxoDespesas.series[indexDoValor];
                                                    const percentual = Math.round(valor / graficoRealizadoPeriodoPorFluxoDespesasSum * 100);
                                                    const percentualFormatado =
                                                        `${new Intl.NumberFormat('pt-br', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(percentual)}%`;

                                                    return `${percentualFormatado} - ${value}`;
                                                }
                                            }}
                                            type="Pie"
                                            style={{
                                                width: '100%',
                                                height: 300
                                            }}
                                            responsiveOptions={[
                                                ['screen and (min-width: 640px)', {
                                                    chartPadding: 30,
                                                    labelOffset: 100,
                                                    labelDirection: 'explode',
                                                }],
                                                ['screen and (min-width: 1024px)', {
                                                    labelOffset: 80,
                                                    chartPadding: 20
                                                }]
                                            ]}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <h6 className='mb-5'>Divergência entre previsto e realizado nas receitas (Por Fluxo)</h6>
                                        <ChartistGraph
                                            className="grafico-divergencia"
                                            data={graficoDivergenciaReceitas}
                                            options={{
                                                seriesBarDistance: 15,
                                                axisY: {
                                                    offset: 110,
                                                    labelInterpolationFnc: function (value) {
                                                        return moneyConvert(parseFloat(value));
                                                    }
                                                }
                                            }}
                                            type="Bar"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col sm="12">
                                <Card>
                                    <CardBody>
                                        <h6 className='mb-5'>Divergência entre previsto e realizado nas despesas (Por Fluxo)</h6>
                                        <ChartistGraph
                                            className="grafico-divergencia"
                                            data={graficoDivergenciaDespesas}
                                            options={{
                                                seriesBarDistance: 15,
                                                axisY: {
                                                    offset: 110,
                                                    labelInterpolationFnc: function (value) {
                                                        return moneyConvert(parseFloat(value));
                                                    }
                                                }
                                            }}
                                            type="Bar"
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Print>
            </Container>
        </Fragment>
    );
}

const mapStateToProps = ({ Transacoes, Auth }) => {
    const {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes,
        dadosTransacoes,
        notAuthorized,
        previsto
    } = Transacoes;

    const { user } = Auth;
    return {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes,
        dadosTransacoes,
        notAuthorized,
        previsto,
        user
    };
}

export default connect(mapStateToProps, {
    getTransacoes,
    addTransacao,
    editTransacao
})(Transacoes);