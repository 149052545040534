import React, { useState, useCallback, useEffect } from 'react';

import { connect } from 'react-redux';
import { useDropzone } from "react-dropzone";
import { toast } from 'react-toastify';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap';

import { Upload, Check } from 'react-feather';

import { Typeahead } from 'react-bootstrap-typeahead';

import { useCashFlowTypeStore } from "../../utils/cashflowType";
import { useAccountStore } from "../../utils/accounts";

import { addBankAccountFile } from "../../redux/actions";

function UploadFile({
  open = false,
  onDismiss = () => { },
  files,
  loadingFiles,
  addBankAccountFile,
}) {

  const [fields, setFields] = useState({
    account_id: null,
    flow: null,
    file: null
  });

  const { tiposFluxo } = useCashFlowTypeStore();
  const { contas } = useAccountStore();

  const onDrop = useCallback(acceptedFiles => {
    setFields({
      ...fields,
      file: acceptedFiles[0]
    })
  }, [fields]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!fields.account_id && !fields.file) {
      toast.error('Preencha todos os campos para prosseguir', {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      addBankAccountFile(fields);
      onDismiss();
    }
  };

  useEffect(() => {
    if (!open) {
      setFields({
        account_id: null,
        flow: null,
        file: null
      });
    }
  }, [open]);

  return (
    <Modal
      size="lg"
      isOpen={!!open}
      toggle={onDismiss}
      backdrop="static"
    >
      <Form
        onSubmit={handleSubmit}
      >
        <ModalHeader
          toggle={onDismiss}
        >
          Incluir Anexo
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col md="6" xs="12">
              <FormGroup>
                <Label>Fluxo</Label>
                <Typeahead
                  id="fluxo"
                  labelKey="nome"
                  clearButton
                  placeholder="Selecione o fluxo"
                  options={tiposFluxo}
                  onChange={data => {
                    if (data?.length) {
                      setFields({
                        ...fields,
                        flow: data[0].id
                      });
                    } else {
                      setFields({
                        ...fields,
                        flow: null
                      });
                    }
                  }}
                  selected={tiposFluxo.filter(({ id }) => id == fields.flow)}
                  emptyLabel="Nenhum resultado encontrado"
                />
              </FormGroup>
            </Col>

            <Col md="6" xs="12">
              <FormGroup>
                <Label>Conta</Label>
                <Typeahead
                  id="conta"
                  labelKey="nome"
                  clearButton
                  placeholder="Selecione o tipo"
                  options={contas
                    .filter(
                      ({ tipos_fluxos_id }) => tipos_fluxos_id == fields.flow
                    )
                    .map(
                      (c) => ({
                        ...c,
                        nome: `${c.conta} - ${c.banco}`
                      })
                    )
                  }
                  onChange={data => {
                    if (data?.length) {
                      setFields({
                        ...fields,
                        account_id: data[0].id
                      });
                    } else {
                      setFields({
                        ...fields,
                        account_id: null,
                      });
                    }
                  }}
                  emptyLabel="Nenhum resultado encontrado"
                />
              </FormGroup>
            </Col>

            <Col md="12">
              <div
                {...getRootProps()}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                  border: `1px solid ${fields.file ? 'green' : '#ddd'}`,
                  padding: '30px 10px'
                }}
              >
                <input {...getInputProps()} />
                {
                  isDragActive
                    ? <p>Solte o arquivo aqui.</p>
                    : <p>Clique para selecionar o arquivo.</p>
                }

                {fields.file ? <Check color='green' /> : <Upload />}
              </div>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            color="danger"
            onClick={onDismiss}
          >
            Fechar
          </Button>

          <Button
            color="primary"
            disabled={loadingFiles}
          >
            {loadingFiles ? 'Carregando...' : 'Salvar'}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

const mapStateToProps = ({ Auth, BankAccountFiles }) => {
  const { user } = Auth;
  const { files, loadingFiles } = BankAccountFiles;

  return {
    user,
    files,
    loadingFiles
  };
};

export default connect(mapStateToProps, {
  addBankAccountFile
})(UploadFile);
