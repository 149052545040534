import { all, call, put, takeEvery, fork, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
  GET_PREVISOES,
  ADD_PREVISOES,
  EDIT_PREVISOES,
  DELETE_PREVISOES,
} from "../actionTypes";

import { setPrevisoes, getPrevisoes } from "../actions";

import api from "../../services/api";

function* sagaGetPrevisoes({ payload }) {
  try {
    const data = yield call(apiGetPrevisoes, payload);

    yield put(
      setPrevisoes({
        previsoes: data,
        lastSearchMes: payload.mes,
        lastSearchAno: payload.ano,
      })
    );
  } catch (err) {
    yield put(setPrevisoes());
  }
}

const apiGetPrevisoes = async (params) => {
  const { data } = await api.get("/api/auth/previsoes", { params });

  return data;
};

function* sagaAddPrevisoes({ payload }) {
  try {
    const data = yield call(apiAddPrevisoes, payload);

    toast.success(data.msg, {
      position: toast.POSITION.TOP_RIGHT,
    });

    const { lastSearchMes, lastSearchAno } = yield select(
      ({ Previsoes }) => Previsoes
    );

    yield put(getPrevisoes({ mes: lastSearchMes, ano: lastSearchAno }));
  } catch (err) {
    toast.error("Não foi possível incluir o registro.", {
      position: toast.POSITION.TOP_RIGHT,
    });

    yield put(setPrevisoes());
  }
}

const apiAddPrevisoes = async (payload) => {
  const { data } = await api.post("/api/auth/foreseen/addMany", payload);

  return data;
};

function* sagaEditPrevisao({ payload }) {
  try {
    const data = yield call(apiEditPrevisao, payload);

    if (data.ok) {
      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });

      const { lastSearchMes, lastSearchAno } = yield select(
        ({ Previsoes }) => Previsoes
      );

      yield put(getPrevisoes({ mes: lastSearchMes, ano: lastSearchAno }));
    } else {
      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });

      yield put(setPrevisoes());
    }
  } catch (err) {
    yield put(setPrevisoes());

    toast.error("Não foi possível alterar o registro.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiEditPrevisao = async (payload) => {
  const { data } = await api.put(
    "/api/auth/previsoes/edit/" + payload.id,
    payload
  );

  return data;
};

function* sagaDeletePrevisao({ payload }) {
  try {
    const data = yield call(apiDeletePrevisao, payload);

    if (data.ok) {
      toast.success(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });

      const { lastSearchMes, lastSearchAno } = yield select(
        ({ Previsoes }) => Previsoes
      );

      yield put(getPrevisoes({ mes: lastSearchMes, ano: lastSearchAno }));
    } else {
      toast.error(data.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });

      yield put(setPrevisoes());
    }
  } catch (err) {
    yield put(setPrevisoes());

    toast.error("Não foi possível excluir o registro.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiDeletePrevisao = async (payload) => {
  const { data } = await api.delete("/api/auth/previsoes/delete/" + payload.id);

  return data;
};

function* watchGetPrevisoes() {
  yield takeEvery(GET_PREVISOES, sagaGetPrevisoes);
}

function* watchAddPrevisoes() {
  yield takeEvery(ADD_PREVISOES, sagaAddPrevisoes);
}

function* watchEditPrevisao() {
  yield takeEvery(EDIT_PREVISOES, sagaEditPrevisao);
}

function* watchDeletePrevisao() {
  yield takeEvery(DELETE_PREVISOES, sagaDeletePrevisao);
}

export default function* rootSaga() {
  yield all([
    fork(watchAddPrevisoes),
    fork(watchGetPrevisoes),
    fork(watchEditPrevisao),
    fork(watchDeletePrevisao),
  ]);
}
