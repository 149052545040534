import {
    GET_GROUPS_ASYNC,
    GET_GROUPS,
    LIST_GROUPS_ASYNC,
    LIST_GROUPS,
    STORE_GROUPS_ASYNC,
    STORE_GROUPS,
    UPDATE_GROUPS_ASYNC,
    UPDATE_GROUPS,
    DESTROY_GROUPS_ASYNC,
    DESTROY_GROUPS
} from "../actionTypes";


const initial_state = {
    loading: false,
    loadingList: false,
    loadingStoreGroups: false,
    loadingUpdateGroups: false,
    loadingDelGroups: false,
    list: [],
    groups: []
};

export default (state = initial_state, action) => {
    switch (action.type) {
        case GET_GROUPS_ASYNC:
            return { ...state, loading: true };
        case GET_GROUPS:
            return { ...state, ...action.payload, loading: false };
        case LIST_GROUPS_ASYNC:
            return { ...state, loadingList: true };
        case LIST_GROUPS:
            return { ...state, ...action.payload, loadingList: false };
        case STORE_GROUPS_ASYNC:
            return { ...state, loadingStoreGroups: true };
        case STORE_GROUPS:
            return { ...state, ...action.payload, loadingStoreGroups: false };
        case UPDATE_GROUPS_ASYNC:
            return { ...state, loadingUpdateGroups: true };
        case UPDATE_GROUPS:
            return { ...state, ...action.payload, loadingUpdateGroups: false };
        case DESTROY_GROUPS_ASYNC:
            return { ...state, loadingDelGroups: true };
        case DESTROY_GROUPS:
            return { ...state, ...action.payload, loadingDelGroups: false };
        default: return state;
    }
}
