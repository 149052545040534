import {
  GET_GRUPOS_CONTA,
  SET_GRUPOS_CONTA,
  ADD_GRUPOS_CONTA,
  GET_CONTAS,
  SET_CONTAS,
  ADD_CONTA,
  EDIT_GRUPOS_CONTA,
  DELETE_GRUPOS_CONTA,
  EDIT_CONTA,
  TRANSFERIR,
  GET_REPASSES,
  SET_REPASSES,
  EDIT_REPASSE,
  REMOVE_REPASSE,
  VINCULAR_GRUPOS_CONTA,
  DESVINCULAR_GRUPOS_CONTA,
} from "../actionTypes";

export const desvincularGruposConta = (payload) => ({
  type: DESVINCULAR_GRUPOS_CONTA,
  payload,
});

export const vincularGruposConta = (payload) => ({
  type: VINCULAR_GRUPOS_CONTA,
  payload,
});

export const editRepasse = (payload) => ({
  type: EDIT_REPASSE,
  payload,
});

export const removeRepasse = (payload) => ({
  type: REMOVE_REPASSE,
  payload,
});

export const transferir = (payload) => ({
  type: TRANSFERIR,
  payload,
});

export const getRepasses = (payload) => ({
  type: GET_REPASSES,
  payload,
});

export const setRepasses = (payload) => ({
  type: SET_REPASSES,
  payload,
});

export const getContas = () => ({
  type: GET_CONTAS,
});

export const setContas = (payload) => ({
  type: SET_CONTAS,
  payload,
});

export const editGruposConta = (payload) => ({
  type: EDIT_GRUPOS_CONTA,
  payload,
});

export const deleteGruposConta = (payload) => ({
  type: DELETE_GRUPOS_CONTA,
  payload,
});

export const addConta = (payload) => ({
  type: ADD_CONTA,
  payload,
});

export const editConta = (payload) => ({
  type: EDIT_CONTA,
  payload,
});

export const getGruposConta = () => ({
  type: GET_GRUPOS_CONTA,
});

export const setGruposConta = (payload) => ({
  type: SET_GRUPOS_CONTA,
  payload,
});

export const addGruposConta = (payload) => ({
  type: ADD_GRUPOS_CONTA,
  payload,
});
