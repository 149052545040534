import React from "react";
import { ThumbsDown } from "react-feather";

function NotAuthorized() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 300,
        width: "100%",
      }}
    >
      <ThumbsDown color="red" />

      <h4 style={{ marginTop: 10 }}>Usuário não autorizado</h4>
    </div>
  );
}

export default NotAuthorized;
