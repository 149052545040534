import { all } from "redux-saga/effects";
import authSagas from "./auth/saga";
import funcionalidadesSagas from "./funcionalidades/saga";
import modulosSagas from "./modulos/saga";
import groupsSagas from "./groups/saga";
import permissionsSagas from "./permissions/saga";
import usersSaga from "./users/saga";
import transacoesSaga from "./transacoes/saga";
import previsoesSaga from "./previsoes/saga";
import gruposSaga from "./consolidado/saga";
import contasSaga from "./contas/saga";
import fornecedoresSagas from "./fornecedores/saga";
import extratoSaga from "./extrato/saga";
import mensagensSaga from "./mensagens/saga";
import conciliacaoSaga from "./conciliacao/saga";
import transacoesFuturasSaga from "./transacoesFuturas/saga";
import reportOneSaga from "./reportOne/saga";
import updateReportSaga from "./updateReport/saga";
import generalBankStatementSaga from "./generalBankStatement/saga";
import bankAccountFilesSaga from "./bankAccountFiles/saga";
import bankBalanceSaga from "./bankBalance/saga";
import bankStatementSheetSaga from "./bankStatementSheet/saga";
import consolidatedTabledSheetSaga from "./consolidatedTabled/saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    funcionalidadesSagas(),
    modulosSagas(),
    groupsSagas(),
    permissionsSagas(),
    usersSaga(),
    transacoesSaga(),
    previsoesSaga(),
    gruposSaga(),
    contasSaga(),
    fornecedoresSagas(),
    extratoSaga(),
    mensagensSaga(),
    conciliacaoSaga(),
    transacoesFuturasSaga(),
    reportOneSaga(),
    updateReportSaga(),
    generalBankStatementSaga(),
    bankAccountFilesSaga(),
    bankBalanceSaga(),
    bankStatementSheetSaga(),
    consolidatedTabledSheetSaga(),
  ]);
}
