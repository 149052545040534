import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import sweetalert2 from "sweetalert2";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    CardBody,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Label,
    Button,
    Input,
    CardHeader,
    CardTitle
} from 'reactstrap';

import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from 'date-fns';
import { ThumbsDown, Edit, List, Delete } from 'react-feather';

import Breadcrumb from '../../layout/breadcrumb';

import {
    getPrevisoes,
    addPrevisoes,
    editPrevisao,
    deletePrevisao
} from '../../redux/actions';

import "react-datepicker/dist/react-datepicker.css";
import { es } from 'date-fns/esm/locale';

const Previsoes = ({
    tiposFluxo,
    tiposTransacoes,
    blocos,
    eventos,
    previsoes,
    loadingPrevisoes,
    getPrevisoes,
    addPrevisoes,
    user,
    editPrevisao,
    deletePrevisao,
    grupos
}) => {

    const [add, setAdd] = useState(false);
    const [edit, setEdit] = useState(null);

    const [search, setSearch] = useState({
        mes: format(new Date, 'MM'),
        ano: format(new Date, 'yyyy')
    });

    const [fields, setFields] = useState({
        tipos_transacoes_id: '',
        transacoes_eventos_id: '',
        tipos_fluxos_id: '',
        transacoes_blocos_id: null,
        valor: 0,
        data: format(new Date, 'yyyy-MM-dd'),
        transacoes_grupo_id: null
    });

    useEffect(() => {
        getPrevisoes({
            mes: format(new Date, 'MM'),
            ano: format(new Date, 'yyyy')
        });
    }, []);

    useEffect(() => {
        if (!add) {
            setFields({
                tipos_transacoes_id: '',
                transacoes_eventos_id: '',
                tipos_fluxos_id: '',
                transacoes_blocos_id: null,
                valor: 0,
                mes: format(new Date, 'MM'),
                ano: format(new Date, 'yyyy'),
                transacoes_grupo_id: null
            });
        }
    }, [add])

    const handleChange = e => {
        const { name, value } = e.target;
        setFields({ ...fields, [name]: value });
    }

    const handleSubmit = e => {
        e.preventDefault();

        const { data, tipos_transacoes_id, transacoes_blocos_id, transacoes_eventos_id, valor, tipos_fluxos_id } = fields;

        if (tipos_fluxos_id == 2) {
            const conditions =
                tipos_transacoes_id == 1
                    ? !!data && !!tipos_transacoes_id && !!transacoes_blocos_id && !!transacoes_eventos_id && !!valor && tipos_fluxos_id
                    : !!data && !!tipos_transacoes_id && !!transacoes_eventos_id && !!valor && tipos_fluxos_id;


            if (conditions) {
                addPrevisoes(fields);

                setAdd(false);

                setFields({
                    tipos_transacoes_id: '',
                    transacoes_eventos_id: '',
                    tipos_fluxos_id: '',
                    transacoes_blocos_id: null,
                    valor: 0,
                    mes: format(new Date, 'MM'),
                    ano: format(new Date, 'yyyy'),
                    transacoes_grupo_id: null
                });
            } else {
                toast.error('Preencha todos os campos para prosseguir', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        } else {
            if (!!data && !!tipos_transacoes_id && !!transacoes_eventos_id && !!valor && !!tipos_fluxos_id) {
                addPrevisoes(fields);

                setAdd(false);

                setFields({
                    tipos_transacoes_id: '',
                    transacoes_eventos_id: '',
                    tipos_fluxos_id: '',
                    transacoes_blocos_id: null,
                    valor: 0,
                    mes: format(new Date, 'MM'),
                    ano: format(new Date, 'yyyy'),
                    transacoes_grupo_id: null
                });
            } else {
                toast.error('Preencha todos os campos para prosseguir', {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    if (loadingPrevisoes) return (
        <Fragment>
            <Breadcrumb parent="Previstos" title="Receitas/Despesas" />
            <Container fluid={true}></Container>
            <div className="loader-box">
                <div className="loader-1"></div>
            </div>
        </Fragment>
    );

    const filtroEventos = eventos.filter(
        e => {
            if (fields.tipos_fluxos_id != 2) {
                return e.tipos_transacoes_id == fields.tipos_transacoes_id
                    && e.transacoes_grupo_id == fields.transacoes_grupo_id
                    && e.tipos_fluxos_id == fields.tipos_fluxos_id;
            } else {
                if (fields.tipos_transacoes_id == 1)
                    return e.tipos_transacoes_id == fields.tipos_transacoes_id
                        && e.transacoes_grupo_id == fields.transacoes_grupo_id
                        && e.tipos_fluxos_id == fields.tipos_fluxos_id;
                else
                    return e.tipos_transacoes_id == fields.tipos_transacoes_id
                        && e.transacoes_grupo_id == fields.transacoes_grupo_id
                        && e.tipos_fluxos_id == fields.tipos_fluxos_id;
            }
        }
    );

    const optionsGrupos = grupos
        .filter(
            ({ tipos_fluxos_id, tipos_transacoes_id }) =>
                tipos_fluxos_id == fields.tipos_fluxos_id
                && tipos_transacoes_id == fields.tipos_transacoes_id
        )

    const optionsMes = [
        { id: 1, nome: 'Janeiro' },
        { id: 2, nome: 'Fevereiro' },
        { id: 3, nome: 'Março' },
        { id: 4, nome: 'Abril' },
        { id: 5, nome: 'Maio' },
        { id: 6, nome: 'Junho' },
        { id: 7, nome: 'Julho' },
        { id: 8, nome: 'Agosto' },
        { id: 9, nome: 'Setembro' },
        { id: 10, nome: 'Outubro' },
        { id: 11, nome: 'Novembro' },
        { id: 12, nome: 'Dezembro' },
    ];

    return (
        <Fragment>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Breadcrumb parent="Previstos" title="Receitas/Despesas" />

                <Button
                    color="primary"
                    onClick={() => {
                        setAdd(true);
                    }}
                >
                    Incluir
                </Button>
            </div>

            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();

                                getPrevisoes(search);
                            }}
                        >
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col sm="5">
                                            <Typeahead
                                                id="mes"
                                                labelKey="nome"
                                                clearButton
                                                placeholder="Selecione o mês"
                                                options={optionsMes}
                                                onChange={data => {
                                                    if (data?.length) {
                                                        setSearch({
                                                            ...search,
                                                            mes: data[0].id
                                                        });
                                                    }
                                                }}
                                                defaultSelected={optionsMes.filter(o => o.id == search.mes)}
                                                emptyLabel="Nenhum resultado encontrado"
                                            />
                                        </Col>

                                        <Col sm="5">
                                            <Input
                                                type="number"
                                                value={search.ano}
                                                onChange={({ target: { value } }) => {
                                                    setSearch({
                                                        ...search,
                                                        ano: value
                                                    });
                                                }}
                                                placeholder="Informe o ano"
                                            />
                                        </Col>

                                        <Col sm="2">
                                            <Button
                                                color="primary"
                                            >
                                                Pesquisar
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </Col>
                </Row>

                <Row>
                    {tiposFluxo.map((fluxo, index) => {
                        return (
                            <Col sm="12" key={fluxo.id}>
                                <h5 style={{ textAlign: 'center', marginBottom: 10, marginTop: 10 }}>{fluxo.nome}</h5>

                                {tiposTransacoes.map((t, key) => {
                                    const registros = previsoes.filter(
                                        p => p.tipos_transacoes_id == t.id && p.tipos_fluxos_id == fluxo.id
                                    );

                                    return (
                                        <Card key={key}>
                                            <CardBody>
                                                <h5>{t.nome}</h5>

                                                {!!registros.length
                                                    ? (
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Recurso</th>
                                                                    <th style={{ width: '15%', textAlign: 'center' }}>Data Prevista</th>
                                                                    <th style={{ width: '15%', textAlign: 'center' }}>Valor Previsto</th>
                                                                    <th style={{ textAlign: 'center', width: '15%' }}>Ações</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {registros.map(
                                                                    (p, key) => (
                                                                        <tr key={key}>
                                                                            <td>{p.evento?.nome}{fluxo.id == 2 ? `- ${p.bloco?.nome}` : ''}</td>
                                                                            <td style={{ textAlign: 'center' }}>{format(parseISO(p.data), 'dd/MM/yyyy')}</td>
                                                                            <td style={{ textAlign: 'center' }}>{new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(p.valor)}</td>
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <span
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        marginLeft: 5
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setEdit(p);
                                                                                    }}
                                                                                >
                                                                                    <Edit
                                                                                        size={18}
                                                                                    />
                                                                                </span>

                                                                                <span
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        marginLeft: 5
                                                                                    }}
                                                                                    onClick={async () => {
                                                                                        const confirm = await sweetalert2.fire({
                                                                                            title: 'Deseja realmente excluir?',
                                                                                            icon: 'question',
                                                                                            showCancelButton: true,
                                                                                            confirmButtonText: 'Sim',
                                                                                            cancelButtonText: 'Não',
                                                                                            reverseButtons: true
                                                                                        });
                                                                                        if (confirm.value) {
                                                                                            deletePrevisao(p);
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <Delete
                                                                                        size={18}
                                                                                    />
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                            </tbody>
                                                        </Table>
                                                    )
                                                    : (
                                                        <p style={{ textAlign: 'center', marginTop: 15 }}>Sem registros previstos para o período selecionado</p>
                                                    )
                                                }

                                            </CardBody>
                                        </Card>
                                    )
                                })}
                            </Col>
                        )
                    })}
                </Row>
            </Container>

            {!!edit && (
                <Modal
                    size="lg"
                    isOpen
                    toggle={() => {
                        setEdit(null);
                    }}
                    backdrop="static"
                >
                    <Form
                        onSubmit={e => {
                            e.preventDefault();

                            if (!!edit.valor && !!edit.data) {
                                editPrevisao(edit);

                                setEdit(null);
                            }
                        }}
                    >
                        <ModalHeader
                            toggle={() => {
                                setEdit(null);
                            }}
                        >
                            Editar Receita/Despesa Prevista
                        </ModalHeader>

                        <ModalBody>
                            <Row>
                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Valor Previsto</Label>
                                        <NumberFormat
                                            placeholder="Informe o valor"
                                            thousandSeparator='.'
                                            customInput={Input}
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale
                                            onValueChange={({ floatValue }) => {
                                                setEdit({
                                                    ...edit,
                                                    valor: floatValue || 0
                                                });
                                            }}
                                            value={parseFloat(edit?.valor)}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Data Prevista</Label>
                                        <DatePicker
                                            locale={br}
                                            autoComplete="off"
                                            placeholderText="Selecione a Data"
                                            // minDate={new Date(1930, 0, 1)}
                                            // maxDate={new Date()}
                                            selected={!!edit?.data ? parseISO(edit.data) : null}
                                            onCalendarClose={() => { }}
                                            dateFormat="dd/MM/yyyy"
                                            className="form-control digits"
                                            onChange={(selected) => {
                                                setEdit({
                                                    ...edit,
                                                    data: format(selected, 'yyyy-MM-dd')
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </ModalBody>

                        <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button
                                color="danger"
                                onClick={() => {
                                    setEdit(null);
                                }}
                            >
                                Fechar
                        </Button>

                            <Button
                                color="primary"
                                disabled={loadingPrevisoes}
                            >
                                {loadingPrevisoes ? 'Carregando...' : 'Salvar'}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            )}

            <Modal
                size="lg"
                isOpen={add}
                toggle={() => {
                    setAdd(false);
                }}
                backdrop="static"
            >
                <Form
                    onSubmit={handleSubmit}
                >
                    <ModalHeader
                        toggle={() => {
                            setAdd(false);
                        }}
                    >
                        Incluir Receita/Despesa Prevista
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Fluxo</Label>
                                    <Typeahead
                                        id="fluxo"
                                        labelKey="nome"
                                        clearButton
                                        placeholder="Selecione o fluxo"
                                        options={
                                            user?.groups[0]?.id == 4
                                                ? tiposFluxo.filter(t =>
                                                    user?.fluxos.map(f => f.id).includes(t.id)
                                                )
                                                : tiposFluxo
                                        }
                                        onChange={data => {
                                            if (data?.length) {
                                                setFields({
                                                    ...fields,
                                                    tipos_fluxos_id: data[0].id
                                                });
                                            } else {
                                                setFields({
                                                    ...fields,
                                                    tipos_fluxos_id: null
                                                });
                                            }
                                        }}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Tipo de Transação</Label>
                                    <Typeahead
                                        id="transacao"
                                        labelKey="nome"
                                        clearButton
                                        placeholder="Selecione o tipo"
                                        options={tiposTransacoes}
                                        onChange={data => {
                                            if (data?.length) {
                                                setFields({
                                                    ...fields,
                                                    tipos_transacoes_id: data[0].id
                                                });
                                            } else {
                                                setFields({
                                                    ...fields,
                                                    tipos_transacoes_id: null
                                                });
                                            }
                                        }}
                                        emptyLabel="Nenhum resultado encontrado"
                                    />
                                </FormGroup>
                            </Col>

                            {fields.tipos_fluxos_id == 2 && fields.tipos_transacoes_id == 1 && (
                                <Col md="6" xs="12">
                                    <FormGroup>
                                        <Label>Bloco</Label>
                                        <Typeahead
                                            id="bloco"
                                            labelKey="nome"
                                            clearButton
                                            placeholder="Selecione o bloco"
                                            options={blocos}
                                            onChange={data => {
                                                if (data?.length) {
                                                    setFields({
                                                        ...fields,
                                                        transacoes_blocos_id: data[0].id
                                                    });
                                                } else {
                                                    setFields({
                                                        ...fields,
                                                        transacoes_blocos_id: null
                                                    });
                                                }
                                            }}
                                            emptyLabel="Nenhum resultado encontrado"
                                        />
                                    </FormGroup>
                                </Col>
                            )}

                            {!!fields.tipos_fluxos_id && !!fields.tipos_transacoes_id && (
                                <Col md="12" xs="12">
                                    <FormGroup>
                                        <Label>Grupo</Label>
                                        <Typeahead
                                            id="tipo"
                                            labelKey="nome"
                                            clearButton
                                            placeholder="Selecione o tipo"
                                            options={optionsGrupos}
                                            onChange={data => {
                                                if (data?.length) {
                                                    setFields({
                                                        ...fields,
                                                        transacoes_grupo_id: data[0].id,
                                                        transacoes_eventos_id: null
                                                    });
                                                } else {
                                                    setFields({
                                                        ...fields,
                                                        transacoes_grupo_id: null,
                                                        transacoes_eventos_id: null
                                                    });
                                                }
                                            }}
                                            emptyLabel="Nenhum resultado encontrado"
                                        />
                                    </FormGroup>
                                </Col>
                            )}

                            {!!fields.tipos_fluxos_id && !!fields.tipos_transacoes_id && !!fields.transacoes_grupo_id && (
                                <Col md="12" xs="12">
                                    <FormGroup>
                                        <Label>Subgrupo</Label>
                                        <Typeahead
                                            id="tipo"
                                            labelKey="nome"
                                            clearButton
                                            placeholder="Selecione o tipo"
                                            options={filtroEventos}
                                            onChange={data => {
                                                if (data?.length) {
                                                    setFields({
                                                        ...fields,
                                                        transacoes_eventos_id: data[0].id
                                                    });
                                                } else {
                                                    setFields({
                                                        ...fields,
                                                        transacoes_eventos_id: null
                                                    });
                                                }
                                            }}
                                            emptyLabel="Nenhum resultado encontrado"
                                        />
                                    </FormGroup>
                                </Col>
                            )}

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Valor Previsto</Label>
                                    <NumberFormat
                                        placeholder="Informe o valor"
                                        thousandSeparator='.'
                                        customInput={Input}
                                        decimalSeparator=","
                                        decimalScale={2}
                                        fixedDecimalScale
                                        onValueChange={({ floatValue }) => {
                                            setFields({
                                                ...fields,
                                                valor: floatValue || 0
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>

                            <Col md="6" xs="12">
                                <FormGroup>
                                    <Label>Data Prevista</Label>
                                    <DatePicker
                                        locale={br}
                                        autoComplete="off"
                                        placeholderText="Selecione a Data"
                                        // minDate={new Date(1930, 0, 1)}
                                        // maxDate={new Date()}
                                        selected={!!fields?.data ? parseISO(fields.data) : null}
                                        onCalendarClose={() => { }}
                                        dateFormat="dd/MM/yyyy"
                                        className="form-control digits"
                                        onChange={(selected) => {
                                            setFields({
                                                ...fields,
                                                data: format(selected, 'yyyy-MM-dd')
                                            });
                                        }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            color="danger"
                            onClick={() => {
                                setAdd(false);
                            }}
                        >
                            Fechar
                        </Button>

                        <Button
                            color="primary"
                            disabled={loadingPrevisoes}
                        >
                            {loadingPrevisoes ? 'Carregando...' : 'Salvar'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    );
}

const mapStateToProps = ({ Transacoes, Previsoes, Auth, Consolidado }) => {
    const {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
    } = Transacoes;

    const { user } = Auth;
    const { previsoes, loadingPrevisoes } = Previsoes;
    const { grupos } = Consolidado;

    return {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        previsoes,
        loadingPrevisoes,
        user,
        grupos
    };
}

export default connect(mapStateToProps, {
    getPrevisoes,
    addPrevisoes,
    editPrevisao,
    deletePrevisao
})(Previsoes);