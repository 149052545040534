import {
    GET_TIPOS_FLUXO,
    SET_TIPOS_FLUXO,
    GET_TIPOS_TRANSACOES,
    SET_TIPOS_TRANSACOES,
    GET_BLOCOS,
    SET_BLOCOS,
    GET_EVENTOS,
    SET_EVENTOS,
    ADD_EVENTO,
    SET_ADD_EVENTO,
    ADD_BLOCO,
    SET_ADD_BLOCO,
    GET_TRANSACOES,
    SET_TRANSACOES,
    ADD_TRANSACAO,
    NOT_AUTHORIZED,
    EDIT_TRANSACAO,
    DELETE_TRANSACAO,
    EDIT_EVENTO,
    DELETE_EVENTO,
    EDIT_BLOCO,
    DELETE_BLOCO
} from '../actionTypes';

export const notAuthorized = () => ({
    type: NOT_AUTHORIZED
});

export const getTiposFluxo = () => ({
    type: GET_TIPOS_FLUXO
});

export const setTiposFluxo = payload => ({
    type: SET_TIPOS_FLUXO,
    payload
});

export const getTiposTransacoes = () => ({
    type: GET_TIPOS_TRANSACOES
});

export const setTiposTransacoes = payload => ({
    type: SET_TIPOS_TRANSACOES,
    payload
});

export const getBlocos = () => ({
    type: GET_BLOCOS
});

export const setBlocos = payload => ({
    type: SET_BLOCOS,
    payload
});

export const getEventos = () => ({
    type: GET_EVENTOS
});

export const setEventos = payload => ({
    type: SET_EVENTOS,
    payload
});

export const addEvento = payload => ({
    type: ADD_EVENTO,
    payload
});

export const setAddEvento = payload => ({
    type: SET_ADD_EVENTO,
    payload
});

export const addBloco = payload => ({
    type: ADD_BLOCO,
    payload
});

export const setAddBloco = payload => ({
    type: SET_ADD_BLOCO,
    payload
});

export const getTransacoes = payload => ({
    type: GET_TRANSACOES,
    payload
});

export const setTransacoes = payload => ({
    type: SET_TRANSACOES,
    payload
});

export const addTransacao = (payload, callback) => ({
    type: ADD_TRANSACAO,
    payload,
    callback
});

export const editTransacao = (payload, callback) => ({
    type: EDIT_TRANSACAO,
    payload,
    callback
});

export const deleteTransacao = (payload, callback) => ({
    type: DELETE_TRANSACAO,
    payload,
    callback
});

export const editEvento = payload => ({
    type: EDIT_EVENTO,
    payload
});

export const deleteEvento = payload => ({
    type: DELETE_EVENTO,
    payload
});

export const editBloco = payload => ({
    type: EDIT_BLOCO,
    payload
});

export const deleteBloco = payload => ({
    type: DELETE_BLOCO,
    payload
});