import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { GET_GENERAL_BANK_STATEMENT } from "../actionTypes";
import { setGeneralBankStatement } from "./actions";

import api from "../../services/api";

function* sagaGetGeneralBankStatement({ payload }) {
  try {
    const data = yield call(apiGetGeneralBankStatement, payload);

    yield put(setGeneralBankStatement(data));
  } catch (error) {
    yield put(setGeneralBankStatement());
  }
}

const apiGetGeneralBankStatement = async (params) => {
  const { data } = await api.get("/api/auth/generalBankStatement", { params });

  return data;
};

function* watchGetGeneralBankStatement() {
  yield takeEvery(GET_GENERAL_BANK_STATEMENT, sagaGetGeneralBankStatement);
}

export default function* rootSaga() {
  yield all([fork(watchGetGeneralBankStatement)]);
}
