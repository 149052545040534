import { useSelector } from "react-redux";

export function useGruposStore() {
  const { loadingGrupos, grupos } = useSelector((state) => state.Consolidado);

  return {
    loadingGrupos,
    grupos,
  };
}
