import { format } from "date-fns";
import { GET_BANK_BALANCE, SET_BANK_BALANCE } from "../actionTypes";

const INITIAL_STATE = {
  balance: [],
  date: format(new Date(), "yyyy-MM-dd"),
  loadingBalance: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_BANK_BALANCE:
      return { ...state, loadingBalance: true };

    case SET_BANK_BALANCE:
      return {
        ...state,
        ...action.payload,
        loadingBalance: false,
      };

    default:
      return state;
  }
};
