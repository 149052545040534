import React, { Fragment, useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";

import { useDropzone } from "react-dropzone";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";

import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";

import { Upload, Check, AlertTriangle } from "react-feather";

import { compareAsc, format, parseISO } from "date-fns";

import NumberFormat from 'react-number-format';
import br from "date-fns/locale/pt-BR";
import DatePicker from "react-datepicker";

import Breadcrumb from "../../layout/breadcrumb";

import { useAccountStore } from "../../utils/accounts";
import { useCashFlowTypeStore } from "../../utils/cashflowType";
import { moneyConvert } from "../../utils/moneyConvert";

import { getConciliacao } from "../../redux/actions";

const Conciliar = ({
  requested_value,
  requested_date,
  final_value,
  conta,
  loadingConciliar,
  getConciliacao,
}) => {
  const { contas } = useAccountStore();
  const { tiposFluxo } = useCashFlowTypeStore();

  const [form, setForm] = useState({
    fluxo: null,
    conta_id: null,
    requested_date: format(new Date(), 'yyyy-MM-dd'),
    requested_value: 0
  });

  const onSubmit = (e) => {
    e.preventDefault();

    const currentAccount = contas.find(
      ({ id }) => id === form.conta_id
    );

    const minimunDateToSearch = !!currentAccount
      ? parseISO(currentAccount.data_inicial)
      : new Date();

    const fields = ['conta_id', 'requested_date', 'requested_value'];
    let fieldsNotProvided = 0;

    for (let i in fields) {
      if (!fields[i]) {
        fieldsNotProvided++;
      }
    }

    if (fieldsNotProvided) {
      toast.error("Preencha todos os campos para prosseguir", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const dateCompare = compareAsc(parseISO(form.requested_date), minimunDateToSearch);

      if (dateCompare >= 0) {
        const data = { ...form };

        getConciliacao(data);
      } else {
        toast.error("A data selecionada é anterior a data inicial da conta " + currentAccount.conta, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Contas Bancárias" title="Conciliar" />
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col md="6" xs="12">
                      <Typeahead
                        id="fluxo"
                        labelKey="nome"
                        clearButton
                        placeholder="Selecione o fluxo"
                        options={tiposFluxo}
                        onChange={data => {
                          if (data?.length) {
                            setForm({
                              ...form,
                              fluxo: data[0].id
                            });
                          } else {
                            setForm({
                              ...form,
                              fluxo: null
                            });
                          }
                        }}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col md="6" xs="12">
                      <Typeahead
                        id="conta"
                        labelKey="conta"
                        clearButton
                        placeholder="Selecione a conta"
                        options={contas.filter(({ tipos_fluxos_id }) => tipos_fluxos_id === form.fluxo)
                          .map(({ conta, ...c }) => ({
                            ...c,
                            conta: String(conta),
                          }))}
                        onChange={(data) => {
                          if (data?.length) {
                            setForm({
                              ...form,
                              conta_id: data[0]?.id,
                            });
                          } else {
                            setForm({
                              ...form,
                              conta_id: "",
                            });
                          }
                        }}
                        selected={contas
                          .map(({ conta, ...c }) => ({
                            ...c,
                            conta: String(conta),
                          }))
                          .filter(({ id }) => id == form.conta_id)}
                        emptyLabel="Nenhum resultado encontrado"
                      />
                    </Col>

                    <Col md="6" xs="12" className="mt-3">
                      <DatePicker
                        locale={br}
                        autoComplete="off"
                        placeholderText="Selecione a Data"
                        selected={!!form?.requested_date ? parseISO(form.requested_date) : null}
                        onCalendarClose={() => { }}
                        dateFormat="dd/MM/yyyy"
                        className="form-control digits"
                        onChange={(selected) => {
                          setForm({
                            ...form,
                            requested_date: format(selected, 'yyyy-MM-dd')
                          });
                        }}
                      />
                    </Col>

                    <Col md="6" xs="12" className="mt-3">
                      <NumberFormat
                        placeholder="Informe o valor"
                        thousandSeparator='.'
                        customInput={Input}
                        decimalSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        value={!!form?.requested_value ? parseFloat(form.requested_value) : 0}
                        onValueChange={({ floatValue }) => {
                          setForm({
                            ...form,
                            requested_value: floatValue || 0,
                          });
                        }}
                      />
                    </Col>

                    <Col sm="12" className="mt-3">
                      <Button color="primary" className="float-right">
                        Pesquisar
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>

            {loadingConciliar
              ? (
                <div className="loader-box">
                  <div className="loader-1"></div>
                </div>
              )
              : (
                <Card>
                  <CardBody>
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Conta</th>
                          <th className="text-center">Data</th>
                          <th className="text-center">Saldo Pesquisado</th>
                          <th className="text-center">Saldo no Sistema</th>
                        </tr>
                      </thead>

                      <tbody>
                        {!!conta
                          ? (
                            <tr>
                              <td>{conta.conta}</td>
                              <td className="text-center">{format(parseISO(requested_date), 'dd/MM/yyyy')}</td>
                              <td className="text-center">{moneyConvert(parseFloat(requested_value))}</td>
                              <td className="text-center">{moneyConvert(parseFloat(final_value))}</td>
                            </tr>
                          )
                          : (
                            <tr>
                              <td colSpan={5} className="text-center">Não existem registros a exibir</td>
                            </tr>
                          )
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              )
            }
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

const mapStateToProps = ({ Conciliacao, Contas, Transacoes }) => {
  const { loadingContas, contas } = Contas;
  const { tiposFluxo } = Transacoes;

  const {
    requested_value,
    requested_date,
    final_value,
    loadingConciliar,
    conta
  } = Conciliacao;

  return {
    requested_value,
    requested_date,
    final_value,
    loadingConciliar,
    conta,
    loadingContas,
    contas,
    tiposFluxo,
  };
};

export default connect(mapStateToProps, {
  getConciliacao,
})(Conciliar);
