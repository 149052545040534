import { useSelector } from "react-redux";

import { useAuthStore } from "./user";
import { getTiposFluxo } from "../redux/actions";

export function getCashFlowType(dispatch = () => {}) {
  dispatch(getTiposFluxo());
}

export function useCashFlowTypeStore() {
  const { user } = useAuthStore();

  const { tiposFluxo } = useSelector((state) => state.Transacoes);

  const tiposFluxoFiltered = tiposFluxo.filter((t) =>
    user?.fluxos.map((f) => f.id).includes(t.id)
  );

  return {
    tiposFluxo: user?.groups[0]?.id === 4 ? tiposFluxoFiltered : tiposFluxo,
  };
}
