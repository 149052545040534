import { call, put, takeLatest, fork, all } from 'redux-saga/effects'
import Api from "../../services/api";
import {
    GET_MODULOS_ASYNC,
    GET_MODULOS,
    LIST_MODULOS_ASYNC,
    LIST_MODULOS,
    STORE_MODULOS_ASYNC,
    STORE_MODULOS,
    UPDATE_MODULOS_ASYNC,
    UPDATE_MODULOS,
    GET_FUNCIONALIDADES,
    DESTORY_MODULOS_ASYNC,
    DESTORY_MODULOS
} from '../actionTypes';
import { toast } from 'react-toastify';

function* getModulosSaga({ payload }) {
    try {
        const { fields } = payload;
        const data = yield call(apiGetModulos,fields);
        if (data.ok) {
            const { modulos } = data;
            yield put({ type: GET_MODULOS, payload: { modulos } });
        }
    } catch (error) {
        yield put({ type: GET_MODULOS, payload: {} });
    }
}
const apiGetModulos = async fields => {
    const { data } = await Api.get('/api/auth/modulos', { params: fields });

    return data;
}

function* listModulosSaga() {
    try {
        const data = yield call(apiListModulos);
        if (data.ok) {
            const { list } = data;
            yield put({ type: LIST_MODULOS, payload: { list } });
        }
    } catch (error) {
        yield put({ type: LIST_MODULOS, payload: {} });
    }
}
const apiListModulos = async () => {
    const { data } = await Api.get(`/api/auth/modulos/list`);

    return data;
}

function* storeModulosSaga({ payload }) {
    try {
        const { fields, onSave } = payload;
        const { data, errors } = yield call(apiStoreModulos, fields);
        if (data.ok) {
            const { modulos, funcionalidades } = data;
            yield put({ type: STORE_MODULOS, payload: { modulos } });
            yield put({ type: GET_FUNCIONALIDADES, payload: { funcionalidades } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            onSave()
        }
        if (!!errors) {
            yield put({ type: STORE_MODULOS, payload: { errors } });
        }
    } catch (error) {
        yield put({ type: STORE_MODULOS, payload: {} });
    }
}
const apiStoreModulos = async fields => {
    const data = await Api.post(`/api/auth/modulos/store`, { ...fields });

    return data;
}

function* updateModulosSaga({ payload }) {
    try {
        const { fields, onUpdate } = payload
        const { data, errors } = yield call(apiUpdateModulos, fields);
        if (data.ok) {
            const { modulos, funcionalidades } = data;
            yield put({ type: UPDATE_MODULOS, payload: { modulos } });
            yield put({ type: GET_FUNCIONALIDADES, payload: { funcionalidades } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            onUpdate();
        }
    } catch (error) {

    }
}
const apiUpdateModulos = async fields => {
    const id = fields.id;
    delete fields.id;
    const data = await Api.put(`/api/modulos/update/${id}`, { ...fields });

    return data;
}

function* destroyModulosSaga({ payload }) {
    try {
        const { fields, onDelete } = payload;
        const data = yield call(apiDestroyModulos, fields);
        if (data.ok) {
            const { modulos, funcionalidades } = data;
            yield put({ type: DESTORY_MODULOS, payload: { modulos } });
            yield put({ type: GET_FUNCIONALIDADES, payload: { funcionalidades } });
            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT,
            });
            onDelete();
        }
    } catch (error) {
        yield put({ type: DESTORY_MODULOS, payload: {} });
    }
}
const apiDestroyModulos = async fields => {
    const { data } = await Api.delete(`/api/auth/modulos/destroy`, { data: { ...fields } });

    return data;
}

function* watchGetModulos() {
    yield takeLatest(GET_MODULOS_ASYNC, getModulosSaga);
}
function* watchListModulos() {
    yield takeLatest(LIST_MODULOS_ASYNC, listModulosSaga);
}
function* watchStoreModulos() {
    yield takeLatest(STORE_MODULOS_ASYNC, storeModulosSaga);
}
function* watchUpdateModulos() {
    yield takeLatest(UPDATE_MODULOS_ASYNC, updateModulosSaga);
}
function* watchDestroyModulos() {
    yield takeLatest(DESTORY_MODULOS_ASYNC, destroyModulosSaga);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetModulos),
        fork(watchListModulos),
        fork(watchStoreModulos),
        fork(watchUpdateModulos),
        fork(watchDestroyModulos)
    ]);
}