import Profile from "../pages/profile";
import SolicitacoesUser from '../pages/solicitacoes';
import Users from '../pages/users';
import Blocos from '../pages/transacoes/blocos';
import Tipos from '../pages/transacoes/tipos';
import Transacoes from '../pages/transacoes/transacoes';
import Home from '../pages/home';
import Previsoes from '../pages/previsoes';
import Geral from '../pages/transacoes/geral';
import Projetado from '../pages/home/projetado'
import TransacoesGrupos from '../pages/consolidado/grupos'
import ContasGrupos from '../pages/contas/grupos'
import Contas from '../pages/contas/contas'
import Consolidado from '../pages/consolidado/relatorio'
import Transferencia from '../pages/contas/transferencia'
import Fornecedores from '../pages/fornecedores'
import Extrato from '../pages/contas/extrato'
import Mensagens from '../pages/mensagens'
import Conciliar from '../pages/conciliar';
import Pagar from "../pages/futuras/pagar";
import Report1 from "../pages/relatorios/geral";
import BankBalance from "../pages/bank_balance";
import ConsolidatedTabled from "../pages/relatorios/consolidatedTabled";
import UpdateReport from "../pages/relatorios/updateReport";
import GeneralBankStatement from "../pages/relatorios/generalBankStatement";
import QuarterlyConsolidated from "../pages/relatorios/quarterlyConsolidated";
import BankAccountFiles from '../pages/bankAccountFiles';
import CheckBalance from '../pages/check_balance';

export const routes = [
        { path: `${process.env.PUBLIC_URL}/home`, Component: Geral },
        { path: `${process.env.PUBLIC_URL}/profile`, Component: Profile },
        // { path: `${process.env.PUBLIC_URL}/solicitacoesUser`, Component: SolicitacoesUser },
        { path: `${process.env.PUBLIC_URL}/users`, Component: Users },
        { path: `${process.env.PUBLIC_URL}/blocos`, Component: Blocos },
        { path: `${process.env.PUBLIC_URL}/tipos`, Component: Tipos },
        { path: `${process.env.PUBLIC_URL}/transacoes/:tipo`, Component: Transacoes },
        // { path: `${process.env.PUBLIC_URL}/previsoes`, Component: Previsoes },
        { path: `${process.env.PUBLIC_URL}/transacoesGrupos`, Component: TransacoesGrupos },
        { path: `${process.env.PUBLIC_URL}/contasGrupos`, Component: ContasGrupos },
        { path: `${process.env.PUBLIC_URL}/contas/:tipo`, Component: Contas },
        { path: `${process.env.PUBLIC_URL}/consolidado/:tipo`, Component: Consolidado },
        { path: `${process.env.PUBLIC_URL}/transferencias`, Component: Transferencia },
        { path: `${process.env.PUBLIC_URL}/fornecedores`, Component: Fornecedores },
        { path: `${process.env.PUBLIC_URL}/extrato`, Component: Extrato },
        { path: `${process.env.PUBLIC_URL}/mensagens`, Component: Mensagens },
        { path: `${process.env.PUBLIC_URL}/conciliar`, Component: Conciliar },
        { path: `${process.env.PUBLIC_URL}/pagar/:tipo`, Component: Pagar },
        { path: `${process.env.PUBLIC_URL}/report1`, Component: Report1 },
        { path: `${process.env.PUBLIC_URL}/bank_balance`, Component: BankBalance },
        { path: `${process.env.PUBLIC_URL}/consolidated_tabled`, Component: ConsolidatedTabled },
        { path: `${process.env.PUBLIC_URL}/update_report`, Component: UpdateReport },
        { path: `${process.env.PUBLIC_URL}/generalBankStatement`, Component: GeneralBankStatement },
        { path: `${process.env.PUBLIC_URL}/quarterlyConsolidated`, Component: QuarterlyConsolidated },
        { path: `${process.env.PUBLIC_URL}/bankAccountFiles`, Component: BankAccountFiles },
        { path: `${process.env.PUBLIC_URL}/check_balance`, Component: CheckBalance },
        // { path: `${process.env.PUBLIC_URL}/geral`, Component: Geral },
        // { path: `${process.env.PUBLIC_URL}/projetado`, Component: Projetado },
];