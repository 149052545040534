import {
    GET_MENSAGENS,
    SET_MENSAGENS,
    ADD_MENSAGENS,
    EDIT_MENSAGENS,
    DELETE_MENSAGENS
} from '../actionTypes'

const INITIAL_STATE = {
    loadingMensagens: false,
    mensagens: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_MENSAGENS:
            return {
                ...state,
                loadingMensagens: true
            }

        case SET_MENSAGENS:
            return {
                ...state,
                loadingMensagens: false,
                mensagens: !!action.payload
                    ? action.payload
                    : state.mensagens
            }

        case ADD_MENSAGENS:
            return {
                ...state,
                loadingMensagens: true
            }

        case EDIT_MENSAGENS:
            return {
                ...state,
                loadingMensagens: true
            }

        case DELETE_MENSAGENS:
            return {
                ...state,
                loadingMensagens: true
            }

        default:
            return state
    }
}