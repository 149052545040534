import {
    LOGIN_USER_ASYNC,
    LOGOUT_USER_ASYNC,
    REGISTER_USER,
    REGISTER_USER_ASYNC,
    UPDATE_PROFILE,
    SET_UPDATE_PROFILE,
    CHANGE_PASSWORD
} from "../actionTypes";

export const loginUser = payload => ({
    type: LOGIN_USER_ASYNC,
    payload
});

export const logoutUser = () => ({
    type: LOGOUT_USER_ASYNC
});

export const registerUserAsync = payload => ({
    type: REGISTER_USER_ASYNC,
    payload
});

export const registerUser = payload => ({
    type: REGISTER_USER,
    payload
});

export const updateProfile = (payload, callback) => ({
    type: UPDATE_PROFILE,
    payload,
    callback
});

export const setUpdateProfile = payload => ({
    type: SET_UPDATE_PROFILE,
    payload
});

export const changePassword = payload => ({
    type: CHANGE_PASSWORD,
    payload
})

