import { all, call, put, takeEvery, fork, select } from "redux-saga/effects";

import fileDownload from "js-file-download";
import { toast } from "react-toastify";

import api from "../../services/api";

import {
  ADD_BANK_ACCOUNT_FILES,
  GET_BANK_ACCOUNT_FILES,
  DOWNLOAD_BANK_ACCOUNT_FILES,
} from "../actionTypes";

import { setBankAccountFiles, getBankAccountFiles } from "../actions";

function* sagaGetBankAccountFiles({ payload }) {
  try {
    const data = yield call(apiGetBankAccountFiles, payload);

    yield put(setBankAccountFiles({ files: data }));
  } catch (error) {
    yield put(setBankAccountFiles({}));
  }
}

const apiGetBankAccountFiles = async (params) => {
  const { data } = await api.get("/api/auth/bankStatementUpload/get", {
    params,
  });

  return data;
};

function* sagaDownloadBankAccountFiles({ payload }) {
  try {
    yield call(apiDownloadBankAccountFiles, payload);

    yield put(setBankAccountFiles({}));
  } catch (error) {
    yield put(setBankAccountFiles({}));
  }
}

const apiDownloadBankAccountFiles = async (path = "") => {
  const { data } = await api.get("/api/auth/bankStatementUpload/getFile", {
    params: { path },
    responseType: "blob",
  });

  const fileName = path.split("/")[1];

  fileDownload(data, fileName);
};

function* sagaAddBankAccountFile({ payload }) {
  try {
    yield call(apiAddBankAccountFile, payload);

    const { lastSearch } = yield select((state) => state.BankAccountFiles);

    yield put(getBankAccountFiles(lastSearch));
  } catch (error) {
    yield put(setBankAccountFiles({}));

    toast.error("Erro ao enviar o arquivo. Tente novamente.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

const apiAddBankAccountFile = async (payload) => {
  const formData = new FormData();

  for (let key in payload) {
    formData.append(key, payload[key]);
  }

  const { data } = await api.post(
    "/api/auth/bankStatementUpload/uploadFile",
    formData
  );

  return data;
};

function* watchGetBankAccountFiles() {
  yield takeEvery(GET_BANK_ACCOUNT_FILES, sagaGetBankAccountFiles);
}

function* watchDownloadBankAccountFiles() {
  yield takeEvery(DOWNLOAD_BANK_ACCOUNT_FILES, sagaDownloadBankAccountFiles);
}

function* watchAddBankAccountFile() {
  yield takeEvery(ADD_BANK_ACCOUNT_FILES, sagaAddBankAccountFile);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetBankAccountFiles),
    fork(watchDownloadBankAccountFiles),
    fork(watchAddBankAccountFile),
  ]);
}
