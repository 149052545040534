import React, { Fragment, useEffect, useState, useRef } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  FormGroup,
  Label,
  Button,
  Input,
} from "reactstrap";

import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import { Edit, List, Delete } from "react-feather";
import sweetalert2 from "sweetalert2";
import { useReactToPrint } from "react-to-print";

import Print from "../../components/Print";
import NotAuthorized from "../../components/NotAuthorized";
import Breadcrumb from "../../layout/breadcrumb";

import { useCashFlowTypeStore } from "../../utils/cashflowType";
import { useAccountStore } from "../../utils/accounts";

import Add from "./add";
import EditConta from "./edit";

import { getContas, getGruposConta, editConta } from "../../redux/actions";

const GruposConta = ({
  user,
  eventos,
  gruposContas,
  getContas,
  getGruposConta,
  editConta,
}) => {
  const { tipo } = useParams();

  const { tiposFluxo } = useCashFlowTypeStore();
  const { contas, loadingContas } = useAccountStore();

  const printRef = useRef();

  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(null);

  useEffect(() => {
    getContas();
    getGruposConta();
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Saldo de Contas`,
    onPrintError: () =>
      toast.error(
        "Ocorreu um erro ao tentar imprimir relatório",
        toast.POSITION.TOP_RIGHT
      ),
    pageStyle: `
            .noPrint {
                display:none;
            }
        `,
  });

  if (loadingContas)
    return (
      <Fragment>
        <Breadcrumb parent="Contas Bancárias" title="Contas" />
        <Container fluid={true}></Container>
        <div className="loader-box">
          <div className="loader-1"></div>
        </div>
      </Fragment>
    );

  const list = contas.filter(({ tipos_fluxos_id }) => tipos_fluxos_id == tipo);

  const saldoInicialTotal = list.reduce(
    (acum, { saldo_inicial }) => parseFloat(saldo_inicial) + acum,
    0
  );

  const saldoAtualTotal = list.reduce(
    (acum, { saldo_atual }) => parseFloat(saldo_atual) + acum,
    0
  );

  if (!tiposFluxo.find(({ id }) => id === Number(tipo)))
    return (
      <Fragment>
        <Breadcrumb parent="Contas Bancárias" title="Contas" />

        <NotAuthorized />
      </Fragment>
    );

  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumb parent="Contas Bancárias" title="Contas" />

        <Button
          color="primary"
          onClick={() => {
            setAdd(true);
          }}
        >
          Incluir
        </Button>
      </div>

      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Button
              color="primary"
              className="mt-5 float-right"
              onClick={handlePrint}
            >
              Imprimir
            </Button>
          </Col>

          <Col sm="12">
            <Print ref={printRef}>
              <Card className="font-arial-rlt">
                <CardBody>
                  <Col
                    sm="12"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <img
                      className="img-fluid for-light"
                      src={require("../../assets/images/logo/logo.jpg")}
                      alt=""
                      style={{
                        width: "130px",
                        height: "130px",
                        objectFit: "contain",
                      }}
                    />

                    <h5>{user?.cidade?.name}</h5>
                  </Col>

                  <Table bordered>
                    <thead>
                      <tr>
                        <th colSpan={3} style={{ textAlign: "center" }}>
                          {tiposFluxo.find(({ id }) => id == tipo)?.nome} -
                          Saldo de Contas
                        </th>
                      </tr>
                      <tr>
                        <th style={{ width: "60%" }}>Grupo</th>
                        <th style={{ width: "20%", textAlign: "center" }}>
                          Saldo Inicial
                        </th>
                        <th style={{ width: "20%", textAlign: "center" }}>
                          Saldo Atual
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {gruposContas.map(({ nome, id }) => {
                        const sumSaldoInicial = list
                          .filter(
                            ({ contas_grupos_id }) => contas_grupos_id == id
                          )
                          .reduce(
                            (acum, { saldo_inicial }) =>
                              acum + parseFloat(saldo_inicial),
                            0
                          );

                        const sumSaldoAtual = list
                          .filter(
                            ({ contas_grupos_id }) => contas_grupos_id == id
                          )
                          .reduce(
                            (acum, { saldo_atual }) =>
                              acum + parseFloat(saldo_atual),
                            0
                          );

                        return (
                          <tr key={id}>
                            <th style={{ width: "60%" }}>
                              Resumo Geral - Saldo Recurso {nome}
                            </th>

                            <td
                              style={{
                                width: "20%",
                                textAlign: "center",
                                color:
                                  sumSaldoInicial > 0
                                    ? "green"
                                    : sumSaldoInicial == 0
                                      ? "black"
                                      : "red",
                              }}
                            >
                              {new Intl.NumberFormat("pt-Br", {
                                currency: "BRL",
                                style: "currency",
                              }).format(sumSaldoInicial)}
                            </td>

                            <td
                              style={{
                                width: "20%",
                                textAlign: "center",
                                color:
                                  sumSaldoAtual > 0
                                    ? "green"
                                    : sumSaldoAtual == 0
                                      ? "black"
                                      : "red",
                              }}
                            >
                              {new Intl.NumberFormat("pt-Br", {
                                currency: "BRL",
                                style: "currency",
                              }).format(sumSaldoAtual)}
                            </td>
                          </tr>
                        );
                      })}

                      <tr>
                        <th style={{ width: "60%" }}>Saldo Atual</th>

                        <th
                          style={{
                            width: "20%",
                            textAlign: "center",
                            color:
                              saldoInicialTotal > 0
                                ? "green"
                                : saldoInicialTotal == 0
                                  ? "black"
                                  : "red",
                          }}
                        >
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(saldoInicialTotal)}
                        </th>

                        <th
                          style={{
                            width: "20%",
                            textAlign: "center",
                            color:
                              saldoAtualTotal > 0
                                ? "green"
                                : saldoAtualTotal == 0
                                  ? "black"
                                  : "red",
                          }}
                        >
                          {new Intl.NumberFormat("pt-Br", {
                            currency: "BRL",
                            style: "currency",
                          }).format(saldoAtualTotal)}
                        </th>
                      </tr>
                    </tbody>
                  </Table>

                  {gruposContas.map(({ nome, id }) => {
                    const listContas = list.filter(
                      ({ contas_grupos_id }) => contas_grupos_id == id
                    );

                    return (
                      <Table bordered style={{ marginTop: 25 }} key={id}>
                        <thead>
                          <tr>
                            <th colSpan={7} style={{ textAlign: "center" }}>
                              Recurso {nome}
                            </th>
                          </tr>

                          <tr>
                            <th>Última Atualização</th>
                            <th>Origem</th>
                            <th>Conta</th>
                            <th>Banco</th>
                            <th>Saldo Inicial</th>
                            <th>Saldo Atual</th>

                            {[1, 5].includes(user?.groups[0]?.id) && (
                              <th className="noPrint">Ações</th>
                            )}
                          </tr>
                        </thead>

                        <tbody>
                          {!listContas.length && (
                            <tr>
                              <td colSpan={7} style={{ textAlign: "center" }}>
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}

                          {listContas.map(
                            ({
                              id,
                              conta,
                              banco,
                              saldo_inicial,
                              saldo_atual,
                              updated_at,
                              origem,
                              data_inicial,
                              agencia,
                            }) => {
                              const origemObj = eventos.find(
                                ({ id }) => id == origem
                              );
                              return (
                                <tr key={id}>
                                  <td>
                                    {format(
                                      parseISO(updated_at),
                                      "dd/MM/yyyy 'às' HH:mm:ss"
                                    )}
                                  </td>

                                  <td>
                                    {origemObj?.nome || "Lançamento Inicial"}
                                  </td>
                                  <td>{conta}</td>
                                  <td>{banco}</td>

                                  <td
                                    style={{
                                      color:
                                        saldo_inicial > 0
                                          ? "green"
                                          : saldo_inicial == 0
                                            ? "black"
                                            : "red",
                                    }}
                                  >
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(saldo_inicial)}
                                  </td>

                                  <td
                                    style={{
                                      color:
                                        saldo_atual > 0
                                          ? "green"
                                          : saldo_atual == 0
                                            ? "black"
                                            : "red",
                                    }}
                                  >
                                    {new Intl.NumberFormat("pt-Br", {
                                      currency: "BRL",
                                      style: "currency",
                                    }).format(saldo_atual)}
                                  </td>

                                  {[1, 5].includes(user?.groups[0]?.id) && (
                                    <td className="noPrint">
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: 5,
                                        }}
                                        onClick={() => {
                                          setEdit({
                                            id,
                                            conta,
                                            banco,
                                            saldo_inicial,
                                            saldo_atual,
                                            updated_at,
                                            origem,
                                            data_inicial,
                                            agencia,
                                          });
                                        }}
                                      >
                                        <Edit size={18} />
                                      </span>

                                      <span
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: 5,
                                        }}
                                        onClick={async () => {
                                          const confirm =
                                            await sweetalert2.fire({
                                              title:
                                                "Deseja realmente excluir?",
                                              icon: "question",
                                              showCancelButton: true,
                                              confirmButtonText: "Sim",
                                              cancelButtonText: "Não",
                                              reverseButtons: true,
                                            });

                                          if (confirm.value) {
                                            editConta({
                                              id,
                                              conta,
                                              banco,
                                              saldo_inicial,
                                              saldo_atual,
                                              updated_at,
                                              origem,
                                              data_inicial,
                                              agencia,
                                              active: 0,
                                            });
                                          }
                                        }}
                                      >
                                        <Delete size={18} />
                                      </span>
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </Table>
                    );
                  })}
                </CardBody>
              </Card>
            </Print>
          </Col>
        </Row>
      </Container>

      <Add onDismiss={() => setAdd(false)} open={add} />

      <EditConta onDismiss={() => setEdit(null)} item={edit} />
    </Fragment>
  );
};

const mapStateToProps = ({ Contas, Transacoes, Auth }) => {
  const { contas, loadingContas, gruposContas } = Contas;
  const { tiposFluxo, eventos } = Transacoes;
  const { user } = Auth;

  return {
    contas,
    tiposFluxo,
    loadingContas,
    user,
    gruposContas,
    eventos,
  };
};

export default connect(mapStateToProps, {
  getContas,
  getGruposConta,
  editConta,
})(GruposConta);
