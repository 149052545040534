import {
    GET_SOLICITACOES_USER,
    SET_SOLICITACOES_USER,
    REMOVER_USER,
    GET_USERS,
    SET_USERS
} from '../actionTypes';

export const getSolicitacoesUser = () => ({
    type: GET_SOLICITACOES_USER
});

export const setSolicitacoesUser = payload => ({
    type: SET_SOLICITACOES_USER,
    payload
});

export const removerUser = payload => ({
    type: REMOVER_USER,
    payload
});

export const getUsers = () => ({
    type: GET_USERS
});

export const setUsers = payload => ({
    type: SET_USERS,
    payload
});