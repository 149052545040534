import React from 'react';
import { useState } from 'react';

import { Button } from 'reactstrap';

const Banner = () => {
  const [show, setShow] = useState(true);

  return !show ? null : (
    <div
      style={{
        backgroundColor: 'rgba(0,0,0,0.4)',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <a href='https://ibgr.com.br' rel="noopener noreferrer" target="_blank" style={{ height: '80%' }}>
        <img
          src={require("../../assets/images/banner_ibgr.jpg")}
          alt=""
          height="100%"
        />
      </a>

      <Button color='primary' className='mt-3' onClick={() => setShow(false)}>
        Fechar Anúncio
      </Button>
    </div>
  );
}

export default Banner;
