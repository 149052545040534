import { GET_TABLED_SHEET, SET_TABLED_SHEET } from '../actionTypes';

const INITIAL_STATE = {
  loadingTabledSheet: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TABLED_SHEET:
      return { ...state, loadingTabledSheet: true };

    case SET_TABLED_SHEET:
      return { ...state, loadingTabledSheet: false };

    default:
      return { ...state };
  }
}
