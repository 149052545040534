import { all, takeEvery, call, put, fork, select } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import {
    GET_FORNECEDORES,
    ADD_FORNECEDORES,
    EDIT_FORNECEDORES,
    DELETE_FORNECEDORES
} from '../actionTypes'

import { setFornecedores, getFornecedores } from '../actions'

import api from '../../services/api'

function* sagaGetFornecedores() {
    try {
        const data = yield call(apiGetFornecedores)

        yield put(setFornecedores(data))
    } catch (err) {
        yield put(setFornecedores())
    }
}

const apiGetFornecedores = async () => {
    const { data } = await api.get('/api/auth/fornecedores')

    return data
}

function* sagaAddFornecedor({ payload }) {
    const { fornecedores } = yield select(state => state.Fornecedores)

    try {
        const data = yield call(apiAddFornecedor, payload)

        if (data.ok) {
            yield put(
                setFornecedores([
                    ...fornecedores,
                    data.fornecedor
                ])
            )

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            yield put(setFornecedores())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    } catch (err) {
        yield put(setFornecedores())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

const apiAddFornecedor = async payload => {
    const { data } = await api.post('/api/auth/fornecedores/add', payload)

    return data
}

function* sagaEditFornecedor({ payload }) {
    try {
        const data = yield call(apiEditFornecedor, payload)

        if (data.ok) {
            yield put(getFornecedores())

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            yield put(setFornecedores())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    } catch (err) {
        yield put(setFornecedores())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

const apiEditFornecedor = async payload => {
    const { data } = await api.put(`/api/auth/fornecedores/edit/${payload.id}`, payload)

    return data
}

function* sagaDeleteFornecedor({ payload }) {
    try {
        const data = yield call(apiDeleteFornecedor, payload)

        if (data.ok) {
            yield put(getFornecedores())

            toast.success(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        } else {
            yield put(setFornecedores())

            toast.error(data.msg, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    } catch (err) {
        yield put(setFornecedores())

        toast.error('Ocorreu um erro', {
            position: toast.POSITION.TOP_RIGHT
        })
    }
}

const apiDeleteFornecedor = async payload => {
    const { data } = await api.delete(`/api/auth/fornecedores/delete/${payload}`)

    return data
}

function* watchGetFornecedores() {
    yield takeEvery(GET_FORNECEDORES, sagaGetFornecedores)
}

function* watchAddFornecedor() {
    yield takeEvery(ADD_FORNECEDORES, sagaAddFornecedor)
}

function* watchEditFornecedor() {
    yield takeEvery(EDIT_FORNECEDORES, sagaEditFornecedor)
}

function* watchDeleteFornecedor() {
    yield takeEvery(DELETE_FORNECEDORES, sagaDeleteFornecedor)
}

export default function* rootSaga() {
    yield all([
        fork(watchGetFornecedores),
        fork(watchAddFornecedor),
        fork(watchEditFornecedor),
        fork(watchDeleteFornecedor),
    ])
}