import { useSelector } from "react-redux";

import { getFornecedores } from "../redux/actions";

export function getProviders(dispatch = () => {}) {
  dispatch(getFornecedores());
}

export function useProvidersStore() {
  const { loadingFornecedores, fornecedores } = useSelector(
    (state) => state.Fornecedores
  );

  return {
    loadingFornecedores,
    fornecedores,
  };
}
