import {
  GET_BANK_STATEMENT_SHEET,
  SET_BANK_STATEMENT_SHEET,
} from "../actionTypes";

export const getBankStatementSheet = (payload) => ({
  type: GET_BANK_STATEMENT_SHEET,
  payload,
});

export const setBankStatementSheet = (payload) => ({
  type: SET_BANK_STATEMENT_SHEET,
  payload,
});

