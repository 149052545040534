import { format, parseISO } from 'date-fns';
import React, { Fragment } from 'react';
import { Table } from 'reactstrap';

const List = ({ updateReport = [] }) => {

  const listCidades = Array.from(
    new Set(
      updateReport
        .map(({ cidade_id, cidade }) => ({ id: cidade_id, name: cidade }))
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
        .map(JSON.stringify)
    )
  ).map(JSON.parse);

  return listCidades.map(({ id, name }) => (
    <Fragment key={id}>
      <h5 className='mt-5 text-center'>{name}</h5>
      <Table bordered className='mt-3'>
        <tbody>
          <tr>
            <th style={{ width: '40%' }}>Fluxo</th>
            <th style={{ width: '40%' }}>Responsável</th>
            <th style={{ width: '20%' }}>Atualizado em</th>
          </tr>

          {updateReport
            .filter(({ cidade_id }) => cidade_id === id)
            .map(({ last_update, user_email, fluxo }, key) => (
              <tr key={key}>
                <td>{fluxo}</td>
                <td>{user_email}</td>
                <td>{format(parseISO(last_update), 'dd/MM/yyyy HH:mm:ss')}</td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    </Fragment>
  ));
}

export default List;
