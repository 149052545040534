import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Table
} from 'reactstrap';

import DatePicker from "react-datepicker";
import { Typeahead } from 'react-bootstrap-typeahead';
import { format, parseISO } from 'date-fns';
import NumberFormat from 'react-number-format';
import { toast } from 'react-toastify';

import { Edit, Delete } from 'react-feather';
import sweetalert2 from 'sweetalert2';

import br from "date-fns/locale/pt-BR";

import { deleteTransacao } from '../../redux/actions';

const Efetuadas = ({
    open = null,
    onDismiss = () => { },
    onChosen = () => { },
    reload = () => { },
    deleteTransacao,
    contas,
    fornecedores,
    loadingTransacoes,
    loadingPrevisoes
}) => {

    return (
        <Modal
            size="lg"
            isOpen={!!open}
            toggle={onDismiss}
            backdrop="static"
        >
            <ModalHeader
                toggle={onDismiss}
            >
                {open?.evento?.nome}
            </ModalHeader>

            <ModalBody>
                {loadingPrevisoes || loadingTransacoes
                    ? (
                        <div className="loader-box">
                            <div className="loader-1"></div>
                        </div>
                    )
                    : (
                        <Row>
                            <Col sm="12">
                                <Table bordered style={{ marginTop: 20 }}>
                                    <thead>
                                        <tr>
                                            <th colSpan={6} style={{ textAlign: 'center' }}>
                                                Receitas
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>Conta</th>
                                            <th style={{ textAlign: 'center' }}>Data Realizado</th>
                                            <th style={{ textAlign: 'center' }}>Valor Realizado</th>
                                            <th>Fornecedor</th>
                                            <th>Ações</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {open?.transacoes?.map(
                                            (t, key) => (
                                                <tr key={key}>
                                                    <td>{contas.find(({ id }) => id == t.conta_id)?.conta || 'Não Infomado'}</td>
                                                    <td style={{ textAlign: 'center' }}>{format(parseISO(t.data), 'dd/MM/yyyy')}</td>
                                                    <td style={{ textAlign: 'center' }}>{new Intl.NumberFormat('pt-Br', { currency: 'BRL', style: 'currency' }).format(parseFloat(t.valor))}</td>
                                                    <td>{fornecedores.find(({ id }) => id == t.fornecedor_id)?.nome || 'Sem fornecedor'}</td>
                                                    <td className="noPrint" style={{ textAlign: 'center', width: '15%' }}>
                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginLeft: 5
                                                            }}
                                                            onClick={() => {
                                                                onChosen(t);
                                                            }}
                                                        >
                                                            <Edit
                                                                size={18}
                                                            />
                                                        </span>

                                                        <span
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginLeft: 5
                                                            }}
                                                            onClick={async () => {
                                                                const confirm = await sweetalert2.fire({
                                                                    title: 'Deseja realmente excluir?',
                                                                    icon: 'question',
                                                                    showCancelButton: true,
                                                                    confirmButtonText: 'Sim',
                                                                    cancelButtonText: 'Não',
                                                                    reverseButtons: true
                                                                });

                                                                if (confirm.value) {
                                                                    deleteTransacao(t, reload);

                                                                    if (open?.transacoes?.length == 1) {
                                                                        onDismiss();
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            <Delete
                                                                size={18}
                                                            />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    )
                }
            </ModalBody>

            <ModalFooter style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                    color="danger"
                    onClick={onDismiss}
                >
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = ({ Contas, Transacoes, Auth, Fornecedores, Consolidado, Previsoes }) => {
    const { loadingContas, gruposContas } = Contas;
    const {
        tiposFluxo,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes,
        dadosTransacoes,
        notAuthorized,
        previsto,
        previsoes,
        evento
    } = Transacoes;

    const { loadingFornecedores, fornecedores } = Fornecedores;
    const { user } = Auth;
    const { contas } = Contas;
    const { grupos } = Consolidado;
    const { loadingPrevisoes } = Previsoes;

    return {
        loadingContas,
        tiposFluxo,
        user,
        gruposContas,
        tiposTransacoes,
        blocos,
        eventos,
        transacoes,
        loadingTransacoes,
        dadosTransacoes,
        notAuthorized,
        previsto,
        previsoes,
        evento,
        fornecedores,
        loadingFornecedores,
        contas,
        grupos,
        loadingPrevisoes
    };
}

export default connect(mapStateToProps, {
    deleteTransacao
})(Efetuadas);