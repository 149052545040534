import { GET_TABLED_SHEET, SET_TABLED_SHEET } from '../actionTypes';

export const getTabledSheet = (payload) => ({
  type: GET_TABLED_SHEET,
  payload,
});

export const setTabledSheet = (payload) => ({
  type: SET_TABLED_SHEET,
  payload,
});
