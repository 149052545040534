import { format } from "date-fns";
import {
  GET_GENERAL_BANK_STATEMENT,
  SET_GENERAL_BANK_STATEMENT,
} from "../actionTypes";

const INITIAL_STATE = {
  loadingGeneralBankStatement: false,
  generalBankStatement: {
    transacoes: [],
    repasses: [],
    dataInicio: format(new Date(), "yyyy-MM-dd"),
    dataFim: format(new Date(), "yyyy-MM-dd"),
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_GENERAL_BANK_STATEMENT:
      return { ...state, loadingGeneralBankStatement: true };

    case SET_GENERAL_BANK_STATEMENT:
      return {
        ...state,
        loadingGeneralBankStatement: false,
        generalBankStatement: !!action.payload
          ? action.payload
          : state.generalBankStatement,
      };

    default:
      return state;
  }
};
