import { all, call, put, takeEvery, fork } from "redux-saga/effects";

import { setReportOne } from "../actions";
import { GET_REPORT_ONE } from "../actionTypes";

import { apiGetReportOne } from "./endpoints";

function* sagaGetReportOne({ payload }) {
  try {
    const data = yield call(apiGetReportOne, payload);

    yield put(setReportOne(data));
  } catch (err) {
    yield put(setReportOne());
  }
}

function* watchReportOne() {
  yield takeEvery(GET_REPORT_ONE, sagaGetReportOne);
}

export default function* rootSaga() {
  yield all([fork(watchReportOne)]);
}
